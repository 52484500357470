const sha256 = require("js-sha256");

export function AuthData() {
  let a = JSON.parse(localStorage.getItem("authsuadm"));
  return a;
}

export function AuthIsLogin(history) {
  let a = JSON.parse(localStorage.getItem("authsuadm"));
  if (!a) history.push(`/login`);
}

export function AuthLogin(auth) {
  auth.password = sha256(auth.password);
  localStorage.setItem("authsuadm", JSON.stringify(auth));
}

export function AuthLogout(history) {
  localStorage.removeItem("authsuadm");
  history.push(`/login`);
}
