import React, { useState, useEffect } from "react";
import { AuthIsLogin } from "../../helper/auth";
import { FaEdit, FaTimes } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import { VscPreview } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import FileUploader from "../../components/FileUploader";
import {
  Modal,
  Button,
  InputGroup,
  FormControl,
  Table,
  Card,
} from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompaniesData,
  deleteCompaniesData,
} from "../../redux/actions/CompaniesActions";
import CreateCompany from "./CreateCompany";
import Pagination from "../../components/Layout/Pagination";
// import ViewQuestion from "../../components/ViewQuestion";
// import EditQuestion from "../../components/EditQuestion";
import { Link } from "react-router-dom";
import "../../assets/css/Dashboard/quiz.css";
import {
  deleteCompaniesDataThunk,
  getCompaniesDataThunk,
} from "../../redux/thunks/CompaniesThunks";
const MySwal = withReactContent(Swal);

function TableCompany() {
  //pagination states
  const [active, setActive] = useState(1);
  const [size, setSize] = useState(10); //pagination size
  const [trigger, setTrigger] = useState(false);
  const [companiesPagination, setCompaniesPagination] = useState();

  //redux methods
  const dispatch = useDispatch();
  const history = useHistory();

  //modal states & functions
  const [pageOpen, setPageOpen] = useState("");
  const [companyData, setCompanyData] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (pageOpen === "Tambah" || pageOpen === "Edit")
      // dispatch(getCompaniesData({ param: `?size=${size}&page=${active}` }));
      dispatch(
        getCompaniesDataThunk({ param: `?size=${size}&page=${active}` })
      );
  };
  const handleShow = (page, value) => {
    setPageOpen(page);
    setCompanyData(value);
    setShow(true);
  };

  //redux taken from store
  const companies = useSelector((state) => state.companies);
  const companiesData = companies.companiesData;

  //functions
  useEffect(() => {
    AuthIsLogin(history);
    // dispatch(getCompaniesData({ param: `?size=${size}&page=${active}` }));
    dispatch(getCompaniesDataThunk({ param: `?size=${size}&page=${active}` }));
  }, []);

  function paginationSelect(newActive) {
    setActive(newActive);
    // dispatch(getCompaniesData({ param: `?size=${size}&page=${newActive}` }));
    dispatch(
      getCompaniesDataThunk({ param: `?size=${size}&page=${newActive}` })
    );
  }

  useEffect(() => {
    if (companies.companiesDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
    }
    if (companies.companiesDataDelete === "success" && trigger) {
      setTrigger(false);
      // dispatch(getCompaniesData({ param: `?size=${size}&page=${active}` }));
      dispatch(
        getCompaniesDataThunk({ param: `?size=${size}&page=${active}` })
      );
    }
  }, [companies]);

  useEffect(() => {
    setCompaniesPagination(companiesData.pagination);
  }, [companiesData]);

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        // dispatch(deleteCompaniesData(id));
        dispatch(deleteCompaniesDataThunk(id));
      }
    });
  };

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div>
          <div className="bank-soal__title mb-2">Perusahaan</div>{" "}
          <div className="fw-400 my-2">
            Total Perusahaan: {companiesData?.pagination?.totalItems}
          </div>
        </div>
        <Link to="/perusahaan/buat/" className="btn btn-primary py-2">
          + Tambah Perusahaan
        </Link>
      </div>

      <Card
        className="question-quest-wrapper-scrollbar mb-4"
        style={{ width: "100%" }}
      >
        {" "}
        <div className="d-flex layout-media">
          <InputGroup className="mb-3" style={{ width: "300px" }}>
            <FormControl placeholder="Cari" />
            {/* <Button className="bank-soal__primary" id="button-addon2">
            Cari
          </Button> */}
          </InputGroup>
          {companiesPagination ? (
            <Pagination
              size={size}
              totalRows={companiesPagination.totalItems}
              totalPages={companiesPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th className="w-50">Nama Perusahaan</th>
              <th>Jml Karyawan</th>
              <th>Company ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {companiesData.items
              ? companiesData.items.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td>{value.name}</td>
                      <td>{value.users_count}</td>
                      <td>{value.company_id}</td>
                      <td className="display-grid">
                        <Button
                          className="mb-2"
                          onClick={() => {
                            handleShow("Edit", value);
                          }}
                          variant="info"
                        >
                          <FaEdit className="fill-white" />
                        </Button>
                        <Button
                          className="mb-2"
                          variant="danger"
                          onClick={() => {
                            handleDelete(value.company_id);
                          }}
                        >
                          <RiDeleteBin2Line className="fill-white" />
                        </Button>
                        <Button
                          className="mb-2"
                          onClick={() => {
                            handleShow("Menampilkan", value);
                          }}
                        >
                          <VscPreview className="fill-white" />
                        </Button>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </Card>
      <Modal
        show={show}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ overflow: "auto", borderRadius: "24px" }}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="modal-header__title px-2"
          >
            {pageOpen} Perusahaan
          </Modal.Title>
          <div onClick={handleClose}>
            <FaTimes />
          </div>
        </Modal.Header>
        <Modal.Body className="px-0 py-1">
          <CreateCompany
            id={companyData.company_id}
            handleClose={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TableCompany;
