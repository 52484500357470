import React, { useState, useEffect } from "react";
import { AuthIsLogin } from "../../helper/auth";
import { FaEdit, FaTimes } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import { VscPreview } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import FileUploader from "../../components/FileUploader";
import {
  Modal,
  Button,
  InputGroup,
  FormControl,
  Table,
  Card,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesDataThunk } from "../../redux/thunks/CompaniesThunks";
import CreateAdmin from "./CreateAdmin";
import Pagination from "../../components/Layout/Pagination";
// import ViewQuestion from "../../components/ViewQuestion";
// import EditQuestion from "../../components/EditQuestion";
import { Link } from "react-router-dom";
import "../../assets/css/Dashboard/quiz.css";
import {
  deleteUsersDataThunk,
  getUsersDataThunk,
} from "../../redux/thunks/UsersThunks";
const MySwal = withReactContent(Swal);

function TableAdmin() {
  //pagination states
  const [active, setActive] = useState(1);
  const [dataCompany, setDataCompany] = useState("");
  const [size, setSize] = useState(10); //pagination size
  const [trigger, setTrigger] = useState(false);
  const [usersPagination, setUsersPagination] = useState();

  //redux methods
  const dispatch = useDispatch();
  const history = useHistory();

  //modal states & functions
  const [pageOpen, setPageOpen] = useState("");
  const [userData, setUserData] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (pageOpen === "Tambah" || pageOpen === "Edit") {
      // dispatch(getUsersData({ param: `?size=${size}&page=${active}` }));
      dispatch(getUsersDataThunk({ param: `?size=${size}&page=${active}` }));
    }
  };
  const handleShow = (page, value) => {
    setPageOpen(page);
    setUserData(value);
    setShow(true);
  };

  //redux taken from store
  const users = useSelector((state) => state.users);
  const usersData = users.usersData;
  const companies = useSelector((state) => state.companies);
  const companiesData = companies.companiesData.items;

  //functions
  useEffect(() => {
    dispatch(getCompaniesDataThunk());
  }, []);

  useEffect(() => {
    AuthIsLogin(history);
    // dispatch(getUsersData({ param: `?size=${size}&page=${active}` }));
    dispatch(
      getUsersDataThunk({ param: `?size=${size}&page=${active}&role=admin` })
    );
  }, []);

  function paginationSelect(newActive) {
    setActive(newActive);
    // dispatch(getUsersData({ param: `?size=${size}&page=${newActive}` }));
    dispatch(
      getUsersDataThunk({
        param: `?size=${size}&page=${newActive}&company_id=${dataCompany}`,
      })
    );
  }

  useEffect(() => {
    if (users.usersDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
    }
    if (users.usersDataDelete === "success" && trigger) {
      setTrigger(false);
      // dispatch(getUsersData({ param: `?size=${size}&page=${active}` }));
      dispatch(getUsersDataThunk({ param: `?size=${size}&page=${active}` }));
    }
  }, [users]);

  useEffect(() => {
    setUsersPagination(usersData.pagination);
  }, [usersData]);

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        // dispatch(deleteUsersData(id));
        dispatch(deleteUsersDataThunk(id));
      }
    });
  };

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }
  function dataCompanyHandler(e) {
    const newDataType = e.target.value;
    setDataCompany(newDataType);

    // if (newDataType === "learning_paths")
    //   dispatch(getLearningPathsData({ type: "filter", param: "" }));
  }

  useEffect(() => {
    dispatch(
      getUsersDataThunk({
        param: `?company_id=${dataCompany}&role=admin`,
      })
    );
  }, [dataCompany]);
  return (
    <>
      <Row className="mb-3 align-items-center">
        <Col lg={8}>
          <div className="bank-soal__title mb-2">Admin</div>{" "}
          <div className="fw-400 my-2">
            Total Admin: {usersData?.pagination?.totalItems}
          </div>
        </Col>
        <Col className="d-flex">
          <Form.Control
            className="py-0  border-search search-course__title"
            as="select"
            value={dataCompany}
            onChange={dataCompanyHandler}
            name="company_name"
            id="company_name"
          >
            <option hidden value="" key="blankChoice">
              Filter Perusahaan
            </option>
            {companiesData?.map((value, index) => {
              return (
                <option key={index} className="pl-1" value={value.company_id}>
                  {value.name}
                </option>
              );
            })}
          </Form.Control>
          <Link to="/admin/buat/" className="button_add btn btn-primary ">
            + Tambah Admin
          </Link>
        </Col>
      </Row>

      <Card
        className="question-quest-wrapper-scrollbar mb-4"
        style={{ width: "100%" }}
      >
        {" "}
        <div className="d-flex layout-media">
          <InputGroup className="mb-3" style={{ width: "300px" }}>
            <FormControl placeholder="Cari" />
            {/* <Button className="bank-soal__primary" id="button-addon2">
              Cari
            </Button> */}
          </InputGroup>
          {usersPagination ? (
            <Pagination
              size={size}
              totalRows={usersPagination.totalItems}
              totalPages={usersPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Perusahaan</th>
              <th>ID Company </th>
              <th>Nama Admin</th>
              <th>ID User </th>
              <th>Role</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {usersData.items
              ? usersData.items.map((value, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{value.company_name}</td>
                        <td>{value.company_id}</td>
                        <td>{value.name}</td>
                        <td>{value.user_id}</td>
                        <td>{value.role}</td>
                        <td>{value.email}</td>
                        <td className="display-grid">
                          <Button
                            className="mb-2"
                            onClick={() => {
                              handleShow("Edit", value);
                            }}
                            variant="info"
                          >
                            <FaEdit className="fill-white" />
                          </Button>
                          <Button
                            className="mb-2"
                            variant="danger"
                            onClick={() => {
                              handleDelete(value.user_id);
                            }}
                          >
                            <RiDeleteBin2Line className="fill-white" />
                          </Button>
                          <Button
                            className="mb-2"
                            onClick={() => {
                              handleShow("Menampilkan", value);
                            }}
                            variant="info"
                          >
                            <VscPreview className="fill-white" />
                          </Button>
                        </td>
                      </tr>
                    </>
                  );
                })
              : null}
          </tbody>
        </Table>
      </Card>
      <Modal
        show={show}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ overflow: "auto", borderRadius: "24px" }}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="modal-header__title px-2"
          >
            {pageOpen} Admin
          </Modal.Title>
          <div onClick={handleClose}>
            <FaTimes />
          </div>
        </Modal.Header>
        <Modal.Body className="px-0 py-1">
          <CreateAdmin id={userData.user_id} handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TableAdmin;
