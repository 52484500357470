export const ASSETS_DATA_LINK = "/assets/files";
//get
export const ASSETS_DATA = "ASSETS_DATA";
export const ASSETS_DATA_SUCCESS = "ASSETS_DATA_SUCCESS";
export const ASSETS_DATA_FAIL = "ASSETS_DATA_FAIL";
//post
export const ASSETS_DATA_POST = "ASSETS_DATA_POST";
export const ASSETS_DATA_POST_SUCCESS = "ASSETS_DATA_POST_SUCCESS";
export const ASSETS_DATA_POST_FAIL = "ASSETS_DATA_FAIL";
//delete
export const ASSETS_DATA_DELETE = "ASSETS_DATA_DELETE";
export const ASSETS_DATA_DELETE_SUCCESS = "ASSETS_DATA_DELETE_SUCCESS";
export const ASSETS_DATA_DELETE_FAIL = "ASSETS_DATA_DELETE_FAIL";
//remove
export const REMOVE_ASSETS_DATA_POST = "REMOVE_ASSETS_DATA_POST";
