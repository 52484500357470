import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const SkSwal = ({
    title,
    callback,
    showConfirm,
    confirmText,
    showCancel,
    cancelText
}
) => {
    MySwal.fire({
        title: title ?? "Anda yakin?",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showConfirmButton: showConfirm ?? false,
        confirmButtonText: confirmText ?? "Ya",
        showCancelButton: showCancel ?? false,
        cancelButtonText: cancelText ?? "Tidak",
    }).then((value) => {
        if (value.isConfirmed) {
            callback && callback()
        }
    });
}