export const LEARNING_PATH_DATA_LINK = "learning_paths"
export const LEARNING_PATH_DATA = "LEARNING_PATH_DATA";
export const LEARNING_PATH_DATA_GET_SUCCESS = "LEARNING_PATH_DATA_GET_SUCCESS";
export const LEARNING_PATH_DATA_GET_ID_SUCCESS = "LEARNING_PATH_DATA_GET_ID_SUCCESS";
export const LEARNING_PATH_DATA_FAIL = "LEARNING_PATH_DATA_FAIL";
export const LEARNING_PATH_DATA_POST = "LEARNING_PATH_DATA_POST";
export const LEARNING_PATH_DATA_POST_SUCCESS = "LEARNING_PATH_DATA_POST_SUCCESS";
export const LEARNING_PATH_DATA_PUT = "LEARNING_PATH_DATA_PUT";
export const LEARNING_PATH_DATA_PUT_SUCCESS = "LEARNING_PATH_DATA_PUT_SUCCESS";
export const LEARNING_PATH_DATA_DELETE = "LEARNING_PATH_DATA_DELETE";
export const LEARNING_PATH_DATA_DELETE_SUCCESS = "LEARNING_PATH_DATA_DELETE_SUCCESS";