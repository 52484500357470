import React, { useState, useEffect } from "react";
import Upload from "rc-upload";
import { postQuestionsDataThunk } from "../redux/thunks/QuestionsThunks";
import { getQuizThemeDataThunk } from "../redux/thunks/QuizThemeThunks";
import { getQuizThemeLiveSearchDataThunk } from "../redux/thunks/QuizThemeThunks";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Row, Col, Card } from "react-bootstrap";
import "../assets/css/Account/fileuploader.css";
import { Capitalize } from '../helper/functions'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { removeQuizThemeData } from "../redux/actions/QuizThemeActions";
function FileUploader() {
  const [fileInfo, setFileInfo] = useState();
  const [data, setData] = useState({ ...defaultData });
  const [trigger, setTrigger] = useState(false);
  const [allItems, setAllItems] = useState();
  const [bucket, setBucket] = useState("lessons");
  const [payload, setPayload] = useState({ url: "?bucket=lessons", body: "" });
  const [searchValue, setSearchValue] = useState();

  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const questions = useSelector((state) => state.questions);
  const quiz_themes = useSelector((state) => state.quiz_themes);
  const questionsDataPost = questions.questionsDataPost;
  const quizThemeLiveSearchData = quiz_themes.quizThemeLiveSearchData;
  const quizThemeData = quiz_themes.quizThemeData;
  const formData = new FormData();

  function handleUpload() {
    if (payload.body != "" && payload.url != "") {
      setTrigger(true);
      dispatch(postQuestionsDataThunk(payload));
    }
  }

  function removeCourseHandler() {
    setData({ theme_id: "", title: "" });
  }

  useEffect(() => {
    dispatch(getQuizThemeDataThunk());
  }, []);

  useEffect(() => {
    if (questionsDataPost && trigger) {
      setTrigger(false);
      if (questionsDataPost === "success") {
        displayPopup(false, "Berhasil Menambahkan Pertanyaan", "Oke");
      } else {
        displayPopup(false, "Gagal Menambahkan Pertanyaan", "Oke");
      }
    }
  }, [questionsDataPost]);
  const liveSearch = (e) => {
    setSearchValue(e.target.value);
  };
  function displayPopup(cancel, title, confirm, nextPage) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }

  const props = {
    action: (file) => {
      return new Promise((resolve) => {
        setFileInfo(file);
        let obj = payload;
        formData.append("upfile", file);
        obj.body = formData;
        setPayload(obj);
      });
    },
    multiple: false,
    onStart(file) {
      console.log("onStart", file.name);
    },
    onSuccess(ret) {
      console.log("onSuccess", ret);
    },
    onError(err) {
      console.log("onError", err);
    },
    beforeUpload(item, all) {
      setAllItems(all);
    },
  };
  const onKeyDownHanlder = (e) => {
    if (e.keyCode == 13) {
      if (searchValue)
        dispatch(
          getQuizThemeLiveSearchDataThunk(`?size=10&keyword=${searchValue}`)
        );
      else dispatch(removeQuizThemeData());
    }
  };
  useEffect(() => {
    console.log(payload);
  }, [payload]);
  function selectCourseHandler(value) {
    setData(value);
    let obj = payload;
    obj.url = `?theme_id=${value.theme_id}`;
    setPayload(obj);
    setBucket(value.theme_id);
    setSearchValue("");
  }
  // useEffect(() => {
  //   let newDefaultData = Clone(defaultData);
  //   if (defaultItems) {
  //     if (defaultItems.theme_id) {
  //       Object.keys(newDefaultData).forEach(function (key) {
  //         if (newDefaultData[key] == null || newDefaultData[key] == 0) {
  //           newDefaultData[key] = defaultItems[key];
  //         }
  //       });

  //       setData(newDefaultData);
  //     }
  //   }
  // }, [defaultItems]);
  return (
    <Row className="height-inherit justify-content-center align-content-center">
      <Col xs={12} sm={12} md={12} lg={12}>
        <Card className="card_container pt-3 pb-0 ">
          <div className="card_inside__padding">
            <div className="title_upload">Unggah Berkas Anda</div>
            <div className="subtitle_upload">Pilih File untuk di unggah</div>
            <Upload {...props} style={{ width: "100%" }} accept="csv">
              <Button
                variant="info"
                className="file-upload-btn py-3 mt-5 mb-4 btn-primary"
              >
                {fileInfo
                  ? allItems.length > 1
                    ? `${allItems.length} berkas terpilih`
                    : fileInfo.name
                  : "Pilih Berkas Kamu"}
              </Button>
            </Upload>
            <Form className="align-items-center justify-content-between">
              <Form.Label className="mb-2 label-font">Tema Soal</Form.Label>
              {data.title?.length > 0 ? (
                <div className="selected-item-wrapper">
                  <div onClick={() => removeCourseHandler()}>
                    <p className="selected-item">{data.title}</p>
                  </div>
                </div>
              ) : null}

              <Form.Control
                className="mb-4"
                required
                type="text"
                name="theme_quiz"
                id="theme_quiz"
                value={searchValue}
                onChange={liveSearch}
                onKeyDown={onKeyDownHanlder}
                autoComplete="off"
                autoCorrect="off"
                spellCheck="off"
                disabled={quiz_themes?.isLoadingLive}
              />
              {searchValue || quizThemeLiveSearchData.items ? (
                <div className="search-list">
                  {quiz_themes?.isLoadingLive ||
                  quizThemeLiveSearchData.length <= 0 ? (
                    <div>
                      <p>Loading...</p>
                    </div>
                  ) : (
                    <ul className="list-wrapper">
                      {quizThemeLiveSearchData.items?.length > 0 ? (
                        quizThemeLiveSearchData.items.map((value, index) => {
                          // const isExist = data.theme_id.some(
                          //   (option) => option.theme_id === value.theme_id
                          // );
                          return (
                            <li
                              className={`list-item`}
                              // "selected-list-item"
                              key={index}
                              onClick={() => selectCourseHandler(value)}
                            >
                              {value.title}
                            </li>
                          );
                        })
                      ) : (
                        <p>not found</p>
                      )}
                    </ul>
                  )}
                </div>
              ) : null}
              <div className="text-right">
                <Button
                  variant="success"
                  className="file-upload-btn my-1 px-1 py-3 w-25 "
                  onClick={handleUpload}
                >
                  Unggah
                </Button>
              </div>
            </Form>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
export default FileUploader;
const defaultData = {
  theme_id: "",
  title: "",
};
