export const COURSES_DATA_LINK = "courses";
export const COURSES_DATA = "COURSES_DATA";
export const COURSES_DATA_SUCCESS = "COURSES_DATA_SUCCESS";
export const COURSES_DATA_ID_SUCCESS = "COURSES_DATA_ID_SUCCESS";
export const COURSES_DATA_POST = "COURSES_DATA_POST";
export const COURSES_DATA_POST_SUCCESS = "COURSES_DATA_POST_SUCCESS";
export const COURSES_DATA_PUT = "COURSES_DATA_PUT";
export const COURSES_DATA_PUT_SUCCESS = "COURSES_DATA_PUT_SUCCESS";
export const COURSES_DATA_DELETE = "COURSES_DATA_DELETE";
export const COURSES_DATA_DELETE_SUCCESS = "COURSES_DATA_DELETE_SUCCESS";
export const COURSES_DATA_FAIL = "COURSES_DATA_FAIL";
export const COURSES_DATA_FAIL_PUT = "COURSES_DATA_FAIL_PUT";
