import {
  //get
  DELIVER_METHOD_DATA,
  DELIVER_METHOD_DATA_ID_SUCCESS,
  DELIVER_METHOD_DATA_SUCCESS,
  DELIVER_METHOD_DATA_FAIL,
  //post
  DELIVER_METHOD_DATA_POST,
  DELIVER_METHOD_DATA_POST_SUCCESS,
  DELIVER_METHOD_DATA_POST_FAIL,
  //put
  DELIVER_METHOD_DATA_PUT,
  DELIVER_METHOD_DATA_PUT_SUCCESS,
  DELIVER_METHOD_DATA_PUT_FAIL,
  //delete
  DELIVER_METHOD_DATA_DELETE,
  DELIVER_METHOD_DATA_DELETE_SUCCESS,
  DELIVER_METHOD_DATA_DELETE_FAIL,
  REMOVE_DELIVER_METHOD_DATA,
} from "../config/DeliveryMethodConfig";

const intialState = {
  deliveryMethodDataPost: null,
  deliveryMethodDataPut: null,
  deliveryMethodDataDelete: null,
  deliveryMethodData: null,
  deliveryMethodDataId: null,
  isLoading: false,
};

export const DeliveryMethodReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case DELIVER_METHOD_DATA:
      return { ...state, isLoading: true };
    case DELIVER_METHOD_DATA_SUCCESS:
      return { ...state, isLoading: false, deliveryMethodData: action.value };
    case DELIVER_METHOD_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, deliveryMethodDataId: action.value };
    case DELIVER_METHOD_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        deliveryMethodData: "error",
        deliveryMethodDataId: "error",
      };
    //post
    case DELIVER_METHOD_DATA_POST:
      return {
        ...state,
        isLoading: true,
        deliveryMethodDataPost: null,
        deliveryMethodDataPut: null,
        deliveryMethodDataDelete: null,
      };
    case DELIVER_METHOD_DATA_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deliveryMethodDataPost: action.value,
      };
    case DELIVER_METHOD_DATA_POST_FAIL:
      return { ...state, isLoading: false, deliveryMethodDataPost: "error" };

    //put
    case DELIVER_METHOD_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        deliveryMethodDataPost: null,
        deliveryMethodDataPut: null,
        deliveryMethodDataDelete: null,
      };
    case DELIVER_METHOD_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, deliveryMethodDataPut: "success" };
    case DELIVER_METHOD_DATA_PUT_FAIL:
      return { ...state, isLoading: false, deliveryMethodDataPut: "error" };

    //delete
    case DELIVER_METHOD_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        deliveryMethodDataPost: null,
        deliveryMethodDataPut: null,
        deliveryMethodDataDelete: null,
      };
    case DELIVER_METHOD_DATA_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deliveryMethodDataDelete: "success",
      };
    case DELIVER_METHOD_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, deliveryMethodDataDelete: "error" };
    case REMOVE_DELIVER_METHOD_DATA:
      return {
        ...state,
        deliveryMethodDataDelete: null,
      };

    default:
      return state;
  }
};
