import {
  // get
  MEMBERSHIPS_DATA,
  MEMBERSHIPS_DATA_SUCCESS,
  MEMBERSHIPS_DATA_ID_SUCCESS,
  // post
  MEMBERSHIPS_DATA_POST,
  MEMBERSHIPS_DATA_POST_SUCCESS,
  MEMBERSHIPS_DATA_POST_FAIL,
  // put
  MEMBERSHIPS_DATA_PUT,
  MEMBERSHIPS_DATA_PUT_SUCCESS,
  MEMBERSHIPS_DATA_PUT_FAIL,
  // delete
  MEMBERSHIPS_DATA_DELETE,
  MEMBERSHIPS_DATA_DELETE_SUCCESS,
  MEMBERSHIPS_DATA_DELETE_FAIL,
  // fail
  MEMBERSHIPS_DATA_FAIL,
  REMOVE_MEMBERSHIPS_DATA
} from "../config/MembershipsConfig";

// get
export const getMembershipsData = (value) => ({
  type: MEMBERSHIPS_DATA,
  value,
});
export const getMembershipsDataSuccess = (value) => ({
  type: MEMBERSHIPS_DATA_SUCCESS,
  value,
});
export const getMembershipsDataIdSuccess = (value) => ({
  type: MEMBERSHIPS_DATA_ID_SUCCESS,
  value,
});
export const getMembershipsDataFail = (value) => ({
  type: MEMBERSHIPS_DATA_FAIL,
  value,
});

// post
export const postMembershipsData = (value) => ({
  type: MEMBERSHIPS_DATA_POST,
  value,
});
export const postMembershipsDataSuccess = (value) => ({
  type: MEMBERSHIPS_DATA_POST_SUCCESS,
  value,
});
export const postMembershipsDataFail = (value) => ({
  type: MEMBERSHIPS_DATA_POST_FAIL,
  value,
});

// put
export const putMembershipsData = (value) => ({
  type: MEMBERSHIPS_DATA_PUT,
  value,
});
export const putMembershipsDataSuccess = (value) => ({
  type: MEMBERSHIPS_DATA_PUT_SUCCESS,
  value,
});
export const putMembershipsDataFail = (value) => ({
  type: MEMBERSHIPS_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteMembershipsData = (value) => ({
  type: MEMBERSHIPS_DATA_DELETE,
  value,
});
export const deleteMembershipsDataSuccess = (value) => ({
  type: MEMBERSHIPS_DATA_DELETE_SUCCESS,
  value,
});
export const deleteMembershipsDataFail = (value) => ({
  type: MEMBERSHIPS_DATA_DELETE_FAIL,
  value,
});


export const removeMembershipsData = (value) => ({
  type: REMOVE_MEMBERSHIPS_DATA,
  value,
});
// fail handle
