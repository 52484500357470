import React, { useEffect, useState } from "react";
import { Table, Form, Card, InputGroup, Button } from "react-bootstrap";
import Pagination from "../../components/Layout/Pagination";
import { DiplomaService } from "../../http/DiplomaHttp";
import SkModal from "../../components/common/SkModal";
import PDFLoader from "../../components/PDFLoader";
import { useDispatch } from "react-redux";
import {
  loadingStart,
  loadingFinish,
} from "../../redux/reducers/CommonReducers";

function ATPI() {
  const dispatch = useDispatch();
  const [usersPagination, setUsersPagination] = useState();
  const [size, setSize] = useState(12);
  const [active, setActive] = useState(1);
  const [rows, setRows] = useState();
  const [modalContent, setModalContent] = useState({
    title: "Modal Title",
    show: false,
    body: "",
  });

  /**
   * @function to get list ijazah
   * @param nothing
   * @returns nothing
   */
  useEffect(() => {
    getDiplomas("");
  }, []);

  /**
   * @function to set current active pagination
   * @param {number} newActive
   * @returns nothing
   */
  function paginationSelect(newActive) {
    setActive(newActive);
    getDiplomas(`?size=${size}&page=${newActive}`);
  }

  /**
   * @function to get ijazah from api
   * @param {string} querystring
   * @returns nothing
   */
  const getDiplomas = async (querystring) => {
    dispatch(loadingStart());
    try {
      const response = await DiplomaService.getDiplomas(querystring);
      const items = response.items;
      const pagination = response.pagination;
      setUsersPagination(pagination);
      setRows(items);
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  /**
   * @function to update ijazah
   * @param {string} type
   * @returns nothing
   */
  const handleAction = async (diploma_id, type) => {
    setModalContent({
      ...modalContent,
      show: false,
    });
    dispatch(loadingStart());
    try {
      const response = await DiplomaService.updateDiplomaById(diploma_id, type);
      getDiplomas("");
    } catch (error) {
      console.log(error);
      alert("An error occured.");
    }
    dispatch(loadingFinish());
  };

  /**
   * @function to display ijazah
   * @param {Object} item
   * @param {string} type
   * @returns nothing
   */
  const handleShow = (item, type) => {
    let body = (
      <div className="m-auto" style={{ width: "fit-content" }}>
        <p>Name: {item.name}</p>
        <p>
          Status:{" "}
          <strong>
            {type.toLowerCase() === "s1"
              ? item.verified_status_s1
              : item.verified_status_sma}
          </strong>
        </p>

        <div className="mb-2">
          {type.toLowerCase() === "s1" &&
            (item.url_s1.slice(-3) === "pdf" ? (
              <PDFLoader url={item.url_s1} />
            ) : (
              <img src={item.url_s1} style={{ maxWidth: "100%" }} />
            ))}
          {type.toLowerCase() === "sma" &&
            (item.url_sma.slice(-3) === "pdf" ? (
              <PDFLoader url={item.url_sma} />
            ) : (
              <img src={item.url_sma} style={{ maxWidth: "100%" }} />
            ))}
        </div>
        <div>Action:</div>
        <Button
          className="mr-2"
          variant="primary"
          onClick={() =>
            handleAction(
              type.toLowerCase() === "s1"
                ? item.diploma_id_s1
                : item.diploma_id_sma,
              "accepted"
            )
          }
        >
          Accept
        </Button>
        <Button
          className="mr-2"
          variant="danger"
          onClick={() =>
            handleAction(
              type.toLowerCase() === "s1"
                ? item.diploma_id_s1
                : item.diploma_id_sma,
              "rejected"
            )
          }
        >
          Reject
        </Button>
      </div>
    );
    setModalContent({
      ...modalContent,
      body: body,
      show: true,
      title: `Ijazah ${type.toLowerCase() === "s1" ? "S1" : "SMA"}`,
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="bank-soal__title mb-2">List Verifikasi Ijazah</div>
      </div>
      <Card className="card_inside__padding">
        <div className="d-flex layout-media">
          {/* <InputGroup className="mb-3" style={{ width: "300px" }}>
            <Form.Control
              className="py-0 style1-input border-search search-course__title"
              type="text"
              name="email"
              id="email"
              placeholder="Search email..."
              value={searchEmail}
              onChange={(e) => {
                setSearchEmail(e.target.value);
              }}
            ></Form.Control>
            <Button variant="primary">Search</Button>
          </InputGroup> */}
          {usersPagination && (
            <Pagination
              size={size}
              totalRows={usersPagination.totalItems}
              totalPages={usersPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          )}
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Email</th>
              <th>Nama</th>
              <th>Ijazah SMA</th>
              <th>Ijazah S1</th>
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows.map((item, index) => (
                <tr>
                  <td>{item.user_id}</td>
                  <td>{item.email}</td>
                  <td>{item.name}</td>
                  <td>
                    {/* belum upload */}
                    {!item.url_sma && "Empty"}

                    {/* menunggu verif */}
                    {item.verified_status_sma.toLowerCase() === "checking" && (
                      <div
                        className="btn btn-primary"
                        onClick={() => handleShow(item, "sma")}
                      >
                        Checking
                      </div>
                    )}

                    {/* gagal */}
                    {item.verified_status_sma.toLowerCase() === "rejected" && (
                      <div
                        className="btn btn-outline-danger"
                        onClick={() => handleShow(item, "sma")}
                      >
                        Rejected
                      </div>
                    )}

                    {/* sukses */}
                    {item.verified_status_sma.toLowerCase() === "accepted" && (
                      <div
                        className="btn btn-outline-primary"
                        onClick={() => handleShow(item, "sma")}
                      >
                        Accepted
                      </div>
                    )}
                  </td>

                  <td>
                    {/* belum upload */}
                    {!item.url_s1 && "Empty"}

                    {/* menunggu verif */}
                    {item.verified_status_s1.toLowerCase() === "checking" && (
                      <>
                        <span className="mr-2">Checking</span>
                        <Button
                          variant="info"
                          onClick={() => handleShow(item, "s1")}
                        >
                          Checking
                        </Button>
                      </>
                    )}

                    {/* gagal */}
                    {item.verified_status_s1.toLowerCase() === "rejected" && (
                      <div
                        className="btn btn-outline-danger"
                        onClick={() => handleShow(item, "s1")}
                      >
                        Rejected
                      </div>
                    )}

                    {/* sukses */}
                    {item.verified_status_s1.toLowerCase() === "accepted" && (
                      <div
                        className="btn btn-outline-primary"
                        onClick={() => handleShow(item, "s1")}
                      >
                        Accepted
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card>
      <SkModal content={modalContent} button={false} />
    </>
  );
}

export default ATPI;
