import {
  //get
  QUIZ_THEME_DATA,
  QUIZ_THEME_DATA_SUCCESS,
  QUIZ_THEME_DATA_FAIL,
  //post
  QUIZ_THEME_DATA_POST,
  QUIZ_THEME_DATA_POST_SUCCESS,
  QUIZ_THEME_DATA_POST_FAIL,
  //live search
  QUIZ_THEME_LIVE_SEARCH_DATA,
  QUIZ_THEME_LIVE_SEARCH_DATA_SUCCESS,
  QUIZ_THEME_LIVE_SEARCH_DATA_FAIL,

  //put
  QUIZ_THEME_DATA_PUT,
  QUIZ_THEME_DATA_PUT_SUCCESS,
  QUIZ_THEME_DATA_PUT_FAIL,
  //delete
  QUIZ_THEME_DATA_DELETE,
  QUIZ_THEME_DATA_DELETE_SUCCESS,
  QUIZ_THEME_DATA_DELETE_FAIL,
  //remove
  REMOVE_QUIZ_THEME_DATA_ID,
  REMOVE_QUIZ_THEME_DATA,
} from "../config/QuizThemeConfig";

//get
export const getQuizThemeData = (value) => ({
  type: QUIZ_THEME_DATA,
  value,
});
export const getQuizThemeDataSuccess = (value) => ({
  type: QUIZ_THEME_DATA_SUCCESS,
  value,
});
export const getQuizThemeDataFail = (value) => ({
  type: QUIZ_THEME_DATA_FAIL,
  value,
});

//post
export const postQuizThemeData = (value) => ({
  type: QUIZ_THEME_DATA_POST,
  value,
});
export const postQuizThemeDataSuccess = (value) => ({
  type: QUIZ_THEME_DATA_POST_SUCCESS,
  value,
});
export const postQuizThemeDataFail = (value) => ({
  type: QUIZ_THEME_DATA_POST_FAIL,
  value,
});

// put
export const putQuizThemeData = (value) => ({
  type: QUIZ_THEME_DATA_PUT,
  value,
});
export const putQuizThemeDataSuccess = (value) => ({
  type: QUIZ_THEME_DATA_PUT_SUCCESS,
  value,
});
export const putQuizThemeDataFail = (value) => ({
  type: QUIZ_THEME_DATA_PUT_FAIL,
  value,
});
// live search
export const getQuizThemeLiveSearchData = (value) => ({
  type: QUIZ_THEME_LIVE_SEARCH_DATA,
  value,
});
export const getQuizThemeLiveSearchDataSuccess = (value) => ({
  type: QUIZ_THEME_LIVE_SEARCH_DATA_SUCCESS,
  value,
});
export const getQuizThemeLiveSearchDataFail = (value) => ({
  type: QUIZ_THEME_LIVE_SEARCH_DATA_FAIL,
  value,
});
//delete
export const deleteQuizThemeData = (value) => ({
  type: QUIZ_THEME_DATA_DELETE,
  value,
});
export const deleteQuizThemeDataSuccess = (value) => ({
  type: QUIZ_THEME_DATA_DELETE_SUCCESS,
  value,
});
export const deleteQuizThemeDataFail = (value) => ({
  type: QUIZ_THEME_DATA_DELETE_FAIL,
  value,
});

// remove

export const removeQuizThemeDataId = (value) => ({
  type: REMOVE_QUIZ_THEME_DATA_ID,
  value,
});
export const removeQuizThemeData = (value) => ({
  type: REMOVE_QUIZ_THEME_DATA,
  value,
});
