import { PromiseService } from "../../services/PromiseService";
import { COURSE_TYPES_DATA_LINK } from "../config/CourseTypesConfig";
import {
  // get
  getCourseTypesData,
  getCourseTypesDataSuccess,
  getCourseTypesDataIdSuccess,
  getCourseTypesDataFail,
} from "../actions/CourseTypesActions";

// get
export const getCourseTypesDataThunk = (data) => async (dispatch) => {
  dispatch(getCourseTypesData(data));
  if (data) {
    try {
      const response = await PromiseService.getData(
        `${COURSE_TYPES_DATA_LINK}/${data}`
      );
      if (response.status === 200 && response.data) {
        dispatch(getCourseTypesDataIdSuccess(response.data));
      } else {
        dispatch(getCourseTypesDataFail(response));
      }
    } catch (error) {
      dispatch(getCourseTypesDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(COURSE_TYPES_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getCourseTypesDataSuccess(response.data));
      } else {
        dispatch(getCourseTypesDataFail(response));
      }
    } catch (error) {
      dispatch(getCourseTypesDataFail(error));
    }
  }
};
