export const LEARNING_LEVELS_DATA_LINK = "learning_levels";

//get
export const LEARNING_LEVELS_DATA = "LEARNING_LEVELS_DATA";
export const LEARNING_LEVELS_DATA_SUCCESS = "LEARNING_LEVELS_DATA_SUCCESS";
export const LEARNING_LEVELS_DATA_ID_SUCCESS = "LEARNING_LEVELS_DATA_ID_SUCCESS";
export const LEARNING_LEVELS_DATA_FAIL = "LEARNING_LEVELS_DATA_FAIL";

//post
export const LEARNING_LEVELS_DATA_POST = "LEARNING_LEVELS_DATA_POST";
export const LEARNING_LEVELS_DATA_POST_SUCCESS = "LEARNING_LEVELS_DATA_POST_SUCCESS";
export const LEARNING_LEVELS_DATA_POST_FAIL = "LEARNING_LEVELS_DATA_POST_FAIL";

// put
export const LEARNING_LEVELS_DATA_PUT = "LEARNING_LEVELS_DATA_PUT";
export const LEARNING_LEVELS_DATA_PUT_SUCCESS = "LEARNING_LEVELS_DATA_PUT_SUCCESS";
export const LEARNING_LEVELS_DATA_PUT_FAIL = "LEARNING_LEVELS_DATA_PUT_FAIL";

// delete
export const LEARNING_LEVELS_DATA_DELETE = "LEARNING_LEVELS_DATA_DELETE";
export const LEARNING_LEVELS_DATA_DELETE_SUCCESS = "LEARNING_LEVELS_DATA_DELETE_SUCCESS";
export const LEARNING_LEVELS_DATA_DELETE_FAIL = "LEARNING_LEVELS_DATA_DELETE_FAIL";
