import React, { useEffect, useState } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/Dashboard/index.css";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

import styled from "styled-components";
import { SELECT_DEFAULT_STYLES } from "../../helper/cssStyledComponent";
import { getDiscussTopicsDataThunk } from "../../redux/thunks/DiscussTopicsThunks";
import { SkValidate } from "../../components/common/SkValidate";
import { getLearningPathDataThunk } from "../../redux/thunks/LearningPathThunks";
import { postMembershipsDataThunk } from "../../redux/thunks/MembershipsThunks";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
import {
  CreateOption,
  CreateResult,
  CreateSelected,
} from "../../helper/functions";

const MySwal = withReactContent(Swal);
function CreateMembership() {
  let defaultData = {
    title: "",
    description: "",
    learning_path_ids: [],
    duration: "",
    price: "",
  };
  const learning_paths = useSelector((state) => state.learning_paths);
  const memberships = useSelector((state) => state.memberships);
  const [data, setData] = useState(defaultData);
  const [trigger, setTrigger] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setLoaded(true);
  }, [loaded]);

  useEffect(() => {
    dispatch(getLearningPathDataThunk());
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const newData = { ...data };
    newData[e.target.id] = e.target.value;

    setData(newData);
  };
  const handleChangeMultiSelect = (type, newValue) => {
    const newData = { ...data };
    newData[type] = newValue;
    setData(newData);
  };
  // const handleLearningPath = (e) => {
  //   e.preventDefault();
  //   const newData = { ...data };
  //   newData.learning_path_ids.push(e.target.value);
  //   setData(newData);
  // };

  useEffect(() => {}, [data]);

  const constraints = {
    title: {
      presence: true,
    },
    description: {
      presence: true,
    },
    duration: {
      presence: true,
    },
    price: {
      presence: true,
    },
    // learning_path_ids: {
    //   presence: true,
    // },
  };

  const handleSubmit = (e) => {
    // console.log("tes");
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      const payload = { ...data };
      payload.duration = parseInt(data.duration);
      payload.price = parseInt(data.price);
      payload.learning_path_ids = Object.values(data?.path_ids);
      setTrigger(true);
      dispatch(postMembershipsDataThunk(payload));
    }
  };

  function displayPopup(cancel, title, confirm, closeTrigger) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }

  useEffect(() => {
    if (memberships?.membershipsDataPost === "success") {
      setTrigger(false);
      displayPopup(false, "Berhasil Menambahkan Membership", "Oke");
      setData(defaultData);
      history.push("/dashboard/membership");
    }
  }, [memberships]);

  return (
    <StyledWrapperMain>
      <Container>
        <Row className="justify-content-center align-items-center mt-2 height-inherit">
          <Col xs={12} sm={12} md={9} lg={9}>
            <div className="">
              <div className="course_create__title">
                {" "}
                Buat Membership Katalog
              </div>
              <div className="course_create__subtitle"></div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={9} lg={9}>
            <StyledWrapperMain>
              <Card className="card_container mb-4">
                <div className="card_inside__padding ">
                  {loaded ? (
                    <Form onSubmit={handleSubmit}>
                      {/* Judul */}
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-600">Judul</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          id="title"
                          name="title"
                          placeholder="Masukkan Judul Membership"
                          value={data.title}
                          type="text"
                        />
                        <div className="messages"></div>
                      </Form.Group>

                      {/* Deskripsi */}
                      <Form.Group className="mb-4">
                        <Form.Label className="fw-600">Deskripsi</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          id="description"
                          name="description"
                          placeholder="Masukkan Deskripsi Memberhsip"
                          value={data.description}
                          as="textarea"
                          rows={3}
                        />
                        <div className="messages"></div>
                      </Form.Group>
                      {/* Durasi Membership */}
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-600">
                          Durasi Membership (hari)
                        </Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          id="duration"
                          name="duration"
                          placeholder="Masukkan Durasi Membership"
                          value={data.duration}
                          type="text"
                        />
                        <div className="messages"></div>
                      </Form.Group>
                      {/* Harga Membership */}
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-600">
                          Harga Membership
                        </Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          id="price"
                          name="price"
                          placeholder="Masukkan Harga Membership"
                          value={data.price}
                          type="text"
                        />
                        <div className="messages"></div>
                      </Form.Group>
                      {/* Learning Path */}
                      <Form.Group>
                        <Form.Label className="fw-600 title_label__input">
                          Learning Path
                        </Form.Label>
                        {learning_paths?.learningPathDataGet?.items?.length >
                        0 ? (
                          <DropdownMultiselect
                            options={CreateOption(
                              learning_paths?.learningPathDataGet?.items,
                              "path_ids",
                              "path_id",
                              "title"
                            )}
                            name="path_ids"
                            placeholder="Pilih Learning Path"
                            handleOnChange={(selected) => {
                              handleChangeMultiSelect(
                                "path_ids",
                                CreateResult(
                                  selected,
                                  "array",
                                  "learning_paths"
                                )
                              );
                            }}
                            selected={CreateSelected(
                              data.learning_path_ids,
                              "learning_paths"
                            )}
                          />
                        ) : null}

                        {/* <Form.Control
                          className="mb-3"
                          as="select"
                          id="learning_path_ids"
                          name="learning_path_ids"
                          placeholder="Masukan Learning Path"
                          value={data?.learning_path_ids}
                          onChange={handleLearningPath}
                          multiple
                        >
                          <option hidden>Pilih Learning Path</option>
                          {learning_paths?.learningPathDataGet?.items?.map(
                            (item, index) => {
                              return (
                                <option key={index} value={item.path_id}>
                                  {item.title}
                                </option>
                              );
                            }
                          )}
                        </Form.Control> */}

                        <div className="messages"></div>
                      </Form.Group>
                      <div className="d-flex mt-4 justify-content-end">
                        <Button type="submit" className="my-4 mx-2">
                          Tambahkan
                        </Button>
                      </div>
                    </Form>
                  ) : null}
                </div>
              </Card>
            </StyledWrapperMain>
          </Col>
        </Row>
      </Container>
    </StyledWrapperMain>
  );
}

export default CreateMembership;
const StyledWrapperMain = styled.section`
  ${SELECT_DEFAULT_STYLES}
`;
