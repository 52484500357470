import {
    // get
    ORDERS_DATA,
    ORDERS_DATA_ID_SUCCESS,
    ORDERS_DATA_SUCCESS,
    ORDERS_DATA_FAIL,
} from "../config/OrdersConfig";

const intialState = {
    ordersDataDelete: null,
    ordersData: null,
    ordersDataId: null,
    isLoading: false,
};

export const OrdersReducers = function (state = intialState, action) {
    switch (action.type) {
        //get
        case ORDERS_DATA:
            return { ...state, isLoading: true };
        case ORDERS_DATA_SUCCESS:
            return { ...state, isLoading: false, ordersData: action.value };
        case ORDERS_DATA_ID_SUCCESS:
            return { ...state, isLoading: false, ordersDataId: action.value };
        case ORDERS_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                ordersData: "error",
                ordersDataId: "error",
            };
        default:
            return state;
    }
}