import {
  //get
  MEMBERSHIPS_DATA,
  MEMBERSHIPS_DATA_ID_SUCCESS,
  MEMBERSHIPS_DATA_SUCCESS,
  MEMBERSHIPS_DATA_FAIL,
  //post
  MEMBERSHIPS_DATA_POST,
  MEMBERSHIPS_DATA_POST_SUCCESS,
  MEMBERSHIPS_DATA_POST_FAIL,
  //put
  MEMBERSHIPS_DATA_PUT,
  MEMBERSHIPS_DATA_PUT_SUCCESS,
  MEMBERSHIPS_DATA_PUT_FAIL,
  //delete
  MEMBERSHIPS_DATA_DELETE,
  MEMBERSHIPS_DATA_DELETE_SUCCESS,
  MEMBERSHIPS_DATA_DELETE_FAIL,
  REMOVE_MEMBERSHIPS_DATA,
} from "../config/MembershipsConfig";

const intialState = {
  membershipsDataPost: null,
  membershipsDataPut: null,
  membershipsDataDelete: null,
  membershipsData: null,
  membershipsDataId: null,
  isLoading: false,
};

export const MembershipsReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case MEMBERSHIPS_DATA:
      return { ...state, isLoading: true };
    case MEMBERSHIPS_DATA_SUCCESS:
      return { ...state, isLoading: false, membershipsData: action.value };
    case MEMBERSHIPS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, membershipsDataId: action.value };
    case MEMBERSHIPS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        membershipsData: "error",
        membershipsDataId: "error",
      };
    //post
    case MEMBERSHIPS_DATA_POST:
      return {
        ...state,
        isLoading: true,
        membershipsDataPost: null,
        membershipsDataPut: null,
        membershipsDataDelete: null,
      };
    case MEMBERSHIPS_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, membershipsDataPost: "success" };
    case MEMBERSHIPS_DATA_POST_FAIL:
      return { ...state, isLoading: false, membershipsDataPost: "error" };

    //put
    case MEMBERSHIPS_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        membershipsDataPost: null,
        membershipsDataPut: null,
        membershipsDataDelete: null,
      };
    case MEMBERSHIPS_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, membershipsDataPut: "success" };
    case MEMBERSHIPS_DATA_PUT_FAIL:
      return { ...state, isLoading: false, membershipsDataPut: "error" };

    //delete
    case MEMBERSHIPS_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        membershipsDataPost: null,
        membershipsDataPut: null,
        membershipsDataDelete: null,
      };
    case MEMBERSHIPS_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, membershipsDataDelete: "success" };
    case MEMBERSHIPS_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, membershipsDataDelete: "error" };
    case REMOVE_MEMBERSHIPS_DATA:
      return { ...state, isLoading: false, membershipsDataDelete: null };
    default:
      return state;
  }
};
