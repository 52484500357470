import { PromiseService } from "../../services/PromiseService";
import {
  USERS_DATA_LINK,
  USERS_CHANGE_PASSWORD_LINK,
} from "../config/UsersConfig";
import {
  //get
  getUsersData,
  getUsersDataSuccess,
  getUsersDataIdSuccess,
  getUsersDataIdOtherSuccess,
  getUsersDataFail,
  //post
  postUsersData,
  postUsersDataSuccess,
  postUsersDataFail,
  // put
  putUsersData,
  putUsersDataSuccess,
  putUsersDataFail,
  // delete
  deleteUsersData,
  deleteUsersDataSuccess,
  deleteUsersDataFail,
  // change password
  changePassword,
  changePasswordSuccess,
  changePasswordFail,
} from "../actions/UsersActions";

//get
export const getUsersDataThunk = (data) => async (dispatch) => {
  dispatch(getUsersData());
  if (data) {
    let url = `${USERS_DATA_LINK}/${data}`;
    if (data.param) url = `${USERS_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getUsersDataSuccess(response.data));
        else dispatch(getUsersDataIdSuccess(response.data));
      } else {
        dispatch(getUsersDataFail(response));
      }
    } catch (error) {
      dispatch(getUsersDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(USERS_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getUsersDataSuccess(response.data));
      } else {
        dispatch(getUsersDataFail(response));
      }
    } catch (error) {
      dispatch(getUsersDataFail(error));
    }
  }
};

export const getUsersIdDataThunk = (data) => async (dispatch) => {
  dispatch(getUsersData());
  if (data) {
    let url = `${USERS_DATA_LINK}/${data}`;
    if (data.param) url = `${USERS_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getUsersDataIdOtherSuccess(response.data));
        else dispatch(getUsersDataIdOtherSuccess(response.data));
      } else {
        dispatch(getUsersDataFail(response));
      }
    } catch (error) {
      dispatch(getUsersDataFail(error));
    }
  }
};

// post
export const postUsersDataThunk = (data) => async (dispatch) => {
  dispatch(postUsersData());
  const body = data;
  try {
    const response = await PromiseService.postData(USERS_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postUsersDataSuccess(response.data));
    } else {
      dispatch(postUsersDataFail(response));
    }
  } catch (error) {
    dispatch(postUsersDataFail(error));
  }
};

// put
export const putUsersDataThunk = (data) => async (dispatch) => {
  dispatch(putUsersData());
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${USERS_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putUsersDataSuccess(response.data));
    } else {
      dispatch(putUsersDataFail(response));
    }
  } catch (error) {
    dispatch(putUsersDataFail(error));
  }
};
// change password
export const changePasswordThunk = (data) => async (dispatch) => {
  dispatch(changePassword(data));
  try {
    const response = await PromiseService.postData(
      USERS_CHANGE_PASSWORD_LINK,
      data
    );
    if (response.status === 200 && response.data) {
      dispatch(changePasswordSuccess(response.data));
    } else {
      dispatch(changePasswordFail(response));
    }
  } catch (error) {
    dispatch(changePasswordFail(error));
  }
};
// delete
export const deleteUsersDataThunk = (data) => async (dispatch) => {
  dispatch(deleteUsersData());
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${USERS_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteUsersDataSuccess(response.data));
    } else {
      dispatch(deleteUsersDataFail(response));
    }
  } catch (error) {
    dispatch(deleteUsersDataFail(error));
  }
};
