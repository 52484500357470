export const PRODUCTS_DATA_LINK = "products";

//get
export const PRODUCTS_DATA = "PRODUCTS_DATA";
export const PRODUCTS_DATA_SUCCESS = "PRODUCTS_DATA_SUCCESS";
export const PRODUCTS_DATA_ID_SUCCESS = "PRODUCTS_DATA_ID_SUCCESS";
export const PRODUCTS_DATA_FAIL = "PRODUCTS_DATA_FAIL";

//post
export const PRODUCTS_DATA_POST = "PRODUCTS_DATA_POST";
export const PRODUCTS_DATA_POST_SUCCESS = "PRODUCTS_DATA_POST_SUCCESS";
export const PRODUCTS_DATA_POST_FAIL = "PRODUCTS_DATA_POST_FAIL";

// put
export const PRODUCTS_DATA_PUT = "PRODUCTS_DATA_PUT";
export const PRODUCTS_DATA_PUT_SUCCESS = "PRODUCTS_DATA_PUT_SUCCESS";
export const PRODUCTS_DATA_PUT_FAIL = "PRODUCTS_DATA_PUT_FAIL";

// delete
export const PRODUCTS_DATA_DELETE = "PRODUCTS_DATA_DELETE";
export const PRODUCTS_DATA_DELETE_SUCCESS = "PRODUCTS_DATA_DELETE_SUCCESS";
export const PRODUCTS_DATA_DELETE_FAIL = "PRODUCTS_DATA_DELETE_FAIL";

export const REMOVE_PRODUCTS_DATA = "REMOVE_PRODUCTS_DATA";
