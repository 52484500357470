import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import Template from "../components/Layout/Template";
import RouteData from './route-data';

export default function Routes() {
  return (
    <>
      <ScrollToTop />
      <Switch>
        {RouteData.map((d, i) => (
          <Route key={i} exact={d.exact} path={d.path}>
            {d.redirect ? (
              <Redirect to={d.redirect} />
            ) : (
              <Template
                isSidebar={d.isSidebar}
                isHeader={d.isHeader}
                InnerComponent={d.component}
              />
            )}
          </Route>
        ))}
      </Switch>
    </>
  );
}
