import React, { useEffect, useState } from "react";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import { Table, Form, Card, InputGroup, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaTimes } from "react-icons/fa";
import Pagination from "../../components/Layout/Pagination";
import {
  deleteMembershipsDataThunk,
  getMembershipsDataThunk,
  putMembershipsDataThunk,
} from "../../redux/thunks/MembershipsThunks";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { removeMembershipsData } from "../../redux/actions/MembershipsActions";
import { SkValidate } from "../../components/common/SkValidate";
import {
  deleteLearningPathDataThunk,
  getLearningPathDataThunk,
} from "../../redux/thunks/LearningPathThunks";
import { getLearningLevelsDataThunk } from "../../redux/thunks/LearningLevelsThunks";
import { getDeliveryMethodDataThunk } from "../../redux/thunks/DeliveryMethodThunks";
import { getDiscussTopicsDataThunk } from "../../redux/thunks/DiscussTopicsThunks";

const MySwal = withReactContent(Swal);
function TableLearningPath() {
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const [size, setSize] = useState(12); //pagination size
  const [dataMembership, setDataMembership] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState();
  const [membershipsPagination, setMembershipsPagination] = useState();
  const learning_paths = useSelector((state) => state.learning_paths);
  const learningPathDataGet = learning_paths?.learningPathDataGet;
  const delivery_method = useSelector((state) => state.delivery_method);
  const deliveryMethodDataGet = delivery_method?.deliveryMethodData;
  const discussion_topics = useSelector((state) => state.discussion_topics);
  const discussionTopicsData = discussion_topics?.discussTopicsData;
  // const learning_level = useSelector((state) => state.learning_level);
  // const learningLevelsData = discussion_topics?.discussTopicsData;
  const history = useHistory();
  useEffect(() => {
    dispatch(getLearningPathDataThunk());
  }, []);
  useEffect(() => {
    if (show) {
      dispatch(getDeliveryMethodDataThunk());
      dispatch(getDiscussTopicsDataThunk());
      dispatch(getLearningPathDataThunk());
    }
  }, [show]);
  const handleDelete = (e, value) => {
    e.preventDefault();
    displayPopup(
      true,
      "Apakah anda yakin akan menghapus item terpilih?",
      "Ya",
      value
    );
    setTrigger(true);
  };

  function displayPopup(cancel, title, confirm, valueDelete) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value.isConfirmed) {
        if (valueDelete !== undefined) {
          dispatch(deleteLearningPathDataThunk(valueDelete.path_id));
          dispatch(getLearningPathDataThunk());
        } else {
          dispatch(removeMembershipsData());
          dispatch(getLearningPathDataThunk());
        }
      }
    });
  }
  useEffect(() => {
    if (learning_paths?.learningPathDataDelete === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil Menghapus Learning Path", "Oke");
    }
    if (learning_paths?.learningPathDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Gagal Menghapus Learning Path", "Oke");
    }
    if (learning_paths?.learningPathDataPut === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil Mengedit Learning Path", "Oke");
      setShow(false);
    }
    if (learning_paths?.learningPathDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Gagal Mengedit Learning Path", "Oke");
      setShow(false);
    }
  }, [learning_paths]);

  useEffect(() => {
    setMembershipsPagination(learning_paths?.learningPathDataGet?.pagination);
  }, [learning_paths]);

  const handleAddLearning = () => {
    history.push("/learning-path/buat/");
  };
  const handleEdit = (value) => {
    setShow(true);
    setEdit(value);
  };
  const constraints = {
    title: {
      presence: true,
    },
    description: {
      presence: true,
    },
    duration: {
      presence: true,
    },
    price: {
      presence: true,
    },
  };
  const handleChange = (e) => {
    e.preventDefault();
    const newData = { ...edit };
    newData[e.target.id] = e.target.value;
    setEdit(newData);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let req = { body: edit, id: edit?.catalogue_id };
      setTrigger(true);
      dispatch(putMembershipsDataThunk(req));
    }
  };

  useEffect(() => {}, [edit]);
  function paginationSelect(newActive) {
    setActive(newActive);
    // dispatch(getUsersData({ param: `?size=${size}&page=${newActive}` }));
    dispatch(
      getLearningPathDataThunk({
        param: `?size=${size}&page=${newActive}`,
      })
    );
  }

  return (
    <div className="">
      <div className="d-flex justify-content-between">
        <div>
          <div className="bank-soal__title mb-2">List Learning Path</div>{" "}
          <div className="fw-400 my-2">
            Total Learning Path: {learningPathDataGet?.items?.length}
          </div>
        </div>
        <div>
          <Button onClick={handleAddLearning}>+ Tambah Learning Path</Button>
        </div>
      </div>

      <Card className="card_inside__padding">
        <div className="d-flex layout-media mb-3">
          <div></div>
          {membershipsPagination ? (
            <Pagination
              size={size}
              totalRows={membershipsPagination.totalItems}
              totalPages={membershipsPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <tr>
            <th id="first" rowSpan="2">
              Learning Path
            </th>
            <th rowSpan="2">Delivery Method</th>
            <th rowSpan="2">Topik Bahasan</th>
            <th rowSpan="2">Objektif</th>
            <th colSpan="3" className="text-center">
              Pathway
            </th>
            <th rowSpan="2">Produk</th>
            <th rowSpan="2">Skema</th>
            <th rowSpan="2">Summary</th>
            <th id="last" rowSpan="2">
              Action
            </th>
          </tr>
          <tr>
            <th>Tingkat Pembelajaran</th>
            <th>Posisi</th>
            <th className="text-center">Spesifikasi</th>
          </tr>
          {learningPathDataGet?.items?.length &&
            learningPathDataGet?.items?.map((item, index) => (
              <tr key={index}>
                <td>{item.title}</td>
                <td>
                  {item.delivery_methods &&
                    item.delivery_methods?.map((item, index) => {
                      return <div key={index}>{item.delivery_method}</div>;
                    })}
                </td>
                <td>
                  {item.discussion_topics &&
                    item.discussion_topics?.map((item, index) => {
                      return <div key={index}>{item.discussion_topic}</div>;
                    })}
                </td>
                <td>
                  {item.objectives &&
                    item.objectives?.map((item, index) => {
                      return <div key={index}>{item}</div>;
                    })}
                </td>
                <td>
                  {item.pathway_learning_levels &&
                    item.pathway_learning_levels?.map((item, index) => {
                      return <div key={index}>{item.learning_level}</div>;
                    })}{" "}
                </td>
                <td>
                  {item.pathway_positions &&
                    item.pathway_positions?.map((item, index) => {
                      return <div key={index}>{item.position}</div>;
                    })}
                </td>{" "}
                <td>
                  {item.pathway_specifications &&
                    item.pathway_specifications?.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            {" "}
                            <div key={index}>{item.sector}</div>
                          </td>
                          <td>
                            {" "}
                            <div key={index}>{item.industry}</div>
                          </td>
                          <td>
                            {" "}
                            <div key={index}>{item.sub_industry}</div>
                          </td>
                        </tr>
                      );
                    })}
                </td>
                <td>
                  {item.products &&
                    item.products?.map((item, index) => {
                      return <div key={index}>{item.product}</div>;
                    })}
                </td>
                <td>
                  {item.schemes &&
                    item.schemes?.map((item, index) => {
                      return <div key={index}>{item.scheme}</div>;
                    })}
                </td>
                <td>{item.summary}</td>
                <td>
                  {" "}
                  {/* <Button
                    onClick={() => handleEdit(item)}
                    className="mb-2 mr-2"
                    variant="info"
                  >
                    <FaEdit className="fill-white" />
                  </Button> */}
                  <Button
                    onClick={(e) => handleDelete(e, item)}
                    className="mb-2"
                    variant="danger"
                  >
                    <FaTimes className="fill-white" />
                  </Button>
                </td>
              </tr>
            ))}
        </Table>
      </Card>
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Learning Path</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* Judul */}
            <Form.Group className="mb-3">
              <Form.Label className="fw-600">Nama Learning Path</Form.Label>
              <Form.Control
                onChange={handleChange}
                id="title"
                name="title"
                placeholder="Masukkan Judul Membership"
                value={edit?.title}
                type="text"
              />
              <div className="messages"></div>
            </Form.Group>

            {/* Deskripsi */}
            <Form.Group className="mb-4">
              <Form.Label className="fw-600">Delivery Method</Form.Label>
              <Form.Control
                onChange={handleChange}
                id="delivery_methods"
                as="select"
                name="delivery_methods"
                value={edit?.delivery_methods}
              >
                <option hidden>Delivery Method</option>
                {deliveryMethodDataGet?.items?.map((item, index) => {
                  return (
                    <option key={index} value={item.delivery_method_id}>
                      {item.title}
                    </option>
                  );
                })}
              </Form.Control>
              <div className="messages"></div>
            </Form.Group>
            {/* Durasi Membership */}
            <Form.Group className="mb-3">
              <Form.Label className="fw-600">Topik Bahasan</Form.Label>
              <Form.Control
                onChange={handleChange}
                id="discussion_topics"
                name="discussion_topics"
                as="select"
              >
                <option hidden>Topik Bahasan</option>
                {discussionTopicsData?.items?.map((item, index) => {
                  return (
                    <option key={index} value={item.discussion_topic_id}>
                      {item.title}
                    </option>
                  );
                })}
              </Form.Control>
              <div className="messages"></div>
            </Form.Group>
            {/* Harga Membership */}
            <Form.Group className="mb-3">
              <Form.Label className="fw-600">Objektif</Form.Label>
              <Form.Control
                onChange={handleChange}
                id="objectives"
                name="objectives"
                placeholder="Masukkan Objective"
                value={edit?.objectives}
                type="text"
              />
              <div className="messages"></div>
            </Form.Group>
            {/* Pathway */}
            <Form.Group className="mb-4">
              <Form.Label className="fw-600">Pathway</Form.Label>

              <Form.Group className="mb-3">
                <Form.Label className="fw-600">Tingkat Pembelajaran</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  id="pathway_learning_levels"
                  name="pathway_learning_levels"
                  value={edit?.pathway_learning_levels}
                  as="select"
                >
                  <option hidden>Tingkat Pembelajaran</option>
                  {discussionTopicsData?.items?.map((item, index) => {
                    return (
                      <option key={index} value={item.discussion_topic_id}>
                        {item.title}
                      </option>
                    );
                  })}
                </Form.Control>
                <div className="messages"></div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="fw-600">Posisi</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  id="price"
                  name="price"
                  placeholder="Masukkan Harga Membership"
                  value={edit?.price}
                  type="text"
                />
                <div className="messages"></div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="fw-600">Spesifikasi</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  id="price"
                  name="price"
                  placeholder="Masukkan Harga Membership"
                  value={edit?.price}
                  type="text"
                />
                <div className="messages"></div>
              </Form.Group>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="fw-600">Produk</Form.Label>
              <Form.Control
                onChange={handleChange}
                id="price"
                name="price"
                placeholder="Masukkan Harga Membership"
                value={edit?.price}
                type="text"
              />
              <div className="messages"></div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="fw-600">Skema</Form.Label>
              <Form.Control
                onChange={handleChange}
                id="price"
                name="price"
                placeholder="Masukkan Harga Membership"
                value={edit?.price}
                type="text"
              />
              <div className="messages"></div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="fw-600">Summary</Form.Label>
              <Form.Control
                onChange={handleChange}
                id="price"
                name="price"
                placeholder="Masukkan Harga Membership"
                value={edit?.price}
                type="text"
              />
              <div className="messages"></div>
            </Form.Group>
            <div className="d-flex mt-4 justify-content-end">
              <Button type="submit" className="my-4 mx-2">
                Edit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TableLearningPath;
