import {
  //get
  DISCUSS_TOPICS_DATA,
  DISCUSS_TOPICS_DATA_ID_SUCCESS,
  DISCUSS_TOPICS_DATA_SUCCESS,
  DISCUSS_TOPICS_DATA_FAIL,
  //post
  DISCUSS_TOPICS_DATA_POST,
  DISCUSS_TOPICS_DATA_POST_SUCCESS,
  DISCUSS_TOPICS_DATA_POST_FAIL,
  //put
  DISCUSS_TOPICS_DATA_PUT,
  DISCUSS_TOPICS_DATA_PUT_SUCCESS,
  DISCUSS_TOPICS_DATA_PUT_FAIL,
  //delete
  DISCUSS_TOPICS_DATA_DELETE,
  DISCUSS_TOPICS_DATA_DELETE_SUCCESS,
  DISCUSS_TOPICS_DATA_DELETE_FAIL,
  REMOVE_TOPICS_DATA,
  // live
  DISCUSS_TOPICS_LIVE_SEARCH_DATA,
  DISCUSS_TOPICS_LIVE_SEARCH_DATA_SUCCESS,
  DISCUSS_TOPICS_LIVE_SEARCH_DATA_FAIL,
  REMOVE_DISCUSS_LIVE_TOPICS,
} from "../config/DiscussTopicsConfig";

const intialState = {
  discussTopicsDataPost: null,
  discussTopicsDataPut: null,
  discussTopicsDataDelete: null,
  discussTopicsLiveSearchData: null,
  discussTopicsData: null,
  discussTopicsDataId: null,
  isLoading: false,
  isLoadingLive: false,
};

export const DiscussTopicsReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case DISCUSS_TOPICS_DATA:
      return { ...state, isLoading: true };
    case DISCUSS_TOPICS_DATA_SUCCESS:
      return { ...state, isLoading: false, discussTopicsData: action.value };
    case DISCUSS_TOPICS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, discussTopicsDataId: action.value };
    case DISCUSS_TOPICS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        discussTopicsData: "error",
        discussTopicsDataId: "error",
      };
    //post
    case DISCUSS_TOPICS_DATA_POST:
      return {
        ...state,
        isLoading: true,
        discussTopicsDataPost: null,
        discussTopicsDataPut: null,
        discussTopicsDataDelete: null,
      };
    case DISCUSS_TOPICS_DATA_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        discussTopicsDataPost: action.value,
      };
    case DISCUSS_TOPICS_DATA_POST_FAIL:
      return { ...state, isLoading: false, discussTopicsDataPost: "error" };

    //put
    case DISCUSS_TOPICS_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        discussTopicsDataPost: null,
        discussTopicsDataPut: null,
        discussTopicsDataDelete: null,
      };
    case DISCUSS_TOPICS_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, discussTopicsDataPut: "success" };
    case DISCUSS_TOPICS_DATA_PUT_FAIL:
      return { ...state, isLoading: false, discussTopicsDataPut: "error" };

    //delete
    case DISCUSS_TOPICS_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        discussTopicsDataPost: null,
        discussTopicsDataPut: null,
        discussTopicsDataDelete: null,
      };
    case DISCUSS_TOPICS_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, discussTopicsDataDelete: "success" };
    case DISCUSS_TOPICS_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, discussTopicsDataDelete: "error" };
    case REMOVE_TOPICS_DATA:
      return { ...state, isLoading: false, discussTopicsDataDelete: null };
    case DISCUSS_TOPICS_LIVE_SEARCH_DATA:
      return {
        ...state,
        isLoadingLive: true,
        discussTopicsLiveSearchData: null,
      };
    case DISCUSS_TOPICS_LIVE_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        isLoadingLive: false,
        discussTopicsLiveSearchData: action.value,
      };
    case DISCUSS_TOPICS_LIVE_SEARCH_DATA_FAIL:
      return {
        ...state,
        isLoadingLive: false,
        discussTopicsLiveSearchData: "error",
      };
    case REMOVE_DISCUSS_LIVE_TOPICS:
      return {
        ...state,
        discussTopicsLiveSearchData: null,
      };
    default:
      return state;
  }
};
