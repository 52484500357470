import {
  // get
  DISCUSS_TOPICS_DATA,
  DISCUSS_TOPICS_DATA_SUCCESS,
  DISCUSS_TOPICS_DATA_ID_SUCCESS,
  // post
  DISCUSS_TOPICS_DATA_POST,
  DISCUSS_TOPICS_DATA_POST_SUCCESS,
  DISCUSS_TOPICS_DATA_POST_FAIL,
  // put
  DISCUSS_TOPICS_DATA_PUT,
  DISCUSS_TOPICS_DATA_PUT_SUCCESS,
  DISCUSS_TOPICS_DATA_PUT_FAIL,
  // delete
  DISCUSS_TOPICS_DATA_DELETE,
  DISCUSS_TOPICS_DATA_DELETE_SUCCESS,
  DISCUSS_TOPICS_DATA_DELETE_FAIL,
  // fail
  DISCUSS_TOPICS_DATA_FAIL,
  REMOVE_TOPICS_DATA,
  // live
  DISCUSS_TOPICS_LIVE_SEARCH_DATA,
  DISCUSS_TOPICS_LIVE_SEARCH_DATA_SUCCESS,
  DISCUSS_TOPICS_LIVE_SEARCH_DATA_FAIL,
  REMOVE_DISCUSS_LIVE_TOPICS,
} from "../config/DiscussTopicsConfig";

// get
export const getDiscussTopicsData = (value) => ({
  type: DISCUSS_TOPICS_DATA,
  value,
});
export const getDiscussTopicsDataSuccess = (value) => ({
  type: DISCUSS_TOPICS_DATA_SUCCESS,
  value,
});
export const getDiscussTopicsDataIdSuccess = (value) => ({
  type: DISCUSS_TOPICS_DATA_ID_SUCCESS,
  value,
});
export const getDiscussTopicsDataFail = (value) => ({
  type: DISCUSS_TOPICS_DATA_FAIL,
  value,
});

// post
export const postDiscussTopicsData = (value) => ({
  type: DISCUSS_TOPICS_DATA_POST,
  value,
});
export const postDiscussTopicsDataSuccess = (value) => ({
  type: DISCUSS_TOPICS_DATA_POST_SUCCESS,
  value,
});
export const postDiscussTopicsDataFail = (value) => ({
  type: DISCUSS_TOPICS_DATA_POST_FAIL,
  value,
});

// put
export const putDiscussTopicsData = (value) => ({
  type: DISCUSS_TOPICS_DATA_PUT,
  value,
});
export const putDiscussTopicsDataSuccess = (value) => ({
  type: DISCUSS_TOPICS_DATA_PUT_SUCCESS,
  value,
});
export const putDiscussTopicsDataFail = (value) => ({
  type: DISCUSS_TOPICS_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteDiscussTopicsData = (value) => ({
  type: DISCUSS_TOPICS_DATA_DELETE,
  value,
});
export const deleteDiscussTopicsDataSuccess = (value) => ({
  type: DISCUSS_TOPICS_DATA_DELETE_SUCCESS,
  value,
});
export const deleteDiscussTopicsDataFail = (value) => ({
  type: DISCUSS_TOPICS_DATA_DELETE_FAIL,
  value,
});
export const removeTopicsData = (value) => ({
  type: REMOVE_TOPICS_DATA,
  value,
});
// fail handle

// live search
export const liveSearchDiscussTopicsData = (value) => ({
  type: DISCUSS_TOPICS_LIVE_SEARCH_DATA,
  value,
});
export const liveSearchDiscussTopicsDataSuccess = (value) => ({
  type: DISCUSS_TOPICS_LIVE_SEARCH_DATA_SUCCESS,
  value,
});
export const liveSearchDiscussTopicsDataFail = (value) => ({
  type: DISCUSS_TOPICS_LIVE_SEARCH_DATA_FAIL,
  value,
});
export const removeDiscussLiveTopics = (value) => ({
  type: REMOVE_DISCUSS_LIVE_TOPICS,
  value,
});
