import {
  //get
  POSITIONS_DATA,
  POSITIONS_DATA_ID_SUCCESS,
  POSITIONS_DATA_SUCCESS,
  POSITIONS_DATA_FAIL,
  //post
  POSITIONS_DATA_POST,
  POSITIONS_DATA_POST_SUCCESS,
  POSITIONS_DATA_POST_FAIL,
  //put
  POSITIONS_DATA_PUT,
  POSITIONS_DATA_PUT_SUCCESS,
  POSITIONS_DATA_PUT_FAIL,
  //delete
  POSITIONS_DATA_DELETE,
  POSITIONS_DATA_DELETE_SUCCESS,
  POSITIONS_DATA_DELETE_FAIL,
} from "../config/PositionsConfig";

const intialState = {
  positionsDataPost: null,
  positionsDataPut: null,
  positionsDataDelete: null,
  positionsData: null,
  positionsDataId: null,
  isLoading: false,
};

export const PositionsReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case POSITIONS_DATA:
      return { ...state, isLoading: true };
    case POSITIONS_DATA_SUCCESS:
      return { ...state, isLoading: false, positionsData: action.value };
    case POSITIONS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, positionsDataId: action.value };
    case POSITIONS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        positionsData: "error",
        positionsDataId: "error",
      };
    //post
    case POSITIONS_DATA_POST:
      return {
        ...state,
        isLoading: true,
        positionsDataPost: null,
        positionsDataPut: null,
        positionsDataDelete: null,
      };
    case POSITIONS_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, positionsDataPost: action.value };
    case POSITIONS_DATA_POST_FAIL:
      return { ...state, isLoading: false, positionsDataPost: "error" };

    //put
    case POSITIONS_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        positionsDataPost: null,
        positionsDataPut: null,
        positionsDataDelete: null,
      };
    case POSITIONS_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, positionsDataPut: "success" };
    case POSITIONS_DATA_PUT_FAIL:
      return { ...state, isLoading: false, positionsDataPut: "error" };

    //delete
    case POSITIONS_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        positionsDataPost: null,
        positionsDataPut: null,
        positionsDataDelete: null,
      };
    case POSITIONS_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, positionsDataDelete: "success" };
    case POSITIONS_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, positionsDataDelete: "error" };

    default:
      return state;
  }
};
