import React, { useEffect, useState } from "react";
import { Table, Form, Card, Button, Modal } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import SkModal from "../../components/common/SkModal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/Layout/Pagination";
import {
  getLearningLevelsDataThunk,
  postLearningLevelsDataThunk,
  putLearningLevelsDataThunk,
  deleteLearningLevelsDataThunk,
} from "../../redux/thunks/LearningLevelsThunks";
import { SkValidate } from "../../components/common/SkValidate";
const MySwal = withReactContent(Swal);
function LearningLevels() {
  const history = useHistory();
  const dispatch = useDispatch();
  const learning_levels = useSelector((state) => state.learning_levels);
  const learningLevelsData = learning_levels.learningLevelsData?.items;
  const [show, setShow] = useState();
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState({
    title: "",
  });
  const [type, setType] = useState();
  const [edit, setEdit] = useState();

  const [usersPagination, setUsersPagination] = useState();
  const [size, setSize] = useState(10); //pagination size
  const [active, setActive] = useState(1);
  useEffect(() => {
    setUsersPagination(learning_levels?.learningLevelsData?.pagination);
  }, [learning_levels]);

  useEffect(() => {
    dispatch(getLearningLevelsDataThunk());
  }, []);

  useEffect(() => {}, [learning_levels]);

  const constraints = {
    title: {
      presence: true,
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    const isValid = SkValidate(form, constraints);

    if (isValid) {
      setTrigger(true);
      if (edit !== undefined) {
        const payload = { ...edit };
        const editData = payload.learning_level_id;
        let reqEdit = {};
        reqEdit.body = {
          title: payload.title,
        };
        reqEdit.id = editData;
        dispatch(putLearningLevelsDataThunk(reqEdit));
        setShow(false);
        dispatch(getLearningLevelsDataThunk());
      } else {
        let req = {
          title: data.title,
        };
        dispatch(postLearningLevelsDataThunk(req));
        setShow(false);
        dispatch(getLearningLevelsDataThunk());
        setData({ title: "" });
      }
    }
  };
  const handleChange = (e) => {
    if (edit !== undefined) {
      const newData = { ...edit };
      newData[e.target.id] = e.target.value;
      setEdit(newData);
    } else {
      const newData = { ...data };
      newData[e.target.id] = e.target.value;
      setData(newData);
    }
  };
  useEffect(() => {}, [data]);
  const handleModal = (type, id) => {
    setEdit(id);
    setType(type);
    setShow(true);
  };

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin <br/>ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        dispatch(deleteLearningLevelsDataThunk(id));
      }
    });
  };

  useEffect(() => {
    if (learning_levels.learningLevelsDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menambahkan data", "Ok");
    }
    // if (
    //   learning_levels.learningLevelsDataPost !== "error" &&
    //   learning_levels.learningLevelsDataPost !== null &&
    //   trigger
    // ) {
    //   setTrigger(false);
    //   displayPopup(false, "Berhasil menambahkan data", "Ok");
    // }

    if (learning_levels.learningLevelsDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat mengedit data", "Ok");
    }
    // if (learning_levels.learningLevelsDataPut === "success" && trigger) {
    //   setTrigger(false);
    //   displayPopup(false, "Berhasil mengedit data", "Ok");
    // }
    if (learning_levels.learningLevelsDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
    }
    if (learning_levels.learningLevelsDataDelete === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil menghapus data", "Ok");
    }
  }, [learning_levels]);

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value.isConfirmed) {
        dispatch(getLearningLevelsDataThunk());
      }
    });
  }

  function paginationSelect(newActive) {
    setActive(newActive);
    // dispatch(getUsersData({ param: `?size=${size}&page=${newActive}` }));
    dispatch(
      getLearningLevelsDataThunk({
        param: `?size=${size}&page=${newActive}`,
      })
    );
  }
  return (
    <div className="">
      <div className="d-flex justify-content-between">
        <div>
          <div className="bank-soal__title mb-2">List Tingkat Pembelajaran</div>{" "}
          <div className="fw-400 my-2">
            {/* Total Pengguna: {dataUserFull?.pagination?.totalItems} */}
          </div>
        </div>
        <div className="mb-3">
          <Button onClick={() => handleModal("Tambah")}>
            + Tambah Tingkat Pembelajaran
          </Button>
        </div>
      </div>

      <Card className="card_inside__padding">
        <div className="d-flex justify-content-end mb-3">
          {usersPagination ? (
            <Pagination
              size={size}
              totalRows={usersPagination.totalItems}
              totalPages={usersPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th className="w-50">Tingkat Pembelajaran</th>
              <th>ID Tingkat Pembelajaran</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {learningLevelsData &&
              learningLevelsData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.title}</td>
                    <td>{item.learning_level_id}</td>
                    <td>{moment(item.created_at).format("DD/MM/YYYY")}</td>
                    <td>
                      <div className="d-flex">
                        <Button
                          onClick={() => handleModal("Edit", item)}
                          className="mr-2 "
                          variant="success"
                        >
                          <FaEdit className="fill-white" />
                        </Button>
                        <Button
                          onClick={() => {
                            handleDelete(item.learning_level_id);
                          }}
                          variant="danger"
                        >
                          {" "}
                          <FaTrash className="fill-white" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{type} Tingkat Pembelajaran</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label className="title_label__input">
                    Nama Tingkat Pembelajaran
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    className="mb-3"
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Masukkan Tingkat Pembelajaran"
                    value={edit !== undefined ? edit.title : data.title}
                  />
                  <div className="messages"></div>
                </Form.Group>
                <div className="mt-4 text-right">
                  <Button type="submit" variant="primary">
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LearningLevels;
