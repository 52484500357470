export function CreateResult(data, type, id) {
  // function that use for multiselect result
  let result = [];
  for (let i = 0; i < data.length; i++) {
    if (type === "array") result.push(data[i]);
    else if (type === "json") result.push({ [`${id}_id`]: data[i] });
  }
  return result;
}

export function CreateSelected(data, id) {
  // function that use for multiselect selected
  let result = [];
  if (data) {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        result.push(data[i][`${id}_id`]);
      }
    }
  }
  return result;
}

export function CreateOption(items, type, id, label) {
  // function that use for multiselect option
  let object = [];
  for (let i = 0; i < items?.length; i++) {
    const cond = items[i]?.role === "instructor" ? true : false;
    const condition = type != "instructors" ? true : cond;
    if (condition) object.push({ key: items[i][id], label: items[i][label] });
  }
  return object;
}

export function CheckIfEmpty(data) {
  // check wheter object have empty key value
  for (var key in data) {
    var value = data[key];
    if (value == []) return true;
    if (value == [""]) return true;
    if (value == "") return true;
    if (value) {
      if (value.length <= 0) return true;
    } else return true;
  }
  return false;
}
export function Clone(obj) {
  //clone object without change the original object
  if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

export function Capitalize(s) {
  //used for capitalized first letter
  return s[0].toUpperCase() + s.slice(1);
}

export function ValidateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
