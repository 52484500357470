import React, { useEffect, useState } from "react";
import { Table, Form, Card, Button, Modal, Tabs, Tab } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import { getLearningLevelsDataThunk } from "../../redux/thunks/LearningLevelsThunks";
import {
  deleteSectorsDataThunk,
  getSectorsDataThunk,
  postSectorsDataThunk,
  putSectorsDataThunk,
} from "../../redux/thunks/SectorsThunks";
import {
  getIndustriesDataThunk,
  postIndustriesDataThunk,
} from "../../redux/thunks/IndustriesThunks";
import {
  getSubIndustriesDataThunk,
  postSubIndustriesDataThunk,
} from "../../redux/thunks/SubIndustriesThunks";
import { getPositionsDataThunk } from "../../redux/thunks/PositionsThunks";
import Pagination from "../../components/Layout/Pagination";
const MySwal = withReactContent(Swal);
function Pathway() {
  const [edit, setEdit] = useState();
  const [show, setShow] = useState(false);
  const [type, setType] = useState();
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState({
    sectors: "",
    industries: "",
    sub_industries: "",
  });
  const dispatch = useDispatch();
  const learning_levels = useSelector((state) => state.learning_levels);
  const learningLevelsData = learning_levels.learningLevelsData?.items;
  const positions = useSelector((state) => state.positions);
  const positionsData = positions.positionsData?.items;
  const industries = useSelector((state) => state.industries);
  const industriesData = industries.industriesData?.items;
  const sectors = useSelector((state) => state.sectors);
  const sectorsData = sectors.sectorsData?.items;
  const sub_industries = useSelector((state) => state.sub_industries);
  const subIndustriesData = sub_industries.subIndustriesData?.items;

  const [usersPagination, setUsersPagination] = useState();
  const [size, setSize] = useState(10); //pagination size
  const [active, setActive] = useState(1);
  const [key, setKey] = useState("tabs-tab-sectors");

  useEffect(() => {
    setUsersPagination(sectors?.sectorsData?.pagination);
  }, [sectors]);

  useEffect(() => {
    dispatch(getLearningLevelsDataThunk());
    dispatch(getSectorsDataThunk());
    dispatch(getIndustriesDataThunk());
    dispatch(getSubIndustriesDataThunk());
    dispatch(getPositionsDataThunk());
  }, []);

  const handleModal = (type, id) => {
    setEdit(id);
    setType(type);
    setShow(true);
  };

  useEffect(() => {}, [edit]);
  useEffect(() => {}, [data]);
  useEffect(() => {}, [key]);

  function paginationSelect(newActive) {
    setActive(newActive);
    // dispatch(getUsersData({ param: `?size=${size}&page=${newActive}` }));
    dispatch(
      getSectorsDataThunk({
        param: `?size=${size}&page=${newActive}`,
      })
    );
  }

  const handleChangeEdit = (e) => {};

  const handleChange = (e) => {
    if (edit !== undefined) {
      const newData = { ...edit };
      newData[e.target.id] = e.target.value;
      setEdit(newData);
    } else {
      const newData = { ...data };
      newData[e.target.id] = e.target.value;
      setData(newData);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (edit !== undefined) {
      const payload = { ...edit };
      const editData = payload.sector_id;
      let reqEdit = {};
      if (key === "tabs-tab-sectors") {
        reqEdit.body = {
          title: payload.title,
        };
        reqEdit.id = editData;
        dispatch(putSectorsDataThunk(reqEdit));
        setShow(false);
        dispatch(getSectorsDataThunk());
      }
      if (key === "tabs-tab-industries") {
        let req = {
          sector_id: edit.sector_id,
          title: edit.industry_title,
        };
        dispatch(postIndustriesDataThunk(req));
        setShow(false);
        dispatch(getIndustriesDataThunk());
      }
      if (key === "tabs-tab-sub_industries") {
        let req = {
          industry_id: edit.industry_id,
          title: edit.sub_industries_name,
        };
        dispatch(postSubIndustriesDataThunk(req));
        setShow(false);
        dispatch(getSubIndustriesDataThunk());
      }
    } else {
      if (key === "tabs-tab-sectors") {
        let req = {
          title: data.title,
        };
        dispatch(postSectorsDataThunk(req));
        setShow(false);
        dispatch(getSectorsDataThunk());
      }
    }
  };
  const handleChangeTabs = (e) => {
    setKey(e.target.id);
  };
  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        dispatch(deleteSectorsDataThunk(id));
      }
    });
  };

  useEffect(() => {
    if (sectors.sectorsDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
    }
    if (sectors.sectorsDataDelete === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil menghapus data", "Ok");
    }
  }, [sectors]);

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value.isConfirmed) {
        dispatch(getSectorsDataThunk());
      }
    });
  }

  return (
    <div className="">
      <div className="d-flex justify-content-between">
        <div>
          <div className="bank-soal__title mb-2">List Pathway</div>{" "}
          <div className="fw-400 my-2">
            {/* Total Pengguna: {dataUserFull?.pagination?.totalItems} */}
          </div>
        </div>
        <div className="mb-3">
          <Button onClick={() => handleModal("Tambah")}>
            + Tambah Pathway
          </Button>
        </div>
      </div>

      <Card className="card_inside__padding">
        <div className="d-flex justify-content-end mb-3">
          {usersPagination ? (
            <Pagination
              size={size}
              totalRows={usersPagination.totalItems}
              totalPages={usersPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Tingkat Pembelajaran </th>
              <th>Jabatan</th>
              <th>Sektor</th>
              <th>Industri</th>
              <th>Sub Industri</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {sectorsData &&
              sectorsData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>Tingkat Pembelajaran {index + 1}</td>
                    <td>Jabatan {index + 1}</td>
                    <td>{item.title}</td>
                    <td>
                      {industriesData &&
                        industriesData.map((e, i) => {
                          return e.sector_id === item.sector_id ? (
                            <div key={i}>{e.title}</div>
                          ) : null;
                        })}
                    </td>
                    <td>
                      {subIndustriesData &&
                        subIndustriesData.map((e, i) => {
                          return e.sector_id === item.sector_id ? (
                            <div key={i}>{e.title}</div>
                          ) : null;
                        })}
                    </td>
                    <td>
                      <div className="d-flex">
                        <Button
                          onClick={() => handleModal("Edit", item)}
                          className="mr-2 "
                          variant="success"
                        >
                          <FaEdit className="fill-white" />
                        </Button>
                        <Button
                          onClick={() => {
                            handleDelete(item.sector_id);
                          }}
                          variant="danger"
                        >
                          {" "}
                          <FaTrash className="fill-white" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{type} Produk</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <Tabs
                defaultActiveKey="sectors"
                id="tabs"
                className="mb-3"
                onClick={handleChangeTabs}
              >
                <Tab eventKey="sectors" title="Sektor">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label className="title_label__input">
                        Sektor
                      </Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        className="mb-3"
                        type="text"
                        id="title"
                        name="title"
                        value={edit !== undefined ? edit.title : data.title}
                        placeholder="Masukkan Nama Sektor"
                      />
                    </Form.Group>
                    <div className="mt-4 text-right">
                      <Button type="submit" variant="primary">
                        Save
                      </Button>
                    </div>
                  </Form>
                </Tab>
                <Tab eventKey="industries" title="Industri">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Control
                        className="mb-3"
                        as="select"
                        name="sector_id"
                        id="sector_id"
                        onChange={handleChange}
                        value={
                          edit !== undefined ? edit.sector_id : data.sector_id
                        }
                      >
                        <option hidden value="" key="blankChoice">
                          Pilih Sektor
                        </option>
                        {sectorsData &&
                          sectorsData.map((item, index) => {
                            return (
                              <option key={index} value={item.sector_id}>
                                {item.title}
                              </option>
                            );
                          })}
                      </Form.Control>
                      {industriesData &&
                        industriesData.map((item, index) => {
                          return item?.sector_id === edit?.sector_id ? (
                            <div key={index}>
                              <Form.Group>
                                <Form.Label className="title_label__input">
                                  Edit Kategori {index + 1}
                                </Form.Label>
                                <Form.Control
                                  onChange={handleChangeEdit}
                                  className="mb-2"
                                  type="text"
                                  id="industry_title"
                                  name="industry_title"
                                  value={item?.title}
                                  placeholder="Masukkan Kategori"
                                />
                              </Form.Group>
                              <div className="mt-3 text-right">
                                <Button type="submit" variant="primary">
                                  Edit
                                </Button>
                              </div>
                            </div>
                          ) : null;
                        })}
                      <Form.Label className="title_label__input">
                        Tambah Industri
                      </Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        className="mb-3"
                        type="text"
                        id="industry_title"
                        name="industry_title"
                        value={
                          edit !== undefined
                            ? edit.industry_title
                            : data.industry_title
                        }
                        placeholder="Masukkan Kategori"
                      />
                    </Form.Group>

                    <div className="mt-4 text-right">
                      <Button type="submit" variant="primary">
                        Save
                      </Button>
                    </div>
                  </Form>
                </Tab>
                <Tab eventKey="sub_industries" title="Sub Industri">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label className="title_label__input">
                        Industri
                      </Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        className="mb-1"
                        as="select"
                        id="industry_id"
                        name="industry_id"
                        placeholder="Pilih Industri"
                        value={
                          edit !== undefined
                            ? edit.industry_id
                            : data.industry_id
                        }
                      >
                        <option hidden value="" key="blankChoice">
                          Pilih Industri
                        </option>
                        {industriesData &&
                          industriesData.map((item, index) => {
                            return (
                              <option key={index} value={item.industry_id}>
                                {item.title}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="title_label__input">
                        Sub Industri
                      </Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        value={
                          edit !== undefined
                            ? edit.sub_industries_name
                            : data.sub_industries_name
                        }
                        className="mb-3"
                        type="text"
                        id="sub_industries_name"
                        name="sub_industries_name"
                        placeholder="Masukkan Sub Kategori"
                      />
                    </Form.Group>
                    <div className="mt-4 text-right">
                      <Button type="submit" variant="primary">
                        Save
                      </Button>
                    </div>
                  </Form>
                </Tab>
              </Tabs>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Pathway;
