import { satukelasApi } from "../constants";

export const UserService = {
  getById(id) {
    return satukelasApi.get(`users/${id}`);
  },
  checkByEmail(email) {
    return satukelasApi.get(`users/check/${email}`);
  },
  updatePassword(payload) {
    return satukelasApi.post(`v2/users/update_password`, payload);
  },
  register(payload) {
    return satukelasApi.post(`users`, payload);
  },
  putUserById(id, payload) {
    return satukelasApi.put(`users/${id}`, payload);
  }
};
