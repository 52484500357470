import {
  //get
  CATEGORIES_DATA,
  CATEGORIES_DATA_ID_SUCCESS,
  CATEGORIES_DATA_SUCCESS,
  CATEGORIES_DATA_FAIL,
  //post
  CATEGORIES_DATA_POST,
  CATEGORIES_DATA_POST_SUCCESS,
  CATEGORIES_DATA_POST_FAIL,
  //put
  CATEGORIES_DATA_PUT,
  CATEGORIES_DATA_PUT_SUCCESS,
  CATEGORIES_DATA_PUT_FAIL,
  //delete
  CATEGORIES_DATA_DELETE,
  CATEGORIES_DATA_DELETE_SUCCESS,
  CATEGORIES_DATA_DELETE_FAIL,
} from "../config/CategoriesConfig";

const intialState = {
  categoriesDataPost: null,
  categoriesDataPut: null,
  categoriesDataDelete: null,
  categoriesData: null,
  categoriesDataId: null,
  isLoading: false,
};

export const CategoriesReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case CATEGORIES_DATA:
      return { ...state, isLoading: true };
    case CATEGORIES_DATA_SUCCESS:
      return { ...state, isLoading: false, categoriesData: action.value };
    case CATEGORIES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, categoriesDataId: action.value };
    case CATEGORIES_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        categoriesData: "error",
        categoriesDataId: "error",
      };
    //post
    case CATEGORIES_DATA_POST:
      return {
        ...state,
        isLoading: true,
        categoriesDataPost: null,
        categoriesDataPut: null,
        categoriesDataDelete: null,
      };
    case CATEGORIES_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, categoriesDataPost: action.value };
    case CATEGORIES_DATA_POST_FAIL:
      return { ...state, isLoading: false, categoriesDataPost: "error" };

    //put
    case CATEGORIES_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        categoriesDataPost: null,
        categoriesDataPut: null,
        categoriesDataDelete: null,
      };
    case CATEGORIES_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, categoriesDataPut: "success" };
    case CATEGORIES_DATA_PUT_FAIL:
      return { ...state, isLoading: false, categoriesDataPut: "error" };

    //delete
    case CATEGORIES_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        categoriesDataPost: null,
        categoriesDataPut: null,
        categoriesDataDelete: null,
      };
    case CATEGORIES_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, categoriesDataDelete: "success" };
    case CATEGORIES_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, categoriesDataDelete: "error" };

    default:
      return state;
  }
};
