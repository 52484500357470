import React, { useEffect, useRef, useState } from "react";
import { UserService } from "../../http/UserHttp";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import { Button, Form } from "react-bootstrap";
import { VerificationService } from "../../http/VerificationHttp";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SkValidate } from "../../components/common/SkValidate";
const sha256 = require("js-sha256");
const MySwal = withReactContent(Swal);
function UserCheck() {
  const passwordRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [show, setShow] = useState(false);
  const [result, setResult] = useState("");
  const [email, setEmail] = useState("");
  const [isVerified, setIsVerified] = useState(true);
  const [dataPassword, setDataPassword] = useState({
    new_password: "",
  });

  const constraints = {
    new_password: {
      presence: true,
      length: {
        minimum: 6,
        maximum: 100,
      },
    },
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const form = e.target;
    let payload = { ...dataPassword };
    payload.new_password = sha256(dataPassword?.new_password);
    payload.user_id = result?.user_data?.user_id;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      dispatch(loadingStart());
      try {
        const response = await UserService.updatePassword(payload);
        if (response) {
          displayPopup(false, "Berhasil Mereset Password", "Oke");
        }
      } catch (error) {
        console.log(error);
        displayPopup(false, "Gagal Mereset Password", "Oke");
      }
      dispatch(loadingFinish());
    }
  };

  // const handleSubmitPassword = async (e) => {
  //   e.preventDefault();
  //   const form = e.target;

  //   let payload = { ...dataPassword };
  //   payload.password_old = sha256(dataPassword?.password_old);
  //   payload.password_new = sha256(dataPassword?.password_new);
  //   payload.password_new_re = sha256(dataPassword?.password_new_re);
  //   payload.user_id = result?.user_data?.user_id;
  //   const isValid = SkValidate(form, constraints);
  //   if (isValid) {
  //     dispatch(loadingStart());
  //     try {
  //       const response = await VerificationService.postChangePassword(payload);
  //       if (response) {
  //         displayPopup(false, "Berhasil Mengubah Password", "Oke");
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       displayPopup(false, "Gagal Mengubah Password", "Oke");
  //     }
  //     dispatch(loadingFinish());
  //   }
  // }

  /**
   * function to get user information
   * by using email
   * takes string as param
   * returns nothing
   */
  const getUserByEmail = async (userEmail) => {
    try {
      const response = await UserService.checkByEmail(userEmail);
      setStatus("success");
      setResult(response);
    } catch (error) {
      setResult(error?.data);
      setStatus("error");
    }
  };

  /**
   * function to handle form submission
   * takes object as param
   * returns nothing
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const email = form.email.value;
    getUserByEmail(email);
    setEmail(email);
    setStatus("loading");
    setResult("");
  };

  // DEBUG
  useEffect(() => {
    if (result) {
      let verified = result?.user_data?.verified;
      if (verified !== 1) {
        setIsVerified(false);
      }
    }
  }, [result]);

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value?.isConfirmed) {
        history.push("/dashboard/users/check");
      }
    });
  }

  const handleVerif = async () => {
    let id = result && result?.user_data?.user_id;
    let payload = {};
    payload.verified = 1;
    loadingStart();
    try {
      const response = await UserService.putUserById(id, payload);
      if (response) {
        let idUser = response.split(" ")[3];
        getUserByEmail(email);
        setStatus("loading");
        setResult("");
        setIsVerified(true);
        alert(`Success! verified user ${idUser}`);
      }
    } catch (error) {
      alert("Error! " + error?.data);
    }
    loadingFinish();
  };

  const handleChangePassword = (e) => {
    const newData = { ...dataPassword };
    newData[e.target.id] = e.target.value;
    setDataPassword(newData);
  };

  const handleShow = (value) => {
    setShow(!show);
    if (value === "show") {
      passwordRef.current.type = "text";
    } else {
      passwordRef.current.type = "password";
    }
  };

  return (
    <>
      <div className="bank-soal__title mb-2">Check User by Email</div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                className="form-control"
                placeholder="Enter email"
                required
              />
            </div>
            <div className="form-group">
              <input
                type={`submit`}
                value="Cek User"
                className="btn btn-primary"
              />
            </div>
          </form>
        </div>
      </div>
      <div>
        {status === "loading" ? <p>Checking user...</p> : ""}
        {result?.status === 404 && <div>Email Tidak Terdaftar</div>}
        {(result?.status === 200 || result?.status === 401) && (
          <>
            <div className="fw-600 mb-1">
              User ID:{" "}
              <span className="fw-400">{result?.user_data?.user_id}</span>
            </div>
            <div className="fw-600 mb-1">
              Email: <span className="fw-400">{email}</span>
            </div>
            <div className="fw-600 mb-1">
              Nama: <span className="fw-400">{result?.user_data?.name}</span>
            </div>

            <div className="fw-600 mb-1">
              Status Verifikasi:{" "}
              <span className="fw-400">
                {result?.user_data?.verified === 1
                  ? "Terverifikasi"
                  : "Belum Terverifikasi"}
                {isVerified ? null : (
                  <button
                    onClick={handleVerif}
                    className="btn btn-primary ml-3"
                  >
                    Verifikasi Sekarang
                  </button>
                )}
              </span>
            </div>

            {/* {isVerified ? null : */}
            <div></div>
            {/* } */}
            <Form onSubmit={handleResetPassword}>
              <Form.Group>
                <Form.Label className="fw-600">Password Baru</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    ref={passwordRef}
                    className="mb-3 field-password"
                    type="password"
                    id="new_password"
                    name="new_password"
                    onChange={handleChangePassword}
                  />
                  <div className="messages"></div>
                  <div className="button-password">
                    {show ? (
                      <Button onClick={() => handleShow("hide")}>Hide</Button>
                    ) : (
                      <Button onClick={() => handleShow("show")}>Show</Button>
                    )}
                  </div>
                </div>
                <div className="w-100 mt-4 text-right">
                  <Button type="submit">Submit Password Baru</Button>
                </div>
              </Form.Group>
            </Form>
            {/* <Form onSubmit={handleSubmitPassword}>
           <Form.Group>
             <Form.Label className="fw-600">Password Saat Ini</Form.Label>
             <Form.Control className="mb-3" type="password" id="password_old" name="password_old" onChange={handleChangePassword} />
             <div className="messages"></div>
           </Form.Group>
           <Form.Group>
             <Form.Label className="fw-600">Password Baru</Form.Label>
             <Form.Control className="mb-3" type="password" id="password_new" name="password_new" onChange={handleChangePassword} />
             <div className="messages"></div>

           </Form.Group>
           <Form.Group>
             <Form.Label className="fw-600">Konfirmasi Password Baru</Form.Label>
             <Form.Control className="mb-3" type="password" id="password_new_re" name="password_confirmation" onChange={handleChangePassword} />
             <div className="messages"></div>
           </Form.Group>
           <div className="w-100 mt-5 text-right">
             <Button type="submit">Submit</Button>
           </div>
         </Form> */}
          </>
        )}
      </div>
    </>
  );
}

export default UserCheck;
