import { LOGIN_DATA_LINK } from "../config/LoginConfig";
import {
  getLoginData,
  getLoginDataSuccess,
  getLoginDataFail,
} from "../actions/LoginActions";
import { PromiseService } from "../../services/PromiseService";

export const getLoginDataThunk = (data) => async (dispatch) => {
  dispatch(getLoginData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(LOGIN_DATA_LINK, body, true);
    if (response.status === 200 && response.data) {
      dispatch(getLoginDataSuccess(response.data));
    } else {
      dispatch(getLoginDataFail(response));
    }
  } catch (error) {
    dispatch(getLoginDataFail(error));
  }
};
