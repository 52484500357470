import React, { useEffect, useState } from "react";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import { Table, Form, Card, InputGroup, Button } from "react-bootstrap";
import { getUsersDataThunk, getUsersIdDataThunk, putUsersDataThunk } from "../../redux/thunks/UsersThunks";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit } from "react-icons/fa";
import Pagination from "../../components/Layout/Pagination";
import SkModal from "../../components/common/SkModal";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { loadingFinish, loadingStart } from "../../redux/reducers/CommonReducers";
import { VerificationService } from "../../http/VerificationHttp";
const MySwal = withReactContent(Swal);

function UserManagement() {
  const [modalContent, setModalContent] = useState({
    title: "Modal Title",
    show: false,
    body: "",
  });
  const defaultData = {
    name: "",
    email: "",
    company_id: "",
    role: "",
    user_id: ''
  };
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const [dataPassword, setDataPassword] = useState({
    password_old: "",
    password_new: "",
    password_new_re: ''
  });
  const [size, setSize] = useState(12); //pagination size
  const [dataUser, setDataUser] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState([]);
  const [id, setId] = useState();
  const [dataSelected, setDataSelected] = useState({ ...defaultData });
  const [usersPagination, setUsersPagination] = useState();
  const [dataRole, setDataRole] = useState("");
  const users = useSelector((state) => state.users);
  const dataUserFull = users.usersData;
  const defaultDetailData = users?.usersDataIdOther;
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  useEffect(() => {
    dispatch(getUsersDataThunk());
  }, []);
  useEffect(() => {
    const dataItems = dataUserFull.items;

    setDataUser(dataItems);
  }, [users]);
  function paginationSelect(newActive) {
    setActive(newActive);
    dispatch(
      getUsersDataThunk({
        param: `?size=${size}&page=${newActive}&role=${dataRole}`,
      })
    );
  }
  useEffect(() => {
    setUsersPagination(dataUserFull.pagination);
  }, [users]);
  const dataRoleHandler = (e) => {
    const newDataType = e.target.value;
    setDataRole(newDataType);
  };
  useEffect(() => {
    if (dataRole !== "") {
      dispatch(
        getUsersDataThunk({
          param: `?role=${dataRole}`,
        })
      );
    } else {
      dispatch(
        getUsersDataThunk()
      );
    }
  }, [dataRole]);

  useEffect(() => {
    let newDefaultData = { ...defaultData };
    if (defaultDetailData) {
      if (defaultDetailData.email) {
        Object.keys(newDefaultData).forEach(function (key) {
          if (newDefaultData[key] == null || newDefaultData[key] == 0) {
            newDefaultData[key] = defaultDetailData[key];
          }
        });
        setDataSelected(newDefaultData);
      }
    }
  }, [defaultDetailData]);

  useEffect(() => {

    if (trigger && users.usersDataPut === "success") {
      setTrigger(false);
      displayPopup(false, "Berhasil Mengubah data Admin", "Oke");
    }
    if (trigger && users.usersDataPut === "error") {
      setTrigger(false);
      displayPopup(
        false,
        "Gagal Mengubah data Admin. Silahkan coba lagi",
        "Oke"
      );
    }
  }, [users])

  useEffect(() => {
    setModalContent({
      ...modalContent,
      body: dataSelected ? (
        <>
          <Form onSubmit={handleSubmitEdit}>
            <Form.Group>
              <Form.Label className="fw-600">Nama</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control type="text" id="name" name="name" value={dataSelected?.name} onChange={handleEdit} />
              </Form.Group>
            </Form.Group>
            <Form.Group>
              <Form.Label className="fw-600">Email</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control type="text" id="email" name="email" value={dataSelected?.email} onChange={handleEdit} />
              </Form.Group>
            </Form.Group>
            <Form.Group>
              <Form.Label className="fw-600">Company ID</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control type="text" id="company_id" name="company_id" value={dataSelected?.company_id} onChange={handleEdit} />
              </Form.Group>
            </Form.Group>
            <Form.Group>
              <Form.Label className="fw-600">Role</Form.Label>
              <Form.Group className="mb-3">
                <select
                  className="form-control"
                  name="role"
                  id="role"
                  onChange={handleEdit}
                  value={dataSelected?.role}
                  disabled={dataSelected?.role === 'student' || dataSelected?.role === 'instructor' ? false : true}
                >
                  <option value={`student`}>Student</option>
                  <option value={`instructor`}>Instructor</option>
                </select>
              </Form.Group>
            </Form.Group>
            <div className="w-100 mt-5 text-right">
              <Button type="submit">Submit</Button>
            </div>
          </Form>
        </>
      ) : null
    })
  }, [dataSelected])

  const handleEdit = (e) => {
    const newData = { ...dataSelected };
    newData[e.target.id] = e.target.value;
    setDataSelected(newData)
  }

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (trigger && users.usersDataPost === "success") {
        history.push("/dashboard/user-management/");
      }
      if (trigger && users.usersDataPut === "success") setModalContent({ ...modalContent, show: false });
    });
  }

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    const newPayload = {
      body: {
        email: dataSelected.email,
        name: dataSelected.name,
        role: dataSelected.role,
        company_id: dataSelected.company_id,
      },
      id: dataSelected?.user_id,
    };
    setTrigger(true);
    dispatch(putUsersDataThunk(newPayload));
  }

  const handleEditData = (e, data) => {
    e.preventDefault();
    const dataDetail = {
      name: data?.name,
      email: data?.email,
      company_id: data?.company_id,
      role: data?.role
    }
    // console.log(data);
    dispatch(getUsersIdDataThunk(data?.user_id));
    setData(dataDetail);
    setModalContent({
      ...modalContent,
      show: true,
      title: "Edit User",
    });
  }

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    let payload = { ...dataPassword };
    payload.user_id = id;
    dispatch(loadingStart());
    try {
      const response = await VerificationService.postChangePassword(payload);
      if (response) {
        displayPopup(false, "Berhasil Mengubah Password", "Oke");
      }
    } catch (error) {
      console.log(error);
      displayPopup(false, "Gagal Mengubah Password", "Oke");
    }
    dispatch(loadingFinish());
  }

  return (
    <div className="">
      <div className="d-flex justify-content-between flex-wrap">
        <div>
          <div className="bank-soal__title mb-2">List Pengguna</div>{" "}
          <div className="fw-400 my-2">
            Total Pengguna: {dataUserFull?.pagination?.totalItems}
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="mr-2">
            <a
              href={`/dashboard/users/check`}
              target={`_blank`}
              className="btn btn-primary"
            >
              Check User
            </a>
          </div>
          <div className="mr-2">
            <a
              href={`/dashboard/users/`}
              target={`_blank`}
              className="btn btn-primary"
            >
              Detail User
            </a>
          </div>
          <div className="">
            <a
              href={`/dashboard/users/register`}
              target={`_blank`}
              className="btn btn-primary"
            >
              Register User
            </a>
          </div>
        </div>
      </div>

      <Card className="card_inside__padding">
        <div className="d-flex layout-media">
          <InputGroup className="mb-3" style={{ width: "300px" }}>
            <Form.Control
              className="py-0 style1-input border-search search-course__title"
              as="select"
              value={dataRole}
              onChange={dataRoleHandler}
              name="role_name"
              id="role_name"
              placeholder="Filter Role"
            >
              <option value="">Semua</option>
              <option value="superadmin">Superadmin</option>
              <option value="admin">Admin</option>
              <option value="student">Student</option>
              <option value="instructor">Instructor</option>
            </Form.Control>
          </InputGroup>{" "}
          {usersPagination ? (
            <Pagination
              size={size}
              totalRows={usersPagination.totalItems}
              totalPages={usersPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              {/* 
              <th>Pilih</th>*/}
              <th>Nama User</th>
              <th>ID User</th>
              <th>Role</th>
              <th>Company</th>
              <th>ID Company </th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {dataUser?.length &&
              dataUser?.map((item, index) => (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.user_id}</td>
                  <td>{item.role}</td>
                  <td>{item.company_name}</td>
                  <td>{item.company_id}</td>
                  <td>{item.email}</td>
                  <td>
                    {/* <Button className="mr-2" variant="info">
                      <FaEdit className="fill-white" />
                    </Button> */}
                    <a
                      className="btn btn-info mr-2"
                      href={`/dashboard/users/${item.user_id}`}
                      target={`_blank`}
                    >
                      Lihat Detail
                    </a>
                    <button
                      className="btn btn-primary mr-2"
                      onClick={(e) => handleEditData(e, item)}
                    >
                      Edit
                    </button>

                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card>
      <SkModal content={modalContent} button={false} size="md" />

    </div>
  );
}

export default UserManagement;
