export const POSITIONS_DATA_LINK = "positions";

//get
export const POSITIONS_DATA = "POSITIONS_DATA";
export const POSITIONS_DATA_SUCCESS = "POSITIONS_DATA_SUCCESS";
export const POSITIONS_DATA_ID_SUCCESS = "POSITIONS_DATA_ID_SUCCESS";
export const POSITIONS_DATA_FAIL = "POSITIONS_DATA_FAIL";

//post
export const POSITIONS_DATA_POST = "POSITIONS_DATA_POST";
export const POSITIONS_DATA_POST_SUCCESS = "POSITIONS_DATA_POST_SUCCESS";
export const POSITIONS_DATA_POST_FAIL = "POSITIONS_DATA_POST_FAIL";

// put
export const POSITIONS_DATA_PUT = "POSITIONS_DATA_PUT";
export const POSITIONS_DATA_PUT_SUCCESS = "POSITIONS_DATA_PUT_SUCCESS";
export const POSITIONS_DATA_PUT_FAIL = "POSITIONS_DATA_PUT_FAIL";

// delete
export const POSITIONS_DATA_DELETE = "POSITIONS_DATA_DELETE";
export const POSITIONS_DATA_DELETE_SUCCESS = "POSITIONS_DATA_DELETE_SUCCESS";
export const POSITIONS_DATA_DELETE_FAIL = "POSITIONS_DATA_DELETE_FAIL";
