import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Container,
  Form,
  Dropdown,
  Card,
  Modal,
} from "react-bootstrap";
import styled from "styled-components";
import { SELECT_DEFAULT_STYLES } from "../../helper/cssStyledComponent";
import { SkValidate } from "../../components/common/SkValidate";
import Library from "../../components/Library";
import {
  postCompaniesData,
  getCompaniesData,
  putCompaniesData,
} from "../../redux/actions/CompaniesActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "../../assets/css/Dashboard/index.css";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  getCompaniesDataThunk,
  postCompaniesDataThunk,
  putCompaniesDataThunk,
} from "../../redux/thunks/CompaniesThunks";
const MySwal = withReactContent(Swal);

function CreateCompany({ id, handleClose }) {
  const refTest = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [dataState, setDataState] = useState("");
  const [selectedLibary, setSelectedLibary] = useState();
  const [data, setData] = useState({
    address: "",
    cp_email: "",
    cp_name: "",
    cp_phone: "",
    logo: "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/cm42036694_3209808701155686.png",
    max_admins: 1,
    max_courses: 1,
    max_students: 1,
    name: "",
  });
  const [trigger, setTrigger] = useState(false);

  const companies = useSelector((state) => state.companies);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      setTrigger(true);
      if (id) {
        const newData = {
          body: data,
          id: id,
        };
        // dispatch(putCompaniesData(newData));
        dispatch(putCompaniesDataThunk(newData));
      } else {
        // dispatch(postCompaniesData(data))
        dispatch(postCompaniesDataThunk(data));
      }
    }
  };

  const handleChange = (e) => {
    const newData = { ...data };
    if (
      e.target.id === "max_admins" ||
      e.target.id === "max_courses" ||
      e.target.id === "max_students"
    )
      newData[e.target.id] = parseInt(e.target.value);
    else newData[e.target.id] = e.target.value;
    setData(newData);
  };

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (trigger && companies.companiesDataPost === "success") {
        history.push("/perusahaan/");
      }
      if (trigger && companies.companiesDataPut === "success") handleClose();
    });
  }

  useEffect(() => {
    if (trigger && companies.companiesDataPost === "success") {
      setTrigger(false);
      displayPopup(false, "Berhasil Menambahkan Perusahaan", "Oke");
    }
    if (trigger && companies.companiesDataPost === "error") {
      setTrigger(false);
      displayPopup(
        false,
        "Gagal menambahkan perusahaan. Silahkan coba lagi",
        "Oke"
      );
    }
    if (trigger && companies.companiesDataPut === "success") {
      setTrigger(false);
      displayPopup(false, "Berhasil Mengubah data Perusahaan", "Oke");
    }
    if (trigger && companies.companiesDataPut === "error") {
      setTrigger(false);
      displayPopup(
        false,
        "Gagal Mengubah data perusahaan. Silahkan coba lagi",
        "Oke"
      );
    }
    if (id && companies.companiesDataId?.company_id) {
      setData({
        address: companies.companiesDataId.address,
        cp_email: companies.companiesDataId.cp_email,
        cp_name: companies.companiesDataId.cp_name,
        cp_phone: companies.companiesDataId.cp_phone,
        logo: companies.companiesDataId.logo,
        max_admins: companies.companiesDataId.max_admins,
        max_courses: companies.companiesDataId.max_courses,
        max_students: companies.companiesDataId.max_students,
        name: companies.companiesDataId.name,
      });
    }
  }, [companies]);

  useEffect(() => {
    // dispatch(getCompaniesData(id));
    dispatch(getCompaniesDataThunk(id));
  }, [id]);
  const handleShow = (data) => {
    setDataState(data);
    setModal(true);
  };
  const libraryHandler = (newValue) => {
    setSelectedLibary(newValue);
  };
  const handleCloseModal = () => {
    setModal(false);
    setSelectedLibary("");
  };
  const handleThumbnail = () => {
    const newData = { ...data };
    newData[dataState] = selectedLibary;
    setData(newData);
    setModal(false);
    setDataState("");
  };
  return (
    <StyledWrapperMain>
      <Container>
        <Row className="justify-content-center align-items-center height-inherit">
          {id ? (
            ""
          ) : (
            <Col xs={12} sm={12} md={`${id ? 12 : 9}`} lg={`${id ? 12 : 9}`}>
              <div className="mb-3">
                <div className="course_create__title">Daftarkan Perusahaan</div>
              </div>
            </Col>
          )}

          <Col xs={12} sm={12} md={`${id ? 12 : 9}`} lg={`${id ? 12 : 9}`}>
            <Card className="card_container mb-5">
              <div className={`${id ? "px-2" : "card_inside__padding"}`}>
                <Form onSubmit={onSubmitHandler} id="form-lesson">
                  <Form.Group>
                    <Form.Label className="fw-600">Nama Perusahaan</Form.Label>
                    <Form.Control
                      className="mb-1"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Masukkan Nama Perusahaan"
                      onChange={handleChange}
                      value={data.name}
                    />
                    <div className="messages"></div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600">
                      Nama Penanggung Jawab
                    </Form.Label>
                    <Form.Control
                      className="mb-1"
                      type="text"
                      id="cp_name"
                      name="cp_name"
                      placeholder="Masukkan Nama Penanggung Jawab"
                      onChange={handleChange}
                      value={data.cp_name}
                    />
                    <div className="messages"></div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600">
                      Nomor Telepon Penanggung Jawab
                    </Form.Label>
                    <Form.Control
                      className="mb-1"
                      type="text"
                      id="cp_phone"
                      name="cp_phone"
                      placeholder="Masukkan Telepon Penanggung Jawab"
                      onChange={handleChange}
                      value={data.cp_phone}
                    />
                    <div className="messages"></div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600">
                      Email Penanggung Jawab
                    </Form.Label>
                    <Form.Control
                      className="mb-1"
                      type="text"
                      id="cp_email"
                      name="cp_email"
                      placeholder="Masukkan Email Penanggung Jawab"
                      onChange={handleChange}
                      value={data.cp_email}
                    />
                    <div className="messages"></div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600">
                      Alamat Perusahaan
                    </Form.Label>
                    <Form.Control
                      className="mb-1"
                      as="textarea"
                      id="address"
                      name="address"
                      placeholder="Masukkan Alamat Perusahaan"
                      onChange={handleChange}
                      value={data.address}
                    />
                    <div className="messages"></div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600">Logo Perusahaan</Form.Label>
                    <Form.Control
                      className="mb-1"
                      type="text"
                      id="logo"
                      name="logo"
                      placeholder="Masukkan Logo Perusahaan"
                      // onChange={handleChange}
                      onClick={() => handleShow("logo")}
                      value={data.logo}
                    />
                    <div className="messages"></div>
                    <div style={{ width: "100px", height: "100px" }}>
                      <img src={`${data.logo}`} width="auto" height="100" />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600">Maksimal Admin</Form.Label>
                    <Form.Control
                      className="mb-1"
                      type="number"
                      id="max_admins"
                      name="max_admins"
                      min={1}
                      placeholder="Maksimal Admin Perusahaan"
                      onChange={handleChange}
                      value={data.max_admins}
                    />
                    <div className="messages"></div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600">
                      Maksimal Pelajaran
                    </Form.Label>
                    <Form.Control
                      className="mb-1"
                      type="number"
                      id="max_courses"
                      name="max_courses"
                      min={1}
                      placeholder="Maksimal Pelajaran Perusahaan"
                      onChange={handleChange}
                      value={data.max_courses}
                    />
                    <div className="messages"></div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600">Maksimal Student</Form.Label>
                    <Form.Control
                      className="mb-1"
                      type="number"
                      id="max_students"
                      name="max_students"
                      min={1}
                      placeholder="Maksimal Student Perusahaan"
                      onChange={handleChange}
                      value={data.max_students}
                    />
                    <div className="messages"></div>
                  </Form.Group>
                  {handleClose ? (
                    <Button
                      onClick={handleClose}
                      variant="danger"
                      className="mt-3 float-right"
                    >
                      Batalkan
                    </Button>
                  ) : null}
                  <Button type="submit" className="mt-3 float-right">
                    {id ? "Edit" : "+ Tambahkan"}
                  </Button>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        show={modal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ overflow: "auto" }}
        className="modal-media-library"
      >
        <Modal.Header className="px-4">
          <Modal.Title id="contained-modal-title-vcenter">
            Pilih Gambar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Library
            libraryHandler={libraryHandler}
            handleCloseModal={handleCloseModal}
          />
        </Modal.Body>
        <Modal.Footer className="px-4">
          <Button variant="danger" onClick={handleCloseModal}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleThumbnail}>
            Pilih
          </Button>
        </Modal.Footer>
      </Modal>
    </StyledWrapperMain>
  );
}

export default CreateCompany;
const StyledWrapperMain = styled.section`
  ${SELECT_DEFAULT_STYLES}
`;

const constraints = {
  address: {
    length: {
      minimum: 3,
    },
  },
  cp_email: {
    presence: true,
    length: {
      maximum: 30,
    },
    email: true,
  },
  cp_name: {
    presence: true,
    length: {
      minimum: 3,
    },
  },
  cp_phone: {
    presence: true,
    length: {
      minimum: 5,
    },
    numericality: true,
  },
  logo: {
    presence: true,
    length: {
      minimum: 3,
    },
    url: true,
  },
  max_admins: {
    presence: true,
    numericality: true,
  },
  max_courses: {
    presence: true,
    numericality: true,
  },
  max_students: {
    presence: true,
    numericality: true,
  },
  name: {
    presence: true,
    length: {
      minimum: 3,
    },
  },
};
