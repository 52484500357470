import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "../../assets/css/Layout/pagination.css";

function PaginationTemplate({
  totalRows,
  size,
  paginationSelect,
  active,
  totalPages,
}) {
  const [display, setDisplay] = useState(false);
  const [jumpPage, setJumpPage] = useState(1);
  const rowsEachPage = Math.ceil(totalRows / size);
  const rows = Array.from(Array(rowsEachPage + 1).keys());
  let clusterFrom = 0;
  let clusterTo = 0;
  if (totalPages < 4) {
    clusterFrom = 1;
    clusterTo = rows.length;
  } else if (active < 4) {
    clusterFrom = 1;
    clusterTo = 3;
  } else if (active > totalPages - 2) {
    clusterFrom = totalPages - 2;
    clusterTo = rows.length;
  }
  return (
    <div className="center">
      <div className="pagination">
        <div
          onClick={() => {
            if (active > 1) paginationSelect(active - 1);
            else {
              if (active > 1) paginationSelect(1);
            }
          }}
        >
          &lt;
        </div>

        {/*rows.map((value, index) => {
          const activePage = active == value ? "active" : "";
          return (
            <div
              key={index}
              className={`${activePage}`}
              onClick={() => paginationSelect(value)}
            >
              {value}
            </div>
          );
        })*/}
        {active > 2 ? (
          <>
            <div
              onClick={() => {
                if (active > 1) paginationSelect(1);
              }}
            >
              1
            </div>
          </>
        ) : null}

        {totalPages < 4 || active < 3 || active > totalPages - 2 ? (
          rows.splice(clusterFrom, clusterTo).map((value, index) => {
            const activePage = active == value ? "active" : "";
            return (
              <div
                key={index}
                className={`${activePage}`}
                onClick={() => paginationSelect(value)}
              >
                {value}
              </div>
            );
          })
        ) : (
          <>
            <div className={``} onClick={() => paginationSelect(active - 1)}>
              {active - 1}
            </div>
            <div className={`active`} onClick={() => paginationSelect(active)}>
              {active}
            </div>
            <div className={``} onClick={() => paginationSelect(active + 1)}>
              {active + 1}
            </div>
          </>
        )}
        {active < totalPages - 1 && totalPages > 3 ? (
          <>
            {active < totalPages - 2 ? (
              <div className="dropdown">
                <Button
                  id="customDropdown"
                  className="dropbtn"
                  onClick={() => {
                    const newDisplay = !display;
                    setDisplay(newDisplay);
                  }}
                >
                  ...
                </Button>
                <div className={`dropdown-content ${display ? "show" : ""}`}>
                  <Form.Control
                    onChange={(e) => {
                      const newValue = parseInt(e.target.value);
                      if (newValue > 0 && newValue <= totalPages)
                        setJumpPage(newValue);
                    }}
                    className="mr-1"
                    type="number"
                    id="page"
                    value={jumpPage}
                    min={1}
                    max={totalPages}
                  />
                  <Button
                    variant="outline-success"
                    onClick={() => {
                      paginationSelect(jumpPage);
                      setDisplay(false);
                    }}
                  >
                    go!
                  </Button>
                </div>
              </div>
            ) : null}
            <div
              onClick={() => {
                if (active < totalPages) paginationSelect(totalPages);
              }}
            >
              {totalPages}
            </div>
          </>
        ) : null}
        <div
          onClick={() => {
            if (active < totalPages - 1) paginationSelect(active + 1);
            else {
              if (active < totalPages) paginationSelect(totalPages);
            }
          }}
        >
          &gt;
        </div>
      </div>
    </div>
  );
}

export default PaginationTemplate;
