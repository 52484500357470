import {
  QUESTIONS_DATA,
  QUESTIONS_DATA_ID_SUCCESS,
  QUESTIONS_DATA_SUCCESS,
  QUESTIONS_DATA_FAIL,
  QUESTIONS_DATA_POST,
  QUESTIONS_DATA_POST_SUCCESS,
  QUESTIONS_DATA_POST_FAIL,
  QUESTIONS_DATA_PUT,
  QUESTIONS_DATA_PUT_SUCCESS,
  QUESTIONS_DATA_PUT_FAIL,
  QUESTIONS_DATA_DELETE,
  QUESTIONS_DATA_DELETE_SUCCESS,
  QUESTIONS_DATA_DELETE_FAIL,
} from "../config/QuestionsConfig";

const intialState = {
  questionsData: [],
  questionsDataId: [],
  questionsDataPost: null,
  questionsDataDelete: null,
  questionsDataPut: null,
  isLoading: false,
};

export const QuestionsReducers = function (state = intialState, action) {
  switch (action.type) {
    case QUESTIONS_DATA:
      return { ...state, isLoading: true };
    case QUESTIONS_DATA_SUCCESS:
      return { ...state, isLoading: false, questionsData: action.value };
    case QUESTIONS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, questionsDataId: action.value };
    case QUESTIONS_DATA_FAIL:
      return { ...state, isLoading: false };
    case QUESTIONS_DATA_POST:
      return { ...state, isLoading: true, questionsDataPost: null };
    case QUESTIONS_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, questionsDataPost: "success" };
    case QUESTIONS_DATA_POST_FAIL:
      return { ...state, isLoading: false, questionsDataPost: "fail" };
    case QUESTIONS_DATA_PUT:
      return { ...state, isLoading: true, questionsDataPut: null };
    case QUESTIONS_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, questionsDataPut: "success" };
    case QUESTIONS_DATA_PUT_FAIL:
      return { ...state, isLoading: false, questionsDataPut: "fail" };
    case QUESTIONS_DATA_DELETE:
      return { ...state, isLoading: true, questionsDataDelete: null };
    case QUESTIONS_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, questionsDataDelete: "success" };
    case QUESTIONS_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, questionsDataDelete: "fail" };
    default:
      return state;
  }
};
