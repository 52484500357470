import { PromiseService } from "../../services/PromiseService";
import { ORDERS_DATA_LINK } from "../config/OrdersConfig";

import {
    //get
    getOrdersData,
    getOrdersDataSuccess,
    getOrdersDataIdSuccess,
    getOrdersDataFail,
} from "../actions/OrdersActions";

//get
export const getOrdersDataThunk = (data) => async (dispatch) => {
    dispatch(getOrdersData());
    if (data) {
        let url = `${ORDERS_DATA_LINK}/${data}`;
        if (data.param) url = `${ORDERS_DATA_LINK}${data.param}`;
        try {
            const response = await PromiseService.getData(url);
            if (response.status === 200 && response.data) {
                if (data.param) {
                    dispatch(getOrdersDataSuccess(response.data));
                } else {
                    dispatch(getOrdersDataIdSuccess(response.data));
                }
            } else {
                dispatch(getOrdersDataFail(response));
            }
        } catch (error) {
            dispatch(getOrdersDataFail(error));
        }
    } else {
        try {
            const response = await PromiseService.getData(ORDERS_DATA_LINK);
            if (response.status === 200 && response.data) {
                dispatch(getOrdersDataSuccess(response.data));
            } else {
                dispatch(getOrdersDataFail(response));
            }
        } catch (error) {
            dispatch(getOrdersDataFail(error));
        }
    }
};
