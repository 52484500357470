import React, { useRef } from "react";
import {
  Button,
  Row,
  Col,
  Container,
  Form,
  Dropdown,
  Card,
} from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import MultipleValueTextInput from "react-multivalue-text-input";
import { MdTitle, MdDescription, MdSubtitles } from "react-icons/md";
import { getCourseTypesData } from "../../redux/actions/CourseTypesActions";
import { HiSelector } from "react-icons/hi";
import styled from "styled-components";
// import { getUsersData } from "../../redux/actions/UsersActions";
// import { getLearningPathData } from "../../redux/actions/LearningPathActions";
import { getCoursesData } from "../../redux/actions/CoursesActions";
import { SELECT_DEFAULT_STYLES } from "../../helper/cssStyledComponent";
import "../../assets/css/Dashboard/index.css";
function CreateCourse() {
  const refTest = useRef(null);
  return (
    <StyledWrapperMain>
      <Container>
        <Row className="justify-content-center align-items-center height-inherit">
          <Col xs={12} sm={12} md={9} lg={9}>
            <div className="">
              <div className="course_create__title">Buat Pelatihan </div>
              <div className="course_create__subtitle">
                Lorem ipsum dolor sit amet
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={9} lg={9}>
            <Card className="card_container mb-5">
              <div className="card_inside__padding ">
                <Form>
                  <Form.Group>
                    <Form.Label className="fw-600"> Judul </Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="text"
                      id="title"
                      placeholder="Masukkan Judul Kursus"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600"> Summary </Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="text"
                      id="summary"
                      placeholder="Masukkan Summary Kursus"
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="fw-600"> Audiens </Form.Label>
                    <MultipleValueTextInput
                      label="Audiens yang Dipilih :"
                      name="audiences"
                      placeholder="Masukan Audiens"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600"> Objektif </Form.Label>
                    <MultipleValueTextInput
                      label="Objektif yang Dipilih :"
                      name="objectives"
                      placeholder="Masukan Objektif"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600"> Persyaratan </Form.Label>
                    <MultipleValueTextInput
                      label="Persyaratan yang Dipilih :"
                      name="requirements"
                      placeholder="Masukan Persyaratan"
                    />
                  </Form.Group>
                  {/* <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className="fw-600"> Level </Form.Label>
                        <Form.Control
                          required
                          as="select"
                          type="select"
                          name="payment_method"
                          id="level"
                        >
                          <option hidden value key="blankChoice">
                            Pilih Level...
                          </option>
                          <option value={"beginner"}>Beginner</option>
                          <option value={"advanced"}>Advanced</option>
                          <option value={"professional"}>Professional</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className="fw-600">
                          {" "}
                          Tipe Kursus{" "}
                        </Form.Label>{" "}
                        <Form.Control
                          required
                          as="select"
                          type="select"
                          name="payment_method"
                          id="course_type_id"
                        >
                          <option hidden value key="blankChoice">
                            Pilih Tipe Kursus...
                          </option>
                          {courseTypesData.items
                            ? courseTypesData.items.map((item, index) => {
                                return (
                                  <option value={item.type_id}>
                                    {item.title}
                                  </option>
                                );
                              })
                            : null}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className="fw-600"> Instruktur </Form.Label>
                        {/* {usersData.items ? ( */}
                        <DropdownMultiselect
                          options={[
                            "Australia",
                            "Canada",
                            "USA",
                            "Poland",
                            "Spain",
                            "France",
                          ]}
                          name="instructor"
                          placeholder="Pilih Instruktur..."
                          // handleOnChange={(selected, s, a) => {
                          //   handleChangeMultiSelect(
                          //     "instructors",
                          //     CreateResult(selected, "json", "user")
                          //   );
                          // }}
                          // selected={CreateSelected(data.instructors, "user")}
                        />
                        {/* // ) : null} */}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className="fw-600">
                          Learning Paths{" "}
                        </Form.Label>{" "}
                        {/* {LearningPathData.items ? ( */}
                        <DropdownMultiselect
                          options={[
                            "Australia",
                            "Canada",
                            "USA",
                            "Poland",
                            "Spain",
                            "France",
                          ]}
                          name="learning_paths"
                          placeholder="Pilih Learning Paths..."
                          // handleOnChange={(selected) => {
                          //   handleChangeMultiSelect(
                          //     "learning_paths",
                          //     CreateResult(selected, "json", "path")
                          //   );
                          // }}
                          // selected={CreateSelected(
                          //   data.learning_paths,
                          //   "path"
                          // )}
                        />
                        {/* ) : null} */}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className="fw-600">Prasyarat </Form.Label>{" "}
                        {/* {courseData.items ? ( */}
                        <DropdownMultiselect
                          className="mt-0"
                          ref={refTest}
                          options={[
                            "Australia",
                            "Canada",
                            "USA",
                            "Poland",
                            "Spain",
                            "France",
                          ]}
                          name="prerequisites"
                          placeholder="Pilih Prasyarat Kursus..."
                          // handleOnChange={(selected) => {
                          //   handleChangeMultiSelect(
                          //     "prerequisites",
                          //     CreateResult(selected, "json", "course")
                          //   );
                          // }}
                          // selected={CreateSelected(
                          //   data.prerequisites,
                          //   "course"
                          // )}
                        />
                        {/* ) : null} */}
                      </Form.Group>
                    </Col>
                    <Col>
                      {" "}
                      <Form.Group>
                        <Form.Label className="fw-600"> Harga </Form.Label>
                        <Form.Control
                          // onChange={handleChange}
                          className="mb-3"
                          type="number"
                          id="price"
                          // value={
                          //   typeof data.price === "string"
                          //     ? data.price === "Free"
                          //       ? 0
                          //       : parseInt(data.price.replace(/\D/g, ""))
                          //     : data.price
                          // }
                          min="0"
                          step="100000"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group>
                    <Form.Label className="fw-600"> Thumbnail </Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="text"
                      id="thumbnail"
                      // value={data.thumbnail}
                      placeholder="Masukkan thumbnail"
                      // onClick={handleShow}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div>
                      <Form.Label className="fw-600"> Deskripsi </Form.Label>{" "}
                    </div>
                    <Form.Control
                      // onChange={handleChange}
                      as="textarea"
                      rows={5}
                      id="description"
                      // value={data.description}
                      placeholder="Masukkan Deskripsi"
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-end">
                    {/* {handleCancel ? ( */}
                    <Button
                      type="submit"
                      variant="light"
                      className="my-4 mx-2"
                      // onClick={() => {
                      //   setData(defaultData);
                      //   handleCancel();
                      // }}
                    >
                      Batal
                    </Button>
                    {/* ) : null} */}
                    <Button
                      type="submit"
                      // onClick={uploadData}
                      className="my-4 mx-2"
                    >
                      Tambahkan
                      {/* {id ? "Edit" : <>{"+ Tambahkan"}</>} */}
                    </Button>
                  </div>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </StyledWrapperMain>
  );
}

export default CreateCourse;
const StyledWrapperMain = styled.section`
  ${SELECT_DEFAULT_STYLES}
`;
