import {
  QUESTIONS_DATA,
  QUESTIONS_DATA_POST,
  QUESTIONS_DATA_SUCCESS,
  QUESTIONS_DATA_ID_SUCCESS,
  QUESTIONS_DATA_POST_SUCCESS,
  QUESTIONS_DATA_FAIL,
  QUESTIONS_DATA_POST_FAIL,
  QUESTIONS_DATA_DELETE,
  QUESTIONS_DATA_DELETE_SUCCESS,
  QUESTIONS_DATA_DELETE_FAIL,
  QUESTIONS_DATA_PUT,
  QUESTIONS_DATA_PUT_SUCCESS,
  QUESTIONS_DATA_PUT_FAIL,
} from "../config/QuestionsConfig";

//get
export const getQuestionsData = (value) => ({
  type: QUESTIONS_DATA,
  value,
});
export const getQuestionsDataIdSuccess = (value) => ({
  type: QUESTIONS_DATA_ID_SUCCESS,
  value,
});
export const getQuestionsDataSuccess = (value) => ({
  type: QUESTIONS_DATA_SUCCESS,
  value,
});
export const getQuestionsDataFail = (value) => ({
  type: QUESTIONS_DATA_FAIL,
  value,
});

//post
export const postQuestionsData = (value) => ({
  type: QUESTIONS_DATA_POST,
  value,
});
export const postQuestionsDataSuccess = (value) => ({
  type: QUESTIONS_DATA_POST_SUCCESS,
  value,
});
export const postQuestionsDataFail = (value) => ({
  type: QUESTIONS_DATA_POST_FAIL,
  value,
});

//put
export const putQuestionsData = (value) => ({
  type: QUESTIONS_DATA_PUT,
  value,
});
export const putQuestionsDataSuccess = (value) => ({
  type: QUESTIONS_DATA_PUT_SUCCESS,
  value,
});
export const putQuestionsDataFail = (value) => ({
  type: QUESTIONS_DATA_PUT_FAIL,
  value,
});

//delete
export const deleteQuestionsData = (value) => ({
  type: QUESTIONS_DATA_DELETE,
  value,
});
export const deleteQuestionsDataSuccess = (value) => ({
  type: QUESTIONS_DATA_DELETE_SUCCESS,
  value,
});
export const deleteQuestionsDataFail = (value) => ({
  type: QUESTIONS_DATA_DELETE_FAIL,
  value,
});
