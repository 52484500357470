import {
  //get
  SKILL_TYPES_DATA,
  SKILL_TYPES_DATA_ID_SUCCESS,
  SKILL_TYPES_DATA_SUCCESS,
  SKILL_TYPES_DATA_FAIL,
  //post
  SKILL_TYPES_DATA_POST,
  SKILL_TYPES_DATA_POST_SUCCESS,
  SKILL_TYPES_DATA_POST_FAIL,
  //put
  SKILL_TYPES_DATA_PUT,
  SKILL_TYPES_DATA_PUT_SUCCESS,
  SKILL_TYPES_DATA_PUT_FAIL,
  //delete
  SKILL_TYPES_DATA_DELETE,
  SKILL_TYPES_DATA_DELETE_SUCCESS,
  SKILL_TYPES_DATA_DELETE_FAIL,
} from "../config/SkillTypesConfig";

const intialState = {
  skillTypesDataPost: null,
  skillTypesDataPut: null,
  skillTypesDataDelete: null,
  skillTypesData: null,
  skillTypesDataId: null,
  isLoading: false,
};

export const SkillTypesReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case SKILL_TYPES_DATA:
      return { ...state, isLoading: true };
    case SKILL_TYPES_DATA_SUCCESS:
      return { ...state, isLoading: false, skillTypesData: action.value };
    case SKILL_TYPES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, skillTypesDataId: action.value };
    case SKILL_TYPES_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        skillTypesData: "error",
        skillTypesDataId: "error",
      };
    //post
    case SKILL_TYPES_DATA_POST:
      return {
        ...state,
        isLoading: true,
        skillTypesDataPost: null,
        skillTypesDataPut: null,
        skillTypesDataDelete: null,
      };
    case SKILL_TYPES_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, skillTypesDataPost: action.value };
    case SKILL_TYPES_DATA_POST_FAIL:
      return { ...state, isLoading: false, skillTypesDataPost: "error" };

    //put
    case SKILL_TYPES_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        skillTypesDataPost: null,
        skillTypesDataPut: null,
        skillTypesDataDelete: null,
      };
    case SKILL_TYPES_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, skillTypesDataPut: "success" };
    case SKILL_TYPES_DATA_PUT_FAIL:
      return { ...state, isLoading: false, skillTypesDataPut: "error" };

    //delete
    case SKILL_TYPES_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        skillTypesDataPost: null,
        skillTypesDataPut: null,
        skillTypesDataDelete: null,
      };
    case SKILL_TYPES_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, skillTypesDataDelete: "success" };
    case SKILL_TYPES_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, skillTypesDataDelete: "error" };

    default:
      return state;
  }
};
