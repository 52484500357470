import {
  LEARNING_PATH_DATA,
  LEARNING_PATH_DATA_GET_SUCCESS,
  LEARNING_PATH_DATA_GET_ID_SUCCESS,
  LEARNING_PATH_DATA_FAIL,
  LEARNING_PATH_DATA_PUT,
  LEARNING_PATH_DATA_PUT_SUCCESS,
  LEARNING_PATH_DATA_POST,
  LEARNING_PATH_DATA_POST_SUCCESS,
  LEARNING_PATH_DATA_DELETE,
  LEARNING_PATH_DATA_DELETE_SUCCESS,
} from "../config/LearningPathConfig";

export const getLearningPathData = (value) => ({
  type: LEARNING_PATH_DATA,
  value,
});
export const getLearningPathDataSuccess = (value) => ({
  type: LEARNING_PATH_DATA_GET_SUCCESS,
  value,
});
export const getLearningPathDataIdSuccess = (value) => ({
  type: LEARNING_PATH_DATA_GET_ID_SUCCESS,
  value,
});
export const callLearningPathDataFail = (value) => ({
  type: LEARNING_PATH_DATA_FAIL,
  value,
});
export const postLearningPathData = (value) => ({
  type: LEARNING_PATH_DATA_POST,
  value,
});
export const postLearningPathDataSuccess = (value) => ({
  type: LEARNING_PATH_DATA_POST_SUCCESS,
  value,
});
export const putLearningPathData = (value) => ({
  type: LEARNING_PATH_DATA_PUT,
  value,
});
export const putLearningPathDataSuccess = (value) => ({
  type: LEARNING_PATH_DATA_PUT_SUCCESS,
  value,
});
export const deleteLearningPathData = (value) => ({
  type: LEARNING_PATH_DATA_DELETE,
  value,
});
export const deleteLearningPathDataSuccess = (value) => ({
  type: LEARNING_PATH_DATA_DELETE_SUCCESS,
  value,
});
