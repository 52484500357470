import { PromiseService } from "../../services/PromiseService";
import {
  QUESTIONS_DATA_LINK,
  QUESTIONS_DATA_LINK_POST,
} from "../config/QuestionsConfig";
import {
  // get
  getQuestionsData,
  getQuestionsDataSuccess,
  getQuestionsDataIdSuccess,
  getQuestionsDataFail,
  // post
  postQuestionsData,
  postQuestionsDataSuccess,
  postQuestionsDataFail,
  // delete
  deleteQuestionsData,
  deleteQuestionsDataSuccess,
  deleteQuestionsDataFail,
  // put
  putQuestionsData,
  putQuestionsDataSuccess,
  putQuestionsDataFail,
} from "../actions/QuestionsActions";

// get
export const getQuestionsDataThunk = (data) => async (dispatch) => {
  dispatch(getQuestionsData());
  if (data) {
    let url = `${QUESTIONS_DATA_LINK}/${data}`;
    if (data.param) url = `${QUESTIONS_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) {
          dispatch(getQuestionsDataSuccess(response.data));
        } else {
          dispatch(getQuestionsDataIdSuccess(response.data));
        }
      } else {
        dispatch(getQuestionsDataFail(response));
      }
    } catch (error) {
      dispatch(getQuestionsDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(QUESTIONS_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getQuestionsDataSuccess(response.data));
      } else {
        dispatch(getQuestionsDataFail(response));
      }
    } catch (error) {
      dispatch(getQuestionsDataFail(error));
    }
  }
};

// post
export const postQuestionsDataThunk = (data) => async (dispatch) => {
  dispatch(postQuestionsData());
  const body = data.body;
  const url = data.url;
  try {
    const response = await PromiseService.postFormData(
      `${QUESTIONS_DATA_LINK_POST}${url}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(postQuestionsDataSuccess(response.data));
    } else {
      dispatch(postQuestionsDataFail(response));
    }
  } catch (error) {
    dispatch(postQuestionsDataFail(error));
  }
};

// delete
export const deleteQuestionsDataThunk = (data) => async (dispatch) => {
  dispatch(deleteQuestionsData());
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${QUESTIONS_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteQuestionsDataSuccess(response.data));
    } else {
      dispatch(deleteQuestionsDataFail(response));
    }
  } catch (error) {
    dispatch(deleteQuestionsDataFail(error));
  }
};

// put
export const putQuestionsDataThunk = (data) => async (dispatch) => {
  dispatch(putQuestionsData());
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${QUESTIONS_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putQuestionsDataSuccess(response.data));
    } else {
      dispatch(putQuestionsDataFail(response));
    }
  } catch (error) {
    dispatch(putQuestionsDataFail(error));
  }
};
