import { PromiseService } from "../../services/PromiseService";
import { INBOX_DATA_LINK } from "../config/InboxConfig";

import {
  //get
  getInboxData,
  getInboxDataSuccess,
  getInboxDataIdSuccess,
  getInboxDataFail,
  // delete
  deleteInboxData,
  deleteInboxDataSuccess,
  deleteInboxDataFail,
} from "../actions/InboxActions";

//get
export const getInboxDataThunk = (data) => async (dispatch) => {
  dispatch(getInboxData());
  if (data) {
    let url = `${INBOX_DATA_LINK}/${data}`;
    if (data.param) url = `${INBOX_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) {
          dispatch(getInboxDataSuccess(response.data));
        } else {
          dispatch(getInboxDataIdSuccess(response.data));
        }
      } else {
        dispatch(getInboxDataFail(response));
      }
    } catch (error) {
      dispatch(getInboxDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(INBOX_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getInboxDataSuccess(response.data));
      } else {
        dispatch(getInboxDataFail(response));
      }
    } catch (error) {
      dispatch(getInboxDataFail(error));
    }
  }
};

// delete
export const deleteInboxDataThunk = (data) => async (dispatch) => {
  dispatch(deleteInboxData());
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${INBOX_DATA_LINK}/s/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteInboxDataSuccess(response.data));
    } else {
      dispatch(deleteInboxDataFail(response));
    }
  } catch (error) {
    dispatch(deleteInboxDataFail(error));
  }
};

