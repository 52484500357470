import {
    // get
    INBOX_DATA,
    INBOX_DATA_ID_SUCCESS,
    INBOX_DATA_SUCCESS,
    INBOX_DATA_FAIL,
    //delete
    INBOX_DATA_DELETE,
    INBOX_DATA_DELETE_SUCCESS,
    INBOX_DATA_DELETE_FAIL,
} from "../config/InboxConfig";

const intialState = {
    inboxDataDelete: null,
    inboxData: null,
    inboxDataId: null,
    isLoading: false,
};

export const InboxReducers = function (state = intialState, action) {
    switch (action.type) {
        //get
        case INBOX_DATA:
            return { ...state, isLoading: true };
        case INBOX_DATA_SUCCESS:
            return { ...state, isLoading: false, inboxData: action.value };
        case INBOX_DATA_ID_SUCCESS:
            return { ...state, isLoading: false, inboxDataId: action.value };
        case INBOX_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                inboxData: "error",
                inboxDataId: "error",
            };
        //delete
        case INBOX_DATA_DELETE:
            return { ...state, isLoading: true };
        case INBOX_DATA_DELETE_SUCCESS:
            return { ...state, isLoading: false, inboxDataDelete: "success" };
        case INBOX_DATA_DELETE_FAIL:
            return { ...state, isLoading: false, inboxDataDelete: "error" };
        default:
            return state;
    }
}