import {
  //get
  ASSETS_DATA,
  ASSETS_DATA_SUCCESS,
  ASSETS_DATA_FAIL,
  //post
  ASSETS_DATA_POST,
  ASSETS_DATA_POST_SUCCESS,
  ASSETS_DATA_POST_FAIL,
  //delete
  ASSETS_DATA_DELETE,
  ASSETS_DATA_DELETE_SUCCESS,
  ASSETS_DATA_DELETE_FAIL,
  //remove
  REMOVE_ASSETS_DATA_POST,
} from "../config/AssetsConfig";

const intialState = {
  assetsData: [],
  assetsDataPost: null,
  assetsDataDelete: [],
  isLoading: false,
};

export const AssetsReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case ASSETS_DATA:
      return { ...state, isLoading: true, assetsData: [] };
    case ASSETS_DATA_SUCCESS:
      return { ...state, isLoading: false, assetsData: action.value };
    case ASSETS_DATA_FAIL:
      return { ...state, isLoading: false, assetsData: [] };
    //post
    case ASSETS_DATA_POST:
      return { ...state, isLoading: true, assetsDataPost: null };
    case ASSETS_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, assetsDataPost: action.value };
    case ASSETS_DATA_POST_FAIL:
      return { ...state, isLoading: false, assetsDataPost: action.value };
    //delete
    case ASSETS_DATA_DELETE:
      return { ...state, isLoading: true, assetsDataDelete: [] };
    case ASSETS_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, assetsDataDelete: "success" };
    case ASSETS_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, assetsDataDelete: "fail" };
    //remove
    case REMOVE_ASSETS_DATA_POST:
      return { ...state, assetsDataPost: null, assetsDataDelete: [] };
    default:
      return state;
  }
};
