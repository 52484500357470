import {
  // get
  SECTORS_DATA,
  SECTORS_DATA_SUCCESS,
  SECTORS_DATA_ID_SUCCESS,
  // post
  SECTORS_DATA_POST,
  SECTORS_DATA_POST_SUCCESS,
  SECTORS_DATA_POST_FAIL,
  // put
  SECTORS_DATA_PUT,
  SECTORS_DATA_PUT_SUCCESS,
  SECTORS_DATA_PUT_FAIL,
  // delete
  SECTORS_DATA_DELETE,
  SECTORS_DATA_DELETE_SUCCESS,
  SECTORS_DATA_DELETE_FAIL,
  // fail
  SECTORS_DATA_FAIL,
} from "../config/SectorsConfig";

// get
export const getSectorsData = (value) => ({
  type: SECTORS_DATA,
  value,
});
export const getSectorsDataSuccess = (value) => ({
  type: SECTORS_DATA_SUCCESS,
  value,
});
export const getSectorsDataIdSuccess = (value) => ({
  type: SECTORS_DATA_ID_SUCCESS,
  value,
});
export const getSectorsDataFail = (value) => ({
  type: SECTORS_DATA_FAIL,
  value,
});

// post
export const postSectorsData = (value) => ({
  type: SECTORS_DATA_POST,
  value,
});
export const postSectorsDataSuccess = (value) => ({
  type: SECTORS_DATA_POST_SUCCESS,
  value,
});
export const postSectorsDataFail = (value) => ({
  type: SECTORS_DATA_POST_FAIL,
  value,
});

// put
export const putSectorsData = (value) => ({
  type: SECTORS_DATA_PUT,
  value,
});
export const putSectorsDataSuccess = (value) => ({
  type: SECTORS_DATA_PUT_SUCCESS,
  value,
});
export const putSectorsDataFail = (value) => ({
  type: SECTORS_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteSectorsData = (value) => ({
  type: SECTORS_DATA_DELETE,
  value,
});
export const deleteSectorsDataSuccess = (value) => ({
  type: SECTORS_DATA_DELETE_SUCCESS,
  value,
});
export const deleteSectorsDataFail = (value) => ({
  type: SECTORS_DATA_DELETE_FAIL,
  value,
});

// fail handle
