export const SKILL_TYPES_DATA_LINK = "skill_types";

//get
export const SKILL_TYPES_DATA = "SKILL_TYPES_DATA";
export const SKILL_TYPES_DATA_SUCCESS = "SKILL_TYPES_DATA_SUCCESS";
export const SKILL_TYPES_DATA_ID_SUCCESS = "SKILL_TYPES_DATA_ID_SUCCESS";
export const SKILL_TYPES_DATA_FAIL = "SKILL_TYPES_DATA_FAIL";

//post
export const SKILL_TYPES_DATA_POST = "SKILL_TYPES_DATA_POST";
export const SKILL_TYPES_DATA_POST_SUCCESS = "SKILL_TYPES_DATA_POST_SUCCESS";
export const SKILL_TYPES_DATA_POST_FAIL = "SKILL_TYPES_DATA_POST_FAIL";

// put
export const SKILL_TYPES_DATA_PUT = "SKILL_TYPES_DATA_PUT";
export const SKILL_TYPES_DATA_PUT_SUCCESS = "SKILL_TYPES_DATA_PUT_SUCCESS";
export const SKILL_TYPES_DATA_PUT_FAIL = "SKILL_TYPES_DATA_PUT_FAIL";

// delete
export const SKILL_TYPES_DATA_DELETE = "SKILL_TYPES_DATA_DELETE";
export const SKILL_TYPES_DATA_DELETE_SUCCESS = "SKILL_TYPES_DATA_DELETE_SUCCESS";
export const SKILL_TYPES_DATA_DELETE_FAIL = "SKILL_TYPES_DATA_DELETE_FAIL";
