import { satukelasApi } from "../constants";

export const DiplomaService = {
  getDiplomas(querystring) {
    return satukelasApi.get(`/diplomas${querystring}`);
  },
  updateDiplomaById(diploma_id, status) {
    return satukelasApi.put(`/diplomas/${diploma_id}?status=${status}`);
  },
};
