import {
  // get
  DELIVER_METHOD_DATA,
  DELIVER_METHOD_DATA_SUCCESS,
  DELIVER_METHOD_DATA_ID_SUCCESS,
  // post
  DELIVER_METHOD_DATA_POST,
  DELIVER_METHOD_DATA_POST_SUCCESS,
  DELIVER_METHOD_DATA_POST_FAIL,
  // put
  DELIVER_METHOD_DATA_PUT,
  DELIVER_METHOD_DATA_PUT_SUCCESS,
  DELIVER_METHOD_DATA_PUT_FAIL,
  // delete
  DELIVER_METHOD_DATA_DELETE,
  DELIVER_METHOD_DATA_DELETE_SUCCESS,
  DELIVER_METHOD_DATA_DELETE_FAIL,
  // fail
  DELIVER_METHOD_DATA_FAIL,
  REMOVE_DELIVER_METHOD_DATA,
} from "../config/DeliveryMethodConfig";

// get
export const getDeliveryMethodData = (value) => ({
  type: DELIVER_METHOD_DATA,
  value,
});
export const getDeliveryMethodDataSuccess = (value) => ({
  type: DELIVER_METHOD_DATA_SUCCESS,
  value,
});
export const getDeliveryMethodDataIdSuccess = (value) => ({
  type: DELIVER_METHOD_DATA_ID_SUCCESS,
  value,
});
export const getDeliveryMethodDataFail = (value) => ({
  type: DELIVER_METHOD_DATA_FAIL,
  value,
});

// post
export const postDeliveryMethodData = (value) => ({
  type: DELIVER_METHOD_DATA_POST,
  value,
});
export const postDeliveryMethodDataSuccess = (value) => ({
  type: DELIVER_METHOD_DATA_POST_SUCCESS,
  value,
});
export const postDeliveryMethodDataFail = (value) => ({
  type: DELIVER_METHOD_DATA_POST_FAIL,
  value,
});

// put
export const putDeliveryMethodData = (value) => ({
  type: DELIVER_METHOD_DATA_PUT,
  value,
});
export const putDeliveryMethodDataSuccess = (value) => ({
  type: DELIVER_METHOD_DATA_PUT_SUCCESS,
  value,
});
export const putDeliveryMethodDataFail = (value) => ({
  type: DELIVER_METHOD_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteDeliveryMethodData = (value) => ({
  type: DELIVER_METHOD_DATA_DELETE,
  value,
});
export const deleteDeliveryMethodDataSuccess = (value) => ({
  type: DELIVER_METHOD_DATA_DELETE_SUCCESS,
  value,
});
export const deleteDeliveryMethodDataFail = (value) => ({
  type: DELIVER_METHOD_DATA_DELETE_FAIL,
  value,
});

export const removeDeliverMethodData = (value) => ({
  type: REMOVE_DELIVER_METHOD_DATA,
  value,
});

// fail handle
