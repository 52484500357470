export const QUIZ_THEME_DATA_LINK = "quiz_themes";
//get
export const QUIZ_THEME_DATA = "QUIZ_THEME_DATA";
export const QUIZ_THEME_DATA_SUCCESS = "QUIZ_THEME_DATA_SUCCESS";
export const QUIZ_THEME_DATA_FAIL = "QUIZ_THEME_DATA_FAIL";
//post
export const QUIZ_THEME_DATA_POST = "QUIZ_THEME_DATA_POST";
export const QUIZ_THEME_DATA_POST_SUCCESS = "QUIZ_THEME_DATA_POST_SUCCESS";
export const QUIZ_THEME_DATA_POST_FAIL = "QUIZ_THEME_DATA_POST_FAIL";

// put
export const QUIZ_THEME_DATA_PUT = "QUIZ_THEME_DATA_PUT";
export const QUIZ_THEME_DATA_PUT_SUCCESS = "QUIZ_THEME_DATA_PUT_SUCCESS";
export const QUIZ_THEME_DATA_PUT_FAIL = "QUIZ_THEME_DATA_PUT_FAIL";

//delete
export const QUIZ_THEME_DATA_DELETE = "QUIZ_THEME_DATA_DELETE";
export const QUIZ_THEME_DATA_DELETE_SUCCESS = "QUIZ_THEME_DATA_DELETE_SUCCESS";
export const QUIZ_THEME_DATA_DELETE_FAIL = "QUIZ_THEME_DATA_DELETE_FAIL";
// live search

export const QUIZ_THEME_LIVE_SEARCH_DATA = "QUIZ_THEME_LIVE_SEARCH_DATA";
export const QUIZ_THEME_LIVE_SEARCH_DATA_SUCCESS =
  "QUIZ_THEME_LIVE_SEARCH_DATA_SUCCESS";
export const QUIZ_THEME_LIVE_SEARCH_DATA_FAIL =
  "QUIZ_THEME_LIVE_SEARCH_DATA_FAIL";

// remove
export const REMOVE_QUIZ_THEME_DATA_ID = "REMOVE_QUIZ_THEME_DATA_ID";
export const REMOVE_QUIZ_THEME_DATA = "REMOVE_QUIZ_THEME_DATA";
