import React, { useRef } from "react";
import {
  Button,
  Row,
  Col,
  Container,
  Form,
  Dropdown,
  Card,
} from "react-bootstrap";
import styled from "styled-components";
import { SELECT_DEFAULT_STYLES } from "../../helper/cssStyledComponent";
import "../../assets/css/Dashboard/index.css";
function LiveSessionCreate() {
  const refTest = useRef(null);
  return (
    <StyledWrapperMain>
      <Container>
        <Row className="justify-content-center align-items-center height-inherit">
          <Col xs={12} sm={12} md={9} lg={9}>
            <div className="">
              <div className="course_create__title">Daftarkan Live Session</div>
              <div className="course_create__subtitle">
                Lorem ipsum dolor sit amet
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={9} lg={9}>
            <Card className="card_container mb-5">
              <div className="card_inside__padding ">
                <Form>
                  <Form.Group>
                    <Form.Label className="fw-600">
                      {" "}
                      Judul Live Session
                    </Form.Label>
                    <Form.Control
                      className="mb-1"
                      type="text"
                      id="title"
                      placeholder="Masukkan Judul Live Session"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600"> Durasi</Form.Label>
                    <Form.Control
                      className="mb-1"
                      type="time"
                      id="title"
                      placeholder="Masukkan Judul Kursus"
                    />
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className="fw-600"> Meeting ID</Form.Label>
                        <Form.Control
                          className="mb-1"
                          type="text"
                          id="title"
                          placeholder="Masukkan Meeting ID"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className="fw-600"> Passcode</Form.Label>
                        <Form.Control
                          className="mb-1"
                          type="text"
                          id="title"
                          placeholder="Masukkan Passcode Kursus"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className="fw-600"> Waktu Mulai</Form.Label>
                        <Form.Control
                          className="mb-1"
                          type="date"
                          placeholder="Masukkan Judul Kursus"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className="fw-600">
                          {" "}
                          Waktu Selesai
                        </Form.Label>
                        <Form.Control
                          className="mb-1"
                          type="date"
                          placeholder="Masukkan Judul Kursus"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group>
                    <Form.Label className="fw-600">
                      {" "}
                      Pilih Instruktor
                    </Form.Label>
                    <Form.Control
                      className="mb-1"
                      as="select"
                      placeholder="Pilih Nama Perusahaan"
                    >
                      <option id="1">Dummy 1</option>
                      <option id="2">Dummy 2</option>
                    </Form.Control>
                  </Form.Group>

                  <Button type="submit" className="mt-3 float-right">
                    + Tambahkan
                  </Button>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </StyledWrapperMain>
  );
}

export default LiveSessionCreate;
const StyledWrapperMain = styled.section`
  ${SELECT_DEFAULT_STYLES}
`;
