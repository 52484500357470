export const USERS_DATA_LINK = "users";
export const USERS_CHANGE_PASSWORD_LINK = "users/change_password";

//get
export const USERS_DATA = "USERS_DATA";
export const USERS_DATA_SUCCESS = "USERS_DATA_SUCCESS";
export const USERS_DATA_ID_SUCCESS = "USERS_DATA_ID_SUCCESS";
export const USERS_DATA_ID_OTHER_SUCCESS = "USERS_DATA_ID_OTHER_SUCCESS";
export const USERS_DATA_FAIL = "USERS_DATA_FAIL";

//post
export const USERS_DATA_POST = "USERS_DATA_POST";
export const USERS_DATA_POST_SUCCESS = "USERS_DATA_POST_SUCCESS";
export const USERS_DATA_POST_FAIL = "USERS_DATA_POST_FAIL";

// put
export const USERS_DATA_PUT = "USERS_DATA_PUT";
export const USERS_DATA_PUT_SUCCESS = "USERS_DATA_PUT_SUCCESS";
export const USERS_DATA_PUT_FAIL = "USERS_DATA_PUT_FAIL";

// change password
export const USERS_CHANGE_PASSWORD = "USERS_CHANGE_PASSWORD";
export const USERS_CHANGE_PASSWORD_SUCCESS = "USERS_CHANGE_PASSWORD_SUCCESS";
export const USERS_CHANGE_PASSWORD_FAIL = "USERS_CHANGE_PASSWORD_FAIL";

// delete
export const USERS_DATA_DELETE = "USERS_DATA_DELETE";
export const USERS_DATA_DELETE_SUCCESS = "USERS_DATA_DELETE_SUCCESS";
export const USERS_DATA_DELETE_FAIL = "USERS_DATA_DELETE_FAIL";
