import {
  //get
  COMPANIES_DATA,
  COMPANIES_DATA_ID_SUCCESS,
  COMPANIES_DATA_SUCCESS,
  COMPANIES_DATA_FAIL,
  //post
  COMPANIES_DATA_POST,
  COMPANIES_DATA_POST_SUCCESS,
  COMPANIES_DATA_POST_FAIL,
  //put
  COMPANIES_DATA_PUT,
  COMPANIES_DATA_PUT_SUCCESS,
  COMPANIES_DATA_PUT_FAIL,
  //delete
  COMPANIES_DATA_DELETE,
  COMPANIES_DATA_DELETE_SUCCESS,
  COMPANIES_DATA_DELETE_FAIL,
} from "../config/CompaniesConfig";

const intialState = {
  companiesDataPost: "",
  companiesDataPut: "",
  companiesDataDelete: "",
  companiesData: [],
  companiesDataId: [],
  isLoading: false,
};

export const CompaniesReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case COMPANIES_DATA:
      return { ...state, isLoading: true };
    case COMPANIES_DATA_SUCCESS:
      return { ...state, isLoading: false, companiesData: action.value };
    case COMPANIES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, companiesDataId: action.value };
    case COMPANIES_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        companiesData: "error",
        companiesDataId: "error",
      };
    //post
    case COMPANIES_DATA_POST:
      return { ...state, isLoading: true, companiesDataPost: "" };
    case COMPANIES_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, companiesDataPost: "success" };
    case COMPANIES_DATA_POST_FAIL:
      return { ...state, isLoading: false, companiesDataPost: "error" };

    //put
    case COMPANIES_DATA_PUT:
      return { ...state, isLoading: true, companiesDataPut: "" };
    case COMPANIES_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, companiesDataPut: "success" };
    case COMPANIES_DATA_PUT_FAIL:
      return { ...state, isLoading: false, companiesDataPut: "error" };

    //delete
    case COMPANIES_DATA_DELETE:
      return { ...state, isLoading: true, companiesDataDelete: "" };
    case COMPANIES_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, companiesDataDelete: "success" };
    case COMPANIES_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, companiesDataDelete: "error" };

    default:
      return state;
  }
};
