import React, { useState, useEffect } from "react";
import { AuthIsLogin } from "../../helper/auth";
import { Table, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getOrdersDataThunk } from "../../redux/thunks/OrdersThunks";

import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin2Line } from "react-icons/ri";
import { VscPreview } from "react-icons/vsc";
import { SkSwal } from "../../components/common/SkSwal";
import SkModal from "../../components/common/SkModal";
import Pagination from "../../components/Layout/Pagination";

function Orders() {
  const history = useHistory();
  const dispatch = useDispatch();
  // get state from store
  const orders = useSelector((state) => state.orders);
  const ordersData = orders.ordersData;
  const ordersDataId = orders.ordersDataId;
  // pagination states
  const [pagination, setPagination] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [size, setSize] = useState(10);

  const [trigger, setTrigger] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "Modal Title",
    show: false,
    body: "",
  });

  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  useEffect(() => {
    dispatch(getOrdersDataThunk());
  }, []);

  useEffect(() => {
    setModalContent({
      ...modalContent,
      body: ordersDataId ? (
        ordersDataId !== "error" ? (
          <>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <b>Date</b>
                  <p>{ordersDataId.last_updated}</p>
                </div>
                <div className="form-group">
                  <b>Order ID</b>
                  <p>{ordersDataId.order_id}</p>
                </div>
                <div className="form-group">
                  <b>Courier</b>
                  <p>{ordersDataId.courier}</p>
                </div>
                <div className="form-group">
                  <b>Destination</b>
                  <p>{ordersDataId.destination}</p>
                </div>
                <div className="form-group">
                  <b>Fee</b>
                  <p>{ordersDataId.fee}</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <b>Name</b>
                  <p>{ordersDataId.name}</p>
                </div>
                <div className="form-group">
                  <b>Items</b>
                  {ordersDataId.items.map((e, i) => {
                    if (e.certificate_id) {
                      return (
                        <>
                          <img
                            src={e.certificate_url}
                            style={{ maxWidth: "100%" }}
                          />
                          <p>{e.certificate_url}</p>
                        </>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>Error</>
        )
      ) : (
        <>Loading...</>
      ),
    });
  }, [ordersDataId]);

  useEffect(() => {
    if (ordersData?.pagination) setPagination(ordersData.pagination);
  }, [ordersData]);

  const handleShow = (id) => {
    dispatch(getOrdersDataThunk(id));
    setModalContent({
      ...modalContent,
      show: true,
      title: "Menampilkan Detail Order",
    });
  };

  const paginationSelect = (newCurrPage) => {
    setCurrPage(newCurrPage);
    dispatch(
      getOrdersDataThunk({ param: `?size=${size}&page=${newCurrPage}` })
    );
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="bank-soal__title mb-2">Orders</div>
            <div className="fw-400 my-2">
              Total Items: {ordersData && ordersData?.pagination?.totalItems}
            </div>
          </div>
        </div>
        <Card
          className="question-quest-wrapper-scrollbar mb-4"
          style={{ width: "100%" }}
        >
          <div className="row ">
            <div className="col-sm-12 d-flex justify-content-end mb-3">
              {pagination ? (
                <Pagination
                  size={size}
                  totalRows={pagination?.totalItems}
                  totalPages={pagination?.totalPages}
                  paginationSelect={paginationSelect}
                  active={currPage}
                />
              ) : null}
            </div>
            <div className="col-sm-12">
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>User</th>
                    <th>Items</th>
                    <th>Courier</th>
                    <th>Fee</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersData &&
                    ordersData?.items?.map((e, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{e.user_id}</td>
                            <td>{e.items}</td>
                            <td>{e.courier}</td>
                            <td>{e.fee}</td>
                            <td className="display-grid">
                              <Button
                                className="mb-2"
                                variant="info"
                                onClick={() => handleShow(e.order_id)}
                              >
                                <VscPreview className="fill-white" />
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </div>
        </Card>
      </div>
      <SkModal content={modalContent} button={false} />
    </>
  );
}

export default Orders;
