import { PromiseService } from "../../services/PromiseService";
import { SCHEMES_DATA_LINK } from "../config/SchemesConfig";
import {
  //get
  getSchemesData,
  getSchemesDataSuccess,
  getSchemesDataIdSuccess,
  getSchemesDataFail,
  //post
  postSchemesData,
  postSchemesDataSuccess,
  postSchemesDataFail,
  // put
  putSchemesData,
  putSchemesDataSuccess,
  putSchemesDataFail,
  // delete
  deleteSchemesData,
  deleteSchemesDataSuccess,
  deleteSchemesDataFail,
} from "../actions/SchemesActions";

// get
export const getSchemesDataThunk = (data) => async (dispatch) => {
  dispatch(getSchemesData(data));
  if (data) {
    let url = `${SCHEMES_DATA_LINK}/${data}`;
    if (data.param) url = `${SCHEMES_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getSchemesDataSuccess(response.data));
        else dispatch(getSchemesDataIdSuccess(response.data));
      } else {
        dispatch(getSchemesDataFail(response));
      }
    } catch (error) {
      dispatch(getSchemesDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(SCHEMES_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getSchemesDataSuccess(response.data));
      } else {
        dispatch(getSchemesDataFail(response));
      }
    } catch (error) {
      dispatch(getSchemesDataFail(error));
    }
  }
};

// post
export const postSchemesDataThunk = (data) => async (dispatch) => {
  dispatch(postSchemesData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(SCHEMES_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postSchemesDataSuccess(response.data));
    } else {
      dispatch(postSchemesDataFail(response));
    }
  } catch (error) {
    dispatch(postSchemesDataFail(error));
  }
};

// put
export const putSchemesDataThunk = (data) => async (dispatch) => {
  dispatch(putSchemesData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${SCHEMES_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putSchemesDataSuccess(response.data));
    } else {
      dispatch(putSchemesDataFail(response));
    }
  } catch (error) {
    dispatch(putSchemesDataFail(error));
  }
};

// delete
export const deleteSchemesDataThunk = (data) => async (dispatch) => {
  dispatch(deleteSchemesData(data));
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${SCHEMES_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteSchemesDataSuccess(response.data));
    } else {
      dispatch(deleteSchemesDataFail(response));
    }
  } catch (error) {
    dispatch(deleteSchemesDataFail(error));
  }
};
