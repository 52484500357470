import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { UserService } from "../../http/UserHttp";
import { Table, Form, Card, InputGroup, Button } from "react-bootstrap";
import moment from "moment";
// import ReactJson from "react-json-view";

function UserDetail() {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [status, setStatus] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [searchId, setSearchId] = useState("");
  const history = useHistory();
  const location = useLocation();

  /**
   * Get user by ID
   * when component renders
   */
  useEffect(() => {
    if (id) {
      getUserById(id);
      setSearchId(id);
    }
    setLoaded(true);
  }, [location]);

  /**
   * function to get user information
   * by using user id
   * takes string as param
   * returns nothing
   */
  const getUserById = async (userId) => {
    try {
      const response = await UserService.getById(userId);
      // validate if response success
      if (response.user_id) {
        setStatus("success");
        setUserData(response);
      } else {
        setStatus("error");
      }
    } catch (error) {
      setUserData(null)
      setStatus("error");
    }
  };

  /**
   * @function to handle search click
   * @param nothing
   * @return nothing
   */
  const handleSearch = () => {
    history.push(`/dashboard/users/${searchId}`);
  };

  /**
   * @function to handle change in search field
   * @param {Object} e
   * @return nothing
   */
  const handleChange = (e) => {
    e.preventDefault();
    setSearchId(e.target.value);
  };

  return (
    <>
      <div className="bank-soal__title mb-2">Detail User</div>
      <div className="contianer">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <Form.Group className="mb-3">
              <Form.Label
                className="fw-600"
                style={{ textTransform: "capitalize" }}
              >
                {`User ID`}
              </Form.Label>
              <div className="input-group mb-3">
                <Form.Control
                  onChange={handleChange}
                  id={`user_id`}
                  name={`user_id`}
                  placeholder={`Enter User ID`}
                  type={`text`}
                  value={searchId}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div id="response_catalogue_id"></div>
              <div className="messages catalogue_id"></div>
            </Form.Group>
          </div>
        </div>
      </div>
      <div>
        {status === "loading" ? (
          <p>Loading data...</p>
        ) : (
          status === "error" && <p>User not found.</p>
        )}
        {
          userData && loaded && (
            <div>
              <div className="mb-2">
                <b>
                  Nama :
                </b>
                {" "}<span>{userData?.name}</span></div>
              <div className="mb-2"><b>
                Saldo :
              </b>
                {" "} <span>{userData?.balance}</span></div>
              <div className="mb-2"><b>
                Email :
              </b>{" "}
                <span>{userData?.email}</span></div>
              <div className="mb-2"><b>
                Membership
              </b>
              </div>
              <Card className="mb-4">
                <Card.Body>
                  <Table>
                    <thead>
                      <tr>
                        <th>Membership</th>
                        <th>Status</th>
                        <th>Expired At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData?.memberships?.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.membership_title === null ? "Membership" : item.membership_title}</td>
                            <td>{item.status}</td>
                            <td>{moment(item.expired_at).format("DD-MM-YYYY")}</td>
                          </tr >
                        )
                      }
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>

              <div className="mb-2">
                <b>
                  My Certificate
                </b>
              </div>
              <Card className="mb-4">
                <Card.Body>
                  <Table>
                    <thead>
                      <tr>
                        <th>Judul Sertifikasi</th>
                        <th>Nilai</th>
                        {/* <th>Preview Sertifikat</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {userData?.my_certificates?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.course}</td>
                            <td>{item.score}</td>
                            {/* <td>{item.</td> */}
                          </tr >
                        )
                      }
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <div className="mb-2">
                <b>
                  My Course
                </b>
              </div>
              <Card className="mb-4">
                <Card.Body>
                  <Table>
                    <thead>
                      <tr>
                        <th>Nama Course</th>
                        <th>Progress</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData?.my_courses?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.course}</td>
                            <td>{item.progress}</td>
                            <td>{item.status}</td>
                            {/* <td>{item.</td> */}
                          </tr >
                        )
                      }
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <div className="mb-2">
                <b>
                  My Webinar
                </b>
              </div>
              <Card className="mb-4">
                <Card.Body>
                  <Table>
                    <thead>
                      <tr>
                        <th>Nama Course</th>
                        <th>Status Feedback</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData?.my_webinars?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.course}</td>
                            <td>{item.feedback === 1 ? 'Sudah Memberi Feedback' : 'Belum Memberi Feedback'}</td>
                            {/* <td>{item.</td> */}
                          </tr >
                        )
                      }
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              {/* {id}: <pre>{JSON.stringify(userData, undefined, 2)}</pre> */}
            </div>
          )
          // <ReactJson src={userData} name={id} displayDataTypes={false} collapsed={1} />
        }
      </div>
    </>
  );
}

export default UserDetail;
