export const QUESTIONS_DATA_LINK = "/questions";
export const QUESTIONS_DATA_LINK_POST = "/questions/import";

export const QUESTIONS_DATA = "QUESTIONS_DATA";
export const QUESTIONS_DATA_SUCCESS = "QUESTIONS_DATA_SUCCESS";
export const QUESTIONS_DATA_ID_SUCCESS = "QUESTIONS_DATA_ID_SUCCESS";
export const QUESTIONS_DATA_FAIL = "QUESTIONS_DATA_FAIL";

export const QUESTIONS_DATA_POST = "QUESTIONS_DATA_POST";
export const QUESTIONS_DATA_POST_SUCCESS = "QUESTIONS_DATA_POST_SUCCESS";
export const QUESTIONS_DATA_POST_FAIL = "QUESTIONS_DATA_POST_FAIL";

export const QUESTIONS_DATA_PUT = "QUESTIONS_DATA_PUT";
export const QUESTIONS_DATA_PUT_SUCCESS = "QUESTIONS_DATA_PUT_SUCCESS";
export const QUESTIONS_DATA_PUT_FAIL = "QUESTIONS_DATA_PUT_FAIL";

export const QUESTIONS_DATA_DELETE = "QUESTIONS_DATA_DELETE";
export const QUESTIONS_DATA_DELETE_SUCCESS = "QUESTIONS_DATA_DELETE_SUCCESS";
export const QUESTIONS_DATA_DELETE_FAIL = "QUESTIONS_DATA_DELETE_FAIL";
