import React, { useEffect, useState } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/Dashboard/index.css";

import styled from "styled-components";
import { SELECT_DEFAULT_STYLES } from "../../helper/cssStyledComponent";
import { SkValidate } from "../../components/common/SkValidate";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
import { MembershipService } from "../../http/MembershipHttp";
import moment from "moment";
import {
  loadingStart,
  loadingFinish,
} from "../../redux/reducers/CommonReducers";

const MySwal = withReactContent(Swal);

function RegisterMembership() {
  let defaultData = {
    user_id: "",
    expired_at: "",
    bill_amount: 0,
    catalogue_id: "",
  };
  const learning_paths = useSelector((state) => state.learning_paths);
  const memberships = useSelector((state) => state.memberships);
  const [data, setData] = useState(defaultData);
  const [trigger, setTrigger] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const constraints = {
    user_id: {
      presence: true,
    },
    expired_at: {
      presence: true,
    },
    bill_amount: {
      presence: true,
      numericality: { onlyInteger: true, greaterThanOrEqualTo: 0 },
    },
    catalogue_id: {
      // presence: true,
    },
  };

  useEffect(() => {
    setLoaded(true);
  }, [loaded]);

  /**
   * @function to handle changes in form
   * @param {Object} e
   * @returns nothing
   */
  const handleChange = (e) => {
    e.preventDefault();
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  /**
   * @function to handle form submission
   * @param {Object} e
   * @returns nothing
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      const payload = { ...data };
      payload.bill_amount = parseInt(data.bill_amount);
      payload.expired_at = moment(payload.expired_at).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      registerMembership(payload);
    }
  };

  /**
   * @function to register membership to a user to API
   * @param {Object} payload
   * @returns nothing
   */
  const registerMembership = async (payload) => {
    dispatch(loadingStart());
    try {
      const response = await MembershipService.registerMembership(payload);
      console.log(response);
      setData(defaultData);
      displayPopup(false, "Berhasil Mendaftarkan Membership", "Oke");
    } catch (error) {
      displayPopup(false, "Gagal Mendaftarkan Membership", "Oke");
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  /**
   * @function to display popup
   * @param {boolean} cancel
   * @param {string} title
   * @param {string} confirm
   * @param {*} closeTrigger
   * @returns nothing
   */
  function displayPopup(cancel, title, confirm, closeTrigger) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }

  /**
   * @function to generate form fields
   * @param {string} field
   * @param {string} type
   * @returns HTML DOM
   */
  const generateField = (field, type) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label className="fw-600" style={{ textTransform: "capitalize" }}>
          {field}
        </Form.Label>
        <Form.Control
          onChange={handleChange}
          id={field}
          name={field}
          placeholder={`Enter ${field}`}
          type={type}
          value={data[field]}
        />
        <div className="messages"></div>
      </Form.Group>
    );
  };

  /**
   * @function to get membership catalogue by id from api
   * @param {string} catalogue_id
   * @returns nothing
   */
  const getMembershipCatalogue = async (catalogue_id) => {
    let html;
    dispatch(loadingStart());
    try {
      const response = await MembershipService.getMembershipCatalogue(
        catalogue_id
      );
      const price = response.price;
      const duration = response.duration;
      // expired = today + duration
      let expired = moment().add(duration, "d");
      let expired_at = moment(expired).format("YYYY-MM-DDTHH:MM");
      setData({ ...data, bill_amount: price, expired_at: expired_at });
      html = `
      <div><strong>${response.title}</strong></div>
      <div>Harga: Rp ${price}</div>
      <div>Durasi: ${duration} hari</div>
      <div>Expired: ${moment(expired).format("YYYY-MM-DD HH:MM")}</div>
      `;
      document.getElementById("response_catalogue_id").innerHTML = html;
    } catch (error) {
      console.log(error);
      html = "Data tidak ditemukan";
    }
    dispatch(loadingFinish());
    document.getElementById("response_catalogue_id").innerHTML = html;
  };

  return (
    <StyledWrapperMain>
      <Container>
        <Row className="justify-content-center align-items-center mt-2 height-inherit">
          <Col xs={12} sm={12} md={9} lg={9}>
            <div className="">
              <div className="course_create__title">Daftarkan Membership</div>
              <div className="course_create__subtitle"></div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={9} lg={9}>
            <StyledWrapperMain>
              <Card className="card_container mb-4">
                <div className="card_inside__padding ">
                  {loaded ? (
                    <Form onSubmit={handleSubmit}>
                      {/* user_id */}
                      {generateField("user_id", "text")}

                      {/* catalogue_id */}
                      <Form.Group className="mb-3">
                        <Form.Label
                          className="fw-600"
                          style={{ textTransform: "capitalize" }}
                        >
                          {`catalogue_id`}
                        </Form.Label>
                        <div className="input-group mb-3">
                          <Form.Control
                            onChange={handleChange}
                            id={`catalogue_id`}
                            name={`catalogue_id`}
                            placeholder={`Enter catalogue_id`}
                            type={`text`}
                            value={data.catalogue_id}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-secondary"
                              type="button"
                              onClick={() =>
                                getMembershipCatalogue(data.catalogue_id)
                              }
                            >
                              Search
                            </button>
                          </div>
                        </div>
                        <div id="response_catalogue_id"></div>
                        <div className="messages catalogue_id"></div>
                      </Form.Group>

                      {/* expired_at */}
                      {generateField("expired_at", "datetime-local")}

                      {/* bill_amount */}
                      {generateField("bill_amount", "text")}

                      <div className="d-flex mt-4 justify-content-end">
                        <Button type="submit" className="my-4 mx-2">
                          Tambahkan
                        </Button>
                      </div>
                    </Form>
                  ) : null}
                </div>
              </Card>
            </StyledWrapperMain>
          </Col>
        </Row>
      </Container>
    </StyledWrapperMain>
  );
}

export default RegisterMembership;
const StyledWrapperMain = styled.section`
  ${SELECT_DEFAULT_STYLES}
`;
