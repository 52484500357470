import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./Layout";
import Loading from "./Loading";
import "../../assets/css/Layout/template.css";
import "../../assets/css/Layout/feather.css";
import { getUsersDataThunk } from "../../redux/thunks/UsersThunks";
import { AuthData } from "../../helper/auth";

function Template({ InnerComponent, isSidebar, isHeader }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const companies = useSelector((state) => state.companies.isLoading);
  const courses = useSelector((state) => state.courses.isLoading);
  const course_types = useSelector((state) => state.course_types.isLoading);
  const learning_paths = useSelector((state) => state.learning_paths.isLoading);
  const login = useSelector((state) => state.login.isLoading);
  const quizzes = useSelector((state) => state.quiz_themes.isLoading);
  const users = useSelector((state) => state.users.isLoading);
  const questions = useSelector((state) => state.questions.isLoading);
  const products = useSelector((state) => state.products.isLoading);
  const delivery_method = useSelector(
    (state) => state.delivery_method.isLoading
  );
  const discussion_topics = useSelector(
    (state) => state.discussion_topics.isLoading
  );
  const skill_types = useSelector((state) => state.skill_types.isLoading);
  const categories = useSelector((state) => state.categories.isLoading);
  const sub_categories = useSelector((state) => state.sub_categories.isLoading);
  const positions = useSelector((state) => state.positions.isLoading);
  const learning_levels = useSelector(
    (state) => state.learning_levels.isLoading
  );
  const industries = useSelector((state) => state.industries.isLoading);
  const sectors = useSelector((state) => state.sectors.isLoading);
  const sub_industries = useSelector((state) => state.sub_industries.isLoading);
  const schemes = useSelector((state) => state.schemes.isLoading);
  const common = useSelector((state) => state.common.isLoading);

  // useEffect(() => {
  //   if (AuthIsLogin("", true)) dispatch(getUsersData(AuthData().userId));
  // }, []);

  // useEffect(() => {
  //   if (logins.loginData) dispatch(getUsersData(logins.loginData.data.user_id));
  // }, [logins.loginData]);
  useEffect(() => {
    if (AuthData()) dispatch(getUsersDataThunk(AuthData().userId));
  }, []);
  useEffect(() => {
    if (
      !courses &&
      !course_types &&
      !learning_paths &&
      !companies &&
      !login &&
      !quizzes &&
      !users &&
      !questions &&
      !products &&
      !delivery_method &&
      !schemes &&
      !discussion_topics &&
      !skill_types &&
      !categories &&
      !sub_categories &&
      !positions &&
      !learning_levels &&
      !industries &&
      !sectors &&
      !sub_industries &&
      !common
    )
      setIsLoading(false);
    else setIsLoading(true);
  }, [
    courses,
    course_types,
    learning_paths,
    companies,
    login,
    quizzes,
    users,
    questions,
    products,
    delivery_method,
    schemes,
    discussion_topics,
    skill_types,
    categories,
    sub_categories,
    positions,
    learning_levels,
    industries,
    sectors,
    sub_industries,
    common
  ]);

  return (
    <>
      <Loading isLoading={isLoading} />
      {isSidebar || isHeader ? (
        <Layout InnerComponent={InnerComponent} />
      ) : (
        <InnerComponent />
      )}
    </>
  );
}

export default Template;
