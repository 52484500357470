import React, { useState, useEffect } from "react";
import { AuthIsLogin } from "../../helper/auth";
import { FaEdit, FaRegEdit, FaTimes } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import { VscPreview } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import FileUploader from "../../components/FileUploader";
import {
  Modal,
  Button,
  InputGroup,
  FormControl,
  Table,
  Card,
  Form,
} from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/Layout/Pagination";
import ViewQuestion from "../../components/ViewQuestion";
import EditQuestion from "../../components/EditQuestion";
import { Link } from "react-router-dom";
import "../../assets/css/Dashboard/questions.css";
import "../../assets/css/Dashboard/quiz.css";
import {
  getQuizThemeDataThunk,
  deleteQuizThemeDataThunk,
  postQuizThemeDataThunk,
  putQuizThemeDataThunk,
} from "../../redux/thunks/QuizThemeThunks";

const MySwal = withReactContent(Swal);

function TemaSoal() {
  //pagination states
  const [active, setActive] = useState(1);
  const [size, setSize] = useState(10); //pagination size

  const [search, setSearch] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [questionPagination, setQuestionPagination] = useState();
  const [checked, setChecked] = useState([]);

  //redux methods
  const dispatch = useDispatch();
  const history = useHistory();

  //modal states & functions
  const [pageOpen, setPageOpen] = useState("");
  const [text, setText] = useState("");
  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (pageOpen === "Unggah" || pageOpen === "Edit")
      // dispatch(getQuestionsData({ param: `?size=${size}&page=${active}` }));
      dispatch(
        getQuizThemeDataThunk({ param: `?size=${size}&page=${active}` })
      );
  };
  const handleShow = (page, value) => {
    setPageOpen(page);
    setData(value);
    setShow(true);
  };
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    pageOpen === "Edit" ? setData(newData) : setText(e.target.value);
    console.log(newData);
  };

  //redux taken from store
  const quiz_themes = useSelector((state) => state.quiz_themes);
  const quizThemeData = quiz_themes.quizThemeData;

  //functions
  useEffect(() => {
    AuthIsLogin(history);
    // dispatch(getQuestionsData({ param: `?size=${size}&page=${active}` }));
    dispatch(getQuizThemeDataThunk({ param: `?size=${size}&page=${active}` }));
  }, []);

  function paginationSelect(newActive) {
    setActive(newActive);
    // dispatch(getQuestionsData({ param: `?size=${size}&page=${newActive}` }));
    dispatch(
      getQuizThemeDataThunk({ param: `?size=${size}&page=${newActive}` })
    );
  }

  function addQuizTheme() {
    setTrigger(true);
    if (pageOpen === "Edit") {
      const newData = {
        body: {
          title: data.title,
        },
        id: data.theme_id,
      };
      dispatch(putQuizThemeDataThunk(newData));
    } else {
      dispatch(postQuizThemeDataThunk({ title: text }));
    }
  }

  useEffect(() => {
    if (quiz_themes.quizThemeDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
    }
    if (quiz_themes.quizThemeDataDelete === "success" && trigger) {
      setTrigger(false);
      // dispatch(getQuestionsData({ param: `?size=${size}&page=${active}` }));
      dispatch(
        getQuizThemeDataThunk({ param: `?size=${size}&page=${active}` })
      );
    }
    if (quiz_themes.quizThemeDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat memasukkan data", "Ok");
    }
    if (quiz_themes.quizThemeDataPost === "success" && trigger) {
      setTrigger(false);
      setShow(false);
      // dispatch(getQuestionsData({ param: `?size=${size}&page=${active}` }));
      dispatch(
        getQuizThemeDataThunk({ param: `?size=${size}&page=${active}` })
      );
    }
    if (quiz_themes.quizThemeDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat memasukkan data", "Ok");
    }
    if (quiz_themes.quizThemeDataPut === "success" && trigger) {
      setTrigger(false);
      setShow(false);
      // dispatch(getQuestionsData({ param: `?size=${size}&page=${active}` }));
      dispatch(
        getQuizThemeDataThunk({ param: `?size=${size}&page=${active}` })
      );
    }
  }, [quiz_themes]);
  useEffect(() => {}, [quiz_themes.quizThemeDataDelete]);
  useEffect(() => {
    setQuestionPagination(quizThemeData.pagination);
    let newChecked = [];
    // console.log(checked.length, quizThemeData.pagination);
    if (checked.length <= 0 && quizThemeData.pagination) {
      for (let i = 0; i < quizThemeData.pagination.totalItems; i++) {
        newChecked.push(false);
      }
      setChecked(newChecked);
    }
  }, [quizThemeData]);

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        dispatch(deleteQuizThemeDataThunk(id));
      }
    });
  };

  function keydownHandler(e) {
    if (e.keyCode == 13) {
      setActive(1);
      dispatch(
        getQuizThemeDataThunk({
          param: `?size=${size}&page=1${search ? "&keyword=" + search : ""}`,
        })
      );
    }
  }

  function onChangeSearch(e) {
    const newValue = e.target.value;
    setSearch(newValue);
  }

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div>
          <div className="bank-soal__title mb-2">Tema Soal</div>{" "}
          <div className="fw-400 my-2">
            Total Tema: {quizThemeData?.pagination?.totalItems}
          </div>
        </div>
        <Button
          className="py-2 px-3 mb-4 bank-soal__primary"
          onClick={() => {
            handleShow("Unggah");
          }}
        >
          Tambahkan Tema Soal
        </Button>
      </div>

      <Card
        className="question-quest-wrapper-scrollbar mb-4"
        style={{ width: "100%" }}
      >
        <div className="d-flex layout-media">
          <InputGroup className="mb-3" style={{ width: "300px" }}>
            <FormControl
              onKeyDown={keydownHandler}
              onChange={onChangeSearch}
              placeholder="Cari Tema Soal"
            />
            {/* <Button
              className="bank-soal__primary disabled"
              id="button-addon2"
              disabled
            >
              Cari
            </Button> */}
          </InputGroup>
          {questionPagination ? (
            <Pagination
              size={size}
              totalRows={questionPagination.totalItems}
              totalPages={questionPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              {/* <th>Pilih</th> */}
              <th className="w-75">Judul</th>
              <th className="w-75">ID Tema</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {quizThemeData.items ? (
              quizThemeData.items.map((value, index) => {
                return (
                  <tr key={index}>
                    {/* <td>
                        <Button
                          variant={`${
                            checked[index] ? "success" : "secondary"
                          }`}
                          style={{ color: "#00000000" }}
                          onClick={() => {
                            let newChecked = [...checked];
                            newChecked[index] = !checked[index];
                            setChecked(newChecked);
                          }}
                        >
                          X
                        </Button>
                      </td> */}
                    <td className="w-100">{value.title}</td>
                    <td className="w-100">{value.theme_id}</td>
                    <td className="display-grid">
                      <Button
                        onClick={() => {
                          handleShow("Edit", value);
                        }}
                        className="mb-2"
                      >
                        <FaRegEdit className="fill-white" />
                      </Button>
                      <Button
                        onClick={() => {
                          handleDelete(value.theme_id);
                        }}
                        className="mb-2"
                        variant="danger"
                      >
                        <RiDeleteBin2Line className="fill-white" />
                      </Button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={2} className="text-center">
                  Tidak ada pelatihan
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
      <Modal
        show={show}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ overflow: "auto", borderRadius: "24px" }}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="modal-header__title px-2"
          >
            {pageOpen} Tema Soal
          </Modal.Title>
          <div onClick={handleClose}>
            <FaTimes />
          </div>
        </Modal.Header>
        <Modal.Body className="px-0 py-1">
          <Form.Group className="p-3">
            {pageOpen === "Edit" ? (
              <>
                <Form.Label className="fw-600 p-2">
                  {pageOpen} Tema Soal
                </Form.Label>
                <Form.Control
                  onChange={handleChange}
                  className="mb-3"
                  type="text"
                  id="title"
                  name="title"
                  value={data.title}
                  placeholder="Ubah Tema Soal"
                />
                <Button onClick={addQuizTheme}>Ubah</Button>
              </>
            ) : (
              <>
                <Form.Label className="fw-600 p-2">
                  {pageOpen} Tema Soal
                </Form.Label>
                <Form.Control
                  onChange={handleChange}
                  className="mb-3"
                  type="text"
                  id="quiz_theme"
                  value={text}
                  placeholder="Masukkan Tema Soal"
                />
                <Button onClick={addQuizTheme}>+Tambahkan</Button>
              </>
            )}
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TemaSoal;
