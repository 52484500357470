import {
  // get
  SKILL_TYPES_DATA,
  SKILL_TYPES_DATA_SUCCESS,
  SKILL_TYPES_DATA_ID_SUCCESS,
  // post
  SKILL_TYPES_DATA_POST,
  SKILL_TYPES_DATA_POST_SUCCESS,
  SKILL_TYPES_DATA_POST_FAIL,
  // put
  SKILL_TYPES_DATA_PUT,
  SKILL_TYPES_DATA_PUT_SUCCESS,
  SKILL_TYPES_DATA_PUT_FAIL,
  // delete
  SKILL_TYPES_DATA_DELETE,
  SKILL_TYPES_DATA_DELETE_SUCCESS,
  SKILL_TYPES_DATA_DELETE_FAIL,
  // fail
  SKILL_TYPES_DATA_FAIL,
} from "../config/SkillTypesConfig";

// get
export const getSkillTypesData = (value) => ({
  type: SKILL_TYPES_DATA,
  value,
});
export const getSkillTypesDataSuccess = (value) => ({
  type: SKILL_TYPES_DATA_SUCCESS,
  value,
});
export const getSkillTypesDataIdSuccess = (value) => ({
  type: SKILL_TYPES_DATA_ID_SUCCESS,
  value,
});
export const getSkillTypesDataFail = (value) => ({
  type: SKILL_TYPES_DATA_FAIL,
  value,
});

// post
export const postSkillTypesData = (value) => ({
  type: SKILL_TYPES_DATA_POST,
  value,
});
export const postSkillTypesDataSuccess = (value) => ({
  type: SKILL_TYPES_DATA_POST_SUCCESS,
  value,
});
export const postSkillTypesDataFail = (value) => ({
  type: SKILL_TYPES_DATA_POST_FAIL,
  value,
});

// put
export const putSkillTypesData = (value) => ({
  type: SKILL_TYPES_DATA_PUT,
  value,
});
export const putSkillTypesDataSuccess = (value) => ({
  type: SKILL_TYPES_DATA_PUT_SUCCESS,
  value,
});
export const putSkillTypesDataFail = (value) => ({
  type: SKILL_TYPES_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteSkillTypesData = (value) => ({
  type: SKILL_TYPES_DATA_DELETE,
  value,
});
export const deleteSkillTypesDataSuccess = (value) => ({
  type: SKILL_TYPES_DATA_DELETE_SUCCESS,
  value,
});
export const deleteSkillTypesDataFail = (value) => ({
  type: SKILL_TYPES_DATA_DELETE_FAIL,
  value,
});

// fail handle
