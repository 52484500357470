import {
  // get
  PRODUCTS_DATA,
  PRODUCTS_DATA_SUCCESS,
  PRODUCTS_DATA_ID_SUCCESS,
  // post
  PRODUCTS_DATA_POST,
  PRODUCTS_DATA_POST_SUCCESS,
  PRODUCTS_DATA_POST_FAIL,
  // put
  PRODUCTS_DATA_PUT,
  PRODUCTS_DATA_PUT_SUCCESS,
  PRODUCTS_DATA_PUT_FAIL,
  // delete
  PRODUCTS_DATA_DELETE,
  PRODUCTS_DATA_DELETE_SUCCESS,
  PRODUCTS_DATA_DELETE_FAIL,
  // fail
  PRODUCTS_DATA_FAIL,
  REMOVE_PRODUCTS_DATA,
} from "../config/ProductsConfig";

// get
export const getProductsData = (value) => ({
  type: PRODUCTS_DATA,
  value,
});
export const getProductsDataSuccess = (value) => ({
  type: PRODUCTS_DATA_SUCCESS,
  value,
});
export const getProductsDataIdSuccess = (value) => ({
  type: PRODUCTS_DATA_ID_SUCCESS,
  value,
});
export const getProductsDataFail = (value) => ({
  type: PRODUCTS_DATA_FAIL,
  value,
});

// post
export const postProductsData = (value) => ({
  type: PRODUCTS_DATA_POST,
  value,
});
export const postProductsDataSuccess = (value) => ({
  type: PRODUCTS_DATA_POST_SUCCESS,
  value,
});
export const postProductsDataFail = (value) => ({
  type: PRODUCTS_DATA_POST_FAIL,
  value,
});

// put
export const putProductsData = (value) => ({
  type: PRODUCTS_DATA_PUT,
  value,
});
export const putProductsDataSuccess = (value) => ({
  type: PRODUCTS_DATA_PUT_SUCCESS,
  value,
});
export const putProductsDataFail = (value) => ({
  type: PRODUCTS_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteProductsData = (value) => ({
  type: PRODUCTS_DATA_DELETE,
  value,
});
export const deleteProductsDataSuccess = (value) => ({
  type: PRODUCTS_DATA_DELETE_SUCCESS,
  value,
});
export const deleteProductsDataFail = (value) => ({
  type: PRODUCTS_DATA_DELETE_FAIL,
  value,
});

export const removeProductsData = (value) => ({
  type: REMOVE_PRODUCTS_DATA,
  value,
});

// fail handle
