import {
  // get
  LEARNING_LEVELS_DATA,
  LEARNING_LEVELS_DATA_SUCCESS,
  LEARNING_LEVELS_DATA_ID_SUCCESS,
  // post
  LEARNING_LEVELS_DATA_POST,
  LEARNING_LEVELS_DATA_POST_SUCCESS,
  LEARNING_LEVELS_DATA_POST_FAIL,
  // put
  LEARNING_LEVELS_DATA_PUT,
  LEARNING_LEVELS_DATA_PUT_SUCCESS,
  LEARNING_LEVELS_DATA_PUT_FAIL,
  // delete
  LEARNING_LEVELS_DATA_DELETE,
  LEARNING_LEVELS_DATA_DELETE_SUCCESS,
  LEARNING_LEVELS_DATA_DELETE_FAIL,
  // fail
  LEARNING_LEVELS_DATA_FAIL,
} from "../config/LearningLevelsConfig";

// get
export const getLearningLevelsData = (value) => ({
  type: LEARNING_LEVELS_DATA,
  value,
});
export const getLearningLevelsDataSuccess = (value) => ({
  type: LEARNING_LEVELS_DATA_SUCCESS,
  value,
});
export const getLearningLevelsDataIdSuccess = (value) => ({
  type: LEARNING_LEVELS_DATA_ID_SUCCESS,
  value,
});
export const getLearningLevelsDataFail = (value) => ({
  type: LEARNING_LEVELS_DATA_FAIL,
  value,
});

// post
export const postLearningLevelsData = (value) => ({
  type: LEARNING_LEVELS_DATA_POST,
  value,
});
export const postLearningLevelsDataSuccess = (value) => ({
  type: LEARNING_LEVELS_DATA_POST_SUCCESS,
  value,
});
export const postLearningLevelsDataFail = (value) => ({
  type: LEARNING_LEVELS_DATA_POST_FAIL,
  value,
});

// put
export const putLearningLevelsData = (value) => ({
  type: LEARNING_LEVELS_DATA_PUT,
  value,
});
export const putLearningLevelsDataSuccess = (value) => ({
  type: LEARNING_LEVELS_DATA_PUT_SUCCESS,
  value,
});
export const putLearningLevelsDataFail = (value) => ({
  type: LEARNING_LEVELS_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteLearningLevelsData = (value) => ({
  type: LEARNING_LEVELS_DATA_DELETE,
  value,
});
export const deleteLearningLevelsDataSuccess = (value) => ({
  type: LEARNING_LEVELS_DATA_DELETE_SUCCESS,
  value,
});
export const deleteLearningLevelsDataFail = (value) => ({
  type: LEARNING_LEVELS_DATA_DELETE_FAIL,
  value,
});

// fail handle
