import {
  //get
  SCHEMES_DATA,
  SCHEMES_DATA_ID_SUCCESS,
  SCHEMES_DATA_SUCCESS,
  SCHEMES_DATA_FAIL,
  //post
  SCHEMES_DATA_POST,
  SCHEMES_DATA_POST_SUCCESS,
  SCHEMES_DATA_POST_FAIL,
  //put
  SCHEMES_DATA_PUT,
  SCHEMES_DATA_PUT_SUCCESS,
  SCHEMES_DATA_PUT_FAIL,
  //delete
  SCHEMES_DATA_DELETE,
  SCHEMES_DATA_DELETE_SUCCESS,
  SCHEMES_DATA_DELETE_FAIL,
  REMOVE_SCHEMES_DATA,
} from "../config/SchemesConfig";

const intialState = {
  schemesDataPost: null,
  schemesDataPut: null,
  schemesDataDelete: null,
  schemesData: null,
  schemesDataId: null,
  isLoading: false,
};

export const SchemesReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case SCHEMES_DATA:
      return { ...state, isLoading: true };
    case SCHEMES_DATA_SUCCESS:
      return { ...state, isLoading: false, schemesData: action.value };
    case SCHEMES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, schemesDataId: action.value };
    case SCHEMES_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        schemesData: "error",
        schemesDataId: "error",
      };
    //post
    case SCHEMES_DATA_POST:
      return {
        ...state,
        isLoading: true,
        schemesDataPost: null,
        schemesDataPut: null,
        schemesDataDelete: null,
      };
    case SCHEMES_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, schemesDataPost: action.value };
    case SCHEMES_DATA_POST_FAIL:
      return { ...state, isLoading: false, schemesDataPost: "error" };

    //put
    case SCHEMES_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        schemesDataPost: null,
        schemesDataPut: null,
        schemesDataDelete: null,
      };
    case SCHEMES_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, schemesDataPut: "success" };
    case SCHEMES_DATA_PUT_FAIL:
      return { ...state, isLoading: false, schemesDataPut: "error" };

    //delete
    case SCHEMES_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        schemesDataPost: null,
        schemesDataPut: null,
        schemesDataDelete: null,
      };
    case SCHEMES_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, schemesDataDelete: "success" };
    case SCHEMES_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, schemesDataDelete: "error" };
    case REMOVE_SCHEMES_DATA:
      return { ...state, schemesDataDelete: null };

    default:
      return state;
  }
};
