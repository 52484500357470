import {
    // get
    INBOX_DATA,
    INBOX_DATA_ID_SUCCESS,
    INBOX_DATA_SUCCESS,
    INBOX_DATA_FAIL,
    //delete
    INBOX_DATA_DELETE,
    INBOX_DATA_DELETE_SUCCESS,
    INBOX_DATA_DELETE_FAIL,
} from "../config/InboxConfig";

// get
export const getInboxData = (value) => ({
    type: INBOX_DATA,
    value,
});
export const getInboxDataSuccess = (value) => ({
    type: INBOX_DATA_SUCCESS,
    value,
});
export const getInboxDataIdSuccess = (value) => ({
    type: INBOX_DATA_ID_SUCCESS,
    value,
});
export const getInboxDataFail = (value) => ({
    type: INBOX_DATA_FAIL,
    value,
});

// delete
export const deleteInboxData = (value) => ({
    type: INBOX_DATA_DELETE,
    value,
});
export const deleteInboxDataSuccess = (value) => ({
    type: INBOX_DATA_DELETE_SUCCESS,
    value,
});
export const deleteInboxDataFail = (value) => ({
    type: INBOX_DATA_DELETE_FAIL,
    value,
});