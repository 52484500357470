import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { CheckIfEmpty, Capitalize, Clone } from "../helper/functions";
import {
  getQuestionsDataThunk,
  putQuestionsDataThunk,
} from "../redux/thunks/QuestionsThunks";
import { getQuizThemeDataThunk } from "../redux/thunks/QuizThemeThunks";
import { SELECT_DEFAULT_STYLES } from "../helper/cssStyledComponent";
const MySwal = withReactContent(Swal);

function EditQuestion({ id, handleClose }) {
  const [data, setData] = useState();
  const [trigger, setTrigger] = useState(false);
  const dispatch = useDispatch();

  const questions = useSelector((state) => state.questions);
  const quiz_themes = useSelector((state) => state.quiz_themes);
  const questionsDataId = questions.questionsDataId;
  const questionsDataPut = questions.questionsDataPut;
  const quizThemeData = quiz_themes.quizThemeData;

  useEffect(() => {
    if (questionsDataId) setData(questionsDataId);
  }, [questionsDataId]);

  const handleChange = (e) => {
    const newData = { ...data };
    if (e.target.id === "weight") {
      if (e.target.value === "") {
        newData[e.target.id] = e.target.value;
      } else {
        newData[e.target.id] = parseInt(e.target.value);
      }
    } else {
      newData[e.target.id] = e.target.value;
    }
    setData(newData);
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    dispatch(getQuizThemeDataThunk());
    dispatch(getQuestionsDataThunk(id));
  }, []);

  useEffect(() => {
    if (questionsDataPut === "fail" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat edit data", "Ok");
    }
    if (questionsDataPut === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Edit Data Sukses", "Ok", true);
    }
  }, [questionsDataPut]);

  function uploadData() {
    const tmp = {
      title: data.title,
      question: data.question,
      choice_a: data.choice_a,
      choice_b: data.choice_b,
      choice_c: data.choice_c,
      choice_d: data.choice_d,
      correct_choice: data.correct_choice,
      weight: data.weight,
      theme_id: data.theme_id,
    };
    if (CheckIfEmpty(tmp)) {
      displayPopup(false, "Mohon diisi semua", "Ok");
    } else {
      setTrigger(true);
      let newData = Clone(defaultData);
      Object.keys(newData).forEach(function (key) {
        if (newData[key] == null || newData[key] == 0) {
          newData[key] = data[key];
        }
      });
      let req = { id: "", body: "" };
      req.id = id;
      req.body = newData;
      dispatch(putQuestionsDataThunk(req));
    }
  }

  function displayPopup(cancel, title, confirm, closeTrigger) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (closeTrigger && value.isConfirmed) handleClose();
    });
  }

  return (
    <StyledWrapperMain>
      <div className="p-3 br-16" style={{ width: "100" }}>
        <Form onSubmit={handleSubmit}>
          {data && (
            <>
              {/* TEMA */}
              {/* <Form.Group>
                <Form.Label className="fw-600"> Tema</Form.Label>
                <Form.Control
                  required
                  as="select"
                  type="select"
                  name="theme_id"
                  id="theme_id"
                  value={data.theme_id}
                  onChange={handleChange}
                >
                  <option hidden value key="blankChoice">
                    Pilih Tema Quiz...
                  </option>
                  {quizThemeData.items
                    ? quizThemeData.items.map((value, index) => {
                        return (
                          <option value={value.theme_id}>{value.title}</option>
                        );
                      })
                    : null}
                </Form.Control>
              </Form.Group> */}
              
              {/* JUDUL */}
              <Form.Group>
                <Form.Label className="fw-600">Judul</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  type="text"
                  id={"title"}
                  value={data.title}
                />
              </Form.Group>

              {/* PERTANYAAN */}
              <Form.Group>
                <Form.Label className="fw-600">Pertanyaan</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  as="textarea"
                  id={"question"}
                  rows={5}
                  value={data.question}
                />
              </Form.Group>

              <div className="row">
                {/* PILIHAN A */}
                <Form.Group className="col-sm-12 col-md-6">
                  <Form.Label className="fw-600">Pilihan A</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type="text"
                    id={"choice_a"}
                    value={data.choice_a}
                  />
                </Form.Group>
                {/* PILIHAN B */}
                <Form.Group className="col-sm-12 col-md-6">
                  <Form.Label className="fw-600">Pilihan B</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type="text"
                    id={"choice_b"}
                    value={data.choice_b}
                  />
                </Form.Group>
                {/* PILIHAN C */}
                <Form.Group className="col-sm-12 col-md-6">
                  <Form.Label className="fw-600">Pilihan C</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type="text"
                    id={"choice_c"}
                    value={data.choice_c}
                  />
                </Form.Group>
                {/* PILIHAN D */}
                <Form.Group className="col-sm-12 col-md-6">
                  <Form.Label className="fw-600">Pilihan D</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type="text"
                    id={"choice_d"}
                    value={data.choice_d}
                  />
                </Form.Group>
                {/* Jawaban benar */}
                <Form.Group className="col-sm-12 col-md-6">
                  <Form.Label className="fw-600"> Jawaban Benar </Form.Label>
                  <Form.Control
                    required
                    as="select"
                    type="select"
                    name="correct_choice"
                    id="correct_choice"
                    value={data.correct_choice}
                    onChange={handleChange}
                  >
                    <option hidden value key="blankChoice">
                      Jawaban Benar...
                    </option>
                    <option value={"choice_a"}>Pilihan A</option>
                    <option value={"choice_b"}>Pilihan B</option>
                    <option value={"choice_c"}>Pilihan C</option>
                    <option value={"choice_d"}>Pilihan D</option>
                  </Form.Control>
                </Form.Group>
                {/* BOBOT */}
                <Form.Group className="col-sm-12 col-md-6">
                  <Form.Label className="fw-600">Bobot</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type="text"
                    id={"weight"}
                    value={data.weight}
                  />
                </Form.Group>
              </div>
            </>
          )}
          <div className="text-right">
            <Button variant="primary" type="submit" onClick={uploadData}>
              Edit
            </Button>
            <Button
              variant="outline-dark"
              onClick={handleClose}
              className="ml-2"
            >
              Batal
            </Button>
          </div>
        </Form>
      </div>
    </StyledWrapperMain>
  );
}

export default EditQuestion;

const StyledWrapperMain = styled.section`
  ${SELECT_DEFAULT_STYLES}
`;

const defaultData = {
  choice_a: "",
  choice_b: "",
  choice_c: "",
  choice_d: "",
  correct_choice: "",
  course_id: "",
  question: "",
  theme_id: "",
  title: "",
  weight: 0,
};
