import {
  // get
  SUB_INDUSTRIES_DATA,
  SUB_INDUSTRIES_DATA_SUCCESS,
  SUB_INDUSTRIES_DATA_ID_SUCCESS,
  // post
  SUB_INDUSTRIES_DATA_POST,
  SUB_INDUSTRIES_DATA_POST_SUCCESS,
  SUB_INDUSTRIES_DATA_POST_FAIL,
  // put
  SUB_INDUSTRIES_DATA_PUT,
  SUB_INDUSTRIES_DATA_PUT_SUCCESS,
  SUB_INDUSTRIES_DATA_PUT_FAIL,
  // delete
  SUB_INDUSTRIES_DATA_DELETE,
  SUB_INDUSTRIES_DATA_DELETE_SUCCESS,
  SUB_INDUSTRIES_DATA_DELETE_FAIL,
  // fail
  SUB_INDUSTRIES_DATA_FAIL,
  REMOVE_SUB_INDUSTRIES_DATA,
} from "../config/SubIndustriesConfig";

// get
export const getSubIndustriesData = (value) => ({
  type: SUB_INDUSTRIES_DATA,
  value,
});
export const getSubIndustriesDataSuccess = (value) => ({
  type: SUB_INDUSTRIES_DATA_SUCCESS,
  value,
});
export const getSubIndustriesDataIdSuccess = (value) => ({
  type: SUB_INDUSTRIES_DATA_ID_SUCCESS,
  value,
});
export const getSubIndustriesDataFail = (value) => ({
  type: SUB_INDUSTRIES_DATA_FAIL,
  value,
});

// post
export const postSubIndustriesData = (value) => ({
  type: SUB_INDUSTRIES_DATA_POST,
  value,
});
export const postSubIndustriesDataSuccess = (value) => ({
  type: SUB_INDUSTRIES_DATA_POST_SUCCESS,
  value,
});
export const postSubIndustriesDataFail = (value) => ({
  type: SUB_INDUSTRIES_DATA_POST_FAIL,
  value,
});

// put
export const putSubIndustriesData = (value) => ({
  type: SUB_INDUSTRIES_DATA_PUT,
  value,
});
export const putSubIndustriesDataSuccess = (value) => ({
  type: SUB_INDUSTRIES_DATA_PUT_SUCCESS,
  value,
});
export const putSubIndustriesDataFail = (value) => ({
  type: SUB_INDUSTRIES_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteSubIndustriesData = (value) => ({
  type: SUB_INDUSTRIES_DATA_DELETE,
  value,
});
export const deleteSubIndustriesDataSuccess = (value) => ({
  type: SUB_INDUSTRIES_DATA_DELETE_SUCCESS,
  value,
});
export const deleteSubIndustriesDataFail = (value) => ({
  type: SUB_INDUSTRIES_DATA_DELETE_FAIL,
  value,
});

export const removeSubIndustriesData = (value) => ({
  type: REMOVE_SUB_INDUSTRIES_DATA,
  value,
});
// fail handle
