import { PromiseService } from "../../services/PromiseService";
import { PRODUCTS_DATA_LINK } from "../config/ProductsConfig";
import {
  //get
  getProductsData,
  getProductsDataSuccess,
  getProductsDataIdSuccess,
  getProductsDataFail,
  //post
  postProductsData,
  postProductsDataSuccess,
  postProductsDataFail,
  // put
  putProductsData,
  putProductsDataSuccess,
  putProductsDataFail,
  // delete
  deleteProductsData,
  deleteProductsDataSuccess,
  deleteProductsDataFail,
} from "../actions/ProductsActions";

// get
export const getProductsDataThunk = (data) => async (dispatch) => {
  dispatch(getProductsData(data));
  if (data) {
    let url = `${PRODUCTS_DATA_LINK}/${data}`;
    if (data.param) url = `${PRODUCTS_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getProductsDataSuccess(response.data));
        else dispatch(getProductsDataIdSuccess(response.data));
      } else {
        dispatch(getProductsDataFail(response));
      }
    } catch (error) {
      dispatch(getProductsDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(PRODUCTS_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getProductsDataSuccess(response.data));
      } else {
        dispatch(getProductsDataFail(response));
      }
    } catch (error) {
      dispatch(getProductsDataFail(error));
    }
  }
};

// post
export const postProductsDataThunk = (data) => async (dispatch) => {
  dispatch(postProductsData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(PRODUCTS_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postProductsDataSuccess(response.data));
    } else {
      dispatch(postProductsDataFail(response));
    }
  } catch (error) {
    dispatch(postProductsDataFail(error));
  }
};

// put
export const putProductsDataThunk = (data) => async (dispatch) => {
  dispatch(putProductsData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${PRODUCTS_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putProductsDataSuccess(response.data));
    } else {
      dispatch(putProductsDataFail(response));
    }
  } catch (error) {
    dispatch(putProductsDataFail(error));
  }
};

// delete
export const deleteProductsDataThunk = (data) => async (dispatch) => {
  dispatch(deleteProductsData(data));
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${PRODUCTS_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteProductsDataSuccess(response.data));
    } else {
      dispatch(deleteProductsDataFail(response));
    }
  } catch (error) {
    dispatch(deleteProductsDataFail(error));
  }
};
