import React, { useEffect } from "react";
import Routes from "./helper/routes";
import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/es/integration/react";
import ReduxStore from "./redux/store";

function App() {
  return (
    <Provider store={ReduxStore.store}>
      {/* <PersistGate loading={null} persistor={ReduxStore.persistor}> */}
        <Routes />
      {/* </PersistGate> */}
    </Provider>
  );
}

export default App;
