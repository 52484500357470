import React from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
function CreateQuiz() {
  return (
    <Container>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={9} lg={9}>
          <div className="">
            <div className="course_create__title"> Buat Kuis </div>
            <div className="course_create__subtitle">
              Lorem ipsum dolor sit amet
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={9} lg={9}>
          <Card className="card_container mb-4">
            <div className="card_inside__padding ">
              <Form>
                <Form.Group>
                  <Form.Label className="fw-600">ID Tema</Form.Label>
                  <Form.Control className="mb-3" type="text" />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="fw-600">ID Pelajaran</Form.Label>
                  <Form.Control className="mb-3" type="text" />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <div>
                    <Form.Label className="fw-600">Pertanyaan</Form.Label>
                  </div>
                  <Form.Control as="textarea" rows={3} />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="fw-600">Pilihan A</Form.Label>
                  <Form.Control className="mb-3" type="text" />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="fw-600">Pilihan B</Form.Label>
                  <Form.Control className="mb-3" type="text" />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="fw-600">Pilihan C</Form.Label>
                  <Form.Control className="mb-3" type="text" />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="fw-600">Pilihan D</Form.Label>
                  <Form.Control className="mb-3" type="text" />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="fw-600">Jawaban Benar</Form.Label>
                  <Form.Control className="mb-3" type="text" />
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Button type="submit" variant="light" className="my-4 mx-2">
                    Batal
                  </Button>
                  <Button type="submit" className="my-4 mx-2">
                    Buat
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CreateQuiz;
