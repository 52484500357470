import Login from "../pages/Account/Login";
// import EmailVerified from "../pages/Account/EmailVerified";
// import ForgotPassword from "../pages/Account/ForgotPassword";
// import Register from "../pages/Account/Register";
// import NewPassword from "../pages/Account/NewPassword";
import BankSoal from "../pages/Dashboard/BankSoal";
import TemaSoal from "../pages/Dashboard/TemaSoal";
import UserManagement from "../pages/Dashboard/UserManagement";
import Inbox from "../pages/Dashboard/Inbox";
import Orders from "../pages/Dashboard/Orders";
import CreateCourse from "../pages/Course/CreateCourse";
import CreateQuiz from "../components/CreateQuiz";
import CreateCompany from "../pages/Company/CreateCompany";
import TableCompany from "../pages/Company/TableCompany";
import CreateLearningPath from "../pages/LearningPath/CreateLearningPath";
import CreateAdmin from "../pages/Admin/CreateAdmin";
import TableAdmin from "../pages/Admin/TableAdmin";
import LiveSessionCreate from "../pages/LiveSession/LiveSessionCreate";
import MediaLibrary from "../pages/Account/MediaLibrary";
import UploadLibrary from "../pages/Account/UploadLibrary";
import Profile from "../pages/Personal/Profile";
import Setting from "../pages/Personal/Setting";
import Product from "../pages/Tagging/Product";
import DeliverMethod from "../pages/Tagging/DeliverMethod";
import Program from "../pages/Tagging/Program";
import TopikBahasan from "../pages/Tagging/TopikBahasan";
import Pathway from "../pages/Tagging/Pathway";
import CategoryTree from "../pages/Tagging/CategoryTree";
import TypeSkill from "../pages/Tagging/TypeSkill";
import CategoryList from "../pages/Tagging/CategoryList";
import SubCategoryList from "../pages/Tagging/SubCategoryList";
import LearningLevels from "../pages/Tagging/LearningLevel";
import Positions from "../pages/Tagging/Positions";
import Sectors from "../pages/Tagging/Sectors";
import SubIndustries from "../pages/Tagging/SubIndustries";
import Industries from "../pages/Tagging/Industries";
import CreateMembership from "../pages/Membership/CreateMembership";
import RegisterMembership from "../pages/Membership/RegisterMembership";
import TableMembership from "../pages/Membership/TableMembership";
import EditMembership from "../pages/Membership/EditMembership";
import TableLearningPath from "../pages/LearningPath/TableLearningPath";
import ATPI from "../pages/ATPI";
import UserDetail from "../pages/Dashboard/UserDetail";
import UserCheck from "../pages/Dashboard/UserCheck";
import UserRegister from "../pages/Dashboard/UserRegister";
const RouteData = [
  {
    exact: true,
    path: ["/"],
    redirect: "/dashboard/user-management/",
    isSidebar: true,
    isHeader: true,
    component: {},
  },
  {
    exact: true,
    path: ["/dashboard/bank-soal/"],
    isSidebar: true,
    isHeader: true,
    component: BankSoal,
  },
  {
    exact: true,
    path: ["/dashboard/membership/create"],
    isSidebar: true,
    isHeader: true,
    component: CreateMembership,
  },
  {
    exact: true,
    path: ["/dashboard/membership/register"],
    isSidebar: true,
    isHeader: true,
    component: RegisterMembership,
  },
  {
    exact: true,
    path: ["/dashboard/membership/edit"],
    isSidebar: true,
    isHeader: true,
    component: EditMembership,
  },
  {
    exact: true,
    path: ["/dashboard/membership/"],
    isSidebar: true,
    isHeader: true,
    component: TableMembership,
  },
  {
    exact: true,
    path: ["/dashboard/tema-soal/"],
    isSidebar: true,
    isHeader: true,
    component: TemaSoal,
  },
  {
    exact: true,
    path: ["/dashboard/user-management/"],
    isSidebar: true,
    isHeader: true,
    component: UserManagement,
  },
  {
    exact: true,
    path: ["/dashboard/inbox/"],
    isSidebar: true,
    isHeader: true,
    component: Inbox,
  },
  {
    exact: true,
    path: ["/dashboard/orders/"],
    isSidebar: true,
    isHeader: true,
    component: Orders,
  },
  {
    exact: true,
    path: ["/kursus/buat/"],
    isSidebar: true,
    isHeader: true,
    component: CreateCourse,
  },
  {
    exact: true,
    path: ["/media/library/"],
    isSidebar: true,
    isHeader: true,
    component: MediaLibrary,
  },
  {
    exact: true,
    path: ["/media/upload/"],
    isSidebar: true,
    isHeader: true,
    component: UploadLibrary,
  },
  {
    exact: true,
    path: ["/learning-path/buat/"],
    isSidebar: true,
    isHeader: true,
    component: CreateLearningPath,
  },
  {
    exact: true,
    path: ["/learning-path/"],
    isSidebar: true,
    isHeader: true,
    component: TableLearningPath,
  },
  {
    exact: true,
    path: ["/kuis/buat/"],
    isSidebar: true,
    isHeader: true,
    component: CreateQuiz,
  },
  {
    exact: true,
    path: ["/perusahaan/buat/"],
    isSidebar: true,
    isHeader: true,
    component: CreateCompany,
  },
  {
    exact: true,
    path: ["/perusahaan/"],
    isSidebar: true,
    isHeader: true,
    component: TableCompany,
  },
  {
    exact: true,
    path: ["/admin/"],
    isSidebar: true,
    isHeader: true,
    component: TableAdmin,
  },
  {
    exact: true,
    path: ["/admin/buat/"],
    isSidebar: true,
    isHeader: true,
    component: CreateAdmin,
  },
  {
    exact: true,
    path: ["/live-session/buat/"],
    isSidebar: true,
    isHeader: true,
    component: LiveSessionCreate,
  },
  {
    exact: true,
    path: ["/profil"],
    isSidebar: true,
    isHeader: true,
    component: Profile,
  },
  {
    exact: true,
    path: ["/peraturan"],
    isSidebar: true,
    isHeader: true,
    component: Setting,
  },
  {
    exact: true,
    path: ["/product"],
    isSidebar: true,
    isHeader: true,
    component: Product,
  },
  {
    exact: true,
    path: ["/deliver-method"],
    isSidebar: true,
    isHeader: true,
    component: DeliverMethod,
  },
  {
    exact: true,
    path: ["/program"],
    isSidebar: true,
    isHeader: true,
    component: Program,
  },
  {
    exact: true,
    path: ["/topik-bahasan"],
    isSidebar: true,
    isHeader: true,
    component: TopikBahasan,
  },
  {
    exact: true,
    path: ["/category-tree"],
    isSidebar: true,
    isHeader: true,
    component: CategoryTree,
  },
  {
    exact: true,
    path: ["/skill-type"],
    isSidebar: true,
    isHeader: true,
    component: TypeSkill,
  },
  {
    exact: true,
    path: ["/category"],
    isSidebar: true,
    isHeader: true,
    component: CategoryList,
  },
  {
    exact: true,
    path: ["/sub-category"],
    isSidebar: true,
    isHeader: true,
    component: SubCategoryList,
  },
  {
    exact: true,
    path: ["/learning-levels"],
    isSidebar: true,
    isHeader: true,
    component: LearningLevels,
  },
  {
    exact: true,
    path: ["/posisi"],
    isSidebar: true,
    isHeader: true,
    component: Positions,
  },
  {
    exact: true,
    path: ["/sektor"],
    isSidebar: true,
    isHeader: true,
    component: Sectors,
  },
  {
    exact: true,
    path: ["/industri"],
    isSidebar: true,
    isHeader: true,
    component: Industries,
  },
  {
    exact: true,
    path: ["/sub-industri"],
    isSidebar: true,
    isHeader: true,
    component: SubIndustries,
  },
  {
    exact: true,
    path: ["/login/"],
    isSidebar: false,
    isHeader: false,
    component: Login,
  },
  {
    exact: true,
    path: ["/dashboard/atpi/"],
    isSidebar: true,
    isHeader: true,
    component: ATPI,
  },
  {
    exact: true,
    path: ["/dashboard/users/check"],
    isSidebar: true,
    isHeader: true,
    component: UserCheck,
  },
  {
    exact: true,
    path: ["/dashboard/users/register"],
    isSidebar: true,
    isHeader: true,
    component: UserRegister,
  },
  {
    exact: true,
    path: ["/dashboard/users/", "/dashboard/users/:id"],
    isSidebar: true,
    isHeader: true,
    component: UserDetail,
  },
];

export default RouteData;
