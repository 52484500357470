import { satukelasApi } from "../constants";

export const MembershipService = {
  registerMembership(payload) {
    return satukelasApi.post(`memberships`, payload);
  },
  getMembershipCatalogue(catalogue_id) {
    return satukelasApi.get(`membership_catalogue/${catalogue_id}`);
  },
  editMembershipUser(queryString,payload){
    return satukelasApi.put(`memberships/${queryString}`,payload);
  }
};
