import React, { useEffect, useState } from "react";
import { Table, Form, Card, Button, Modal, Tabs, Tab } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  deleteSkillTypesDataThunk,
  getSkillTypesDataThunk,
  postSkillTypesDataThunk,
  putSkillTypesDataThunk,
} from "../../redux/thunks/SkillTypesThunks";
import Pagination from "../../components/Layout/Pagination";
import moment from "moment";
import { SkValidate } from "../../components/common/SkValidate";
const MySwal = withReactContent(Swal);
function TypeSkill() {
  const [edit, setEdit] = useState();
  const [show, setShow] = useState(false);
  const [type, setType] = useState();
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState({
    skill_types: "",
  });
  const dispatch = useDispatch();
  const skill_types = useSelector((state) => state.skill_types);
  const skillTypeData = skill_types.skillTypesData?.items;

  const [usersPagination, setUsersPagination] = useState();
  const [size, setSize] = useState(10); //pagination size
  const [active, setActive] = useState(1);
  const [key, setKey] = useState("tabs-tab-skill_type");
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setUsersPagination(skill_types?.skillTypesData?.pagination);
  }, [skill_types]);

  useEffect(() => {
    dispatch(getSkillTypesDataThunk());
  }, []);

  const handleModal = (type, id) => {
    setEdit(id);
    setType(type);
    setShow(true);
  };

  useEffect(() => {}, [edit]);

  useEffect(() => {}, [data]);

  const handleChange = (e) => {
    if (edit !== undefined) {
      const newData = { ...edit };
      newData[e.target.id] = e.target.value;
      setEdit(newData);
    } else {
      const newData = { ...data };
      newData[e.target.id] = e.target.value;
      setData(newData);
    }
  };

  function paginationSelect(newActive) {
    setActive(newActive);
    dispatch(
      getSkillTypesDataThunk({
        param: `?size=${size}&page=${newActive}`,
      })
    );
  }

  const constraints = {
    title: {
      presence: true,
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    const isValid = SkValidate(form, constraints);

    if (isValid) {
      setTrigger(true);
      if (edit !== undefined) {
        const payload = { ...edit };
        const editData = payload.skill_type_id;
        let reqEdit = {};
        reqEdit.body = {
          title: payload.title,
        };
        reqEdit.id = editData;
        dispatch(putSkillTypesDataThunk(reqEdit));
        setShow(false);
        dispatch(getSkillTypesDataThunk());
      } else {
        let req = {
          title: data.title,
        };
        dispatch(postSkillTypesDataThunk(req));
        setShow(false);
        dispatch(getSkillTypesDataThunk());
        setData({ title: "" });
      }
    }
  };

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        dispatch(deleteSkillTypesDataThunk(id));
      }
    });
  };
  useEffect(() => {
    if (skill_types.skillTypesDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menambahkan data", "Ok");
    }
    // if (
    //   skill_types.skillTypesDataPost !== "error" &&
    //   skill_types.skillTypesDataPost !== null &&
    //   trigger
    // ) {
    //   setTrigger(false);
    //   displayPopup(false, "Berhasil menambahkan data", "Ok");
    // }
    if (skill_types.skillTypesDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat mengedit data", "Ok");
    }
    // if (skill_types.skillTypesDataPut === "success" && trigger) {
    //   setTrigger(false);
    //   displayPopup(false, "Berhasil mengedit data", "Ok");
    // }
    if (skill_types.skillTypesDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
    }
    // if (skill_types.skillTypesDataDelete === "success" && trigger) {
    //   setTrigger(false);
    //   displayPopup(false, "Berhasil menghapus data", "Ok");
    // }
  }, [skill_types]);

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value.isConfirmed) {
        dispatch(getSkillTypesDataThunk());
      }
    });
  }
  return (
    <div className="">
      <div className="d-flex justify-content-between">
        <div>
          <div className="bank-soal__title mb-2">List Tipe Skill</div>{" "}
          <div className="fw-400 my-2">
            {/* Total Pengguna: {dataUserFull?.pagination?.totalItems} */}
          </div>
        </div>
        <div className="mb-3">
          <Button onClick={() => handleModal("Tambah")}>
            + Tambah Tipe Skill
          </Button>
        </div>
      </div>

      <Card className="card_inside__padding">
        <div className="d-flex justify-content-end mb-3">
          {usersPagination ? (
            <Pagination
              size={size}
              totalRows={usersPagination.totalItems}
              totalPages={usersPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Tipe Skill</th>
              <th className="w-25">ID Tipe Skill</th>
              <th className="w-25">Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {skillTypeData &&
              skillTypeData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.title}</td>
                    <td>{item.skill_type_id}</td>
                    <td>{moment(item.created_at).format("DD/MM/YYYY")}</td>
                    <td>
                      <div className="d-flex">
                        <Button
                          onClick={() => handleModal("Edit", item)}
                          className="mr-2 "
                          variant="success"
                        >
                          <FaEdit className="fill-white" />
                        </Button>
                        <Button
                          onClick={() => handleDelete(item.skill_type_id)}
                          variant="danger"
                        >
                          {" "}
                          <FaTrash className="fill-white" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{type} Tipe Skill</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label className="title_label__input">Tipe Skill</Form.Label>
              <Form.Control
                onChange={handleChange}
                className="mb-3"
                type="text"
                id="title"
                name="title"
                value={edit !== undefined ? edit.title : data.title}
                placeholder="Masukkan Tipe Skill"
              />
              <div className="messages"></div>
            </Form.Group>
            <div className="mt-4 text-right">
              <Button type="submit" variant="primary">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TypeSkill;
