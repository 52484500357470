import { PromiseService } from "../../services/PromiseService";
import { LEARNING_LEVELS_DATA_LINK } from "../config/LearningLevelsConfig";
import {
  //get
  getLearningLevelsData,
  getLearningLevelsDataSuccess,
  getLearningLevelsDataIdSuccess,
  getLearningLevelsDataFail,
  //post
  postLearningLevelsData,
  postLearningLevelsDataSuccess,
  postLearningLevelsDataFail,
  // put
  putLearningLevelsData,
  putLearningLevelsDataSuccess,
  putLearningLevelsDataFail,
  // delete
  deleteLearningLevelsData,
  deleteLearningLevelsDataSuccess,
  deleteLearningLevelsDataFail,
} from "../actions/LearningLevelsActions";

// get
export const getLearningLevelsDataThunk = (data) => async (dispatch) => {
  dispatch(getLearningLevelsData(data));
  if (data) {
    let url = `${LEARNING_LEVELS_DATA_LINK}/${data}`;
    if (data.param) url = `${LEARNING_LEVELS_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getLearningLevelsDataSuccess(response.data));
        else dispatch(getLearningLevelsDataIdSuccess(response.data));
      } else {
        dispatch(getLearningLevelsDataFail(response));
      }
    } catch (error) {
      dispatch(getLearningLevelsDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(LEARNING_LEVELS_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getLearningLevelsDataSuccess(response.data));
      } else {
        dispatch(getLearningLevelsDataFail(response));
      }
    } catch (error) {
      dispatch(getLearningLevelsDataFail(error));
    }
  }
};

// post
export const postLearningLevelsDataThunk = (data) => async (dispatch) => {
  dispatch(postLearningLevelsData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(LEARNING_LEVELS_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postLearningLevelsDataSuccess(response.data));
    } else {
      dispatch(postLearningLevelsDataFail(response));
    }
  } catch (error) {
    dispatch(postLearningLevelsDataFail(error));
  }
};

// put
export const putLearningLevelsDataThunk = (data) => async (dispatch) => {
  dispatch(putLearningLevelsData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${LEARNING_LEVELS_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putLearningLevelsDataSuccess(response.data));
    } else {
      dispatch(putLearningLevelsDataFail(response));
    }
  } catch (error) {
    dispatch(putLearningLevelsDataFail(error));
  }
};

// delete
export const deleteLearningLevelsDataThunk = (data) => async (dispatch) => {
  dispatch(deleteLearningLevelsData(data));
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${LEARNING_LEVELS_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteLearningLevelsDataSuccess(response.data));
    } else {
      dispatch(deleteLearningLevelsDataFail(response));
    }
  } catch (error) {
    dispatch(deleteLearningLevelsDataFail(error));
  }
};
