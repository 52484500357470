export const SCHEMES_DATA_LINK = "schemes";

//get
export const SCHEMES_DATA = "SCHEMES_DATA";
export const SCHEMES_DATA_SUCCESS = "SCHEMES_DATA_SUCCESS";
export const SCHEMES_DATA_ID_SUCCESS = "SCHEMES_DATA_ID_SUCCESS";
export const SCHEMES_DATA_FAIL = "SCHEMES_DATA_FAIL";

//post
export const SCHEMES_DATA_POST = "SCHEMES_DATA_POST";
export const SCHEMES_DATA_POST_SUCCESS = "SCHEMES_DATA_POST_SUCCESS";
export const SCHEMES_DATA_POST_FAIL = "SCHEMES_DATA_POST_FAIL";

// put
export const SCHEMES_DATA_PUT = "SCHEMES_DATA_PUT";
export const SCHEMES_DATA_PUT_SUCCESS = "SCHEMES_DATA_PUT_SUCCESS";
export const SCHEMES_DATA_PUT_FAIL = "SCHEMES_DATA_PUT_FAIL";

// delete
export const SCHEMES_DATA_DELETE = "SCHEMES_DATA_DELETE";
export const SCHEMES_DATA_DELETE_SUCCESS = "SCHEMES_DATA_DELETE_SUCCESS";
export const SCHEMES_DATA_DELETE_FAIL = "SCHEMES_DATA_DELETE_FAIL";

export const REMOVE_SCHEMES_DATA = "REMOVE_SCHEMES_DATA";