import React, { useEffect, useState } from "react";
import { Table, Form, Card, Button, Modal, Tabs, Tab } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  deleteSkillTypesDataThunk,
  getSkillTypesDataThunk,
  postSkillTypesDataThunk,
  putSkillTypesDataThunk,
} from "../../redux/thunks/SkillTypesThunks";
import {
  getCategoriesDataThunk,
  postCategoriesDataThunk,
} from "../../redux/thunks/CategoriesThunks";
import {
  getSubCategoriesDataThunk,
  postSubCategoriesDataThunk,
} from "../../redux/thunks/SubCategoriesThunks";
import Pagination from "../../components/Layout/Pagination";
const MySwal = withReactContent(Swal);
function CategoryTree() {
  const [edit, setEdit] = useState();
  const [editTree, setEditTree] = useState();
  const [show, setShow] = useState(false);
  const [type, setType] = useState();
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState({
    skill_types: "",
    categories: "",
    sub_categories: "",
  });
  const dispatch = useDispatch();
  const skill_types = useSelector((state) => state.skill_types);
  const skillTypeData = skill_types.skillTypesData?.items;
  const categories = useSelector((state) => state.categories);
  const categoriesData = categories.categoriesData?.items;
  const sub_categories = useSelector((state) => state.sub_categories);
  const subCategoriesData = sub_categories.subCategoriesData?.items;

  const [usersPagination, setUsersPagination] = useState();
  const [size, setSize] = useState(10); //pagination size
  const [active, setActive] = useState(1);
  const [key, setKey] = useState("tabs-tab-skill_type");
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setUsersPagination(skill_types?.skillTypesData?.pagination);
  }, [skill_types]);

  useEffect(() => {
    dispatch(getSkillTypesDataThunk());
    dispatch(getCategoriesDataThunk());
    dispatch(getSubCategoriesDataThunk());
  }, []);

  const handleModal = (type, id) => {
    setEdit(id);
    setType(type);
    setShow(true);
  };

  useEffect(() => {}, [edit]);
  useEffect(() => {}, [data]);
  useEffect(() => {}, [key]);

  const handleChange = (e) => {
    if (edit !== undefined) {
      const newData = { ...edit };
      newData[e.target.id] = e.target.value;
      setEdit(newData);
    } else {
      const newData = { ...data };
      newData[e.target.id] = e.target.value;
      setData(newData);
    }
  };

  function paginationSelect(newActive) {
    setActive(newActive);
    // dispatch(getUsersData({ param: `?size=${size}&page=${newActive}` }));
    dispatch(
      getSkillTypesDataThunk({
        param: `?size=${size}&page=${newActive}`,
      })
    );
  }

  const handleChangeTabs = (e) => {
    setKey(e.target.id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (edit !== undefined) {
      const payload = { ...edit };
      const editData = payload.skill_type_id;
      let reqEdit = {};
      if (key === "tabs-tab-skill_type") {
        reqEdit.body = {
          title: payload.title,
        };
        reqEdit.id = editData;
        dispatch(putSkillTypesDataThunk(reqEdit));
        setShow(false);
        dispatch(getSkillTypesDataThunk());
      }
      if (key === "tabs-tab-kategori") {
        let req = {
          skill_type_id: edit.skill_type_id,
          title: edit.category_title,
        };
        dispatch(postCategoriesDataThunk(req));
        setShow(false);
        dispatch(getCategoriesDataThunk());
      }
      if (key === "tabs-tab-sub_kategori") {
        let req = {
          category_id: edit.category_id,
          title: edit.sub_categories_name,
        };
        dispatch(postSubCategoriesDataThunk(req));
        setShow(false);
        dispatch(getSubCategoriesDataThunk());
      }
    } else {
      if (key === "tabs-tab-skill_type") {
        let req = {
          title: data.title,
        };
        dispatch(postSkillTypesDataThunk(req));
        setShow(false);
        dispatch(getSkillTypesDataThunk());
      }
    }
  };

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        dispatch(deleteSkillTypesDataThunk(id));
      }
    });
  };
  useEffect(() => {
    if (skill_types.skillTypesDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
    }
    if (skill_types.skillTypesDataDelete === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil menghapus data", "Ok");
    }
  }, [skill_types]);

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value.isConfirmed) {
        dispatch(getSkillTypesDataThunk());
      }
    });
  }

  const handleEditValue = (value) => {
    setShowEdit(true);
    dispatch(getSkillTypesDataThunk(value));
    dispatch(getSkillTypesDataThunk());
  };
  return (
    <div className="">
      <div className="d-flex justify-content-between">
        <div>
          <div className="bank-soal__title mb-2">List Category Tree</div>{" "}
          <div className="fw-400 my-2">
            {/* Total Pengguna: {dataUserFull?.pagination?.totalItems} */}
          </div>
        </div>
        <div className="mb-3">
          <Button onClick={() => handleModal("Tambah")}>
            + Tambah Category Tree
          </Button>
        </div>
      </div>

      <Card className="card_inside__padding">
        <div className="d-flex justify-content-end mb-3">
          {usersPagination ? (
            <Pagination
              size={size}
              totalRows={usersPagination.totalItems}
              totalPages={usersPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Tipe Skill</th>
              <th className="w-25">Judul Kategori</th>
              <th className="w-25">Sub Kategori</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {skillTypeData &&
              skillTypeData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.title}</td>
                    <td>
                      {categoriesData &&
                        categoriesData.map((e, i) => {
                          return e.skill_type_id === item.skill_type_id ? (
                            <div key={i}>{e.title + ","}</div>
                          ) : null;
                        })}
                    </td>
                    <td>
                      {subCategoriesData &&
                        subCategoriesData.map((e, i) => {
                          return e.skill_type_id === item.skill_type_id ? (
                            <div key={i}>{e.title + ","}</div>
                          ) : null;
                        })}
                    </td>
                    {/* <td>
                      <div className="d-flex">
                        <Button
                          onClick={() => handleModal("Edit", item)}
                          className="mr-2 "
                          variant="success"
                        >
                          <FaEdit className="fill-white" />
                        </Button>
                        <Button
                          onClick={() => {
                            handleDelete(item.skill_type_id);
                          }}
                          variant="danger"
                        >
                          {" "}
                          <FaTrash className="fill-white" />
                        </Button>
                      </div>
                    </td> */}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{type} Category Tree</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <Tabs
                defaultActiveKey="skill_type"
                id="tabs"
                className="mb-3"
                onClick={handleChangeTabs}
              >
                <Tab eventKey="skill_type" title="Tipe Skill">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label className="title_label__input">
                        Tipe Skill
                      </Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        className="mb-3"
                        type="text"
                        id="title"
                        name="title"
                        value={edit !== undefined ? edit.title : data.title}
                        placeholder="Masukkan Tipe Skill"
                      />
                    </Form.Group>
                    <div className="mt-4 text-right">
                      <Button type="submit" variant="primary">
                        Save
                      </Button>
                    </div>
                  </Form>
                </Tab>
                <Tab eventKey="kategori" title="Kategori">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label className="title_label__input">
                        Tipe Skill
                      </Form.Label>
                      <Form.Control
                        className="mb-1"
                        as="select"
                        name="skill_type_id"
                        id="skill_type_id"
                        onChange={handleChange}
                        placeholder="Pilih Tipe Skill"
                        value={
                          edit !== undefined
                            ? edit.skill_type_id
                            : data.skill_type_id
                        }
                      >
                        <option hidden value="" key="blankChoice">
                          Pilih Tipe Skill
                        </option>
                        {skillTypeData &&
                          skillTypeData.map((item, index) => {
                            return (
                              <option key={index} value={item.skill_type_id}>
                                {item.title}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                    {categoriesData?.length > 0 ? (
                      <div className="mb-2">Edit Kategori</div>
                    ) : null}
                    {categoriesData &&
                      categoriesData.map((item, index) => {
                        return item?.skill_type_id === edit?.skill_type_id ? (
                          <div className="d-flex mb-2 align-items-center justify-content-between">
                            <div key={index} className="mr-2">
                              {item.title}
                            </div>
                            <div>
                              <Button
                                className="mr-2"
                                onClick={() =>
                                  handleEditValue(item.skill_type_id)
                                }
                              >
                                Edit
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => handleDelete(item.skill_type_id)}
                              >
                                Hapus
                              </Button>
                            </div>
                          </div>
                        ) : null;
                      })}
                    {showEdit ? (
                      <Form onSubmit={handleSubmit}>
                        <Form.Group>
                          <Form.Label className="title_label__input">
                            Tipe Skill Edit
                          </Form.Label>
                          <Form.Control
                            onChange={handleChange}
                            className="mb-3"
                            type="text"
                            id="type_skill"
                            name="type_skill"
                            placeholder="Masukkan Tipe Skill"
                          />
                        </Form.Group>
                        <div className="mt-4 text-right">
                          <Button type="submit" variant="primary">
                            Save
                          </Button>
                        </div>
                      </Form>
                    ) : null}

                    <Form.Group>
                      <Form.Label className="title_label__input">
                        Tambah Kategori
                      </Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        className="mb-3"
                        type="text"
                        id="category_title"
                        name="category_title"
                        value={
                          edit !== undefined
                            ? edit.category_title
                            : data.category_title
                        }
                        placeholder="Masukkan Kategori"
                      />
                    </Form.Group>
                    <div className="mt-4 text-right">
                      <Button type="submit" variant="primary">
                        Save
                      </Button>
                    </div>
                  </Form>
                </Tab>
                <Tab eventKey="sub_kategori" title="Sub Kategori">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3"></Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="title_label__input">
                        Kategori
                      </Form.Label>

                      <Form.Control
                        onChange={handleChange}
                        className="mb-1"
                        as="select"
                        id="category_id"
                        name="category_id"
                        placeholder="Pilih Kategori"
                        value={
                          edit !== undefined
                            ? edit.category_id
                            : data.category_id
                        }
                      >
                        <option hidden value="" key="blankChoice">
                          Pilih Kategori
                        </option>
                        {categoriesData &&
                          categoriesData.map((item, index) => {
                            return (
                              <option key={index} value={item.category_id}>
                                {item.title}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                    {subCategoriesData &&
                      subCategoriesData.map((item, index) => {
                        return item?.skill_type_id === edit?.skill_type_id ? (
                          <div className="d-flex mb-2 align-items-center justify-content-between">
                            <div key={index} className="mr-2">
                              {item.title}
                            </div>
                            <div>
                              <Button
                                className="mr-2"
                                onClick={() =>
                                  handleEditValue(item.sub_categories_id)
                                }
                              >
                                Edit
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() =>
                                  handleEditValue(item.sub_categories_id)
                                }
                              >
                                Hapus
                              </Button>
                            </div>
                          </div>
                        ) : null;
                      })}
                    <Form.Group>
                      <Form.Label className="title_label__input">
                        Sub Kategori
                      </Form.Label>
                      <Form.Control
                        className="mb-3"
                        type="text"
                        placeholder="Masukkan Sub Kategori"
                        onChange={handleChange}
                        name="sub_categories_name"
                        id="sub_categories_name"
                        value={
                          edit !== undefined
                            ? edit.sub_categories_name
                            : data.sub_categories_name
                        }
                      />
                    </Form.Group>
                    <div className="mt-4 text-right">
                      <Button type="submit" variant="primary">
                        Save
                      </Button>
                    </div>
                  </Form>
                </Tab>
              </Tabs>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CategoryTree;
