import {
  //get
  INDUSTRIES_DATA,
  INDUSTRIES_DATA_ID_SUCCESS,
  INDUSTRIES_DATA_SUCCESS,
  INDUSTRIES_DATA_FAIL,
  //post
  INDUSTRIES_DATA_POST,
  INDUSTRIES_DATA_POST_SUCCESS,
  INDUSTRIES_DATA_POST_FAIL,
  //put
  INDUSTRIES_DATA_PUT,
  INDUSTRIES_DATA_PUT_SUCCESS,
  INDUSTRIES_DATA_PUT_FAIL,
  //delete
  INDUSTRIES_DATA_DELETE,
  INDUSTRIES_DATA_DELETE_SUCCESS,
  INDUSTRIES_DATA_DELETE_FAIL,
  REMOVE_INDUSTRIES_DATA,
} from "../config/IndustriesConfig";

const intialState = {
  industriesDataPost: null,
  industriesDataPut: null,
  industriesDataDelete: null,
  industriesData: null,
  industriesDataId: null,
  isLoading: false,
};

export const IndustriesReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case INDUSTRIES_DATA:
      return { ...state, isLoading: true };
    case INDUSTRIES_DATA_SUCCESS:
      return { ...state, isLoading: false, industriesData: action.value };
    case INDUSTRIES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, industriesDataId: action.value };
    case INDUSTRIES_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        industriesData: "error",
        industriesDataId: "error",
      };
    //post
    case INDUSTRIES_DATA_POST:
      return {
        ...state,
        isLoading: true,
        industriesDataPost: null,
        industriesDataPut: null,
        industriesDataDelete: null,
      };
    case INDUSTRIES_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, industriesDataPost: action.value };
    case INDUSTRIES_DATA_POST_FAIL:
      return { ...state, isLoading: false, industriesDataPost: "error" };

    //put
    case INDUSTRIES_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        industriesDataPost: null,
        industriesDataPut: null,
        industriesDataDelete: null,
      };
    case INDUSTRIES_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, industriesDataPut: "success" };
    case INDUSTRIES_DATA_PUT_FAIL:
      return { ...state, isLoading: false, industriesDataPut: "error" };

    //delete
    case INDUSTRIES_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        industriesDataPost: null,
        industriesDataPut: null,
        industriesDataDelete: null,
      };
    case INDUSTRIES_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, industriesDataDelete: "success" };
    case INDUSTRIES_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, industriesDataDelete: "error" };

    case REMOVE_INDUSTRIES_DATA:
      return { ...state, industriesDataDelete: null };
    default:
      return state;
  }
};
