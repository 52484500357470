import React, { useState, useEffect } from "react";
import "../../assets/css/Layout/header.css";
import styled from "styled-components";
import { FaUserAlt, FaUserCog, FaPowerOff } from "react-icons/fa";
import { AuthLogout } from "../../helper/auth";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";

const MySwal = withReactContent(Swal);

function Header() {
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const users = useSelector((state) => state.users);
  const mySelf = users.usersDataId;

  useEffect(() => {
    const nameUser = users.usersDataId.name;
    const email = users.usersDataId.email;
    setName(nameUser);
    setEmail(email);
  }, [users]);

  const handleShow = () =>
    MySwal.fire({
      title: "Anda yakin ingin keluar ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        AuthLogout(history);
      }
    });

  return (
    <StyledWrapperMain>
      <div className="main-content">
        <div className="middle-sidebar-header bg-white">
          <ul className="d-flex ml-auto right-menu-icon m-0">
            <li>
              <a>
                {mySelf?.avatar === "" ? (
                  <div className="container-user grey">
                    <FaUserAlt className="size-profile" />
                  </div>
                ) : (
                  <img
                    src={`${mySelf?.avatar}`}
                    alt="user"
                    className="w40 rounded-circle mt--1"
                  />
                )}
                <div className="menu-dropdown menu-dropdown-profile">
                  <div className="menu_name">{name}</div>
                  <div className="email-text mb-3">{email}</div>
                  <Link className="dropdown-item px-0" to="/profil">
                    <FaUserAlt className="icon-user mr-2" />
                    Profile
                  </Link>
                  <Link className="dropdown-item px-0" to="/peraturan">
                    <FaUserCog className="icon-cog mr-2"/>
                    Pengaturan
                  </Link>
                  <div className="dropdown-item px-0" onClick={handleShow}>
                    <FaPowerOff className="icon-power mr-2"/>
                    Keluar
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </StyledWrapperMain>
  );
}

export default Header;

const StyledWrapperMain = styled.section`
  svg {
    height: 32px;
    width: 32px;
  }
  .main-content {
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #00000011;
  }
`;
