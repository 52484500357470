import { PromiseService } from "../../services/PromiseService";
import { SECTORS_DATA_LINK } from "../config/SectorsConfig";
import {
  //get
  getSectorsData,
  getSectorsDataSuccess,
  getSectorsDataIdSuccess,
  getSectorsDataFail,
  //post
  postSectorsData,
  postSectorsDataSuccess,
  postSectorsDataFail,
  // put
  putSectorsData,
  putSectorsDataSuccess,
  putSectorsDataFail,
  // delete
  deleteSectorsData,
  deleteSectorsDataSuccess,
  deleteSectorsDataFail,
} from "../actions/SectorsActions";

// get
export const getSectorsDataThunk = (data) => async (dispatch) => {
  dispatch(getSectorsData(data));
  if (data) {
    let url = `${SECTORS_DATA_LINK}/${data}`;
    if (data.param) url = `${SECTORS_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getSectorsDataSuccess(response.data));
        else dispatch(getSectorsDataIdSuccess(response.data));
      } else {
        dispatch(getSectorsDataFail(response));
      }
    } catch (error) {
      dispatch(getSectorsDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(SECTORS_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getSectorsDataSuccess(response.data));
      } else {
        dispatch(getSectorsDataFail(response));
      }
    } catch (error) {
      dispatch(getSectorsDataFail(error));
    }
  }
};

// post
export const postSectorsDataThunk = (data) => async (dispatch) => {
  dispatch(postSectorsData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(SECTORS_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postSectorsDataSuccess(response.data));
    } else {
      dispatch(postSectorsDataFail(response));
    }
  } catch (error) {
    dispatch(postSectorsDataFail(error));
  }
};

// put
export const putSectorsDataThunk = (data) => async (dispatch) => {
  dispatch(putSectorsData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${SECTORS_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putSectorsDataSuccess(response.data));
    } else {
      dispatch(putSectorsDataFail(response));
    }
  } catch (error) {
    dispatch(putSectorsDataFail(error));
  }
};

// delete
export const deleteSectorsDataThunk = (data) => async (dispatch) => {
  dispatch(deleteSectorsData(data));
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${SECTORS_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteSectorsDataSuccess(response.data));
    } else {
      dispatch(deleteSectorsDataFail(response));
    }
  } catch (error) {
    dispatch(deleteSectorsDataFail(error));
  }
};
