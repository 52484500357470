export const SUB_INDUSTRIES_DATA_LINK = "sub_industries";

//get
export const SUB_INDUSTRIES_DATA = "SUB_INDUSTRIES_DATA";
export const SUB_INDUSTRIES_DATA_SUCCESS = "SUB_INDUSTRIES_DATA_SUCCESS";
export const SUB_INDUSTRIES_DATA_ID_SUCCESS = "SUB_INDUSTRIES_DATA_ID_SUCCESS";
export const SUB_INDUSTRIES_DATA_FAIL = "SUB_INDUSTRIES_DATA_FAIL";

//post
export const SUB_INDUSTRIES_DATA_POST = "SUB_INDUSTRIES_DATA_POST";
export const SUB_INDUSTRIES_DATA_POST_SUCCESS =
  "SUB_INDUSTRIES_DATA_POST_SUCCESS";
export const SUB_INDUSTRIES_DATA_POST_FAIL = "SUB_INDUSTRIES_DATA_POST_FAIL";

// put
export const SUB_INDUSTRIES_DATA_PUT = "SUB_INDUSTRIES_DATA_PUT";
export const SUB_INDUSTRIES_DATA_PUT_SUCCESS =
  "SUB_INDUSTRIES_DATA_PUT_SUCCESS";
export const SUB_INDUSTRIES_DATA_PUT_FAIL = "SUB_INDUSTRIES_DATA_PUT_FAIL";

// delete
export const SUB_INDUSTRIES_DATA_DELETE = "SUB_INDUSTRIES_DATA_DELETE";
export const SUB_INDUSTRIES_DATA_DELETE_SUCCESS =
  "SUB_INDUSTRIES_DATA_DELETE_SUCCESS";
export const SUB_INDUSTRIES_DATA_DELETE_FAIL =
  "SUB_INDUSTRIES_DATA_DELETE_FAIL";

export const REMOVE_SUB_INDUSTRIES_DATA = "REMOVE_SUB_INDUSTRIES_DATA";
