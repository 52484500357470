import {
  //get
  LEARNING_LEVELS_DATA,
  LEARNING_LEVELS_DATA_ID_SUCCESS,
  LEARNING_LEVELS_DATA_SUCCESS,
  LEARNING_LEVELS_DATA_FAIL,
  //post
  LEARNING_LEVELS_DATA_POST,
  LEARNING_LEVELS_DATA_POST_SUCCESS,
  LEARNING_LEVELS_DATA_POST_FAIL,
  //put
  LEARNING_LEVELS_DATA_PUT,
  LEARNING_LEVELS_DATA_PUT_SUCCESS,
  LEARNING_LEVELS_DATA_PUT_FAIL,
  //delete
  LEARNING_LEVELS_DATA_DELETE,
  LEARNING_LEVELS_DATA_DELETE_SUCCESS,
  LEARNING_LEVELS_DATA_DELETE_FAIL,
} from "../config/LearningLevelsConfig";

const intialState = {
  learningLevelsDataPost: null,
  learningLevelsDataPut: null,
  learningLevelsDataDelete: null,
  learningLevelsData: null,
  learningLevelsDataId: null,
  isLoading: false,
};

export const LearningLevelsReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case LEARNING_LEVELS_DATA:
      return { ...state, isLoading: true };
    case LEARNING_LEVELS_DATA_SUCCESS:
      return { ...state, isLoading: false, learningLevelsData: action.value };
    case LEARNING_LEVELS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, learningLevelsDataId: action.value };
    case LEARNING_LEVELS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        learningLevelsData: "error",
        learningLevelsDataId: "error",
      };
    //post
    case LEARNING_LEVELS_DATA_POST:
      return {
        ...state,
        isLoading: true,
        learningLevelsDataPost: null,
        learningLevelsDataPut: null,
        learningLevelsDataDelete: null,
      };
    case LEARNING_LEVELS_DATA_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningLevelsDataPost: action.value,
      };
    case LEARNING_LEVELS_DATA_POST_FAIL:
      return { ...state, isLoading: false, learningLevelsDataPost: "error" };

    //put
    case LEARNING_LEVELS_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        learningLevelsDataPost: null,
        learningLevelsDataPut: null,
        learningLevelsDataDelete: null,
      };
    case LEARNING_LEVELS_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, learningLevelsDataPut: "success" };
    case LEARNING_LEVELS_DATA_PUT_FAIL:
      return { ...state, isLoading: false, learningLevelsDataPut: "error" };

    //delete
    case LEARNING_LEVELS_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        learningLevelsDataPost: null,
        learningLevelsDataPut: null,
        learningLevelsDataDelete: null,
      };
    case LEARNING_LEVELS_DATA_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningLevelsDataDelete: "success",
      };
    case LEARNING_LEVELS_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, learningLevelsDataDelete: "error" };

    default:
      return state;
  }
};
