import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
function ViewQuestion({ questionData }) {
  const [data, setData] = useState(questionData);
  return (
    <Col
      xs={12}
      className="quiz-quest-main-col d-flex flex-column justify-content-center align-items-start"
    >
      <Card.Text className="quiz-quest-no">{data.title}</Card.Text>
      <div className="quiz-quest-wrapper-scrollbar" id="containerDiv">
        <Card.Title className="quiz-quest-question">{data.question}</Card.Title>
      </div>
      <Col className="quiz-quest-ans-col">
        <Row
          className={`quiz-quest-ans-row  ${
            false ? "quiz-quest-ans-row-selected" : null
          }`}
        >
          <Button disabled variant={false ? "primary" : "secondary"}>
            A
          </Button>
          <div className="quiz-quest-ans-wrapper quiz-quest-wrapper-scrollbar">
            <Card.Text className="quiz-quest-ans-text">{data.choice_a}</Card.Text>
          </div>
        </Row>
        <Row
          className={`quiz-quest-ans-row  ${
            false ? "quiz-quest-ans-row-selected" : null
          }`}
        >
          <Button disabled variant={false ? "primary" : "secondary"}>
            B
          </Button>
          <div className="quiz-quest-ans-wrapper quiz-quest-wrapper-scrollbar">
            <Card.Text className="quiz-quest-ans-text">{data.choice_b}</Card.Text>
          </div>
        </Row>{" "}
        <Row
          className={`quiz-quest-ans-row  ${
            false ? "quiz-quest-ans-row-selected" : null
          }`}
        >
          <Button disabled variant={false ? "primary" : "secondary"}>
            C
          </Button>
          <div className="quiz-quest-ans-wrapper quiz-quest-wrapper-scrollbar">
            <Card.Text className="quiz-quest-ans-text">{data.choice_c}</Card.Text>
          </div>
        </Row>{" "}
        <Row
          className={`quiz-quest-ans-row  ${
            false ? "quiz-quest-ans-row-selected" : null
          }`}
        >
          <Button disabled variant={false ? "primary" : "secondary"}>
            D
          </Button>
          <div className="quiz-quest-ans-wrapper quiz-quest-wrapper-scrollbar">
            <Card.Text className="quiz-quest-ans-text">{data.choice_d}</Card.Text>
          </div>
        </Row>
      </Col>
    </Col>
  );
}
export default ViewQuestion;