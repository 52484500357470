import {
  //get
  SUB_INDUSTRIES_DATA,
  SUB_INDUSTRIES_DATA_ID_SUCCESS,
  SUB_INDUSTRIES_DATA_SUCCESS,
  SUB_INDUSTRIES_DATA_FAIL,
  //post
  SUB_INDUSTRIES_DATA_POST,
  SUB_INDUSTRIES_DATA_POST_SUCCESS,
  SUB_INDUSTRIES_DATA_POST_FAIL,
  //put
  SUB_INDUSTRIES_DATA_PUT,
  SUB_INDUSTRIES_DATA_PUT_SUCCESS,
  SUB_INDUSTRIES_DATA_PUT_FAIL,
  //delete
  SUB_INDUSTRIES_DATA_DELETE,
  SUB_INDUSTRIES_DATA_DELETE_SUCCESS,
  SUB_INDUSTRIES_DATA_DELETE_FAIL,
  REMOVE_SUB_INDUSTRIES_DATA,
} from "../config/SubIndustriesConfig";

const intialState = {
  subIndustriesDataPost: null,
  subIndustriesDataPut: null,
  subIndustriesDataDelete: null,
  subIndustriesData: null,
  subIndustriesDataId: null,
  isLoading: false,
};

export const SubIndustriesReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case SUB_INDUSTRIES_DATA:
      return { ...state, isLoading: true };
    case SUB_INDUSTRIES_DATA_SUCCESS:
      return { ...state, isLoading: false, subIndustriesData: action.value };
    case SUB_INDUSTRIES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, subIndustriesDataId: action.value };
    case SUB_INDUSTRIES_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        subIndustriesData: "error",
        subIndustriesDataId: "error",
      };
    //post
    case SUB_INDUSTRIES_DATA_POST:
      return {
        ...state,
        isLoading: true,
        subIndustriesDataPost: null,
        subIndustriesDataPut: null,
        subIndustriesDataDelete: null,
      };
    case SUB_INDUSTRIES_DATA_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subIndustriesDataPost: action.value,
      };
    case SUB_INDUSTRIES_DATA_POST_FAIL:
      return { ...state, isLoading: false, subIndustriesDataPost: "error" };

    //put
    case SUB_INDUSTRIES_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        subIndustriesDataPost: null,
        subIndustriesDataPut: null,
        subIndustriesDataDelete: null,
      };
    case SUB_INDUSTRIES_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, subIndustriesDataPut: "success" };
    case SUB_INDUSTRIES_DATA_PUT_FAIL:
      return { ...state, isLoading: false, subIndustriesDataPut: "error" };

    //delete
    case SUB_INDUSTRIES_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        subIndustriesDataPost: null,
        subIndustriesDataPut: null,
        subIndustriesDataDelete: null,
      };
    case SUB_INDUSTRIES_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, subIndustriesDataDelete: "success" };
    case SUB_INDUSTRIES_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, subIndustriesDataDelete: "error" };

    case REMOVE_SUB_INDUSTRIES_DATA:
      return { ...state, subIndustriesDataDelete: null };
    default:
      return state;
  }
};
