import { PromiseService } from "../../services/PromiseService";
import { MEMBERSHIPS_DATA_LINK } from "../config/MembershipsConfig";
import {
  //get
  getMembershipsData,
  getMembershipsDataSuccess,
  getMembershipsDataIdSuccess,
  getMembershipsDataFail,
  //post
  postMembershipsData,
  postMembershipsDataSuccess,
  postMembershipsDataFail,
  // put
  putMembershipsData,
  putMembershipsDataSuccess,
  putMembershipsDataFail,
  // delete
  deleteMembershipsData,
  deleteMembershipsDataSuccess,
  deleteMembershipsDataFail,
} from "../actions/MembershipsActions";

// get
export const getMembershipsDataThunk = (data) => async (dispatch) => {
  dispatch(getMembershipsData(data));
  if (data) {
    let url = `${MEMBERSHIPS_DATA_LINK}/${data}`;
    if (data.param) url = `${MEMBERSHIPS_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getMembershipsDataSuccess(response.data));
        else dispatch(getMembershipsDataIdSuccess(response.data));
      } else {
        dispatch(getMembershipsDataFail(response));
      }
    } catch (error) {
      dispatch(getMembershipsDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(MEMBERSHIPS_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getMembershipsDataSuccess(response.data));
      } else {
        dispatch(getMembershipsDataFail(response));
      }
    } catch (error) {
      dispatch(getMembershipsDataFail(error));
    }
  }
};

// post
export const postMembershipsDataThunk = (data) => async (dispatch) => {
  dispatch(postMembershipsData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(MEMBERSHIPS_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postMembershipsDataSuccess(response.data));
    } else {
      dispatch(postMembershipsDataFail(response));
    }
  } catch (error) {
    dispatch(postMembershipsDataFail(error));
  }
};

// put
export const putMembershipsDataThunk = (data) => async (dispatch) => {
  dispatch(putMembershipsData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${MEMBERSHIPS_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putMembershipsDataSuccess(response.data));
    } else {
      dispatch(putMembershipsDataFail(response));
    }
  } catch (error) {
    dispatch(putMembershipsDataFail(error));
  }
};

// delete
export const deleteMembershipsDataThunk = (data) => async (dispatch) => {
  dispatch(deleteMembershipsData(data));
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${MEMBERSHIPS_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteMembershipsDataSuccess(response.data));
    } else {
      dispatch(deleteMembershipsDataFail(response));
    }
  } catch (error) {
    dispatch(deleteMembershipsDataFail(error));
  }
};
