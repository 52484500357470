export const CATEGORIES_DATA_LINK = "categories";

//get
export const CATEGORIES_DATA = "CATEGORIES_DATA";
export const CATEGORIES_DATA_SUCCESS = "CATEGORIES_DATA_SUCCESS";
export const CATEGORIES_DATA_ID_SUCCESS = "CATEGORIES_DATA_ID_SUCCESS";
export const CATEGORIES_DATA_FAIL = "CATEGORIES_DATA_FAIL";

//post
export const CATEGORIES_DATA_POST = "CATEGORIES_DATA_POST";
export const CATEGORIES_DATA_POST_SUCCESS = "CATEGORIES_DATA_POST_SUCCESS";
export const CATEGORIES_DATA_POST_FAIL = "CATEGORIES_DATA_POST_FAIL";

// put
export const CATEGORIES_DATA_PUT = "CATEGORIES_DATA_PUT";
export const CATEGORIES_DATA_PUT_SUCCESS = "CATEGORIES_DATA_PUT_SUCCESS";
export const CATEGORIES_DATA_PUT_FAIL = "CATEGORIES_DATA_PUT_FAIL";

// delete
export const CATEGORIES_DATA_DELETE = "CATEGORIES_DATA_DELETE";
export const CATEGORIES_DATA_DELETE_SUCCESS = "CATEGORIES_DATA_DELETE_SUCCESS";
export const CATEGORIES_DATA_DELETE_FAIL = "CATEGORIES_DATA_DELETE_FAIL";
