import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Container, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import {
  AuthIsLogin,
  AuthData,
  ResetLogin,
  AuthLogin,
} from "../../helper/auth";
import { useHistory } from "react-router-dom";
import { changePasswordThunk } from "../../redux/thunks/UsersThunks";
import { SkValidate } from "../../components/common/SkValidate";
import { getUsersDataThunk } from "../../redux/thunks/UsersThunks";

const MySwal = withReactContent(Swal);
const sha256 = require("js-sha256");

function Setting() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const history = useHistory();
  const [usersDataId, setUsersDataId] = useState();
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState({
    password_old: "",
    password_new: "",
    password_new_repeat: "",
  });

  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  useEffect(() => {
    setUsersDataId(users.usersDataId);

    if (users.usersChangePassword?.status === "error" && trigger) {
      setTrigger(false);
      const msg =
        users.usersChangePassword.response?.error?.response?.data?.detail;
      displayPopup(true, msg ?? "Terjadi masalah saat edit data", false);
    }
    if (users.usersChangePassword?.status === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil mengubah password", true, "Ok");
      // change cookies
      const { password_new_repeat } = data;
      let newData = {
        userId: usersDataId.user_id,
        password: password_new_repeat,
        username: usersDataId.email,
      };
      AuthLogin(newData);
    }
  }, [users]);

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        dispatch(getUsersDataThunk(AuthData().userId));
      }
    });
  }

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);

    if (isValid) {
      const { password_new_repeat, ...newObj } = data;
      newObj.password_old = sha256(data.password_old);
      newObj.password_new = sha256(data.password_new);
      newObj.password_new_re = sha256(data.password_new_repeat);
      newObj.user_id = usersDataId.user_id;
      setTrigger(true);
      dispatch(changePasswordThunk(newObj));
      setData({ password_old: "", password_new: "", password_new_repeat: "" });
    }
  };

  const constraints = {
    password_old: {
      presence: true,
      length: {
        minimum: 6,
        maximum: 100,
      },
    },
    password_new: {
      presence: true,
      length: {
        minimum: 6,
        maximum: 100,
      },
    },
    password_new_repeat: {
      presence: true,
      equality: "password_new",
    },
  };

  return (
    <Container className="dashboard_page__container">
      <h2 className="h2 dashboard__section_title mb-3">Pengaturan Saya</h2>
      <div>
        {users && (
          <Row>
            <Col md={5}>
              <Card className="card_inside__padding">
                <div className="form_ubah_data">
                  <Form onSubmit={handleSubmitForm}>
                    <Row>
                      <Col>
                        <div className="form_akun">
                          <div className="title_input mb-3">Ubah Password</div>
                          <Form.Group>
                            <Form.Label className="label_input__sub mb-2">
                              Password
                            </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              className="mb-1 br-8 input_setting"
                              name="password_old"
                              id="password_old"
                              type="password"
                              value={data.password_old}
                            />
                            <div className="messages"></div>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label className="label_input__sub mb-2">
                              Password Baru
                            </Form.Label>
                            <Form.Control
                              className="mb-1 br-8 input_setting"
                              onChange={handleChange}
                              name="password_new"
                              id="password_new"
                              type="password"
                              value={data.password_new}
                            />
                            <div className="messages"></div>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label className="label_input__sub mb-2">
                              Konfirmasi Password
                            </Form.Label>
                            <Form.Control
                              className="mb-1 br-8 input_setting"
                              onChange={handleChange}
                              name="password_new_repeat"
                              id="password_new_repeat"
                              type="password"
                              value={data.password_new_repeat}
                            />
                            <div className="messages"></div>
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-right mt-4">
                      <Button className="button_save__setting" type="submit">
                        Simpan
                      </Button>
                    </div>
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
}

export default Setting;
