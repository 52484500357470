import {
  // get
  COMPANIES_DATA,
  COMPANIES_DATA_SUCCESS,
  COMPANIES_DATA_ID_SUCCESS,
  // post
  COMPANIES_DATA_POST,
  COMPANIES_DATA_POST_SUCCESS,
  COMPANIES_DATA_POST_FAIL,
  // put
  COMPANIES_DATA_PUT,
  COMPANIES_DATA_PUT_SUCCESS,
  COMPANIES_DATA_PUT_FAIL,
  // delete
  COMPANIES_DATA_DELETE,
  COMPANIES_DATA_DELETE_SUCCESS,
  COMPANIES_DATA_DELETE_FAIL,
  // fail
  COMPANIES_DATA_FAIL,
} from "../config/CompaniesConfig";

// get
export const getCompaniesData = (value) => ({
  type: COMPANIES_DATA,
  value,
});
export const getCompaniesDataSuccess = (value) => ({
  type: COMPANIES_DATA_SUCCESS,
  value,
});
export const getCompaniesDataIdSuccess = (value) => ({
  type: COMPANIES_DATA_ID_SUCCESS,
  value,
});
export const getCompaniesDataFail = (value) => ({
  type: COMPANIES_DATA_FAIL,
  value,
});

// post
export const postCompaniesData = (value) => ({
  type: COMPANIES_DATA_POST,
  value,
});
export const postCompaniesDataSuccess = (value) => ({
  type: COMPANIES_DATA_POST_SUCCESS,
  value,
});
export const postCompaniesDataFail = (value) => ({
  type: COMPANIES_DATA_POST_FAIL,
  value,
});

// put
export const putCompaniesData = (value) => ({
  type: COMPANIES_DATA_PUT,
  value,
});
export const putCompaniesDataSuccess = (value) => ({
  type: COMPANIES_DATA_PUT_SUCCESS,
  value,
});
export const putCompaniesDataFail = (value) => ({
  type: COMPANIES_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteCompaniesData = (value) => ({
  type: COMPANIES_DATA_DELETE,
  value,
});
export const deleteCompaniesDataSuccess = (value) => ({
  type: COMPANIES_DATA_DELETE_SUCCESS,
  value,
});
export const deleteCompaniesDataFail = (value) => ({
  type: COMPANIES_DATA_DELETE_FAIL,
  value,
});

// fail handle
