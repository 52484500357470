import React, { useEffect, useState } from "react";
import { Table, Form, Card, Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FaEdit, FaTrash } from "react-icons/fa";
import {
  deleteSubCategoriesDataThunk,
  getSubCategoriesDataThunk,
  postSubCategoriesDataThunk,
  putSubCategoriesDataThunk,
} from "../../redux/thunks/SubCategoriesThunks";
import Pagination from "../../components/Layout/Pagination";
import { getCategoriesDataThunk } from "../../redux/thunks/CategoriesThunks";
import { SkValidate } from "../../components/common/SkValidate";
import { getSkillTypesDataThunk } from "../../redux/thunks/SkillTypesThunks";
const MySwal = withReactContent(Swal);

function SubCategoryList() {
  const [edit, setEdit] = useState();
  const [editTree, setEditTree] = useState();
  const [show, setShow] = useState(false);
  const [type, setType] = useState();
  const [trigger, setTrigger] = useState(false);

  const [data, setData] = useState({
    sub_categories: "",
  });
  const dispatch = useDispatch();

  const skill_types = useSelector((state) => state.skill_types);
  const sub_categories = useSelector((state) => state.sub_categories);
  const subCategoriesData = sub_categories.subCategoriesData?.items;
  const categories = useSelector((state) => state.categories);
  const categoriesData = categories.categoriesData?.items;
  const skillTypeData = skill_types.skillTypesData?.items;
  const [usersPagination, setUsersPagination] = useState();
  const [size, setSize] = useState(10); //pagination size
  const [active, setActive] = useState(1);
  const [showEdit, setShowEdit] = useState(false);
  useEffect(() => {
    dispatch(getSubCategoriesDataThunk());
    dispatch(
      getCategoriesDataThunk({
        param: `?size=100`,
      })
    );
    dispatch(getSkillTypesDataThunk());
  }, []);
  useEffect(() => {
    setUsersPagination(sub_categories.subCategoriesData?.pagination);
  }, [sub_categories]);
  const handleModal = (type, id) => {
    setEdit(id);
    setType(type);
    setShow(true);
  };

  useEffect(() => {
    if (sub_categories.subCategoriesDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menambahkan data", "Ok");
    }
    // if (
    //   sub_categories.subCategoriesDataPost !== "error" &&
    //   sub_categories.subCategoriesDataPost !== null &&
    //   trigger
    // ) {
    //   setTrigger(false);
    //   displayPopup(false, "Berhasil menambahkan data", "Ok");
    // }
    if (sub_categories.subCategoriesDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat mengedit data", "Ok");
    }
    // if (sub_categories.subCategoriesDataPut === "success" && trigger) {
    //   setTrigger(false);
    //   displayPopup(false, "Berhasil mengedit data", "Ok");
    // }
    if (sub_categories.subCategoriesDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
    }
    if (sub_categories.subCategoriesDataDelete === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil menghapus data", "Ok");
    }
  }, [sub_categories]);

  const handleChange = (e) => {
    if (edit !== undefined) {
      const newData = { ...edit };
      newData[e.target.id] = e.target.value;
      setEdit(newData);
    } else {
      const newData = { ...data };
      newData[e.target.id] = e.target.value;
      setData(newData);
    }
  };

  function paginationSelect(newActive) {
    setActive(newActive);
    // dispatch(getUsersData({ param: `?size=${size}&page=${newActive}` }));
    dispatch(
      getSubCategoriesDataThunk({
        param: `?size=${size}&page=${newActive}`,
      })
    );
  }

  const constraints = {
    title: {
      presence: true,
    },
    category_id: {
      presence: true,
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    const isValid = SkValidate(form, constraints);

    if (isValid) {
      setTrigger(true);
      if (edit !== undefined) {
        const payload = { ...edit };
        const editData = payload.sub_category_id;
        let reqEdit = {};
        reqEdit.body = {
          title: payload.title,
        };
        reqEdit.id = editData;
        dispatch(putSubCategoriesDataThunk(reqEdit));
        setShow(false);
        dispatch(getSubCategoriesDataThunk());
      } else {
        let req = {
          category_id: data.category_id,
          title: data.title,
        };

        dispatch(postSubCategoriesDataThunk(req));
        setShow(false);
        dispatch(getSubCategoriesDataThunk());
        setData({ title: "" });
      }
    }
  };

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        dispatch(deleteSubCategoriesDataThunk(id));
      }
    });
  };

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value.isConfirmed) {
        dispatch(getSubCategoriesDataThunk());
      }
    });
  }

  return (
    <div className="">
      <div className="d-flex justify-content-between">
        <div>
          <div className="bank-soal__title mb-2">Sub Kategori</div>{" "}
          <div className="fw-400 my-2">
            {/* Total Pengguna: {dataUserFull?.pagination?.totalItems} */}
          </div>
        </div>
        <div className="mb-3">
          <Button onClick={() => handleModal("Tambah")}>
            + Tambah Sub Category
          </Button>
        </div>
      </div>

      <Card className="card_inside__padding">
        <div className="d-flex justify-content-end mb-3">
          {usersPagination ? (
            <Pagination
              size={size}
              totalRows={usersPagination.totalItems}
              totalPages={usersPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Tipe Skill</th>
              <th>ID Tipe Skill</th>
              <th>Kategori</th>
              <th>ID Kategori</th>
              <th>Judul Sub Kategori</th>
              <th>ID Sub Kategori</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {subCategoriesData &&
              subCategoriesData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.skill_type}</td>
                    <td>{item.skill_type_id}</td>
                    <td>{item.category}</td>
                    <td>{item.category_id}</td>
                    <td>{item.title}</td>
                    <td>{item.sub_category_id}</td>
                    <td>
                      <div className="d-flex">
                        <Button
                          onClick={() => handleModal("Edit", item)}
                          className="mr-2 "
                          variant="success"
                        >
                          <FaEdit className="fill-white" />
                        </Button>
                        <Button
                          onClick={() => {
                            handleDelete(item.sub_category_id);
                          }}
                          variant="danger"
                        >
                          {" "}
                          <FaTrash className="fill-white" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{type} Sub Category List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label className="title_label__input">
                    Tipe Skill
                  </Form.Label>
                  <Form.Control
                    className="mb-1"
                    as="select"
                    name="skill_type_id"
                    id="skill_type_id"
                    onChange={handleChange}
                    value={
                      edit !== undefined
                        ? edit.skill_type_id
                        : data.skill_type_id
                    }
                  >
                    <option hidden key="blankChoice">
                      Pilih Tipe Skill
                    </option>
                    {skillTypeData &&
                      skillTypeData.map((item, index) => {
                        return (
                          <option key={index} value={item.skill_type_id}>
                            {item.title}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <div className="messages"></div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="title_label__input">
                    Kategori
                  </Form.Label>
                  <Form.Control
                    className="mb-1"
                    as="select"
                    name="category_id"
                    id="category_id"
                    onChange={handleChange}
                    value={
                      edit !== undefined ? edit.category_id : data.category_id
                    }
                  >
                    <option hidden value="" key="blankChoice">
                      Pilih Kategori
                    </option>
                    {categoriesData &&
                      categoriesData.map((item, index) => {
                        return type === "Edit" ? (
                          item.skill_type_id === edit.skill_type_id ? (
                            <option key={index} value={item.category_id}>
                              {item.title}
                            </option>
                          ) : null
                        ) : (
                          <option key={index} value={item.category_id}>
                            {item.title}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <div className="messages"></div>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="title_label__input">
                    Sub Kategori
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    className="mb-3"
                    type="text"
                    id="title"
                    name="title"
                    value={edit !== undefined ? edit.title : data.title}
                    placeholder="Masukkan Sub Kategori"
                  />
                  <div className="messages"></div>
                </Form.Group>{" "}
                <div className="mt-4 text-right">
                  <Button type="submit" variant="primary">
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SubCategoryList;
