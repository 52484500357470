import { sha256 } from "js-sha256";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SkValidate } from "../../components/common/SkValidate";
import { UserService } from "../../http/UserHttp";
import {
  loadingStart,
  loadingFinish,
} from "../../redux/reducers/CommonReducers";
import { PromiseService } from "../../services/PromiseService";

const MySwal = withReactContent(Swal);
function UserRegister() {
  const initialData = {
    name: "",
    role: "student",
    email: "",
    birthday: "",
    company_id: "",
    division: "",
    position: "",
    phone: "",
    sex: "",
  };
  const constraints = {
    name: {
      presence: true,
    },
    role: {
      presence: true,
    },
    email: {
      presence: true,
      email: true,
    },
    birthday: {
      presence: true,
    },
    sex: {
      presence: true,
    },
  };
  const history = useHistory();
  const [userData, setUserData] = useState({ ...initialData });
  const [isVerified, setIsVerified] = useState(false);
  const [inputType, setInputType] = useState("manual_input");
  const [fileUpload, setFileUpload] = useState("");

  useEffect(() => {
    var today = new Date().toJSON().slice(0, 10);
    var date = document.getElementById("birthday");
    date.setAttribute("max", today);
  }, []);

  /**
   * function to get user information
   * by using email
   * takes string as param
   * returns nothing
   */
  const createUser = async (payload) => {
    loadingStart();
    try {
      const response = await UserService.register(payload);
      setUserData({ ...initialData });
      if (response) {
        let idUser = response.split(" ")[5];
        if (isVerified) {
          let tmp = {};
          tmp.verified = 1;
          verifiedUser(idUser, tmp);
        } else {
          alert("Success! " + response);
        }
      }
    } catch (error) {
      alert("Error! " + error?.data);
    }
    loadingFinish();
  };

  const verifiedUser = async (id, payload) => {
    loadingStart();
    try {
      const response = await UserService.putUserById(id, payload);
      if (response) {
        let idUser = response.split(" ")[3];
        alert("Success! " + `create & verified user ${idUser}`);
      }
    } catch (error) {
      alert("Error! " + error?.data);
    }
    loadingFinish();
  };

  /**
   * function to handle form submission
   * takes object as param
   * returns nothing
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      const newData = {
        ...userData,
        password: sha256(userData.email),
      };
      createUser(newData);
    }
  };

  /**
   * function to handle form changes
   * takes object as param
   * returns nothing
   */
  const handleChange = (e) => {
    const newData = { ...userData };
    newData[e.target.name] = e.target.value;
    setUserData(newData);
  };

  /**
   * function to generate form field
   * takes string;string as param
   * returns nothing
   */
  const generateFormGroup = (name, type) => {
    return (
      <div className="form-group">
        <label htmlFor={name} style={{ textTransform: "capitalize" }}>
          {name}
        </label>
        <input
          id={name}
          name={name}
          type={type}
          className="form-control"
          placeholder={`Enter ${name}`}
          value={userData[name]}
          onChange={handleChange}
        />
        <div className="messages"></div>
      </div>
    );
  };

  /**
   *
   * function to check for is user register want to auto regist?
   * takes checked value
   * returns boolean
   */
  const handleVerified = (e) => {
    setIsVerified(e.target.checked);
  };

  function handleDropzone(files) {
    if (files[0]) {
      setFileUpload(files[0]);
    } else {
      displayPopup(
        true,
        "File yang akan di import tidak sesuai (Hanya .csv dengan format yang benar)"
      );
    }
  }

  const onSubmitBulkImport = async (e) => {
    e.preventDefault();
    if (fileUpload) {
      const formData = new FormData();
      formData.append("upfile", fileUpload);
      const url = "v2/users/bulk_upload";
      try {
        const response = await PromiseService.postFormData(url, formData);
        if (response?.status == 201) {
          history.push("/dashboard/user-management/");
        } else {
          displayPopup(true, response?.data || "Terjadi Kesalahan", false);
        }
      } catch (err) {
        displayPopup(true, err?.message || "Terjadi Kesalahan", false);
      }
    }
  };

  function displayPopup(cancel, title, confirm, nextPage) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
      showConfirmButton: confirm,
    }).then((value) => {});
  }

  return (
    <>
      <div className="bank-soal__title mb-2">Register A New User</div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          {/* gender */}
          <div className="form-group">
            <label>Input Type</label>
            <div className="d-flex justify-content-around">
              <div className="form-check">
                <input
                  name="input_type"
                  id="manual_input"
                  value="manual_input"
                  type="radio"
                  className="form-check-input"
                  checked={inputType === "manual_input"}
                  onChange={(e) => setInputType(e.target.value)}
                />
                <label htmlFor="manual_input" className="form-check-label">
                  Manual Input
                </label>
              </div>
              <div className="form-check">
                <input
                  name="input_type"
                  id="bulk_input"
                  value="bulk_input"
                  type="radio"
                  checked={inputType === "bulk_input"}
                  className="form-check-input"
                  onChange={(e) => setInputType(e.target.value)}
                />
                <label htmlFor="bulk_input" className="form-check-label">
                  Bulk Input
                </label>
              </div>
            </div>
          </div>
          {inputType === "bulk_input" ? (
            <>
              <a
                href="https://satukelas-fe-asset.ap-south-1.linodeobjects.com/users_import_template_v3.csv"
                target="_blank"
              >Download Template</a>
              <form autoComplete="off" onSubmit={onSubmitBulkImport}>
                <div className="my-4">
                  <Dropzone accept=".csv" onDrop={handleDropzone}>
                    {({ getRootProps, getInputProps }) => (
                      <div className="container dropzone-style">
                        <div
                          {...getRootProps({
                            className: "dropzone",
                          })}
                        >
                          <input {...getInputProps()} />
                          <p>
                            Drag 'n' drop some files here, or click to select
                            files
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div>{fileUpload?.path}</div>
                </div>
                {/* submit */}
                <div className="form-group">
                  <input
                    type={`submit`}
                    value="Register Users"
                    className="btn btn-primary"
                    disabled={!fileUpload}
                  />
                </div>
              </form>
            </>
          ) : (
            <form onSubmit={handleSubmit} autoComplete="off">
              {/* name */}
              {generateFormGroup("name", "text")}
              {/* role */}
              <div className="form-group">
                <label htmlFor="role">Role</label>
                <select
                  className="form-control"
                  name="role"
                  id="role"
                  onChange={handleChange}
                >
                  <option value={`student`}>Student</option>
                  <option value={`instructor`}>Instructor</option>
                </select>
              </div>

              {/* email */}
              {generateFormGroup("email", "email")}

              {/* password */}
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  type="text"
                  className="form-control"
                  placeholder={`Enter password`}
                  value={userData.email}
                  readOnly
                />
              </div>

              {/* dob */}
              {generateFormGroup("birthday", "date")}

              {/* company_id */}
              {generateFormGroup("company_id", "text")}

              {/* division */}
              {generateFormGroup("division", "text")}

              {/* position */}
              {generateFormGroup("position", "text")}

              {/* phone */}
              {generateFormGroup("phone", "text")}

              {/* gender */}
              <div className="form-group">
                <label>Gender</label>
                <div className="d-flex justify-content-around">
                  <div className="form-check">
                    <input
                      name="sex"
                      id="laki-laki"
                      value="laki-laki"
                      type="radio"
                      className="form-check-input"
                      checked={userData.sex === "laki-laki"}
                      onChange={handleChange}
                    />
                    <label htmlFor="laki-laki" className="form-check-label">
                      Laki-laki
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      name="sex"
                      id="perempuan"
                      value="perempuan"
                      type="radio"
                      checked={userData.sex === "perempuan"}
                      className="form-check-input"
                      onChange={handleChange}
                    />
                    <label htmlFor="perempuan" className="form-check-label">
                      Perempuan
                    </label>
                  </div>
                </div>
                <div className="messages"></div>
              </div>

              <div className="form-group mb-5">
                <label>Verifikasi</label>
                <div className="d-flex">
                  <div className="form-check">
                    <input
                      name="verified"
                      id="verified"
                      type="checkbox"
                      className="form-check-input"
                      onChange={handleVerified}
                    />
                    <label className="form-check-label">
                      Verifikasi User Ini Sekarang
                    </label>
                  </div>
                </div>
              </div>

              {/* submit */}
              <div className="form-group">
                <input
                  type={`submit`}
                  value="Register User"
                  className="btn btn-primary"
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export default UserRegister;
