import {
    // get
    ORDERS_DATA,
    ORDERS_DATA_ID_SUCCESS,
    ORDERS_DATA_SUCCESS,
    ORDERS_DATA_FAIL,
} from "../config/OrdersConfig";

// get
export const getOrdersData = (value) => ({
    type: ORDERS_DATA,
    value,
});
export const getOrdersDataSuccess = (value) => ({
    type: ORDERS_DATA_SUCCESS,
    value,
});
export const getOrdersDataIdSuccess = (value) => ({
    type: ORDERS_DATA_ID_SUCCESS,
    value,
});
export const getOrdersDataFail = (value) => ({
    type: ORDERS_DATA_FAIL,
    value,
});