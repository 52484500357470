import React, { useEffect, useRef, useState } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/Dashboard/index.css";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

import styled from "styled-components";
import { SELECT_DEFAULT_STYLES } from "../../helper/cssStyledComponent";
import {
  CreateOption,
  CreateResult,
  CreateSelected,
} from "../../helper/functions";

import { getSkillTypesDataThunk } from "../../redux/thunks/SkillTypesThunks";
import { getCategoriesDataThunk } from "../../redux/thunks/CategoriesThunks";
import { getSubCategoriesDataThunk } from "../../redux/thunks/SubCategoriesThunks";
import {
  getLearningLevelsDataThunk,
  postLearningLevelsDataThunk,
} from "../../redux/thunks/LearningLevelsThunks";
import { getPositionsDataThunk } from "../../redux/thunks/PositionsThunks";
import { getSectorsDataThunk } from "../../redux/thunks/SectorsThunks";
import { getIndustriesDataThunk } from "../../redux/thunks/IndustriesThunks";
import { getSubIndustriesDataThunk } from "../../redux/thunks/SubIndustriesThunks";
import { getProductsDataThunk } from "../../redux/thunks/ProductsThunks";
import { getSchemesDataThunk } from "../../redux/thunks/SchemesThunks";
import {
  getDiscussTopicsDataThunk,
  liveSearchDiscussTopicsDataThunk,
} from "../../redux/thunks/DiscussTopicsThunks";
import { getCourseTypesDataThunk } from "../../redux/thunks/CourseTypesThunks";
import { SkValidate } from "../../components/common/SkValidate";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import MultipleValueTextInput from "react-multivalue-text-input/build/components/MultipleValueTextInput";
import { getUsersDataThunk } from "../../redux/thunks/UsersThunks";
import { getDeliveryMethodDataThunk } from "../../redux/thunks/DeliveryMethodThunks";
import { removeDiscussLiveTopics } from "../../redux/actions/DiscussTopicsActions";
import { useHistory } from "react-router-dom";
import { postLearningPathDataThunk } from "../../redux/thunks/LearningPathThunks";
const MySwal = withReactContent(Swal);
function CreateLearningPath() {
  const refTest = useRef(null);
  let defaultData = {
    title: "",
    description: "",
    summary: "",
    objectives: [],
    discussion_topics: [],
    delivery_methods: [],
    pathway_learning_levels: [],
    pathway_positions: [],
    pathway_specifications: [],
    products: [],
    schemes: [],
  };
  const history = useHistory();
  const [data, setData] = useState(defaultData);
  const [searchValue, setSearchValue] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [categoryTreeTotal, setCategoryTreeTotal] = useState(1);
  const [skillTypeId, setSkillTypeId] = useState([""]);
  const [categoryId, setCategoryId] = useState([""]);
  const [subCategoryId, setSubCategoryId] = useState([""]);
  const [learningLevelId, setLearningLevelId] = useState([""]);
  const [positionId, setPositionId] = useState([""]);
  const [sectorId, setSectorId] = useState([""]);
  const [industryId, setIndustryId] = useState([""]);
  const [subIndustryId, setSubIndustryId] = useState([""]);
  const [pathwayTotal, setPathwayTotal] = useState(1);
  const [discussionTopics, setDiscussionTopics] = useState();
  const [product, setProduct] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [scheme, setScheme] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const [searchDT, setSearchDT] = useState("");
  const dispatch = useDispatch();
  const skill_types = useSelector((state) => state.skill_types);
  const categories = useSelector((state) => state.categories);
  const learning_levels = useSelector((state) => state.learning_levels);
  const positions = useSelector((state) => state.positions);
  const sectors = useSelector((state) => state.sectors);
  const learning_paths = useSelector((state) => state.learning_paths);
  const industries = useSelector((state) => state.industries);
  const sub_industries = useSelector((state) => state.sub_industries);
  const products = useSelector((state) => state.products);
  const productsData = products?.productsData;
  const schemes = useSelector((state) => state.schemes);
  const course_types = useSelector((state) => state.course_types);
  const sub_categories = useSelector((state) => state.sub_categories);
  const discussion_topics = useSelector((state) => state.discussion_topics);
  const delivery_method = useSelector((state) => state.delivery_method);
  const discussionTopicsData = discussion_topics?.discussTopicsData;
  const discussTopicsLiveSearchData =
    discussion_topics.discussTopicsLiveSearchData;
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };
  function selectDTHandler(value) {
    const newData = { ...data };
    let DTExists = -1;
    if (newData.discussion_topics?.length > 0) {
      DTExists = newData.discussion_topics
        .map(function (e) {
          return e.discussion_topic_id;
        })
        .indexOf(value.discussion_topic_id);
    }
    if (DTExists === -1) {
      newData.discussion_topics.push({
        discussion_topic_id: value.discussion_topic_id,
        name: value.title,
      });
    } else {
      newData.discussion_topics.splice(DTExists, 1);
    }
    setData(newData);
  }
  const liveSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const onKeyDownHanlderDT = (e) => {
    if (e.keyCode == 13) {
      if (searchDT)
        dispatch(liveSearchDiscussTopicsDataThunk(`?keyword=${searchDT}`));
      else dispatch(removeDiscussLiveTopics());
    }
  };
  function removeDTHandler(index) {
    const newData = { ...data };
    newData.discussion_topics.splice(index, 1);
    setData(newData);
  }
  const handleChangeMultiInput = (type, newValue, allItems) => {
    const newData = { ...data };
    newData[type].push(newValue);
    setData(newData);
  };
  const liveSearchDT = (e) => {
    setSearchDT(e.target.value);
  };
  const handleChangeMultiSelect = (type, newValue) => {
    const newData = { ...data };
    newData[type] = newValue;
    setData(newData);
  };
  const handleRemoveMultiInput = (type, newValue) => {
    const newData = { ...data };
    newData[type] = newData[type].filter((e) => e !== newValue);
    setData(newData);
  };
  useEffect(() => {
    if (products?.productsData?.items?.length > 0) {
      setProduct(products.productsData.items);
    }
  }, [products]);
  useEffect(() => {
    if (schemes?.schemesData?.items?.length > 0) {
      setScheme(schemes.schemesData.items);
    }
  }, [schemes]);
  useEffect(() => {
    if (delivery_method?.deliveryMethodData?.items?.length > 0) {
      setDeliveryMethod(delivery_method.deliveryMethodData.items);
    }
  }, [delivery_method]);
  function selectCourseHandler(value) {
    // const newData = { ...data };
    // let userExists = -1;
    // if (newData.discussion_topics?.length > 0) {
    //   userExists = newData.discussion_topics
    //     .map(function (e) {
    //       return e.course_id;
    //     })
    //     .indexOf(value.course_id);
    // }
    // if (userExists === -1) {
    //   newData.prerequisites.push({
    //     course_id: value.course_id,
    //     course: value.title,
    //   });
    // } else {
    //   newData.prerequisites.splice(userExists, 1);
    // }
    // setData(newData);
  }

  useEffect(() => {
    dispatch(getCourseTypesDataThunk());
    dispatch(getUsersDataThunk());
    dispatch(getDiscussTopicsDataThunk());
    dispatch(getSkillTypesDataThunk({ param: `?size=100` }));
    dispatch(getLearningLevelsDataThunk());
    dispatch(getPositionsDataThunk());
    dispatch(getSectorsDataThunk());
    dispatch(getProductsDataThunk());
    dispatch(getSchemesDataThunk());
    dispatch(getCourseTypesDataThunk());
    dispatch(getDeliveryMethodDataThunk());
    dispatch(getCategoriesDataThunk({ param: `?size=100` }));
    dispatch(getSubCategoriesDataThunk({ param: `?size=100` }));
    dispatch(getIndustriesDataThunk());
    dispatch(getSubIndustriesDataThunk());
  }, []);

  useEffect(() => {
    if (data.skill_type_id) {
      dispatch(getCategoriesDataThunk());
    }
  }, [data?.skill_type_id]);

  useEffect(() => {
    if (data.category_id) {
      dispatch(getSubCategoriesDataThunk());
    }
  }, [data?.category_id]);

  useEffect(() => {
    if (data.sectors_id) {
      dispatch(getIndustriesDataThunk());
    }
  }, [data?.sectors_id]);

  useEffect(() => {
    if (data.industry_id) {
      dispatch(getSubIndustriesDataThunk());
    }
  }, [data?.industry_id]);

  useEffect(() => {
    console.log(data);
  }, [data]);
  useEffect(() => {
    if (discussionTopicsData?.items) {
      setDiscussionTopics(discussionTopicsData?.items);
    }
  }, [discussionTopicsData]);
  const constraints = {
    title: {
      presence: true,
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTrigger(true);
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      const payload = { ...data };
      const newDiscussTopics = payload.discussion_topics.map(function (item) {
        return item["discussion_topic_id"];
      });
      payload.products = data.product;
      payload.discussion_topics = newDiscussTopics;
      payload.pathway_positions = positionId;
      payload.sub_category_id = subCategoryId;
      payload.pathway_learning_levels = learningLevelId;
      payload.pathway_specifications = subIndustryId;
      // console.log(payload);
      dispatch(postLearningPathDataThunk(payload));
    }
  };

  useEffect(() => {
    if (learning_paths.learningPathDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    }
    if (learning_paths.learningPathDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat edit data", "Ok");
    }
    if (learning_paths.learningPathDataPut === "success" && trigger) {
      setTrigger(false);
      setData({ ...defaultData });
      // removeReduxState();
    }
    if (learning_paths.learningPathDataPost === "success" && trigger) {
      defaultData = {
        audiences: [],
        categories: [],
        course_type_id: "",
        description: "",
        handouts: [],
        instructors: [],
        learning_paths: [],
        level: "",
        objectives: [],
        points: 0,
        prerequisites: [],
        price: 0,
        requirements: [],
        skills_covered: [],
        summary: "",
        thumbnail: "",
        title: "",
      };
      setData({ ...defaultData });
      setSearchValue("");
      setSearchDT("");
      // removeReduxState();
      // dispatch(removeCourseData());
      history.push("/learning-path/");
    }
  }, [learning_paths]);

  function removeCourseHandler(index) {
    const newData = { ...data };
    newData.discussion_topics.splice(index, 1);
    setData(newData);
  }

  useEffect(() => {
    setLoaded(true);
  }, [loaded]);

  function addCategoryTreeHandler() {
    setCategoryTreeTotal(categoryTreeTotal + 1);
    setSkillTypeId([...skillTypeId, ""]);
    setCategoryId([...categoryId, ""]);
    setSubCategoryId([...subCategoryId, ""]);
  }
  function addPathwayHandler() {
    setPathwayTotal(pathwayTotal + 1);
    setLearningLevelId([...learningLevelId, ""]);
    setPositionId([...positionId, ""]);
    setSectorId([...sectorId, ""]);
    setIndustryId([...industryId, ""]);
    setSubIndustryId([...subIndustryId, ""]);
  }
  function handleChangeCT(e, index) {
    let newData = [];
    switch (e.target.id.split("_")[0]) {
      case "skillTypeId":
        newData = [...skillTypeId];
        newData.splice(index, 1, e.target.value);
        setSkillTypeId(newData);
        break;
      case "categoryId":
        newData = [...categoryId];
        newData.splice(index, 1, e.target.value);
        setCategoryId(newData);
        break;
      case "subCategoryId":
        newData = [...subCategoryId];
        newData.splice(index, 1, e.target.value);
        setSubCategoryId(newData);
        break;
      case "learningLevelId":
        newData = [...learningLevelId];
        newData.splice(index, 1, e.target.value);
        setLearningLevelId(newData);
        break;
      case "positionId":
        newData = [...positionId];
        newData.splice(index, 1, e.target.value);
        setPositionId(newData);
        break;
      case "sectorId":
        newData = [...sectorId];
        newData.splice(index, 1, e.target.value);
        setSectorId(newData);
        break;
      case "industryId":
        newData = [...industryId];
        newData.splice(index, 1, e.target.value);
        setIndustryId(newData);
        break;
      case "subIndustryId":
        newData = [...subIndustryId];
        newData.splice(index, 1, e.target.value);
        setSubIndustryId(newData);
        break;
      default:
        newData = [];
        break;
    }
  }
  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => { });
  }
  return (
    <StyledWrapperMain>
      <Container>
        <Row className="justify-content-center align-items-center mt-2 height-inherit">
          <Col xs={12} sm={12} md={9} lg={9}>
            <div className="">
              <div className="course_create__title"> Buat Learning Path</div>
              <div className="course_create__subtitle"></div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={9} lg={9}>
            <StyledWrapperMain>
              <Card className="card_container mb-4">
                <div className="card_inside__padding ">
                  {loaded ? (
                    <Form onSubmit={handleSubmit}>
                      {/* Judul */}
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-600">Judul</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          id="title"
                          name="title"
                          placeholder="Masukkan Judul Learning Path"
                          value={data.title}
                          type="text"
                        />
                        <div className="messages"></div>
                      </Form.Group>
                      {/* Summary */}
                      <Form.Group>
                        <Form.Label className="fw-600">Summary</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          id="summary"
                          placeholder="Masukkan Summary Learning Path"
                          value={data.summary}
                          className="mb-3"
                          type="text"
                        />
                      </Form.Group>
                      {/* Deskripsi */}
                      <Form.Group className="mb-4">
                        <div>
                          <Form.Label className="fw-600">Deskripsi</Form.Label>
                        </div>
                        <Form.Control
                          onChange={handleChange}
                          id="description"
                          placeholder="Masukkan Deskripsi Learning Path"
                          value={data.description}
                          as="textarea"
                          rows={3}
                        />
                      </Form.Group>
                      {/* Objektif */}
                      <Form.Group>
                        <Form.Label className="fw-600">Objektif</Form.Label>

                        <MultipleValueTextInput
                          onItemAdded={(item, allItems) => {
                            handleChangeMultiInput(
                              "objectives",
                              item,
                              allItems
                            );
                          }}
                          onItemDeleted={(item, allItems) => {
                            handleRemoveMultiInput(
                              "objectives",
                              item,
                              allItems
                            );
                          }}
                          label="Objektif Learning Path (pisahkan dengan koma atau enter) :"
                          name="objectives"
                          placeholder="Masukkan Objektif Learning Path"
                          values={data.objectives}
                        />
                        {/* <Form.Control
                          placeholder=""
                          onChange={handleChange}
                          id="objectives"
                          value={data.objectives}
                          className="mb-3"
                          type="text"
                        /> */}
                        <div className="messages"></div>
                      </Form.Group>
                      {/* Pilih Course */}
                      <Form.Group>
                        <Form.Label className="fw-600">Pilih Course</Form.Label>{" "}
                        <Form.Control
                          placeholder="Pilih Course Learning Path"
                          as="select"
                          name="select_course"
                          id="select_course"
                          onChange={handleChange}
                        >
                          <option value="manual">Pilih Manual</option>
                          <option value="tagging">Pilih by Tagging</option>
                        </Form.Control>
                      </Form.Group>
                      {/* Tipe Course */}
                      {/* <Form.Group>
                        <Form.Label className="fw-600">Tipe Course</Form.Label>{" "}
                        <Form.Control
                          required
                          as="select"
                          type="select"
                          name="course_type"
                          id="course_type"
                          onChange={handleChange}
                        >
                          <option hidden value key="blankChoice">
                            Pilih Tipe Course...
                          </option>
                          {course_types &&
                            course_types?.courseTypesData?.items?.map(
                              (item, index) => {
                                return (
                                  <option value={item.type_id} key={index}>
                                    {item.title}
                                  </option>
                                );
                              }
                            )}
                        </Form.Control>
                      </Form.Group> */}
                      {/* Produk */}
                      {product ? (
                        <Form.Group>
                          <Form.Label className="title_label__input fw-600">
                            Produk{" "}
                          </Form.Label>{" "}
                          <DropdownMultiselect
                            ref={refTest}
                            options={CreateOption(
                              product,
                              "products",
                              "product_id",
                              "title"
                            )}
                            name="products"
                            placeholder="Pilih Product..."
                            handleOnChange={(selected) => {
                              handleChangeMultiSelect(
                                "product",
                                CreateResult(selected, "array")
                              );
                            }}
                            selected={CreateSelected(
                              data.products,
                              "products",
                              product
                            )}
                          />
                        </Form.Group>
                      ) : null}
                      {/* Skema */}
                      {scheme ? (
                        <Form.Group>
                          <Form.Label className="title_label__input">
                            Skema{" "}
                          </Form.Label>{" "}
                          <DropdownMultiselect
                            options={CreateOption(
                              scheme,
                              "schemes",
                              "scheme_id",
                              "title"
                            )}
                            name="schemes"
                            placeholder="Pilih Skema..."
                            handleOnChange={(selected) => {
                              handleChangeMultiSelect(
                                "schemes",
                                CreateResult(selected, "array")
                              );
                            }}
                            selected={CreateSelected(
                              data.schemes,
                              "schemes",
                              scheme
                            )}
                          />
                        </Form.Group>
                      ) : null}
                      {/* Delivery Method */}
                      {deliveryMethod ? (
                        <Form.Group>
                          <Form.Label className="title_label__input">
                            Delivery Method{" "}
                          </Form.Label>{" "}
                          <DropdownMultiselect
                            ref={refTest}
                            options={CreateOption(
                              deliveryMethod,
                              "delivery_methods",
                              "delivery_method_id",
                              "title"
                            )}
                            name="delivery_methods"
                            placeholder="Pilih Delivery Method..."
                            handleOnChange={(selected) => {
                              handleChangeMultiSelect(
                                "delivery_methods",
                                CreateResult(selected, "array")
                              );
                            }}
                            selected={CreateSelected(
                              data.delivery_methods,
                              "schemes",
                              deliveryMethod
                            )}
                          />
                        </Form.Group>
                      ) : null}
                      {/* Topik Bahasan */}
                      <Form.Group>
                        <Form.Label className="title_label__input">
                          Topik Bahasan
                        </Form.Label>
                        <div className="selected-item-wrapper">
                          {data.discussion_topics?.length > 0
                            ? data.discussion_topics.map((value, index) => {
                              return (
                                <p
                                  key={index}
                                  className="selected-item"
                                  onClick={() => removeDTHandler(index)}
                                >
                                  {value.name}
                                </p>
                              );
                            })
                            : null}
                        </div>
                        <Form.Control
                          className="mb-3"
                          type="text"
                          name="discussion_topics"
                          placeholder="Masukan Persyaratan Pelatihan"
                          onChange={liveSearchDT}
                          value={searchDT}
                          onKeyDown={onKeyDownHanlderDT}
                          autoComplete="off"
                          autoCorrect="off"
                          spellCheck="off"
                          disabled={discussion_topics?.isLoadingLive}
                        />
                        {searchDT || discussTopicsLiveSearchData?.items ? (
                          <div className="search-list">
                            {discussion_topics?.isLoadingLive ||
                              discussTopicsLiveSearchData?.length <= 0 ? (
                              <div>
                                <p>Loading...</p>
                              </div>
                            ) : (
                              <ul className="list-wrapper">
                                {discussTopicsLiveSearchData?.items?.length >
                                  0 ? (
                                  discussTopicsLiveSearchData?.items.map(
                                    (value, index) => {
                                      const isExist =
                                        data.discussion_topics.some(
                                          (option) =>
                                            option.discussion_topic_id ===
                                            value.discussion_topic_id
                                        );
                                      return (
                                        <li
                                          className={`list-item ${isExist ? "selected-list-item" : ""
                                            }`}
                                          key={index}
                                          onClick={() => selectDTHandler(value)}
                                        >
                                          {value.title}
                                        </li>
                                      );
                                    }
                                  )
                                ) : (
                                  <p>not found</p>
                                )}
                              </ul>
                            )}
                          </div>
                        ) : null}
                        <div className="messages"></div>
                      </Form.Group>
                      {/* Category Tree */}
                      <Form.Label className="fw-600">Category Tree</Form.Label>
                      <Row className="mb-5">
                        {[...Array(categoryTreeTotal)].map(
                          (valueCT, indexCT) => {
                            return (
                              <>
                                <Col md={4}>
                                  {/* Tipe Skill */}
                                  <Form.Group>
                                    <Form.Label className="title_label__input">
                                      {" "}
                                      Tipe Skill
                                    </Form.Label>{" "}
                                    <Form.Control
                                      required
                                      as="select"
                                      type="select"
                                      name={`skillTypeId_${indexCT}`}
                                      id={`skillTypeId_${indexCT}`}
                                      onChange={(e) =>
                                        handleChangeCT(e, indexCT)
                                      }
                                      value={skillTypeId[indexCT]}
                                    >
                                      <option hidden value key="blankChoice">
                                        Pilih Tipe Skill...
                                      </option>
                                      {skill_types?.skillTypesData?.items?.map(
                                        (item, index) => {
                                          return (
                                            <option
                                              value={item.skill_type_id}
                                              key={index}
                                            >
                                              {item.title}
                                            </option>
                                          );
                                        }
                                      )}
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  {/* Kategori */}
                                  <Form.Group>
                                    <Form.Label className="title_label__input">
                                      {" "}
                                      Kategori
                                    </Form.Label>{" "}
                                    <Form.Control
                                      required
                                      as="select"
                                      type="select"
                                      name={`categoryId_${indexCT}`}
                                      id={`categoryId_${indexCT}`}
                                      onChange={(e) =>
                                        handleChangeCT(e, indexCT)
                                      }
                                      value={categoryId[indexCT]}
                                    >
                                      <option hidden value key="blankChoice">
                                        Pilih Kategori...
                                      </option>
                                      {categories?.categoriesData?.items?.map(
                                        (item, index) => {
                                          return item.skill_type_id ===
                                            skillTypeId[indexCT] ? (
                                            <option
                                              value={item.category_id}
                                              key={index}
                                            >
                                              {item.title}
                                            </option>
                                          ) : null;
                                        }
                                      )}
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  {/* Sub Kategori */}
                                  <Form.Group>
                                    <Form.Label className="title_label__input">
                                      {" "}
                                      Sub Kategori
                                    </Form.Label>{" "}
                                    <Form.Control
                                      required
                                      as="select"
                                      type="select"
                                      name={`subCategoryId_${indexCT}`}
                                      id={`subCategoryId_${indexCT}`}
                                      value={subCategoryId[indexCT]}
                                      onChange={(e) =>
                                        handleChangeCT(e, indexCT)
                                      }
                                    >
                                      <option hidden value key="blankChoice">
                                        Pilih Sub Kategori...
                                      </option>
                                      {sub_categories?.subCategoriesData?.items?.map(
                                        (item, index) => {
                                          return item.category_id ===
                                            categoryId[indexCT] ? (
                                            <option
                                              value={item.sub_category_id}
                                              key={index}
                                            >
                                              {item.title}
                                            </option>
                                          ) : null;
                                        }
                                      )}
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                              </>
                            );
                          }
                        )}

                        <Col
                          md={12}
                          className="d-flex justify-content-end mt-3"
                        >
                          <Button onClick={addCategoryTreeHandler}>
                            + Add Category Tree
                          </Button>
                        </Col>
                      </Row>
                      {/* Pathway */}
                      <Form.Label className="fw-600">Pathway</Form.Label>
                      <Row>
                        {[...Array(pathwayTotal)].map((value, indexCT) => {
                          return (
                            <>
                              <Col md={6}>
                                {/* Tingkat Pembelajaran  */}
                                <Form.Group>
                                  <Form.Label className="title_label__input">
                                    Tingkat Pembelajaran
                                  </Form.Label>{" "}
                                  <Form.Control
                                    required
                                    as="select"
                                    type="select"
                                    name={`learningLevelId_${indexCT}`}
                                    id={`learningLevelId_${indexCT}`}
                                    value={learningLevelId[indexCT]}
                                    onChange={(e) => handleChangeCT(e, indexCT)}
                                  >
                                    <option hidden value key="blankChoice">
                                      Pilih Tingkat Pembelajaran...
                                    </option>
                                    {learning_levels?.learningLevelsData?.items?.map(
                                      (item, index) => {
                                        return (
                                          <option
                                            value={item.learning_level_id}
                                            key={index}
                                          >
                                            {item.title}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Form.Control>
                                </Form.Group>
                              </Col>

                              <Col md={6}>
                                {/* Posisi */}
                                <Form.Group>
                                  <Form.Label className="title_label__input">
                                    Posisi
                                  </Form.Label>{" "}
                                  <Form.Control
                                    required
                                    as="select"
                                    type="select"
                                    name={`positionId_${indexCT}`}
                                    id={`positionId_${indexCT}`}
                                    value={positionId[indexCT]}
                                    onChange={(e) => handleChangeCT(e, indexCT)}
                                  >
                                    <option hidden value key="blankChoice">
                                      Pilih Posisi...
                                    </option>
                                    {positions?.positionsData?.items?.map(
                                      (item, index) => {
                                        return (
                                          <option
                                            value={item.position_id}
                                            key={index}
                                          >
                                            {item.title}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Form.Control>
                                </Form.Group>
                              </Col>

                              <Col md={4}>
                                {/* Sektor */}
                                <Form.Group>
                                  <Form.Label className="title_label__input">
                                    {" "}
                                    Sektor
                                  </Form.Label>{" "}
                                  <Form.Control
                                    required
                                    as="select"
                                    type="select"
                                    name={`sectorId_${indexCT}`}
                                    id={`sectorId_${indexCT}`}
                                    value={sectorId[indexCT]}
                                    onChange={(e) => handleChangeCT(e, indexCT)}
                                  >
                                    <option hidden value key="blankChoice">
                                      Pilih Sektor...
                                    </option>
                                    {sectors?.sectorsData?.items?.map(
                                      (item, index) => {
                                        return (
                                          <option
                                            value={item.sector_id}
                                            key={index}
                                          >
                                            {item.title}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Form.Control>
                                </Form.Group>
                              </Col>

                              <Col md={4}>
                                {/* Industri */}
                                <Form.Group>
                                  <Form.Label className="title_label__input">
                                    {" "}
                                    Industri
                                  </Form.Label>{" "}
                                  <Form.Control
                                    required
                                    as="select"
                                    type="select"
                                    name={`industryId_${indexCT}`}
                                    id={`industryId_${indexCT}`}
                                    value={industryId[indexCT]}
                                    onChange={(e) => handleChangeCT(e, indexCT)}
                                  >
                                    <option hidden value key="blankChoice">
                                      Pilih Industri...
                                    </option>
                                    {industries?.industriesData?.items?.map(
                                      (item, index) => {
                                        return item.sector_id ===
                                          sectorId[indexCT] ? (
                                          <option
                                            value={item.industry_id}
                                            key={index}
                                          >
                                            {item.title}
                                          </option>
                                        ) : null;
                                      }
                                    )}
                                  </Form.Control>
                                </Form.Group>
                              </Col>

                              <Col md={4}>
                                {/* Sub Industri */}
                                <Form.Group>
                                  <Form.Label className="title_label__input">
                                    {" "}
                                    Sub Industri
                                  </Form.Label>{" "}
                                  <Form.Control
                                    required
                                    as="select"
                                    type="select"
                                    name={`subIndustryId_${indexCT}`}
                                    id={`subIndustryId_${indexCT}`}
                                    value={subIndustryId[indexCT]}
                                    onChange={(e) => handleChangeCT(e, indexCT)}
                                  >
                                    <option hidden value key="blankChoice">
                                      Pilih Sub Industri...
                                    </option>
                                    {sub_industries?.subIndustriesData?.items?.map(
                                      (item, index) => {
                                        return item.sector_id ===
                                          industryId[indexCT] ? (
                                          <option
                                            value={item.sector_id}
                                            key={index}
                                          >
                                            {item.title}
                                          </option>
                                        ) : null;
                                      }
                                    )}
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                            </>
                          );
                        })}
                        <Col
                          md={12}
                          className="d-flex justify-content-end mt-3"
                        >
                          <Button onClick={addPathwayHandler}>
                            + Add Pathway
                          </Button>
                        </Col>
                      </Row>
                      <div className="d-flex mt-4 justify-content-end">
                        <Button type="submit" className="my-4 mx-2">
                          Tambahkan
                        </Button>
                      </div>
                    </Form>
                  ) : null}
                </div>
              </Card>
            </StyledWrapperMain>
          </Col>
        </Row>
      </Container>
    </StyledWrapperMain>
  );
}

export default CreateLearningPath;
const StyledWrapperMain = styled.section`
  ${SELECT_DEFAULT_STYLES}
`;
