import {
  //get
  SUB_CATEGORIES_DATA,
  SUB_CATEGORIES_DATA_ID_SUCCESS,
  SUB_CATEGORIES_DATA_SUCCESS,
  SUB_CATEGORIES_DATA_FAIL,
  //post
  SUB_CATEGORIES_DATA_POST,
  SUB_CATEGORIES_DATA_POST_SUCCESS,
  SUB_CATEGORIES_DATA_POST_FAIL,
  //put
  SUB_CATEGORIES_DATA_PUT,
  SUB_CATEGORIES_DATA_PUT_SUCCESS,
  SUB_CATEGORIES_DATA_PUT_FAIL,
  //delete
  SUB_CATEGORIES_DATA_DELETE,
  SUB_CATEGORIES_DATA_DELETE_SUCCESS,
  SUB_CATEGORIES_DATA_DELETE_FAIL,
} from "../config/SubCategoriesConfig";

const intialState = {
  subCategoriesDataPost: null,
  subCategoriesDataPut: null,
  subCategoriesDataDelete: null,
  subCategoriesData: null,
  subCategoriesDataId: null,
  isLoading: false,
};

export const SubCategoriesReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case SUB_CATEGORIES_DATA:
      return { ...state, isLoading: true };
    case SUB_CATEGORIES_DATA_SUCCESS:
      return { ...state, isLoading: false, subCategoriesData: action.value };
    case SUB_CATEGORIES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, subCategoriesDataId: action.value };
    case SUB_CATEGORIES_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        subCategoriesData: "error",
        subCategoriesDataId: "error",
      };
    //post
    case SUB_CATEGORIES_DATA_POST:
      return {
        ...state,
        isLoading: true,
        subCategoriesDataPost: null,
        subCategoriesDataPut: null,
        subCategoriesDataDelete: null,
      };
    case SUB_CATEGORIES_DATA_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subCategoriesDataPost: action.value,
      };
    case SUB_CATEGORIES_DATA_POST_FAIL:
      return { ...state, isLoading: false, subCategoriesDataPost: "error" };

    //put
    case SUB_CATEGORIES_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        subCategoriesDataPost: null,
        subCategoriesDataPut: null,
        subCategoriesDataDelete: null,
      };
    case SUB_CATEGORIES_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, subCategoriesDataPut: "success" };
    case SUB_CATEGORIES_DATA_PUT_FAIL:
      return { ...state, isLoading: false, subCategoriesDataPut: "error" };

    //delete
    case SUB_CATEGORIES_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        subCategoriesDataPost: null,
        subCategoriesDataPut: null,
        subCategoriesDataDelete: null,
      };
    case SUB_CATEGORIES_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, subCategoriesDataDelete: "success" };
    case SUB_CATEGORIES_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, subCategoriesDataDelete: "error" };

    default:
      return state;
  }
};
