export const DISCUSS_TOPICS_DATA_LINK = "discussion_topics";

//get
export const DISCUSS_TOPICS_DATA = "DISCUSS_TOPICS_DATA";
export const DISCUSS_TOPICS_DATA_SUCCESS = "DISCUSS_TOPICS_DATA_SUCCESS";
export const DISCUSS_TOPICS_DATA_ID_SUCCESS = "DISCUSS_TOPICS_DATA_ID_SUCCESS";
export const DISCUSS_TOPICS_DATA_FAIL = "DISCUSS_TOPICS_DATA_FAIL";

//post
export const DISCUSS_TOPICS_DATA_POST = "DISCUSS_TOPICS_DATA_POST";
export const DISCUSS_TOPICS_DATA_POST_SUCCESS =
  "DISCUSS_TOPICS_DATA_POST_SUCCESS";
export const DISCUSS_TOPICS_DATA_POST_FAIL = "DISCUSS_TOPICS_DATA_POST_FAIL";

// put
export const DISCUSS_TOPICS_DATA_PUT = "DISCUSS_TOPICS_DATA_PUT";
export const DISCUSS_TOPICS_DATA_PUT_SUCCESS =
  "DISCUSS_TOPICS_DATA_PUT_SUCCESS";
export const DISCUSS_TOPICS_DATA_PUT_FAIL = "DISCUSS_TOPICS_DATA_PUT_FAIL";

// delete
export const DISCUSS_TOPICS_DATA_DELETE = "DISCUSS_TOPICS_DATA_DELETE";
export const DISCUSS_TOPICS_DATA_DELETE_SUCCESS =
  "DISCUSS_TOPICS_DATA_DELETE_SUCCESS";
export const DISCUSS_TOPICS_DATA_DELETE_FAIL =
  "DISCUSS_TOPICS_DATA_DELETE_FAIL";
export const REMOVE_TOPICS_DATA = "REMOVE_TOPICS_DATA";

export const DISCUSS_TOPICS_LIVE_SEARCH_DATA =
  "DISCUSS_TOPICS_LIVE_SEARCH_DATA";
export const DISCUSS_TOPICS_LIVE_SEARCH_DATA_SUCCESS =
  "DISCUSS_TOPICS_LIVE_SEARCH_DATA_SUCCESS";
export const DISCUSS_TOPICS_LIVE_SEARCH_DATA_FAIL =
  "DISCUSS_TOPICS_LIVE_SEARCH_DATA_FAIL";
export const REMOVE_DISCUSS_LIVE_TOPICS = "REMOVE_DISCUSS_LIVE_TOPICS";
