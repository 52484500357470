// url
export const INBOX_DATA_LINK = "inbox";

// get
export const INBOX_DATA = "INBOX_DATA";
export const INBOX_DATA_SUCCESS = "INBOX_DATA_SUCCESS";
export const INBOX_DATA_ID_SUCCESS = "INBOX_DATA_ID_SUCCESS";
export const INBOX_DATA_FAIL = "INBOX_DATA_FAIL";

// delete
export const INBOX_DATA_DELETE = "INBOX_DATA_DELETE";
export const INBOX_DATA_DELETE_SUCCESS = "INBOX_DATA_DELETE_SUCCESS";
export const INBOX_DATA_DELETE_FAIL = "INBOX_DATA_DELETE_FAIL";
