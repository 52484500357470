import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FaUserAlt,
  FaBookmark,
  FaPowerOff,
  FaBuilding,
  FaUserFriends,
  FaHashtag,
} from "react-icons/fa";
import { MdCardMembership } from "react-icons/md";
// import sidebarBg from "../../assets/images/bg1.jpg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import reactLogo from "../../assets/images/logo.svg";
import logo from "../../assets/images/logo-satukelas.jpeg";
import { AuthLogout } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";

const MySwal = withReactContent(Swal);

const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
  const history = useHistory();
  const users = useSelector((state) => state.users);
  const handleShow = () =>
    MySwal.fire({
      title: "Anda yakin ingin keluar ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        AuthLogout(history);
      }
    });
  return (
    <StyledWrapperMain>
      <ProSidebar
        rtl={rtl}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="lg"
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader>
          <div
            style={{
              padding: "24px",
              fontWeight: "bold",
              color: "#fff",
              fontSize: 16,
              letterSpacing: "1px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Halo, {users.usersDataId.name}{" "}
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape={false}>
            {/* <MenuItem
              icon={<FaHome />}
              suffix={<span className="badge red">new</span>}
            >
              Homepage
              <Link to="/" />
            </MenuItem> */}

            <SubMenu title={"Dashboard"} icon={<FaUserAlt />}>
              <MenuItem>
                Bank Soal
                <Link to="/dashboard/bank-soal/" />
              </MenuItem>
              <MenuItem>
                User Management
                <Link to="/dashboard/user-management/" />
              </MenuItem>
              <MenuItem>
                Tema Soal
                <Link to="/dashboard/tema-soal/" />
              </MenuItem>
              <MenuItem>
                Inbox
                <Link to="/dashboard/inbox/" />
              </MenuItem>
              <MenuItem>
                Orders
                <Link to="/dashboard/orders/" />
              </MenuItem>
            </SubMenu>
            <SubMenu title={"Membership"} icon={<MdCardMembership />}>
              <MenuItem>
                Membership Katalog
                <Link to="/dashboard/membership/" />
              </MenuItem>
              <MenuItem>
                Buat Membership
                <Link to="/dashboard/membership/create" />
              </MenuItem>
              <MenuItem>
                Regis Membership User
                <Link to="/dashboard/membership/register" />
              </MenuItem>
              <MenuItem>
                Edit Membership User
                <Link to="/dashboard/membership/edit" />
              </MenuItem>
            </SubMenu>
            {/* <SubMenu title={"Kursus"} icon={<FaBookReader />}>
              <MenuItem>
                Buat Kursus
                <Link to="/kursus/buat" />
              </MenuItem>
            </SubMenu> */}
            <SubMenu title={"Learning Path"} icon={<FaBookmark />}>
              <MenuItem>
                Tabel Learning Path
                <Link to="/learning-path" />
              </MenuItem>
              <MenuItem>
                Buat Learning Path
                <Link to="/learning-path/buat" />
              </MenuItem>
            </SubMenu>
            <SubMenu title={"Perusahaan"} icon={<FaBuilding />}>
              <MenuItem>
                Tabel Perusahaan
                <Link to="/perusahaan" />
              </MenuItem>
              <MenuItem>
                Buat Perusahaan
                <Link to="/perusahaan/buat" />
              </MenuItem>
            </SubMenu>
            <SubMenu title={"Admin"} icon={<FaUserFriends />}>
              <MenuItem>
                Tabel Admin
                <Link to="/admin" />
              </MenuItem>
              <MenuItem>
                Buat Admin
                <Link to="/admin/buat" />
              </MenuItem>
            </SubMenu>
            <SubMenu title={"Tagging"} icon={<FaHashtag />}>
              <MenuItem>
                Product
                <Link to="/product" />
              </MenuItem>
              <MenuItem>
                Delivery Method
                <Link to="/deliver-method" />
              </MenuItem>{" "}
              <MenuItem>
                Program
                <Link to="/program" />
              </MenuItem>{" "}
              <MenuItem>
                Topik Bahasan
                <Link to="/topik-bahasan" />
              </MenuItem>{" "}
              <SubMenu title="Category Tree">
                {/* <MenuItem>
                  Category Tree
                  <Link to="/category-tree" />
                </MenuItem> */}
                <MenuItem>
                  Tipe Skill
                  <Link to="/skill-type" />
                </MenuItem>
                <MenuItem>
                  Kategori
                  <Link to="/category" />
                </MenuItem>{" "}
                <MenuItem>
                  Sub Kategori
                  <Link to="/sub-category" />
                </MenuItem>
              </SubMenu>{" "}
              <SubMenu title="Pathway">
                <MenuItem>
                  Tingkat Pembelajaran
                  <Link to="/learning-levels" />
                </MenuItem>
                <MenuItem>
                  Posisi
                  <Link to="/posisi" />
                </MenuItem>
                <MenuItem>
                  Sektor
                  <Link to="/sektor" />
                </MenuItem>{" "}
                <MenuItem>
                  Industri
                  <Link to="/industri" />
                </MenuItem>{" "}
                <MenuItem>
                  Sub Industri
                  <Link to="/sub-industri" />
                </MenuItem>
              </SubMenu>{" "}
            </SubMenu>
            {/* <SubMenu title={"Kuis"} icon={<FaBookmark />}>
              <MenuItem>
                Buat Kuis
                <Link to="/kuis/buat" />
              </MenuItem>
            </SubMenu> */}
            {/* <SubMenu title={"Live Session"} icon={<FaBookmark />}>
              <MenuItem>
                Buat Live Session
                <Link to="/live-session/buat" />
              </MenuItem>
            </SubMenu> */}
            <MenuItem  icon={<FaBookmark />}>
              ATPI
              <Link to="/dashboard/atpi" />
            </MenuItem>
            <MenuItem
              className="mobile-logout"
              onClick={handleShow}
              icon={<FaPowerOff />}
            >
              Keluar
            </MenuItem>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </StyledWrapperMain>
  );
};

export default Aside;

const StyledWrapperMain = styled.section`
  .pro-sidebar-inner {
    background: #ffffff;
  }
  .pro-sidebar {
    color: #000;
    font-weight: bold;
  }
  svg {
    fill: #677ca7 !important;
  }
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: #fafafa;
  }
  .pro-sidebar {
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    border-color: #00000011;
  }
`;
