import { PromiseService } from "../../services/PromiseService";
import { DELIVER_METHOD_DATA_LINK } from "../config/DeliveryMethodConfig";
import {
  //get
  getDeliveryMethodData,
  getDeliveryMethodDataSuccess,
  getDeliveryMethodDataIdSuccess,
  getDeliveryMethodDataFail,
  //post
  postDeliveryMethodData,
  postDeliveryMethodDataSuccess,
  postDeliveryMethodDataFail,
  // put
  putDeliveryMethodData,
  putDeliveryMethodDataSuccess,
  putDeliveryMethodDataFail,
  // delete
  deleteDeliveryMethodData,
  deleteDeliveryMethodDataSuccess,
  deleteDeliveryMethodDataFail,
} from "../actions/DeliveryMethodActions";

// get
export const getDeliveryMethodDataThunk = (data) => async (dispatch) => {
  dispatch(getDeliveryMethodData(data));
  if (data) {
    let url = `${DELIVER_METHOD_DATA_LINK}/${data}`;
    if (data.param) url = `${DELIVER_METHOD_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getDeliveryMethodDataSuccess(response.data));
        else dispatch(getDeliveryMethodDataIdSuccess(response.data));
      } else {
        dispatch(getDeliveryMethodDataFail(response));
      }
    } catch (error) {
      dispatch(getDeliveryMethodDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(DELIVER_METHOD_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getDeliveryMethodDataSuccess(response.data));
      } else {
        dispatch(getDeliveryMethodDataFail(response));
      }
    } catch (error) {
      dispatch(getDeliveryMethodDataFail(error));
    }
  }
};

// post
export const postDeliveryMethodDataThunk = (data) => async (dispatch) => {
  dispatch(postDeliveryMethodData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(DELIVER_METHOD_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postDeliveryMethodDataSuccess(response.data));
    } else {
      dispatch(postDeliveryMethodDataFail(response));
    }
  } catch (error) {
    dispatch(postDeliveryMethodDataFail(error));
  }
};

// put
export const putDeliveryMethodDataThunk = (data) => async (dispatch) => {
  dispatch(putDeliveryMethodData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${DELIVER_METHOD_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putDeliveryMethodDataSuccess(response.data));
    } else {
      dispatch(putDeliveryMethodDataFail(response));
    }
  } catch (error) {
    dispatch(putDeliveryMethodDataFail(error));
  }
};

// delete
export const deleteDeliveryMethodDataThunk = (data) => async (dispatch) => {
  dispatch(deleteDeliveryMethodData(data));
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${DELIVER_METHOD_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteDeliveryMethodDataSuccess(response.data));
    } else {
      dispatch(deleteDeliveryMethodDataFail(response));
    }
  } catch (error) {
    dispatch(deleteDeliveryMethodDataFail(error));
  }
};
