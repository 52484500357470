import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Container,
  Form,
  Dropdown,
  Card,
} from "react-bootstrap";
import styled from "styled-components";
import { SELECT_DEFAULT_STYLES } from "../../helper/cssStyledComponent";
import "../../assets/css/Dashboard/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SkValidate } from "../../components/common/SkValidate";
import { FaCalendar } from "react-icons/fa";
import { getCompaniesDataThunk } from "../../redux/thunks/CompaniesThunks";
import {
  getUsersDataThunk,
  postUsersDataThunk,
  putUsersDataThunk,
} from "../../redux/thunks/UsersThunks";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);
const sha256 = require("js-sha256");

function CreateAdmin({ id, handleClose }) {
  const refTest = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState({
    email: "",
    name: "",
    password: "",
    repassword: "",
    company_id: "",
    phone: "",
    birthday: "",
  });
  const [listCompanies, setListCompanies] = useState();
  const [trigger, setTrigger] = useState(false);

  const companies = useSelector((state) => state.companies);
  const companiesData = companies.companiesData;
  const users = useSelector((state) => state.users);

  useEffect(() => {
    // dispatch(getCompaniesData());
    dispatch(getCompaniesDataThunk());
  }, []);

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  useEffect(() => {
    if (companiesData?.items) setListCompanies(companiesData.items);
  }, [companiesData]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      const newData = {
        email: data.email,
        name: data.name,
        password: sha256(data.password),
        role: "admin",
        company_id: data.company_id,
        phone: data.phone,
        birthday: data.birthday,
      };
      setTrigger(true);
      if (id) {
        const newDataPy = {
          body: {
            email: data.email,
            name: data.name,
            role: data.role,
            company_id: data.company_id,
          },
          id: id,
        };
        // dispatch(putUsersData(newDataPy));
        dispatch(putUsersDataThunk(newDataPy));
      } else {
        dispatch(postUsersDataThunk(newData));
        // dispatch(postUsersData(newData));
      }
    }
  };

  useEffect(() => {
    if (trigger && users.usersDataPost === "success") {
      setTrigger(false);
      displayPopup(false, "Berhasil Menambahkan Admin", "Oke");
    }
    if (trigger && users.usersDataPost === "error") {
      setTrigger(false);
      displayPopup(false, "Gagal menambahkan Admin. Silahkan coba lagi", "Oke");
    }
    if (trigger && users.usersDataPut === "success") {
      setTrigger(false);
      displayPopup(false, "Berhasil Mengubah data Admin", "Oke");
    }
    if (trigger && users.usersDataPut === "error") {
      setTrigger(false);
      displayPopup(
        false,
        "Gagal Mengubah data Admin. Silahkan coba lagi",
        "Oke"
      );
    }
    if (id && users.usersDataId?.user_id) {
      setData({
        email: users.usersDataId.email,
        name: users.usersDataId.name,
        company_id: users.usersDataId.company_id,
        role: users.usersDataId.role,
      });
    }
  }, [users]);

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (trigger && users.usersDataPost === "success") {
        history.push("/admin/");
      }
      if (trigger && users.usersDataPut === "success") handleClose();
    });
  }

  useEffect(() => {
    // dispatch(getUsersData(id));
    dispatch(getUsersDataThunk(id));
  }, [id]);

  const constraints = {
    email: {
      presence: true,
      length: {
        maximum: 30,
      },
      email: true,
    },
    password: {
      presence: id ? false : true,
      length: {
        minimum: 6,
        maximum: 100,
      },
    },
    repassword: {
      presence: id ? false : true,
      length: {
        minimum: 6,
        maximum: 100,
      },
      equality: "password",
    },
    name: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    company_id: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    phone: {
      presence: id ? false : true,
      length: {
        minimum: 5,
      },
      numericality: true,
    },
    birthday: {
      presence: id ? false : true,
      length: {
        minimum: 5,
      },
    },
  };

  return (
    <StyledWrapperMain>
      <Container>
        <Row className="justify-content-center align-items-center height-inherit">
          <Col xs={12} sm={12} md={9} lg={9}>
            <div className="mb-3">
              <div className="course_create__title">Daftarkan Admin</div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={9} lg={9}>
            <Card className="card_container mb-5">
              <div className="card_inside__padding ">
                <Form onSubmit={onSubmitHandler}>
                  {/*<Form.Group>
                    <Form.Label className="fw-600"> Foto</Form.Label>
                    <Form.Control className="mb-1" type="file" />
                  </Form.Group>*/}
                  <Form.Group>
                    <Form.Label className="fw-600">Nama Perusahaan</Form.Label>
                    <Form.Control
                      className="mb-1"
                      as="select"
                      id="company_id"
                      name="company_id"
                      placeholder="Pilih Nama Perusahaan"
                      onChange={handleChange}
                      value={data.company_id}
                    >
                      <option hidden value="" key="blankChoice">
                        Pilih Perusahaan...
                      </option>
                      {listCompanies
                        ? listCompanies.map((value, index) => {
                            return (
                              <option
                                id={value.company_id}
                                value={value.company_id}
                                key={index}
                              >
                                {value.name}
                              </option>
                            );
                          })
                        : ""}
                    </Form.Control>
                    <div className="messages"></div>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className="fw-600"> Email </Form.Label>
                        <Form.Control
                          className="mb-1"
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Masukkan Email"
                          onChange={handleChange}
                          value={data.email}
                        />
                        <div className="messages"></div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className="fw-600"> Nama </Form.Label>
                        <Form.Control
                          className="mb-1"
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Masukkan Nama"
                          onChange={handleChange}
                          value={data.name}
                        />
                        <div className="messages"></div>
                      </Form.Group>
                    </Col>
                  </Row>
                  {!id ? (
                    <>
                      <Form.Group>
                        <Form.Label className="fw-600"> Password </Form.Label>
                        <Form.Control
                          className="mb-1"
                          type="text"
                          id="password"
                          name="password"
                          placeholder="Masukkan Password"
                          onChange={handleChange}
                          value={data.password}
                        />
                        <div className="messages"></div>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="fw-600">
                          {" "}
                          Ulang Password{" "}
                        </Form.Label>
                        <Form.Control
                          className="mb-1"
                          type="text"
                          id="repassword"
                          name="repassword"
                          placeholder="Masukkan Ulang Password"
                          onChange={handleChange}
                          value={data.repassword}
                        />
                        <div className="messages"></div>
                      </Form.Group>

                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label className="fw-600">
                              Tanggal Lahir
                            </Form.Label>
                            {/* <div className="position-absolute p-3 d-flex">
                              <FaCalendar />
                            </div> */}
                            <Form.Control
                              className="mb-3 "
                              type="date"
                              id="birthday"
                              name="birthday"
                              value={data.birthday}
                              onChange={handleChange}
                            />
                            <div className="messages"></div>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label className="fw-600">
                              Nomor Telepon
                            </Form.Label>
                            <Form.Control
                              className="mb-1"
                              type="text"
                              id="phone"
                              name="phone"
                              placeholder="Masukkan Nomor Telepon"
                              onChange={handleChange}
                              value={data.phone}
                            />
                            <div className="messages"></div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  <Button type="submit" className="mt-3 float-right">
                    {id ? "edit" : "Daftarkan"}
                  </Button>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </StyledWrapperMain>
  );
}

export default CreateAdmin;
const StyledWrapperMain = styled.section`
  ${SELECT_DEFAULT_STYLES}
`;
