import {
  // get
  CATEGORIES_DATA,
  CATEGORIES_DATA_SUCCESS,
  CATEGORIES_DATA_ID_SUCCESS,
  // post
  CATEGORIES_DATA_POST,
  CATEGORIES_DATA_POST_SUCCESS,
  CATEGORIES_DATA_POST_FAIL,
  // put
  CATEGORIES_DATA_PUT,
  CATEGORIES_DATA_PUT_SUCCESS,
  CATEGORIES_DATA_PUT_FAIL,
  // delete
  CATEGORIES_DATA_DELETE,
  CATEGORIES_DATA_DELETE_SUCCESS,
  CATEGORIES_DATA_DELETE_FAIL,
  // fail
  CATEGORIES_DATA_FAIL,
} from "../config/CategoriesConfig";

// get
export const getCategoriesData = (value) => ({
  type: CATEGORIES_DATA,
  value,
});
export const getCategoriesDataSuccess = (value) => ({
  type: CATEGORIES_DATA_SUCCESS,
  value,
});
export const getCategoriesDataIdSuccess = (value) => ({
  type: CATEGORIES_DATA_ID_SUCCESS,
  value,
});
export const getCategoriesDataFail = (value) => ({
  type: CATEGORIES_DATA_FAIL,
  value,
});

// post
export const postCategoriesData = (value) => ({
  type: CATEGORIES_DATA_POST,
  value,
});
export const postCategoriesDataSuccess = (value) => ({
  type: CATEGORIES_DATA_POST_SUCCESS,
  value,
});
export const postCategoriesDataFail = (value) => ({
  type: CATEGORIES_DATA_POST_FAIL,
  value,
});

// put
export const putCategoriesData = (value) => ({
  type: CATEGORIES_DATA_PUT,
  value,
});
export const putCategoriesDataSuccess = (value) => ({
  type: CATEGORIES_DATA_PUT_SUCCESS,
  value,
});
export const putCategoriesDataFail = (value) => ({
  type: CATEGORIES_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteCategoriesData = (value) => ({
  type: CATEGORIES_DATA_DELETE,
  value,
});
export const deleteCategoriesDataSuccess = (value) => ({
  type: CATEGORIES_DATA_DELETE_SUCCESS,
  value,
});
export const deleteCategoriesDataFail = (value) => ({
  type: CATEGORIES_DATA_DELETE_FAIL,
  value,
});

// fail handle
