import {
  // get
  POSITIONS_DATA,
  POSITIONS_DATA_SUCCESS,
  POSITIONS_DATA_ID_SUCCESS,
  // post
  POSITIONS_DATA_POST,
  POSITIONS_DATA_POST_SUCCESS,
  POSITIONS_DATA_POST_FAIL,
  // put
  POSITIONS_DATA_PUT,
  POSITIONS_DATA_PUT_SUCCESS,
  POSITIONS_DATA_PUT_FAIL,
  // delete
  POSITIONS_DATA_DELETE,
  POSITIONS_DATA_DELETE_SUCCESS,
  POSITIONS_DATA_DELETE_FAIL,
  // fail
  POSITIONS_DATA_FAIL,
} from "../config/PositionsConfig";

// get
export const getPositionsData = (value) => ({
  type: POSITIONS_DATA,
  value,
});
export const getPositionsDataSuccess = (value) => ({
  type: POSITIONS_DATA_SUCCESS,
  value,
});
export const getPositionsDataIdSuccess = (value) => ({
  type: POSITIONS_DATA_ID_SUCCESS,
  value,
});
export const getPositionsDataFail = (value) => ({
  type: POSITIONS_DATA_FAIL,
  value,
});

// post
export const postPositionsData = (value) => ({
  type: POSITIONS_DATA_POST,
  value,
});
export const postPositionsDataSuccess = (value) => ({
  type: POSITIONS_DATA_POST_SUCCESS,
  value,
});
export const postPositionsDataFail = (value) => ({
  type: POSITIONS_DATA_POST_FAIL,
  value,
});

// put
export const putPositionsData = (value) => ({
  type: POSITIONS_DATA_PUT,
  value,
});
export const putPositionsDataSuccess = (value) => ({
  type: POSITIONS_DATA_PUT_SUCCESS,
  value,
});
export const putPositionsDataFail = (value) => ({
  type: POSITIONS_DATA_PUT_FAIL,
  value,
});

// delete
export const deletePositionsData = (value) => ({
  type: POSITIONS_DATA_DELETE,
  value,
});
export const deletePositionsDataSuccess = (value) => ({
  type: POSITIONS_DATA_DELETE_SUCCESS,
  value,
});
export const deletePositionsDataFail = (value) => ({
  type: POSITIONS_DATA_DELETE_FAIL,
  value,
});

// fail handle
