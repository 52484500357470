import React, { useEffect } from "react";
import { useState } from "react";
import { Table, Card, Button, Form, Modal } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProductsDataThunk,
  getProductsDataThunk,
  postProductsDataThunk,
  putProductsDataThunk,
} from "../../redux/thunks/ProductsThunks";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/Layout/Pagination";
import { removeProductsData } from "../../redux/actions/ProductsActions";
import { SkValidate } from "../../components/common/SkValidate";
const MySwal = withReactContent(Swal);

function Product() {
  const history = useHistory();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const productsData = products?.productsData && products.productsData?.items;
  const [show, setShow] = useState();
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState({
    title: "",
  });
  const [type, setType] = useState();
  const [edit, setEdit] = useState();
  const [usersPagination, setUsersPagination] = useState();
  const [size, setSize] = useState(10); //pagination size
  const [active, setActive] = useState(1);
  useEffect(() => {
    setUsersPagination(products?.productsData?.pagination);
  }, [products]);

  useEffect(() => {
    dispatch(getProductsDataThunk());
  }, []);
  const constraints = {
    title: {
      presence: true,
    },
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    const isValid = SkValidate(form, constraints);

    if (isValid) {
      setTrigger(true);
      if (edit !== undefined) {
        const payload = { ...edit };
        const editData = payload.product_id;
        let reqEdit = {};
        reqEdit.body = {
          title: payload.title,
        };
        reqEdit.id = editData;
        dispatch(putProductsDataThunk(reqEdit));
        setShow(false);
        dispatch(getProductsDataThunk());
      } else {
        let req = {
          title: data.title,
        };
        // console.log(req);
        dispatch(postProductsDataThunk(req));
        setShow(false);
        dispatch(getProductsDataThunk());
        setData({ title: "" });
      }
    }
  };

  const handleChange = (e) => {
    if (edit !== undefined) {
      const newData = { ...edit };
      newData[e.target.id] = e.target.value;
      setEdit(newData);
    } else {
      const newData = { ...data };
      newData[e.target.id] = e.target.value;
      setData(newData);
    }
  };

  useEffect(() => {
    if (products) {
      if (products.productsDataPost === "error" && trigger) {
        setTrigger(false);
        displayPopup(false, "Terjadi masalah saat menambahkan data", "Ok");
      }
      // if (
      //   products.productsDataPost !== "error" &&
      //   products.productsDataPost !== null &&
      //   trigger
      // ) {
      //   setTrigger(false);
      //   displayPopup(false, "Berhasil menambahkan data", "Ok");
      // }

      if (products.productsDataPut === "error" && trigger) {
        setTrigger(false);
        displayPopup(false, "Terjadi masalah saat mengedit data", "Ok");
      }
      // if (products.productsDataPut === "success" && trigger) {
      //   setTrigger(false);
      //   displayPopup(false, "Berhasil mengedit data", "Ok");
      // }

      if (products.productsDataDelete === "error" && trigger) {
        setTrigger(false);
        displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
      }
      if (products.productsDataDelete === "success" && trigger) {
        setTrigger(false);
        displayPopup(false, "Berhasil menghapus data", "Ok");
      }
    }
  }, [products]);

  useEffect(() => {}, [data]);

  useEffect(() => {}, [products]);

  const handleModal = (type, id) => {
    setEdit(id);
    setType(type);
    setShow(true);
  };

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin <br/>ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        dispatch(deleteProductsDataThunk(id));
      }
    });
  };

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value.isConfirmed) {
        if (products?.productsDataDelete === "success") {
          dispatch(removeProductsData());
        }
        dispatch(getProductsDataThunk());
      }
    });
  }
  function paginationSelect(newActive) {
    setActive(newActive);
    dispatch(
      getProductsDataThunk({
        param: `?size=${size}&page=${newActive}`,
      })
    );
  }
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <div className="bank-soal__title mb-2">List Produk</div>{" "}
          <div className="fw-400 my-2">
            {/* Total Pengguna: {dataUserFull?.pagination?.totalItems} */}
          </div>
        </div>
        <div className="mb-3">
          <Button onClick={() => handleModal("Tambah")}>
            + Tambah Product
          </Button>
        </div>
      </div>

      <Card className="card_inside__padding">
        <div className="d-flex justify-content-end mb-3">
          {usersPagination ? (
            <Pagination
              size={size}
              totalRows={usersPagination.totalItems}
              totalPages={usersPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th className="w-50">Judul</th>
              <th>Product ID</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {productsData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.title}</td>
                  <td>{item.product_id}</td>
                  <td>{moment(item.created_at).format("DD/MM/YYYY")}</td>
                  <td>
                    <div className="d-flex">
                      <Button
                        onClick={() => handleModal("Edit", item)}
                        className="mr-2 "
                        variant="success"
                      >
                        <FaEdit className="fill-white" />
                      </Button>
                      <Button
                        onClick={() => {
                          handleDelete(item.product_id);
                        }}
                        variant="danger"
                      >
                        {" "}
                        <FaTrash className="fill-white" />
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{type} Produk</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label className="title_label__input">
                    Nama Produk
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    className="mb-2"
                    type="text"
                    id="title"
                    name="title"
                    value={edit !== undefined ? edit.title : data.title}
                    placeholder="Masukkan Judul Pelatihan"
                  />
                  <div className="messages"></div>
                </Form.Group>
                <div className="mt-4 text-right">
                  <Button type="submit" variant="primary">
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Product;
