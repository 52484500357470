import React, { useEffect, useState } from "react";
import { Table, Form, Card, Button, Modal } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import SkModal from "../../components/common/SkModal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/Layout/Pagination";
import {
  deleteIndustriesDataThunk,
  getIndustriesDataThunk,
  postIndustriesDataThunk,
  putIndustriesDataThunk,
} from "../../redux/thunks/IndustriesThunks";
import { getSectorsDataThunk } from "../../redux/thunks/SectorsThunks";
import { removeIndustriesData } from "../../redux/actions/IndustriesActions";
import { SkValidate } from "../../components/common/SkValidate";
const MySwal = withReactContent(Swal);
function Industries() {
  const history = useHistory();
  const dispatch = useDispatch();
  const industries = useSelector((state) => state.industries);
  const industriesData = industries.industriesData?.items;

  const sectors = useSelector((state) => state.sectors);
  const sectorsData = sectors.sectorsData?.items;
  const [show, setShow] = useState();
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState({
    title: "",
  });
  const [type, setType] = useState();
  const [edit, setEdit] = useState();

  const [usersPagination, setUsersPagination] = useState();
  const [size, setSize] = useState(10); //pagination size
  const [active, setActive] = useState(1);
  useEffect(() => {
    setUsersPagination(industries?.industriesData?.pagination);
  }, [industries]);

  useEffect(() => {
    dispatch(getIndustriesDataThunk());
    dispatch(
      getSectorsDataThunk({
        param: `?size=100`,
      })
    );
  }, []);

  useEffect(() => {}, [industries]);
  const constraints = {
    title: {
      presence: true,
    },
    sector_id: {
      presence: true,
    },
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    const isValid = SkValidate(form, constraints);

    if (isValid) {
      setTrigger(true);
      if (edit !== undefined) {
        const payload = { ...edit };
        const editData = payload.industry_id;
        let reqEdit = {};
        reqEdit.body = {
          title: payload.title,
        };
        reqEdit.id = editData;
        dispatch(putIndustriesDataThunk(reqEdit));
        setShow(false);
        dispatch(getIndustriesDataThunk());
      } else {
        let req = {
          title: data.title,
          sector_id: data.sector_id,
        };
        dispatch(postIndustriesDataThunk(req));
        setShow(false);
        dispatch(getIndustriesDataThunk());
        setData({ title: "" });
      }
    }
  };
  const handleChange = (e) => {
    if (edit !== undefined) {
      const newData = { ...edit };
      newData[e.target.id] = e.target.value;
      setEdit(newData);
    } else {
      const newData = { ...data };
      newData[e.target.id] = e.target.value;
      setData(newData);
    }
  };
  useEffect(() => {}, [data]);
  const handleModal = (type, id) => {
    setEdit(id);
    setType(type);
    setShow(true);
  };

  useEffect(() => {
    if (industries.industriesDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menambahkan data", "Ok");
    }
    // if (
    //   industries.industriesDataPost !== "error" &&
    //   industries.industriesDataPost !== null &&
    //   trigger
    // ) {
    //   setTrigger(false);
    //   displayPopup(false, "Berhasil menambahkan data", "Ok");
    // }

    if (industries.industriesDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat mengedit data", "Ok");
    }
    // if (industries.industriesDataPut === "success" && trigger) {
    //   setTrigger(false);
    //   displayPopup(false, "Berhasil mengedit data", "Ok");
    // }
    if (industries.industriesDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
    }
    if (industries.industriesDataDelete === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil menghapus data", "Ok");
    }
  }, [industries]);

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin <br/>ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        dispatch(deleteIndustriesDataThunk(id));
      }
    });
  };
  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value.isConfirmed) {
        if (industries.industriesDataDelete === "success") {
          dispatch(removeIndustriesData());
        }
        dispatch(getIndustriesDataThunk());
      }
    });
  }

  function paginationSelect(newActive) {
    setActive(newActive);
    // dispatch(getUsersData({ param: `?size=${size}&page=${newActive}` }));
    dispatch(
      getIndustriesDataThunk({
        param: `?size=${size}&page=${newActive}`,
      })
    );
  }
  return (
    <div className="">
      <div className="d-flex justify-content-between">
        <div>
          <div className="bank-soal__title mb-2">List Industri</div>{" "}
          <div className="fw-400 my-2">
            {/* Total Pengguna: {dataUserFull?.pagination?.totalItems} */}
          </div>
        </div>
        <div className="mb-3">
          <Button onClick={() => handleModal("Tambah")}>
            + Tambah Industri
          </Button>
        </div>
      </div>

      <Card className="card_inside__padding">
        <div className="d-flex justify-content-end mb-3">
          {usersPagination ? (
            <Pagination
              size={size}
              totalRows={usersPagination.totalItems}
              totalPages={usersPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Sektor</th>
              <th>Industri</th>
              <th>Industri ID</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {industriesData &&
              industriesData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.sector}</td>
                    <td>{item.title}</td>
                    <td>{item.industry_id}</td>
                    <td>{moment(item.created_at).format("DD/MM/YYYY")}</td>
                    <td>
                      <div className="d-flex">
                        <Button
                          onClick={() => handleModal("Edit", item)}
                          className="mr-2 "
                          variant="success"
                        >
                          <FaEdit className="fill-white" />
                        </Button>
                        <Button
                          onClick={() => {
                            handleDelete(item.industry_id);
                          }}
                          variant="danger"
                        >
                          {" "}
                          <FaTrash className="fill-white" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{type} Industri</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label className="title_label__input">Sektor</Form.Label>
                  <Form.Control
                    className="mb-3"
                    as="select"
                    name="sector_id"
                    id="sector_id"
                    onChange={handleChange}
                    value={edit !== undefined ? edit.sector_id : data.sector_id}
                  >
                    <option hidden value="" key="blankChoice">
                      Pilih Sektor
                    </option>
                    {sectorsData &&
                      sectorsData.map((item, index) => {
                        return (
                          <option key={index} value={item.sector_id}>
                            {item.title}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <div className="messages"></div>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="title_label__input">
                    Nama Industri
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    className="mb-3"
                    type="text"
                    id="title"
                    name="title"
                    value={edit !== undefined ? edit.title : data.title}
                    placeholder="Masukkan Industri"
                  />
                  <div className="messages"></div>
                </Form.Group>
                <div className="mt-4 text-right">
                  <Button type="submit" variant="primary">
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Industries;
