import {
  // get
  INDUSTRIES_DATA,
  INDUSTRIES_DATA_SUCCESS,
  INDUSTRIES_DATA_ID_SUCCESS,
  // post
  INDUSTRIES_DATA_POST,
  INDUSTRIES_DATA_POST_SUCCESS,
  INDUSTRIES_DATA_POST_FAIL,
  // put
  INDUSTRIES_DATA_PUT,
  INDUSTRIES_DATA_PUT_SUCCESS,
  INDUSTRIES_DATA_PUT_FAIL,
  // delete
  INDUSTRIES_DATA_DELETE,
  INDUSTRIES_DATA_DELETE_SUCCESS,
  INDUSTRIES_DATA_DELETE_FAIL,
  // fail
  INDUSTRIES_DATA_FAIL,
  REMOVE_INDUSTRIES_DATA,
} from "../config/IndustriesConfig";

// get
export const getIndustriesData = (value) => ({
  type: INDUSTRIES_DATA,
  value,
});
export const getIndustriesDataSuccess = (value) => ({
  type: INDUSTRIES_DATA_SUCCESS,
  value,
});
export const getIndustriesDataIdSuccess = (value) => ({
  type: INDUSTRIES_DATA_ID_SUCCESS,
  value,
});
export const getIndustriesDataFail = (value) => ({
  type: INDUSTRIES_DATA_FAIL,
  value,
});

// post
export const postIndustriesData = (value) => ({
  type: INDUSTRIES_DATA_POST,
  value,
});
export const postIndustriesDataSuccess = (value) => ({
  type: INDUSTRIES_DATA_POST_SUCCESS,
  value,
});
export const postIndustriesDataFail = (value) => ({
  type: INDUSTRIES_DATA_POST_FAIL,
  value,
});

// put
export const putIndustriesData = (value) => ({
  type: INDUSTRIES_DATA_PUT,
  value,
});
export const putIndustriesDataSuccess = (value) => ({
  type: INDUSTRIES_DATA_PUT_SUCCESS,
  value,
});
export const putIndustriesDataFail = (value) => ({
  type: INDUSTRIES_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteIndustriesData = (value) => ({
  type: INDUSTRIES_DATA_DELETE,
  value,
});
export const deleteIndustriesDataSuccess = (value) => ({
  type: INDUSTRIES_DATA_DELETE_SUCCESS,
  value,
});
export const deleteIndustriesDataFail = (value) => ({
  type: INDUSTRIES_DATA_DELETE_FAIL,
  value,
});

export const removeIndustriesData = (value) => ({
  type: REMOVE_INDUSTRIES_DATA,
  value,
});

// fail handle
