export const COMPANIES_DATA_LINK = "companies";

//get
export const COMPANIES_DATA = "COMPANIES_DATA";
export const COMPANIES_DATA_SUCCESS = "COMPANIES_DATA_SUCCESS";
export const COMPANIES_DATA_ID_SUCCESS = "COMPANIES_DATA_ID_SUCCESS";
export const COMPANIES_DATA_FAIL = "COMPANIES_DATA_FAIL";

//post
export const COMPANIES_DATA_POST = "COMPANIES_DATA_POST";
export const COMPANIES_DATA_POST_SUCCESS = "COMPANIES_DATA_POST_SUCCESS";
export const COMPANIES_DATA_POST_FAIL = "COMPANIES_DATA_POST_FAIL";

// put
export const COMPANIES_DATA_PUT = "COMPANIES_DATA_PUT";
export const COMPANIES_DATA_PUT_SUCCESS = "COMPANIES_DATA_PUT_SUCCESS";
export const COMPANIES_DATA_PUT_FAIL = "COMPANIES_DATA_PUT_FAIL";

// delete
export const COMPANIES_DATA_DELETE = "COMPANIES_DATA_DELETE";
export const COMPANIES_DATA_DELETE_SUCCESS = "COMPANIES_DATA_DELETE_SUCCESS";
export const COMPANIES_DATA_DELETE_FAIL = "COMPANIES_DATA_DELETE_FAIL";
