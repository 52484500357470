import {
  //get
  QUIZ_THEME_DATA,
  QUIZ_THEME_DATA_SUCCESS,
  QUIZ_THEME_DATA_FAIL,
  //live
  QUIZ_THEME_LIVE_SEARCH_DATA,
  QUIZ_THEME_LIVE_SEARCH_DATA_SUCCESS,
  QUIZ_THEME_LIVE_SEARCH_DATA_FAIL,
  //post
  QUIZ_THEME_DATA_POST,
  QUIZ_THEME_DATA_POST_SUCCESS,
  QUIZ_THEME_DATA_POST_FAIL,
  //put
  QUIZ_THEME_DATA_PUT,
  QUIZ_THEME_DATA_PUT_SUCCESS,
  QUIZ_THEME_DATA_PUT_FAIL,
  //delete
  QUIZ_THEME_DATA_DELETE,
  QUIZ_THEME_DATA_DELETE_SUCCESS,
  QUIZ_THEME_DATA_DELETE_FAIL,
  REMOVE_QUIZ_THEME_DATA,
  REMOVE_QUIZ_THEME_DATA_ID,
} from "../config/QuizThemeConfig";

const intialState = {
  quizThemeDataPost: [],
  quizThemeDataPut: "",
  quizThemeDataDelete: [],
  quizThemeData: [],
  quizThemeLiveSearchData: [],
  quizThemeDataId: [],
  isLoading: false,
  isLoadingLive: false,
};

export const QuizThemeReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case QUIZ_THEME_DATA:
      return { ...state, isLoading: true };
    case QUIZ_THEME_DATA_SUCCESS:
      return { ...state, isLoading: false, quizThemeData: action.value };
    case QUIZ_THEME_DATA_FAIL:
      return { ...state, isLoading: false, quizThemeData: "error" };

    //post
    case QUIZ_THEME_DATA_POST:
      return { ...state, isLoading: true };
    case QUIZ_THEME_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, quizThemeDataPost: "success" };
    case QUIZ_THEME_DATA_POST_FAIL:
      return { ...state, isLoading: false, quizThemeDataPost: "error" };

    //put
    case QUIZ_THEME_DATA_PUT:
      return { ...state, isLoading: true, quizThemeDataPut: "" };
    case QUIZ_THEME_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, quizThemeDataPut: "success" };
    case QUIZ_THEME_DATA_PUT_FAIL:
      return { ...state, isLoading: false, quizThemeDataPut: "error" };
    //get live search
    case QUIZ_THEME_LIVE_SEARCH_DATA:
      return { ...state, isLoadingLive: true, quizThemeLiveSearchData: [] };
    case QUIZ_THEME_LIVE_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        isLoadingLive: false,
        quizThemeLiveSearchData: action.value,
      };
    case QUIZ_THEME_LIVE_SEARCH_DATA_FAIL:
      return {
        ...state,
        isLoadingLive: false,
        quizThemeLiveSearchData: "error",
      };
    //delete
    case QUIZ_THEME_DATA_DELETE:
      return { ...state, isLoading: true };
    case QUIZ_THEME_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, quizThemeDataDelete: "success" };
    case QUIZ_THEME_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, quizThemeDataDelete: "error" };

    //remove
    case REMOVE_QUIZ_THEME_DATA_ID:
      return {
        ...state,
        quizThemeDataId: [],
        quizThemeDataPost: [],
        quizThemeDataPut: "",
        quizThemeDataDelete: [],
      };
    case REMOVE_QUIZ_THEME_DATA:
      return {
        ...state,
        quizThemeData: [],
        quizThemeLiveSearchData: [],
      };

    default:
      return state;
  }
};
