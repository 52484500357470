import React, { useState } from "react";
import { FaHome, FaInbox, FaList, FaListAlt, FaUserAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function StickyNav({ InnerComponent }) {
  const users = useSelector((state) => state.users);
  const mySelf = users.usersDataId;
  return (
    <div className="app-footer border-0 shadow-lg">
      <Link to="/dashboard/bank-soal/" className="nav-content-bttn nav-center">
        <FaHome />
      </Link>
      <Link to="/dashboard/user-management/" className="nav-content-bttn">
        <FaList/>
      </Link>
      <Link to="/dashboard/tema-soal/" className="nav-content-bttn">
        <FaListAlt />
      </Link>
      <Link to="/dashboard/inbox/" className="nav-content-bttn sidebar-layer">
        <FaInbox />
      </Link>
      <Link to="/profil" className="nav-content-bttn">
        {mySelf?.avatar === "" ? (
          <FaUserAlt className="size-profile" />
        ) : (
          <img
            src={`${mySelf?.avatar}`}
            alt="user"
            className="w30 rounded-circle mt--1"
          />
        )}
      </Link>
    </div>
  );
}

export default StickyNav;
