import {
  //get
  SECTORS_DATA,
  SECTORS_DATA_ID_SUCCESS,
  SECTORS_DATA_SUCCESS,
  SECTORS_DATA_FAIL,
  //post
  SECTORS_DATA_POST,
  SECTORS_DATA_POST_SUCCESS,
  SECTORS_DATA_POST_FAIL,
  //put
  SECTORS_DATA_PUT,
  SECTORS_DATA_PUT_SUCCESS,
  SECTORS_DATA_PUT_FAIL,
  //delete
  SECTORS_DATA_DELETE,
  SECTORS_DATA_DELETE_SUCCESS,
  SECTORS_DATA_DELETE_FAIL,
} from "../config/SectorsConfig";

const intialState = {
  sectorsDataPost: null,
  sectorsDataPut: null,
  sectorsDataDelete: null,
  sectorsData: null,
  sectorsDataId: null,
  isLoading: false,
};

export const SectorsReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case SECTORS_DATA:
      return { ...state, isLoading: true };
    case SECTORS_DATA_SUCCESS:
      return { ...state, isLoading: false, sectorsData: action.value };
    case SECTORS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, sectorsDataId: action.value };
    case SECTORS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        sectorsData: "error",
        sectorsDataId: "error",
      };
    //post
    case SECTORS_DATA_POST:
      return {
        ...state,
        isLoading: true,
        sectorsDataPost: null,
        sectorsDataPut: null,
        sectorsDataDelete: null,
      };
    case SECTORS_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, sectorsDataPost: action.value };
    case SECTORS_DATA_POST_FAIL:
      return { ...state, isLoading: false, sectorsDataPost: "error" };

    //put
    case SECTORS_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        sectorsDataPost: null,
        sectorsDataPut: null,
        sectorsDataDelete: null,
      };
    case SECTORS_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, sectorsDataPut: "success" };
    case SECTORS_DATA_PUT_FAIL:
      return { ...state, isLoading: false, sectorsDataPut: "error" };

    //delete
    case SECTORS_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        sectorsDataPost: null,
        sectorsDataPut: null,
        sectorsDataDelete: null,
      };
    case SECTORS_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, sectorsDataDelete: "success" };
    case SECTORS_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, sectorsDataDelete: "error" };

    default:
      return state;
  }
};
