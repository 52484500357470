import {
  //get
  ASSETS_DATA,
  ASSETS_DATA_SUCCESS,
  ASSETS_DATA_FAIL,
  //post
  ASSETS_DATA_POST,
  ASSETS_DATA_POST_SUCCESS,
  ASSETS_DATA_POST_FAIL,
  //delete
  ASSETS_DATA_DELETE,
  ASSETS_DATA_DELETE_SUCCESS,
  ASSETS_DATA_DELETE_FAIL,
  //remove
  REMOVE_ASSETS_DATA_POST,
} from "../config/AssetsConfig";

//get
export const getAssetsData = (value) => ({
  type: ASSETS_DATA,
  value,
});
export const getAssetsDataSuccess = (value) => ({
  type: ASSETS_DATA_SUCCESS,
  value,
});
export const getAssetsDataFail = (value) => ({
  type: ASSETS_DATA_FAIL,
  value,
});

//post
export const postAssetsData = (value) => ({
  type: ASSETS_DATA_POST,
  value,
});
export const postAssetsDataSuccess = (value) => ({
  type: ASSETS_DATA_POST_SUCCESS,
  value,
});
export const postAssetsDataFail = (value) => ({
  type: ASSETS_DATA_POST_FAIL,
  value,
});

//delete
export const deleteAssetsData = (value) => ({
  type: ASSETS_DATA_DELETE,
  value,
});
export const deleteAssetsDataSuccess = (value) => ({
  type: ASSETS_DATA_DELETE_SUCCESS,
  value,
});
export const deleteAssetsDataFail = (value) => ({
  type: ASSETS_DATA_DELETE_FAIL,
  value,
});

//remove
export const removeAssetsDataPost = (value) => ({
  type: REMOVE_ASSETS_DATA_POST,
  value,
});
