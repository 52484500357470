export const INDUSTRIES_DATA_LINK = "industries";

//get
export const INDUSTRIES_DATA = "INDUSTRIES_DATA";
export const INDUSTRIES_DATA_SUCCESS = "INDUSTRIES_DATA_SUCCESS";
export const INDUSTRIES_DATA_ID_SUCCESS = "INDUSTRIES_DATA_ID_SUCCESS";
export const INDUSTRIES_DATA_FAIL = "INDUSTRIES_DATA_FAIL";

//post
export const INDUSTRIES_DATA_POST = "INDUSTRIES_DATA_POST";
export const INDUSTRIES_DATA_POST_SUCCESS = "INDUSTRIES_DATA_POST_SUCCESS";
export const INDUSTRIES_DATA_POST_FAIL = "INDUSTRIES_DATA_POST_FAIL";

// put
export const INDUSTRIES_DATA_PUT = "INDUSTRIES_DATA_PUT";
export const INDUSTRIES_DATA_PUT_SUCCESS = "INDUSTRIES_DATA_PUT_SUCCESS";
export const INDUSTRIES_DATA_PUT_FAIL = "INDUSTRIES_DATA_PUT_FAIL";

// delete
export const INDUSTRIES_DATA_DELETE = "INDUSTRIES_DATA_DELETE";
export const INDUSTRIES_DATA_DELETE_SUCCESS = "INDUSTRIES_DATA_DELETE_SUCCESS";
export const INDUSTRIES_DATA_DELETE_FAIL = "INDUSTRIES_DATA_DELETE_FAIL";

export const REMOVE_INDUSTRIES_DATA = "REMOVE_INDUSTRIES_DATA";
