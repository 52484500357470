import React, { useState } from "react";
import { Spinner, Modal } from "react-bootstrap";
import "../../assets/css/Layout/loading.css";

function Loading({ isLoading }) {
  return (
    <>
      {isLoading ? (
        <div className="LoadingWrapper">
          <Spinner animation="border" role="status"></Spinner>
          <p className={"LoadingFont"}>Loading...</p>
        </div>
      ) : null}
    </>
  );
}
export default Loading;
