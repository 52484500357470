export const SUB_CATEGORIES_DATA_LINK = "sub_categories";

//get
export const SUB_CATEGORIES_DATA = "SUB_CATEGORIES_DATA";
export const SUB_CATEGORIES_DATA_SUCCESS = "SUB_CATEGORIES_DATA_SUCCESS";
export const SUB_CATEGORIES_DATA_ID_SUCCESS = "SUB_CATEGORIES_DATA_ID_SUCCESS";
export const SUB_CATEGORIES_DATA_FAIL = "SUB_CATEGORIES_DATA_FAIL";

//post
export const SUB_CATEGORIES_DATA_POST = "SUB_CATEGORIES_DATA_POST";
export const SUB_CATEGORIES_DATA_POST_SUCCESS = "SUB_CATEGORIES_DATA_POST_SUCCESS";
export const SUB_CATEGORIES_DATA_POST_FAIL = "SUB_CATEGORIES_DATA_POST_FAIL";

// put
export const SUB_CATEGORIES_DATA_PUT = "SUB_CATEGORIES_DATA_PUT";
export const SUB_CATEGORIES_DATA_PUT_SUCCESS = "SUB_CATEGORIES_DATA_PUT_SUCCESS";
export const SUB_CATEGORIES_DATA_PUT_FAIL = "SUB_CATEGORIES_DATA_PUT_FAIL";

// delete
export const SUB_CATEGORIES_DATA_DELETE = "SUB_CATEGORIES_DATA_DELETE";
export const SUB_CATEGORIES_DATA_DELETE_SUCCESS = "SUB_CATEGORIES_DATA_DELETE_SUCCESS";
export const SUB_CATEGORIES_DATA_DELETE_FAIL = "SUB_CATEGORIES_DATA_DELETE_FAIL";
