import React, { useState, useEffect } from "react";
import { AuthIsLogin } from "../../helper/auth";
import { FaEdit, FaTimes } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import { VscPreview } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import FileUploader from "../../components/FileUploader";
import { Modal, Button, InputGroup, Form, Table, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/Layout/Pagination";
import ViewQuestion from "../../components/ViewQuestion";
import EditQuestion from "../../components/EditQuestion";
import { Link } from "react-router-dom";
import "../../assets/css/Dashboard/questions.css";
import "../../assets/css/Dashboard/quiz.css";
import { getQuizThemeDataThunk } from "../../redux/thunks/QuizThemeThunks";
import {
  deleteQuestionsDataThunk,
  getQuestionsDataThunk,
} from "../../redux/thunks/QuestionsThunks";

const MySwal = withReactContent(Swal);

function BankSoal() {
  //pagination states
  const [active, setActive] = useState(1);
  const [size, setSize] = useState(30); //pagination size
  const [trigger, setTrigger] = useState(false);
  const [questionPagination, setQuestionPagination] = useState();
  const [checked, setChecked] = useState([]);

  //redux methods
  const dispatch = useDispatch();
  const history = useHistory();

  //modal states & functions
  const [pageOpen, setPageOpen] = useState("");
  const [search, setSearch] = useState("");
  const [themeID, setThemeID] = useState("");
  const [questionData, setQuestionData] = useState("");
  const [show, setShow] = useState(false);
  const quiz_themes = useSelector((state) => state.quiz_themes);
  const quiztheme = quiz_themes.quizThemeData.items;

  useEffect(() => {
    dispatch(getQuizThemeDataThunk());
  }, []);

  const handleClose = () => {
    setShow(false);
    if (pageOpen === "Unggah" || pageOpen === "Edit")
      dispatch(
        getQuestionsDataThunk({ param: `?size=${size}&page=${active}&theme_id=${themeID}` })
      );
  };

  const handleShow = (page, value) => {
    setPageOpen(page);
    setQuestionData(value);
    setShow(true);
  };

  //redux taken from store
  const questions = useSelector((state) => state.questions);
  const questionsData = questions.questionsData;

  //functions
  useEffect(() => {
    AuthIsLogin(history);
    dispatch(getQuestionsDataThunk({ param: `?size=${size}&page=${active}` }));
  }, []);

  function paginationSelect(newActive) {
    setActive(newActive);
    dispatch(
      getQuestionsDataThunk({
        param: `?size=${size}&page=${newActive}&theme_id=${themeID}`,
      })
    );
  }

  useEffect(() => {
    if (questions.questionsDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
    }
    if (questions.questionsDataDelete === "success" && trigger) {
      setTrigger(false);
      dispatch(
        getQuestionsDataThunk({ param: `?size=${size}&page=${active}&theme_id=${themeID}` })
      );
    }
  }, [questions]);

  useEffect(() => {
    setQuestionPagination(questionsData.pagination);
    let newChecked = [];
    if (checked.length <= 0 && questionsData.pagination) {
      for (let i = 0; i < questionsData.pagination.totalItems; i++) {
        newChecked.push(false);
      }
      setChecked(newChecked);
    }
  }, [questionsData]);

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        // dispatch(deleteQuestionsData(id));
        dispatch(deleteQuestionsDataThunk(id));
      }
    });
  };

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }

  // handle enter pressed
  function keydownHandlerByTheme(e) {
    if (e.keyCode == 13) {
      setActive(1);
      dispatch(
        getQuestionsDataThunk({
          param: `?size=${size}&page=1&theme_id=${themeID}`,
        })
      );
    }
  }

  function onChangeSearchByTheme(e) {
    const newValue = e.target.value;
    setThemeID(newValue);
  }

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="w-50">
          <div className="bank-soal__title mb-2">Bank Soal</div>{" "}
          <div className="fw-400 my-2">
            Total Soal: {questionsData?.pagination?.totalItems}
          </div>
        </div>
        <div className="d-flex">
          <Button
            className="py-2 w-100 px-3 mb-4 bank-soal__primary"
            onClick={() => {
              handleShow("Unggah");
            }}
          >
            Unggah Soal
          </Button>
        </div>
      </div>

      <Card
        className="question-quest-wrapper-scrollbar mb-4"
        style={{ width: "100%" }}
      >
        <div className="d-flex layout-media">
          <InputGroup className="mb-3" style={{ width: "300px" }}>
            {/* <Form.Control
              type="text"
              id="search"
              name="search"
              value={search}
              onKeyDown={keydownHandler}
              onChange={onChangeSearch}
              placeholder="Cari Soal"
            /> */}
            <Form.Control
              type="text"
              id="search"
              name="search"
              value={themeID}
              onKeyDown={keydownHandlerByTheme}
              onChange={onChangeSearchByTheme}
              placeholder="Masukkan ID Tema"
            />
          </InputGroup>
          {questionPagination ? (
            <Pagination
              size={size}
              totalRows={questionPagination.totalItems}
              totalPages={questionPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table id="bank_soal" responsive hover>
          <thead>
            <tr>
              <th>No</th>
              <th>Tema Soal</th>
              <th>Judul</th>
              <th>Pertanyaan</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {questionsData.items
              ? questionsData.items.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td>{size * (active - 1) + (index + 1)}</td>
                      <td>{value.quiz_theme}</td>
                      <td>{value.title}</td>
                      <td className="text_wrapper__section">
                        {value.question}
                      </td>
                      <td className="">
                        {/* view */}
                        <Button
                          className="mb-2"
                          onClick={() => {
                            handleShow("Menampilkan", value);
                          }}
                          variant="info"
                        >
                          <VscPreview className="fill-white" />
                        </Button>
                        {/* edit */}
                        <Button
                          className="mb-2"
                          onClick={() => {
                            handleShow("Edit", value);
                          }}
                          variant="info"
                        >
                          <FaEdit className="fill-white" />
                        </Button>
                        {/* delete */}
                        <Button className="mb-2" variant="danger">
                          <RiDeleteBin2Line
                            onClick={() => {
                              handleDelete(value.question_id);
                            }}
                            className="fill-white"
                          />
                        </Button>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </Card>
      
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ overflow: "auto", borderRadius: "24px" }}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="modal-header__title px-2"
          >
            {pageOpen} Pertanyaan
          </Modal.Title>
          <div onClick={handleClose}>
            <FaTimes />
          </div>
        </Modal.Header>
        <Modal.Body className="px-0 py-1">
          {pageOpen === "Unggah" ? (
            // Upload CSV File Component
            <FileUploader />
          ) : pageOpen === "Menampilkan" ? (
            // Menampilkan CSV File Component
            <ViewQuestion questionData={questionData} />
          ) : (
            // Edit CSV File Component
            <EditQuestion
              id={questionData.question_id}
              handleClose={handleClose}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BankSoal;
