import {
  LEARNING_PATH_DATA,
  LEARNING_PATH_DATA_GET_SUCCESS,
  LEARNING_PATH_DATA_GET_ID_SUCCESS,
  LEARNING_PATH_DATA_FAIL,
  LEARNING_PATH_DATA_PUT,
  LEARNING_PATH_DATA_PUT_SUCCESS,
  LEARNING_PATH_DATA_POST,
  LEARNING_PATH_DATA_POST_SUCCESS,
  LEARNING_PATH_DATA_DELETE,
  LEARNING_PATH_DATA_DELETE_SUCCESS,
} from "../config/LearningPathConfig";

const intialState = {
  learningPathDataGet: [],
  learningPathDataGetId: [],
  learningPathDataPost: [],
  learningPathDataDelete: [],
  learningPathDataPut: [],
  isLoading: false,
};

export const LearningPathReducers = function (state = intialState, action) {
  switch (action.type) {
    case LEARNING_PATH_DATA:
      return { ...state, isLoading: true };
    case LEARNING_PATH_DATA_GET_SUCCESS:
      return { ...state, isLoading: false, learningPathDataGet: action.value };
    case LEARNING_PATH_DATA_GET_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningPathDataGetId: action.value,
      };
    case LEARNING_PATH_DATA_POST:
      return { ...state, isLoading: true };
    case LEARNING_PATH_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, learningPathDataPost: "success" };
    case LEARNING_PATH_DATA_DELETE:
      return { ...state, isLoading: true };
    case LEARNING_PATH_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, learningPathDataDelete: "success" };
    case LEARNING_PATH_DATA_PUT:
      return { ...state, isLoading: true };
    case LEARNING_PATH_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, learningPathDataPut: "success" };
    case LEARNING_PATH_DATA_FAIL:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
