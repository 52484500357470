import {
  // get
  SUB_CATEGORIES_DATA,
  SUB_CATEGORIES_DATA_SUCCESS,
  SUB_CATEGORIES_DATA_ID_SUCCESS,
  // post
  SUB_CATEGORIES_DATA_POST,
  SUB_CATEGORIES_DATA_POST_SUCCESS,
  SUB_CATEGORIES_DATA_POST_FAIL,
  // put
  SUB_CATEGORIES_DATA_PUT,
  SUB_CATEGORIES_DATA_PUT_SUCCESS,
  SUB_CATEGORIES_DATA_PUT_FAIL,
  // delete
  SUB_CATEGORIES_DATA_DELETE,
  SUB_CATEGORIES_DATA_DELETE_SUCCESS,
  SUB_CATEGORIES_DATA_DELETE_FAIL,
  // fail
  SUB_CATEGORIES_DATA_FAIL,
} from "../config/SubCategoriesConfig";

// get
export const getSubCategoriesData = (value) => ({
  type: SUB_CATEGORIES_DATA,
  value,
});
export const getSubCategoriesDataSuccess = (value) => ({
  type: SUB_CATEGORIES_DATA_SUCCESS,
  value,
});
export const getSubCategoriesDataIdSuccess = (value) => ({
  type: SUB_CATEGORIES_DATA_ID_SUCCESS,
  value,
});
export const getSubCategoriesDataFail = (value) => ({
  type: SUB_CATEGORIES_DATA_FAIL,
  value,
});

// post
export const postSubCategoriesData = (value) => ({
  type: SUB_CATEGORIES_DATA_POST,
  value,
});
export const postSubCategoriesDataSuccess = (value) => ({
  type: SUB_CATEGORIES_DATA_POST_SUCCESS,
  value,
});
export const postSubCategoriesDataFail = (value) => ({
  type: SUB_CATEGORIES_DATA_POST_FAIL,
  value,
});

// put
export const putSubCategoriesData = (value) => ({
  type: SUB_CATEGORIES_DATA_PUT,
  value,
});
export const putSubCategoriesDataSuccess = (value) => ({
  type: SUB_CATEGORIES_DATA_PUT_SUCCESS,
  value,
});
export const putSubCategoriesDataFail = (value) => ({
  type: SUB_CATEGORIES_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteSubCategoriesData = (value) => ({
  type: SUB_CATEGORIES_DATA_DELETE,
  value,
});
export const deleteSubCategoriesDataSuccess = (value) => ({
  type: SUB_CATEGORIES_DATA_DELETE_SUCCESS,
  value,
});
export const deleteSubCategoriesDataFail = (value) => ({
  type: SUB_CATEGORIES_DATA_DELETE_FAIL,
  value,
});

// fail handle
