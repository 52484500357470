import {
  //get
  PRODUCTS_DATA,
  PRODUCTS_DATA_ID_SUCCESS,
  PRODUCTS_DATA_SUCCESS,
  PRODUCTS_DATA_FAIL,
  //post
  PRODUCTS_DATA_POST,
  PRODUCTS_DATA_POST_SUCCESS,
  PRODUCTS_DATA_POST_FAIL,
  //put
  PRODUCTS_DATA_PUT,
  PRODUCTS_DATA_PUT_SUCCESS,
  PRODUCTS_DATA_PUT_FAIL,
  //delete
  PRODUCTS_DATA_DELETE,
  PRODUCTS_DATA_DELETE_SUCCESS,
  PRODUCTS_DATA_DELETE_FAIL,
  REMOVE_PRODUCTS_DATA,
} from "../config/ProductsConfig";

const intialState = {
  productsDataPost: null,
  productsDataPut: null,
  productsDataDelete: null,
  productsData: null,
  productsDataId: null,
  isLoading: false,
};

export const ProductsReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case PRODUCTS_DATA:
      return { ...state, isLoading: true };
    case PRODUCTS_DATA_SUCCESS:
      return { ...state, isLoading: false, productsData: action.value };
    case PRODUCTS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, productsDataId: action.value };
    case PRODUCTS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        productsData: "error",
        productsDataId: "error",
      };
    //post
    case PRODUCTS_DATA_POST:
      return {
        ...state,
        isLoading: true,
        productsDataPost: null,
        productsDataPut: null,
        productsDataDelete: null,
      };
    case PRODUCTS_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, productsDataPost: action.value };
    case PRODUCTS_DATA_POST_FAIL:
      return { ...state, isLoading: false, productsDataPost: "error" };

    //put
    case PRODUCTS_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        productsDataPost: null,
        productsDataPut: null,
        productsDataDelete: null,
      };
    case PRODUCTS_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, productsDataPut: "success" };
    case PRODUCTS_DATA_PUT_FAIL:
      return { ...state, isLoading: false, productsDataPut: "error" };

    //delete
    case PRODUCTS_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        productsDataPost: null,
        productsDataPut: null,
        productsDataDelete: null,
      };
    case PRODUCTS_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, productsDataDelete: "success" };
    case PRODUCTS_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, productsDataDelete: "error" };
    case REMOVE_PRODUCTS_DATA:
      return {
        ...state,
        productsDataDelete: null,
      };

    default:
      return state;
  }
};
