import {
  // get
  USERS_DATA,
  USERS_DATA_SUCCESS,
  USERS_DATA_ID_SUCCESS,
  USERS_DATA_ID_OTHER_SUCCESS,
  // post
  USERS_DATA_POST,
  USERS_DATA_POST_SUCCESS,
  USERS_DATA_POST_FAIL,
  // put
  USERS_DATA_PUT,
  USERS_DATA_PUT_SUCCESS,
  USERS_DATA_PUT_FAIL,
  // delete
  USERS_DATA_DELETE,
  USERS_DATA_DELETE_SUCCESS,
  USERS_DATA_DELETE_FAIL,
  // fail
  USERS_DATA_FAIL, // change password
  USERS_CHANGE_PASSWORD,
  USERS_CHANGE_PASSWORD_SUCCESS,
  USERS_CHANGE_PASSWORD_FAIL,
} from "../config/UsersConfig";

// get
export const getUsersData = (value) => ({
  type: USERS_DATA,
  value,
});
export const getUsersDataSuccess = (value) => ({
  type: USERS_DATA_SUCCESS,
  value,
});
export const getUsersDataIdSuccess = (value) => ({
  type: USERS_DATA_ID_SUCCESS,
  value,
});
export const getUsersDataIdOtherSuccess = (value) => ({
  type: USERS_DATA_ID_OTHER_SUCCESS,
  value
});
export const getUsersDataFail = (value) => ({
  type: USERS_DATA_FAIL,
  value,
});

// post
export const postUsersData = (value) => ({
  type: USERS_DATA_POST,
  value,
});
export const postUsersDataSuccess = (value) => ({
  type: USERS_DATA_POST_SUCCESS,
  value,
});
export const postUsersDataFail = (value) => ({
  type: USERS_DATA_POST_FAIL,
  value,
});

// put
export const putUsersData = (value) => ({
  type: USERS_DATA_PUT,
  value,
});
export const putUsersDataSuccess = (value) => ({
  type: USERS_DATA_PUT_SUCCESS,
  value,
});
export const putUsersDataFail = (value) => ({
  type: USERS_DATA_PUT_FAIL,
  value,
});
// change password
export const changePassword = (value) => ({
  type: USERS_CHANGE_PASSWORD,
  value,
});
export const changePasswordSuccess = (value) => ({
  type: USERS_CHANGE_PASSWORD_SUCCESS,
  value,
});
export const changePasswordFail = (value) => ({
  type: USERS_CHANGE_PASSWORD_FAIL,
  value,
});
// delete
export const deleteUsersData = (value) => ({
  type: USERS_DATA_DELETE,
  value,
});
export const deleteUsersDataSuccess = (value) => ({
  type: USERS_DATA_DELETE_SUCCESS,
  value,
});
export const deleteUsersDataFail = (value) => ({
  type: USERS_DATA_DELETE_FAIL,
  value,
});

// fail handle
