import React, { useState, useEffect } from "react";
import { AuthIsLogin } from "../../helper/auth";
import { Table, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  deleteInboxDataThunk,
  getInboxDataThunk,
} from "../../redux/thunks/InboxThunks";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin2Line } from "react-icons/ri";
import { VscPreview } from "react-icons/vsc";
import { SkSwal } from "../../components/common/SkSwal";
import SkModal from "../../components/common/SkModal";
import Pagination from "../../components/Layout/Pagination";
import moment from "moment";
import { FaEye } from "react-icons/fa";

function Inbox() {
  const history = useHistory();
  const dispatch = useDispatch();
  // get state from store
  const inbox = useSelector((state) => state.inbox);
  const inboxData = inbox.inboxData;
  const inboxDataId = inbox.inboxDataId;
  // pagination states
  const [pagination, setPagination] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [size, setSize] = useState(10);

  const [trigger, setTrigger] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "Modal Title",
    show: false,
    body: "",
  });

  useEffect(() => {
    AuthIsLogin(history);
    dispatch(getInboxDataThunk());
  }, []);

  useEffect(() => {
    if (inbox.inboxDataDelete === "error" && trigger) {
      setTrigger(false);
      SkSwal({
        title: "Terjadi masalah saat menghapus data",
        showConfirm: true,
        confirmText: "OK",
      });
    }
    if (inbox.inboxDataDelete === "success" && trigger) {
      setTrigger(false);
      dispatch(
        // getInboxDataThunk({ param: `?size=${size}&page=${active}` })
        getInboxDataThunk()
      );
    }
  }, [inbox]);

  useEffect(() => {
    setModalContent({
      ...modalContent,
      body: inboxDataId ? (
        inboxDataId !== "error" ? (
          <>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <b>Alamat</b>
                  <p>{inboxDataId.address}</p>
                </div>
                <div className="form-group">
                  <b>Ukuran Perusahaan</b>
                  <p>{inboxDataId.company_size}</p>
                </div>
                <div className="form-group">
                  <b>Tanggal</b>
                  <p>
                    {moment(inboxDataId.created_at).format(
                      "DD-MMMM-YYYY HH:mm:ss"
                    )}
                  </p>
                </div>
                <div className="form-group">
                  <b>Email</b>
                  <p>{inboxDataId.email}</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <b>Nama</b>
                  <p>{inboxDataId.name}</p>
                </div>
                <div className="form-group">
                  <b>Division</b>
                  <p>{inboxDataId.work_division}</p>
                </div>
                <div className="form-group">
                  <b>Phone</b>
                  <p>{inboxDataId.phone}</p>
                </div>
                <div className="form-group">
                  <b>Pesan</b>
                  <p>{inboxDataId.message}</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>Error</>
        )
      ) : (
        <>Loading...</>
      ),
    });
  }, [inboxDataId]);

  useEffect(() => {
    if (inboxData?.pagination) setPagination(inboxData.pagination);
  }, [inboxData]);

  const handleDelete = (id) => {
    SkSwal({
      title: "Anda yakin ingin menghapus ?",
      showConfirm: true,
      showCancel: true,
      callback: () => {
        setTrigger(true);
        dispatch(deleteInboxDataThunk(id));
      },
    });
  };

  const handleShow = (id) => {
    dispatch(getInboxDataThunk(id));
    setModalContent({
      ...modalContent,
      show: true,
      title: "Menampilkan Pesan",
    });
  };

  const paginationSelect = (newActive) => {
    setCurrPage(newActive);
    dispatch(getInboxDataThunk({ param: `?size=${size}&page=${newActive}` }));
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="bank-soal__title mb-2">Inbox</div>
            <div className="fw-400 my-2">
              Total Items: {inboxData && inboxData?.pagination.totalItems}
            </div>
          </div>
        </div>
        <Card
          className="question-quest-wrapper-scrollbar mb-4"
          style={{ width: "100%" }}
        >
          <div className="row ">
            <div className="col-sm-12 d-flex justify-content-end mb-3">
              {pagination ? (
                <Pagination
                  size={size}
                  totalRows={pagination.totalItems}
                  totalPages={pagination.totalPages}
                  paginationSelect={paginationSelect}
                  active={currPage}
                />
              ) : null}
            </div>
            <div className="col-sm-12">
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama</th>
                    <th>Email</th>
                    <th>Pesan</th>
                    <th>Tanggal</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {inboxData &&
                    inboxData?.items.map((e, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{e.name}</td>
                            <td>{e.email}</td>
                            <td>{e.message}</td>
                            <td>
                              {" "}
                              {moment(e.created_at).format(
                                "DD-MMMM-YYYY HH:mm:ss"
                              )}
                            </td>
                            <td className="display-grid">
                              <Button
                                className="mb-2"
                                variant="danger"
                                onClick={() => handleDelete(e.message_id)}
                              >
                                <RiDeleteBin2Line className="fill-white" />
                              </Button>
                              <Button
                                className="mb-2"
                                variant="info"
                                onClick={() => handleShow(e.message_id)}
                              >
                                <FaEye className="fill-white" />
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </div>
        </Card>
      </div>
      <SkModal content={modalContent} button={false} />
    </>
  );
}

export default Inbox;
