export const MEMBERSHIPS_DATA_LINK = "membership_catalogue";

//get
export const MEMBERSHIPS_DATA = "MEMBERSHIPS_DATA";
export const MEMBERSHIPS_DATA_SUCCESS = "MEMBERSHIPS_DATA_SUCCESS";
export const MEMBERSHIPS_DATA_ID_SUCCESS = "MEMBERSHIPS_DATA_ID_SUCCESS";
export const MEMBERSHIPS_DATA_FAIL = "MEMBERSHIPS_DATA_FAIL";

//post
export const MEMBERSHIPS_DATA_POST = "MEMBERSHIPS_DATA_POST";
export const MEMBERSHIPS_DATA_POST_SUCCESS = "MEMBERSHIPS_DATA_POST_SUCCESS";
export const MEMBERSHIPS_DATA_POST_FAIL = "MEMBERSHIPS_DATA_POST_FAIL";

// put
export const MEMBERSHIPS_DATA_PUT = "MEMBERSHIPS_DATA_PUT";
export const MEMBERSHIPS_DATA_PUT_SUCCESS = "MEMBERSHIPS_DATA_PUT_SUCCESS";
export const MEMBERSHIPS_DATA_PUT_FAIL = "MEMBERSHIPS_DATA_PUT_FAIL";

// delete
export const MEMBERSHIPS_DATA_DELETE = "MEMBERSHIPS_DATA_DELETE";
export const MEMBERSHIPS_DATA_DELETE_SUCCESS =
  "MEMBERSHIPS_DATA_DELETE_SUCCESS";
export const MEMBERSHIPS_DATA_DELETE_FAIL = "MEMBERSHIPS_DATA_DELETE_FAIL";
export const REMOVE_MEMBERSHIPS_DATA = "REMOVE_MEMBERSHIPS_DATA";
