import { PromiseService } from "../../services/PromiseService";
import { QUIZ_THEME_DATA_LINK } from "../config/QuizThemeConfig";
import {
  // get
  getQuizThemeData,
  getQuizThemeDataSuccess,
  getQuizThemeDataFail,
  //live
  getQuizThemeLiveSearchData,
  getQuizThemeLiveSearchDataSuccess,
  getQuizThemeLiveSearchDataFail,
  // post
  postQuizThemeData,
  postQuizThemeDataSuccess,
  postQuizThemeDataFail,
  // put
  putQuizThemeData,
  putQuizThemeDataSuccess,
  putQuizThemeDataFail,
  //delete
  deleteQuizThemeData,
  deleteQuizThemeDataSuccess,
  deleteQuizThemeDataFail,
} from "../actions/QuizThemeActions";
// get
export const getQuizThemeDataThunk = (data) => async (dispatch) => {
  dispatch(getQuizThemeData());
  if (data) {
    try {
      const response = await PromiseService.getData(
        `${QUIZ_THEME_DATA_LINK}${data.param}`
      );

      if (response.status === 200 && response.data) {
        dispatch(getQuizThemeDataSuccess(response.data));
      } else {
        dispatch(getQuizThemeDataFail(response));
      }
    } catch (error) {
      dispatch(getQuizThemeDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(QUIZ_THEME_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getQuizThemeDataSuccess(response.data));
      } else {
        dispatch(getQuizThemeDataFail(response));
      }
    } catch (error) {
      dispatch(getQuizThemeDataFail(error));
    }
  }
};

// post
export const postQuizThemeDataThunk = (data) => async (dispatch) => {
  dispatch(postQuizThemeData());
  const body = data;
  try {
    const response = await PromiseService.postData(QUIZ_THEME_DATA_LINK, body);

    if (response.status === 201 && response.data) {
      dispatch(postQuizThemeDataSuccess(response.data));
    } else {
      dispatch(postQuizThemeDataFail(response));
    }
  } catch (error) {
    dispatch(postQuizThemeDataFail(error));
  }
};
// put
export const putQuizThemeDataThunk = (data) => async (dispatch) => {
  dispatch(putQuizThemeData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${QUIZ_THEME_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putQuizThemeDataSuccess(response.data));
    } else {
      dispatch(putQuizThemeDataFail(response));
    }
  } catch (error) {
    dispatch(putQuizThemeDataFail(error));
  }
};
// delete
export const deleteQuizThemeDataThunk = (data) => async (dispatch) => {
  dispatch(deleteQuizThemeData());
  const body = data;
  try {
    const response = await PromiseService.deleteData(
      `${QUIZ_THEME_DATA_LINK}/${body}`
    );

    if (response.status === 200 && response.data) {
      dispatch(deleteQuizThemeDataSuccess(response.data));
    } else {
      dispatch(deleteQuizThemeDataFail(response));
    }
  } catch (error) {
    dispatch(deleteQuizThemeDataFail(error));
  }
};

//get live search

export const getQuizThemeLiveSearchDataThunk = (data) => async (dispatch) => {
  dispatch(getQuizThemeLiveSearchData(data));
  let url = `${QUIZ_THEME_DATA_LINK}${data}`;
  try {
    const response = await PromiseService.getData(url);
    if (response.status === 200 && response.data) {
      dispatch(getQuizThemeLiveSearchDataSuccess(response.data));
    } else {
      dispatch(getQuizThemeLiveSearchDataFail(response));
    }
  } catch (error) {
    dispatch(getQuizThemeLiveSearchDataFail(error));
  }
};
