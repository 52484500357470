import {
  COURSES_DATA,
  COURSES_DATA_POST,
  COURSES_DATA_POST_SUCCESS,
  COURSES_DATA_PUT,
  COURSES_DATA_PUT_SUCCESS,
  COURSES_DATA_DELETE,
  COURSES_DATA_DELETE_SUCCESS,
  COURSES_DATA_SUCCESS,
  COURSES_DATA_FAIL,
  COURSES_DATA_FAIL_PUT,
  COURSES_DATA_ID_SUCCESS,
} from "../config/CoursesConfig";

const intialState = {
  coursesDataPut: [],
  coursesDataPost: [],
  coursesDataDelete: [],
  courseData: [],
  coursesDataId: [],
  isLoading: false,
};
export const CoursesReducers = function (state = intialState, action) {
  switch (action.type) {
    case COURSES_DATA_POST:
      return { ...state, isLoading: true };
    case COURSES_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, coursesDataPost: "success" };
    case COURSES_DATA_DELETE:
      return { ...state, isLoading: true };
    case COURSES_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, coursesDataDelete: "success" };
    case COURSES_DATA_PUT:
      return { ...state, isLoading: true };
    case COURSES_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, coursesDataPut: "success" };
    case COURSES_DATA:
      return { ...state, isLoading: true };
    case COURSES_DATA_SUCCESS:
      return { ...state, isLoading: false, courseData: action.value };
    case COURSES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, coursesDataId: action.value };
    case COURSES_DATA_FAIL:
      return { ...state, isLoading: false, coursesDataPost: "error" };
    case COURSES_DATA_FAIL_PUT:
      return { ...state, isLoading: false, coursesDataPut: "error" };
    default:
      return state;
  }
};
