import {
  // get
  SCHEMES_DATA,
  SCHEMES_DATA_SUCCESS,
  SCHEMES_DATA_ID_SUCCESS,
  // post
  SCHEMES_DATA_POST,
  SCHEMES_DATA_POST_SUCCESS,
  SCHEMES_DATA_POST_FAIL,
  // put
  SCHEMES_DATA_PUT,
  SCHEMES_DATA_PUT_SUCCESS,
  SCHEMES_DATA_PUT_FAIL,
  // delete
  SCHEMES_DATA_DELETE,
  SCHEMES_DATA_DELETE_SUCCESS,
  SCHEMES_DATA_DELETE_FAIL,
  // fail
  SCHEMES_DATA_FAIL,
  REMOVE_SCHEMES_DATA,
} from "../config/SchemesConfig";

// get
export const getSchemesData = (value) => ({
  type: SCHEMES_DATA,
  value,
});
export const getSchemesDataSuccess = (value) => ({
  type: SCHEMES_DATA_SUCCESS,
  value,
});
export const getSchemesDataIdSuccess = (value) => ({
  type: SCHEMES_DATA_ID_SUCCESS,
  value,
});
export const getSchemesDataFail = (value) => ({
  type: SCHEMES_DATA_FAIL,
  value,
});

// post
export const postSchemesData = (value) => ({
  type: SCHEMES_DATA_POST,
  value,
});
export const postSchemesDataSuccess = (value) => ({
  type: SCHEMES_DATA_POST_SUCCESS,
  value,
});
export const postSchemesDataFail = (value) => ({
  type: SCHEMES_DATA_POST_FAIL,
  value,
});

// put
export const putSchemesData = (value) => ({
  type: SCHEMES_DATA_PUT,
  value,
});
export const putSchemesDataSuccess = (value) => ({
  type: SCHEMES_DATA_PUT_SUCCESS,
  value,
});
export const putSchemesDataFail = (value) => ({
  type: SCHEMES_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteSchemesData = (value) => ({
  type: SCHEMES_DATA_DELETE,
  value,
});
export const deleteSchemesDataSuccess = (value) => ({
  type: SCHEMES_DATA_DELETE_SUCCESS,
  value,
});
export const deleteSchemesDataFail = (value) => ({
  type: SCHEMES_DATA_DELETE_FAIL,
  value,
});
export const removeSchemesData = (value) => ({
  type: REMOVE_SCHEMES_DATA,
  value,
});
// fail handle
