import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Form, Modal } from "react-bootstrap";
import { FaEnvelope } from "react-icons/fa";
import { AuthData, AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Personal/Profile.css";
import { useDispatch, useSelector } from "react-redux";
import {
  putUsersDataThunk,
  getUsersDataThunk,
} from "../../redux/thunks/UsersThunks";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
function Profile() {
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState({
    name: "",
    company: "",
    division: "",
    email: "",
  });
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  const users = useSelector((state) => state.users);

  const dispatch = useDispatch();
  useEffect(() => {
    setData({
      name: users.usersDataId.name,
      company: users.usersDataId.company,
      division: users.usersDataId.division,
      email: users.usersDataId.email,
    });
  }, [users]);

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };
  useEffect(() => {
    if (users.usersDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(true, "Terjadi masalah saat edit data", false);
    }
    if (users.usersDataPut === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil mengubah data", true, "Ok");
    }
  }, [users]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setTrigger(true);
    let req = {};
    req.body = {
      name: data.name,
      company: data.company,
      division: data.division,
    };
    req.id = users.usersDataId.user_id;
    dispatch(putUsersDataThunk(req));
  };
  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        dispatch(getUsersDataThunk(AuthData().userId));
      }
    });
  }
  return (
    <Container className="dashboard_page__container">
      <div className="course_create__title mb-3">Profile Saya</div>
      <Row>
        <Col sm={12} md={4}>
          <div className="profile__top_wrapper">
            <div className="profile__name_wrapper">
              <div className="profile__name">{data?.name}</div>
              <div className="profile__join_date">
                Bergabung {moment(data.created_at).format("MMMM YYYY")}
              </div>{" "}
            </div>
            <div className="profile__kontak_wrapper">
              <div className="profile__kontak">Kontak</div>
              <div className="d-flex align-items-center">
                <div className="profile_icon__wrapper satukelas_flex ">
                  <FaEnvelope />
                </div>
                <div className="profile_email">{data?.email}</div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={8}>
          <div className="biodata_wrapper">
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col sm={12} md={12}>
                  <Form.Group>
                    <Form.Label className="profile_label_input">
                      Nama Lengkap
                    </Form.Label>
                    <Form.Control
                      className="satukelas_input w-100"
                      onChange={handleChange}
                      type="text"
                      id="name"
                      name="name"
                      value={data?.name}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={12}>
                  <Form.Group>
                    <Form.Label className="profile_label_input">
                      Nama Kantor
                    </Form.Label>
                    <Form.Control
                      className="satukelas_input w-100"
                      onChange={handleChange}
                      type="text"
                      id="company"
                      name="company"
                      value={data?.company}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={12}>
                  <Form.Group>
                    <Form.Label className="profile_label_input">
                      Jabatan
                    </Form.Label>
                    <Form.Control
                      className="satukelas_input w-100"
                      onChange={handleChange}
                      type="text"
                      id="division"
                      name="division"
                      value={data?.division}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="mt-3">
                  <div className="profile_biodata_btn__wrapper d-flex">
                    <Button
                      onClick={() => setData(data)}
                      className="profile_biodata_btn secondary mr-4"
                    >
                      Batal
                    </Button>
                    <Button
                      type="submit"
                      className="profile_biodata_btn primary"
                    >
                      Ubah
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
      {/* <Modal id="topup" centered show={topup} onHide={() => setTopup(false)}>
        <Topup setTopup={setTopup} />
      </Modal>
      <ModalPembayaran
        id="tp"
        content={modalContent}
        handleVerify={handleVerify}
        paymentsMethod={paymentsMethod}
        handleCopy={handleCopy}
      /> */}
    </Container>
  );
}

export default Profile;
