import React from "react";
import Switch from "react-switch";
import { FaHeart, FaBars, FaPowerOff } from "react-icons/fa";
import reactLogo from "../../assets/images/logo.svg";
import Header from "./Header";
import "../../assets/css/Layout/main.css";
import { AuthLogout } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Main = ({
  collapsed,
  rtl,
  image,
  handleToggleSidebar,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
  InnerComponent,
}) => {
  const history = useHistory();
  const handleLogout = () =>
    MySwal.fire({
      title: "Anda yakin ingin keluar ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        AuthLogout(history);
      }
    });
  return (
    <main>
      <div className="btn-toggle d-flex justify-content-between w-100">
        <div onClick={() => handleToggleSidebar(true)}>
          <FaBars />
        </div>
        <div className="logout_button">
          <FaPowerOff onClick={handleLogout} />
        </div>
      </div>
      <header>
        <Header />
      </header>
      <InnerComponent />
      <footer>
        <small>
          © 2021 powered by -{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://azouaoui.netlify.com"
          >
            Koneksi Group
          </a>
        </small>
        <br />
      </footer>
    </main>
  );
};

export default Main;
