import React, { useEffect, useState } from "react";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import { Table, Form, Card, InputGroup, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaTrash } from "react-icons/fa";
import Pagination from "../../components/Layout/Pagination";
import {
  deleteMembershipsDataThunk,
  getMembershipsDataThunk,
  putMembershipsDataThunk,
} from "../../redux/thunks/MembershipsThunks";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { removeMembershipsData } from "../../redux/actions/MembershipsActions";
import { SkValidate } from "../../components/common/SkValidate";
import { getLearningPathDataThunk } from "../../redux/thunks/LearningPathThunks";

const MySwal = withReactContent(Swal);
function TableMembership() {
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const [size, setSize] = useState(12); //pagination size
  const [dataMembership, setDataMembership] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState();
  const [membershipsPagination, setMembershipsPagination] = useState();
  const memberships = useSelector((state) => state.memberships);
  const learning_paths = useSelector((state) => state.learning_paths);
  const membershipsData = memberships?.membershipsData;
  const history = useHistory();
  useEffect(() => {
    dispatch(getMembershipsDataThunk());
    if (show) {
      dispatch(getLearningPathDataThunk());
    }
  }, []);
  const handleDelete = (e, value) => {
    e.preventDefault();
    setTrigger(true);
    displayPopup(
      true,
      "Apakah anda yakin akan menghapus item terpilih?",
      "Ya",
      value
    );
  };

  function displayPopup(cancel, title, confirm, valueDelete) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value.isConfirmed) {
        if (valueDelete !== undefined) {
          dispatch(deleteMembershipsDataThunk(valueDelete?.catalogue_id));
          dispatch(getMembershipsDataThunk());
        } else {
          dispatch(removeMembershipsData());
          dispatch(getMembershipsDataThunk());
        }
      }
    });
  }
  useEffect(() => {
    if (memberships?.membershipsDataDelete === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil Menghapus Membership", "Oke");
    }
    if (memberships?.membershipsDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Gagal Menghapus Membership", "Oke");
    }
    if (memberships?.membershipsDataPut === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil Mengedit Membership", "Oke");
      setShow(false);
    }
    if (memberships?.membershipsDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Gagal Mengedit Membership", "Oke");
      setShow(false);
    }
  }, [memberships]);

  useEffect(() => {
    setMembershipsPagination(memberships?.membershipsData?.pagination);
  }, [memberships]);

  const handleAddMembership = () => {
    history.push("/dashboard/membership/create");
  };
  const handleEdit = (value) => {
    setShow(true);
    setEdit(value);
  };
  const constraints = {
    title: {
      presence: true,
    },
    description: {
      presence: true,
    },
    duration: {
      presence: true,
    },
    price: {
      presence: true,
    },
  };
  const handleChange = (e) => {
    e.preventDefault();
    const newData = { ...edit };
    newData[e.target.id] = e.target.value;
    setEdit(newData);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let req = { body: edit, id: edit?.catalogue_id };
      setTrigger(true);
      dispatch(putMembershipsDataThunk(req));
    }
  };

  useEffect(() => {}, [edit]);
  function paginationSelect(newActive) {
    setActive(newActive);
    // dispatch(getUsersData({ param: `?size=${size}&page=${newActive}` }));
    dispatch(
      getMembershipsDataThunk({
        param: `?size=${size}&page=${newActive}`,
      })
    );
  }

  return (
    <div className="">
      <div className="d-flex justify-content-between">
        <div>
          <div className="bank-soal__title mb-2">List Membership</div>{" "}
          <div className="fw-400 my-2">
            Total Membership: {membershipsData?.items?.length}
          </div>
        </div>
        <div>
          <Button onClick={handleAddMembership}>+ Tambah Membership</Button>
        </div>
      </div>

      <Card className="card_inside__padding">
        <div className="d-flex layout-media mb-3">
          <div></div>
          {membershipsPagination ? (
            <Pagination
              size={size}
              totalRows={membershipsPagination.totalItems}
              totalPages={membershipsPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              {/* 
              <th>Pilih</th>*/}
              <th>Nama Membership</th>
              <th>ID Membership</th>
              <th>Durasi (hari)</th>
              <th>Harga</th>
              <th>Deskripsi</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {membershipsData?.items?.length &&
              membershipsData?.items?.map((item, index) => (
                <tr key={index}>
                  <td>{item.title}</td>
                  <td>{item.catalogue_id}</td>
                  <td>{item.duration}</td>
                  <td>{item.price}</td>
                  <td>{item.description}</td>
                  <td>
                    {" "}
                    <Button
                      onClick={() => handleEdit(item)}
                      className="mb-2 mr-2"
                      variant="info"
                    >
                      <FaEdit className="fill-white" />
                    </Button>
                    <Button
                      onClick={(e) => handleDelete(e, item)}
                      className="mb-2"
                      variant="danger"
                    >
                      <FaTrash className="fill-white" />
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card>
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Membership</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* Judul */}
            <Form.Group className="mb-3">
              <Form.Label className="fw-600">Judul</Form.Label>
              <Form.Control
                onChange={handleChange}
                id="title"
                name="title"
                placeholder="Masukkan Judul Membership"
                value={edit?.title}
                type="text"
              />
              <div className="messages"></div>
            </Form.Group>

            {/* Deskripsi */}
            <Form.Group className="mb-4">
              <Form.Label className="fw-600">Deskripsi</Form.Label>
              <Form.Control
                onChange={handleChange}
                id="description"
                name="description"
                placeholder="Masukkan Deskripsi Memberhsip"
                value={edit?.description}
                as="textarea"
                rows={3}
              />
              <div className="messages"></div>
            </Form.Group>
            {/* Durasi Membership */}
            <Form.Group className="mb-3">
              <Form.Label className="fw-600">
                Durasi Membership (hari)
              </Form.Label>
              <Form.Control
                onChange={handleChange}
                id="duration"
                name="duration"
                placeholder="Masukkan Durasi Membership"
                value={edit?.duration}
                type="text"
              />
              <div className="messages"></div>
            </Form.Group>
            {/* Harga Membership */}
            <Form.Group className="mb-3">
              <Form.Label className="fw-600">Harga Membership</Form.Label>
              <Form.Control
                onChange={handleChange}
                id="price"
                name="price"
                placeholder="Masukkan Harga Membership"
                value={edit?.price}
                type="text"
              />
              <div className="messages"></div>
            </Form.Group>

            <div className="d-flex mt-4 justify-content-end">
              <Button type="submit" className="my-4 mx-2">
                Edit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TableMembership;
